import React from 'react';
import classNames from 'classnames';
import { useEddConext } from '../../../context/EddContext';
import { EddRa } from '../../../context/model.type';
import { checkActiveRaScore } from '../util';
import './ProductRisk.scss';

interface Props {
  use_ra: boolean;
}
function ProductRisk({ use_ra }: Props) {
  const { edit, state, setState } = useEddConext();
  const { service, edd_category } = state.data;
  const serviceCategory = edd_category.find((el) => el.edd_type === 'service') as EddRa.EddCategory;

  const totalScore = service.reduce((acc, cur) => acc + cur.score, 0);
  const availableScore = 100 - totalScore;

  const checkScore = checkActiveRaScore(service);
  const activeAtLeastOne = service.some((el) => el.active);

  const error = (use_ra && (availableScore !== 0 || !checkScore)) || (serviceCategory.active && !activeAtLeastOne);

  const errorMessage = (() => {
    if (serviceCategory.active && !activeAtLeastOne) return '최소 1개 항목은 활성화 되어야 합니다.';
    if (use_ra && (availableScore !== 0 || !checkScore)) return '남은 점수는 0점이 되어야 합니다.';
    return '';
  })();

  return (
    <article className={classNames('edd-setting-product-risk', { error, disabled: !serviceCategory.active })}>
      <div className="title" onClick={() => setState({ type: 'detailPage', payload: 'service' })}>
        <h4>상품 및 서비스 위험</h4>
        <div className="error-message">{errorMessage && <div className="message">{errorMessage}</div>}</div>
        {use_ra && (
          <div className="score">
            <>
              <div>
                <span>부여 점수</span>
                <span>{totalScore}점</span>
              </div>
              <div>
                {edit && (
                  <>
                    <span>남은 점수</span>
                    <span className={classNames('extra-score', { error: availableScore !== 0 })}>
                      {availableScore}점
                    </span>
                  </>
                )}
              </div>
            </>
          </div>
        )}
        <i />
      </div>
    </article>
  );
}

export default ProductRisk;
