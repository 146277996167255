import { useEffect, useState } from 'react';

export const useInputHook = (direction: string, text: string) => {
  const [subcodeLeft, setSubcodeLeft] = useState(direction === 'left' ? text : '');
  const [subcodeRight, setSubcodeRight] = useState(direction === 'right' ? text : '');

  useEffect(() => {
    if (direction === 'left') setSubcodeLeft(text);
    if (direction === 'right') setSubcodeRight(text);
  }, [direction, text]);

  return { subcodeLeft, setSubcodeLeft, subcodeRight, setSubcodeRight };
};
