import React, { useContext } from 'react';
import { Button, Dialog } from 'usebeauty';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import { DownArrow } from '@Icons';
import { State } from '../../AuthenticateContent';
import './Di_Identification.scss';

interface Props {
  state: State;
  loadHandler: () => void;
  closeHandler: () => void;
}

const Di_Identification: React.FC<Props> = ({ state, loadHandler, closeHandler }) => {
  const {
    vas: { identification },
  } = state;
  const { set } = Alert.Context();
  const { setLoading } = useContext(ContentContext);

  const submitHandler = async () => {
    setLoading(true);
    await process(
      api.reqData({ url: 'configs', data: { identification: String(!identification), auto_correction_birth: 'true' } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        loadHandler();
        closeHandler();

        if (response) {
          set({ success: `정보 수신이 ${!identification ? '중지' : '설정'}되었습니다.` });
        } else {
          set({ fail: `정보 수신이 설정변경이 실패하였습니다.` });
        }
        setLoading(false);
        return response;
      })
    );
  };

  return (
    <Dialog.Base id="identification__dialog__wrapper" st={{ width: 500, gap: 16 }}>
      <Dialog.Summary
        st={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        사용자 필수 정보 수신
      </Dialog.Summary>
      <Dialog.Content>
        <div className="main">
          <p>사용자 필수 정보 수신을 {!identification ? '중지' : '사용'}하시겠습니까?</p>
          <p>사용 여부를 전환할 경우 서비스에 바로 반영됩니다.</p>
        </div>
        <div className="sub">
          {!identification ? '사용' : '사용 안 함'} <DownArrow />
          <span className="accent">{!identification ? '사용 안 함' : '사용'}</span>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button ripple={false} className="cancel" onClick={closeHandler}>
          취소
        </Button>
        <Button ripple={false} onClick={submitHandler}>
          {!identification ? '사용 안 함' : '사용'}
        </Button>
      </Dialog.Actions>
    </Dialog.Base>
  );
};

export default Di_Identification;
