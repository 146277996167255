import { margin, padding } from 'common/functions';
import styled, { css } from 'styled-components';
import RootContent from './root/content/Content';
import RootManage from './root/manage/Manage';
import RootNationManage from './root/manage/NationManage';
import RootTemplateManage from './root/manage/TemplateManage';

import AdminContent from './admin/content/Content';
import AdminManageAuthentcation from './admin/manage/ManageAuthencation';
import AdminManageOther from './admin/manage/ManageOther';
import FaceManage from './admin/manage/face/Manage';
import EddManage from './admin/manage/edd/Manage';

import ReviewerContent from './reviewer/content/Content';

export { default as Page } from './page/Page';

export { LModal as modal } from './modal';

export const root = {
  Content: RootContent,
  Manage: RootManage,
  SubManage: { NationManage: RootNationManage, TemplateManage: RootTemplateManage },
};
export const admin = {
  Content: AdminContent,
  Manage: { Authencation: AdminManageAuthentcation, Other: AdminManageOther },
  Face: FaceManage,
  Edd: EddManage,
};
export const reviewer = { Content: ReviewerContent };

type Margin = { margin?: string; marginTop?: number; marginBottom?: number; marginLeft?: number; marginRight?: number };
type Padding = {
  padding?: string;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
};

export const Layer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['marginTop', 'marginRight', 'marginBottom', 'marginLeft'].includes(prop),
})<{ styles?: Margin & Padding }>`
  ${(props) => {
    if (props.styles)
      return css`
        ${margin(props.styles)}
        ${padding(props.styles)}
      `;
    return '';
  }}
`;
