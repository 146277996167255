import React, { Dispatch } from 'react';
import type { Action, State } from '../GeneralContent';
import GeneralSetting from './GeneralSetting';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const CtGeneralSetting: React.FC<Props> = ({ state, setState, loadHandler }) => {
  return <GeneralSetting state={state} setState={setState} loadHandler={loadHandler} />;
};

export default CtGeneralSetting;
