import React, { useReducer } from 'react';
import { Layer, modal } from 'layout';
import { api, process } from 'common/functions';
import { Alert, Input, TextArea } from 'common/components';
import { produce } from 'immer';
import type { Data as RowData } from '../../CtPolicyTable';
// import type { Data } from '../Expand';
import './Modal_ModifyPolicy.scss';

type Action = { type: 'loading'; payload: boolean } | { type: 'content'; payload: string };
export interface State {
  loading: boolean;
  content: string;
}

const initialState: State = { loading: false, content: '' };
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'loading': draft['loading'] = action.payload; break;
      case 'content': draft['content'] = action.payload; break;
    }
  });
};

interface Props {
  data: string;
  rowData: RowData;
  closeHandler: () => void;
  getExpandData: (type?: 'update') => void;
}

const ModalModifyPolicy: React.FC<Props> = ({ closeHandler, rowData, getExpandData, ...rest }) => {
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState, (init) => ({ ...init, content: rest.data }));
  const { content } = state;

  const submitHandler = () => {
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({
        url: `guides/${rowData.key}`,
        data: { content: btoa(encodeURIComponent(JSON.stringify({ policy: { content } }))) },
      }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          closeHandler();
          set({ success: '약관 편집이 완료되었습니다.' });
          getExpandData('update');
        }
      })
    );
  };

  const active = content.length !== rest.data.length ? true : api.differ(content, rest.data);

  return (
    <modal.Container id="root-page-guidance-modify-policy" styles={{ width: 720, maxHeight: 600 }}>
      <modal.Header>약관 편집</modal.Header>
      <modal.Content>
        <Layer styles={{ marginTop: 8, marginBottom: 20 }}>
          <modal.Heading>약관 Key</modal.Heading>
          <Input
            placeholder="약관 Key를 입력하세요"
            className="basic"
            value={rowData.key}
            styles={{ width: '100%' }}
            readonly
          />
        </Layer>

        <modal.Heading>약관 내용 (HTML)</modal.Heading>
        <Layer styles={{ marginTop: 8, marginBottom: 20 }} className="terms-layer">
          <div className="content">
            <TextArea
              placeholder="약관 내용은 HTML 형태로 입력하세요."
              styles={{ width: '100%', height: 274 }}
              value={content}
              onChange={(e) => setState({ type: 'content', payload: e.target.value })}
            />
          </div>
        </Layer>
      </modal.Content>
      <modal.Footer
        loading={state.loading}
        submitText="변경"
        disabled={!active}
        closeHandler={closeHandler}
        submitHandler={submitHandler}
      />
    </modal.Container>
  );
};

export default ModalModifyPolicy;
