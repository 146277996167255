import { NavigateFunction } from 'react-router-dom';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, process } from 'common/functions';
import { Root } from 'models';
import type { RootState } from './configStore';

type State = Root.Auth.state;
type Data = Root.Auth.data;

const initialState: State = { data: null, timer: 0, isDuplicateLogin: false };

export const authSlice = createSlice({
  name: 'root/auth/slice',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<Data>) => {
      state['data'] = payload;
      localStorage.setItem('ROOT_TOKEN', JSON.stringify(payload));
    },
    setTimer: (state, { payload }: PayloadAction<number>) => {
      state['timer'] = payload;
    },
    setIsDuplicateLogin: (state, { payload }: PayloadAction<boolean>) => {
      state['isDuplicateLogin'] = payload;
    },
    init: (state) => {
      state['data'] = null;
      state['timer'] = 0;
      state['isDuplicateLogin'] = false;
    },
    unMount: (state) => {
      state['data'] = null;
      state['timer'] = 0;
    },
  },
});

const unMount = createAsyncThunk('root/auth/unmount', (_, { dispatch }) => {
  localStorage.removeItem('ROOT_TOKEN');
  dispatch(authSlice.actions.unMount());
});

const logout = createAsyncThunk('root/auth/logout', (payload: { navigate: NavigateFunction }, { dispatch }) => {
  dispatch(unMount());
  dispatch(authSlice.actions.init());
  payload.navigate('/root');
});

// 로그인 인증시 에러 메세지 케이스
const errorMessage = (response: { result_code: string; result_message: string }) => {
  // prettier-ignore
  switch (response.result_code) {
    case 'F301': return '아이디 혹은 비밀번호를 확인해 주세요.';
    case 'F302': return '비활성화된 아이디입니다.';
    case 'F304': return '비활성화된 아이디입니다.';
    case 'F303': return '비활성화된 고객사입니다.';
    case 'E580': return '데이터 베이스가 유효하지 않습니다.';
    default: return response.result_message === '접근 권한이 없음' ? '아이디 혹은 비밀번호를 확인해 주세요.' : '알수없는 에러'
  }
};

const login = createAsyncThunk(
  'root/auth/login',
  (payload: { root_name: string; password: string; navigate: NavigateFunction }, { dispatch }) => {
    const { navigate, root_name, password } = payload;
    if (!root_name) return '아이디를 입력하세요.';
    if (!password) return '비밀번호를 입력하세요';
    return process(
      api.reqData({ url: 'root/sign-in', data: payload }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        if (response) {
          dispatch(authSlice.actions.setData({ ...response.data })); // store 저장
          const MINUTE_30 = 1000 * 60 * 30 - 1000; // 토큰 만료 30분 타이머, bumper로 1초 뺐음
          const remain = Math.min(MINUTE_30, new Date(response.data.expire).getTime());
          dispatch(authSlice.actions.setTimer(remain));
          navigate('/root/client-management');
        }
        if (error) return errorMessage(error.data.api_response);
        return '';
      })
    );
  }
);

const timer = createAsyncThunk('root/auth/timer', (payload: { time: number }, { dispatch }) => {
  dispatch(authSlice.actions.setTimer(payload.time));
});

const refreshToken = createAsyncThunk('root/auth/refreshToken', (_, { dispatch, getState }) => {
  const { auth } = getState() as RootState;

  return process(
    api.reqData({ url: '/refresh-jwt ' }),
    api.post.request,
    api.fullFilled(({ response, error }) => {
      if (response) {
        dispatch(authSlice.actions.setData({ ...auth.data, ...response.data })); // store 저장
        const MINUTE_30 = 1000 * 60 * 30 - 1000; // 토큰 만료 30분 타이머, bumper로 1초 뺐음
        const remain = Math.min(MINUTE_30, new Date(response.data.expire).getTime());
        dispatch(authSlice.actions.setTimer(remain));
        return response;
      }
      return error;
    })
  );
});

export const authActions = { ...authSlice.actions, login, logout, timer, refreshToken, unMount };
