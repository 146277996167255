import React, { Dispatch, useState } from 'react';
import { Button, Dialog } from 'usebeauty';
import { NullishGuard } from 'hoc';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { Action, State } from '../CtSecurityContentGroup';
import './Di_CreateHmac.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  closeHandler: () => void;
}

const Di_CreateHmac: React.FC<Props> = ({ state, setState, loadHandler, closeHandler }) => {
  const {
    vas: { hmac: isHmac },
  } = state;
  const [hmac, setHmac] = useState<string>('');
  const [step, setStep] = useState<number>(0);
  const { set } = Alert.Context();

  const onCopy = () => {
    navigator.clipboard.writeText(hmac).then(() => {
      set({ success: 'HMAC Key가 복사되었습니다.' });
    });
  };

  const submitHandler = () => {
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'secrets/hmac' }),
      api.put.request,
      api.fullFilled(({ response }) => {
        setHmac(response?.data.value);
        setState({ type: 'loading', payload: false });
        set({ success: `HMAC Key가 ${!isHmac ? '생성' : '갱신'}되었습니다.` });
        setStep(1);
        return response;
      })
    );
  };

  return (
    <Dialog.Base id="hmac__dialog__wrapper" st={{ width: 500, gap: 16 }}>
      <Dialog.Summary
        st={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <i className="icon" />
        {isHmac ? 'HMAC Key 갱신' : 'HMAC Key 생성'}
      </Dialog.Summary>
      <NullishGuard data={[step === 0]}>
        <Dialog.Content>
          <div className="step-1">
            <div className="detail">
              <p>HMAC Key는 보안 코드 형태이며 인증 시 사용됩니다.</p>
              <p>
                생성된 HMAC Key는 <span className="accent">최초 1회만 확인이 가능</span>하므로
              </p>
              <p>반드시 안전한 곳에 별도 보관해 주세요.</p>
            </div>
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <Button ripple={false} className="cancel" onClick={closeHandler}>
            취소
          </Button>
          <Button ripple={false} onClick={submitHandler}>
            다음
          </Button>
        </Dialog.Actions>
      </NullishGuard>
      <NullishGuard data={[step !== 0]}>
        <Dialog.Content st={{ marginBottom: 16 }}>
          <div className="step-2">
            <div className="hmac">
              <div className="hmac-value">{hmac}</div>
              <Button ripple={false} type="submit" className="copy" onClick={onCopy}>
                복사하기
              </Button>
            </div>
            <div className="hmac-detail">
              <p>보안을 위해 반드시 인쇄 또는 별도의 필기를 통해 보관해 주세요.</p>
              <p>
                PC 또는 휴대폰 기기, 메일함 등에 보관하실 경우 해킹의 도구로 이용될 수 있습니다. 생성된 HMAC을
                잃어버리면 복구할 수 있는 방법이 없으니 각별히 유의하시기 바랍니다.
              </p>
            </div>
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            className="hmac-submit-btn"
            ripple={false}
            onClick={() => {
              loadHandler();
              closeHandler();
            }}
          >
            네, 안전한 곳에 보관했습니다.
          </Button>
        </Dialog.Actions>
      </NullishGuard>
    </Dialog.Base>
  );
};

export default Di_CreateHmac;
