import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { admin } from 'store';
import { Admin } from 'pages';
import * as layout from 'layout';
import { Authentication, InterCeptor } from 'hoc/admin';
import MyBox from 'layout/page/header/mybox/client/Popover_MyBox';
import TokenTimer from 'layout/page/header/tokenTimer/client/TokenTimer';
import TempPassModifyPage from 'pages/tempPassModify/TempPassModifyPage';

export default {
  path: '/admin',
  element: (
    <Provider store={admin.store}>
      <InterCeptor>
        <Outlet />
      </InterCeptor>
    </Provider>
  ),
  children: [
    { index: true, element: <Admin.LoginPage /> } /* login page */,
    { path: 'temp-pass-modify/:role', element: <TempPassModifyPage /> },
    {
      path: '*',
      element: (
        <Authentication>
          <layout.Page
            tokenTimer={TokenTimer}
            myBox={MyBox}
            links={[
              { url: '/admin/reviewer-management', text: '심사자 관리' },
              { url: '/admin/terms-guidance', text: '약관 가이드' },
              { url: '/admin/manage/authentication', text: '인증 설정' },
              { url: '/admin/manage/other', text: '기타 설정' },
            ]}
          />
        </Authentication>
      ),
      children: [
        {
          path: 'reviewer-management',
          element: <layout.admin.Content />,
          children: [{ index: true, element: <Admin.ReviewerPage /> }],
        },
        {
          path: 'terms-guidance',
          element: <layout.admin.Content />,
          children: [{ index: true, element: <Admin.TermsPage /> }],
        },
        {
          path: 'manage',
          element: <layout.admin.Content />,
          children: [
            {
              path: 'authentication',
              element: <layout.admin.Manage.Authencation />,
              children: [
                { path: 'examination', element: <Admin.Manage.Authentication.ExaminationPage /> },
                { path: 'idcard', element: <Admin.Manage.Authentication.IdcardPage /> },
                { path: 'account', element: <Admin.Manage.Authentication.AccountPage /> },
                {
                  path: 'face',
                  element: <layout.admin.Face />,
                  children: [
                    {
                      path: 'compare',
                      children: [
                        { path: 'setting', element: <Admin.Manage.Authentication.Face.CompareSettingPage /> },
                        { path: 'log', element: <Admin.Manage.Authentication.Face.CompareLogPage /> },
                        { path: '*', element: <Navigate to="setting" /> },
                      ],
                    },
                    {
                      path: 'liveness',
                      children: [
                        { path: 'setting', element: <Admin.Manage.Authentication.Face.LivenessSettingPage /> },
                        { path: 'log', element: <Admin.Manage.Authentication.Face.LivenessLogPage /> },
                        { path: '*', element: <Navigate to="setting" /> },
                      ],
                    },
                    { path: '*', element: <Navigate to="compare" /> },
                  ],
                },
                {
                  path: 'edd',
                  element: <layout.admin.Edd />,
                  children: [
                    { path: 'ra', element: <Admin.Manage.Authentication.Edd.RaPage /> },
                    { path: 'weight', element: <Admin.Manage.Authentication.Edd.WeightPage /> },
                    { path: 'realscore', element: <Admin.Manage.Authentication.Edd.RealScorePage /> },
                    { path: 'criterion', element: <Admin.Manage.Authentication.Edd.CriterionPage /> },
                    { path: 'customField', element: <Admin.Manage.Authentication.Edd.CustomFieldPage /> },
                    { path: '*', element: <Navigate to="ra" /> },
                  ],
                },
                { path: '*', element: <Navigate to="examination" /> },
              ],
            },
            {
              path: 'other',
              element: <layout.admin.Manage.Other />,
              children: [
                { path: 'general', element: <Admin.Manage.Other.GeneralPage /> },
                { path: 'authenticate', element: <Admin.Manage.Other.AuthenticatePage /> },
                { path: 'security', element: <Admin.Manage.Other.SecurityPage /> },
                { path: 'theme', element: <Admin.Manage.Other.ThemeSettingPage /> },
                { path: '*', element: <Navigate to="general" /> },
              ],
            },
          ],
        },
        { path: '*', element: <Navigate to="reviewer-management" /> },
      ],
    },
  ],
};
