import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, Loading } from 'usebeauty';
import { FlexBox, Form } from '@usebeauty';
import { Layer, modal } from 'layout';
import { api, findCustomer, process } from 'common/functions';
import classNames from 'classnames';
import { Alert } from 'common/components';
import { N_Select } from 'usebeauty/dist/@types';
import './Modal_RegistClient.scss';

interface Props {
  closeHandler: () => void;
  refresh: () => void;
}

type Values = {
  name: string;
  check: boolean;
  message: string;
  phone_number: string;
  industry: N_Select.Selected<number>;
  purchase_type: N_Select.Selected<number>;
  active: N_Select.Selected<boolean>;
};

const defaultValues = {
  name: '',
  check: false,
  message: '',
  phone_number: '',
  industry: null,
  purchase_type: null,
  active: null,
};

const ModalRegistClient: React.FC<Props> = ({ closeHandler, refresh }) => {
  const { set } = Alert.Context();
  const contentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const styles = { marginTop: 8, marginBottom: 20 };

  const {
    control,
    watch,
    setValue,
    formState: { dirtyFields, errors },
    setError,
    clearErrors,
  } = useForm<Values>({ mode: 'onChange', defaultValues });

  const field = watch();

  // 고객사 중복 확인
  const clientCheckHandler = async () => {
    if (field.message && field.message !== '중복여부를 확인하세요.') return;

    if (!field.name) setValue('message', '고객사 명을 입력하세요.');
    else {
      const params = { customer_name: field.name };
      const { id } = await findCustomer(params);
      const result =
        id > -1
          ? { title: '이미 사용 중인 고객사명입니다.', value: false }
          : { title: '사용 가능한 고객사명입니다.', value: true };
      id > -1 ? setError('name', { message: '' }) : clearErrors('name');
      setValue('message', result.title);
      setValue('check', result.value);
    }
  };

  const submitHandler = () => {
    setLoading(true);
    const params = {
      name: field.name,
      phone_number: field.phone_number,
      industry: field.industry?.value,
      purchase_type: field.purchase_type?.value,
      active: field.active?.value,
    };

    process(
      api.reqData({ url: 'customers', data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          closeHandler();
          set({ success: '고객사 등록이 완료되었습니다.' });
          refresh();
        }
      })
    );
    setLoading(false);
  };

  const disabled =
    field.check &&
    !errors.phone_number &&
    !!field.phone_number &&
    !!field.industry?.value &&
    !!field.purchase_type?.value &&
    !!dirtyFields.active;

  return (
    <>
      {loading && <Loading />}
      <Dialog.Base id="root-client-register-provider" st={{ width: 500 }}>
        <Dialog.Summary>신규 등록</Dialog.Summary>
        <Dialog.Content>
          <div className="content" ref={contentRef}>
            <modal.Heading>고객사명</modal.Heading>
            <div className="customer-check">
              <FlexBox st={{ gap: 8 }}>
                <Form.Input
                  control={control}
                  name="name"
                  placeholder="고객사명을 입력하세요"
                  onChange={() => {
                    setValue('check', false);
                    setValue('message', '중복여부를 확인하세요.');
                    setError('name', { message: '' });
                  }}
                />
                <Button onClick={clientCheckHandler}>중복확인</Button>
              </FlexBox>
              {field.message && (
                <div className={classNames('api-check-message', { error: !field.check })}>{field.message}</div>
              )}
            </div>

            <Layer styles={styles}>
              <modal.Heading>전화번호</modal.Heading>
              <Form.Input
                placeholder="전화번호를 입력하세요."
                control={control}
                name="phone_number"
                rules={{ required: '전화번호를 입력하세요.' }}
              />
            </Layer>

            <Layer styles={styles}>
              <modal.Heading>구분</modal.Heading>
              <Form.Select control={control} name="industry">
                <Form.Select.Summary placeholder="선택" />
                <Form.Select.Options>
                  <Form.Select.Item title="비금융권" value={1} />
                  <Form.Select.Item title="금융권" value={2} />
                  <Form.Select.Item title="가상자산사업자" value={3} />
                  <Form.Select.Item title="P2P온투업자" value={4} />
                </Form.Select.Options>
              </Form.Select>
            </Layer>

            <Layer styles={styles}>
              <modal.Heading>구매형태</modal.Heading>
              <Form.Toggle
                name="purchase_type"
                control={control}
                className="box"
                columns={2}
                defaultValue={field.purchase_type}
              >
                <Form.Toggle.Item title="솔루션" value={1} />
                <Form.Toggle.Item title="API" value={0} />
              </Form.Toggle>
            </Layer>

            <Layer styles={styles}>
              <modal.Heading>활성여부</modal.Heading>
              <Form.Toggle name="active" control={control} className="box" columns={2}>
                <Form.Toggle.Item title="활성" value />
                <Form.Toggle.Item title="비활성" value={false} />
              </Form.Toggle>
            </Layer>
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <Button className="cancel" onClick={closeHandler}>
            취소
          </Button>
          <Button onClick={submitHandler} disabled={!disabled}>
            등록
          </Button>
        </Dialog.Actions>
      </Dialog.Base>
    </>
  );
};

export default ModalRegistClient;
