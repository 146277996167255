import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { SubController } from 'common/components';

function TemplateManage() {
  return (
    <div id="root-eddra-nation-navigator">
      <SubController
        title={
          <>
            <NavLink to="/root/eddra-management/template/setting">템플릿 설정</NavLink>
          </>
        }
      />
      <Outlet />
    </div>
  );
}

export default TemplateManage;
