import React, { useContext, useEffect, useReducer } from 'react';
import { getConfigs, getIdcardName, getModules } from 'common/api';
import { parseConfigs } from 'common/functions';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { Config } from '../../models/model.types';
import CtGeneralSetting from './setting/CtGeneralSetting';
import InfoSubscribe from './info/InfoSubscribe';
import InfoAdditional from './info/InfoAdditional';
import './GeneralContent.scss';

export type SubmitPayload = { key: string; value: unknown; text?: string };
export type Module = typeof getModules.res;
export type Configs = { key: string; value: string };
export type VAS = Config.Other.General;
export type Disabled = { manual: boolean; custom: boolean };
export type Data = { module: Module; vas: VAS; defaultVas: VAS; kDriver: boolean };
export type State = { data: Data; disabled: Disabled; detect: boolean };

export const initVas: VAS = {
  email_notification: false,
  sms_notification: false,
  use_custom_end_message: false,
  custom_end_message: '',
  custom_end_title: '',
  allow_keep_privacy: false,
  keep_privacy_day: 1,
  allow_reuse: false,
  reuse_limit_minute: 1440,
  allow_ra: false,
};
const initData: Data = {
  module: {
    account_verification: false,
    face_authentication: false,
    id_card_ocr: false,
    id_card_verification: false,
    liveness: false,
    custom_field: false,
    edd_field: false,
  },
  vas: { ...initVas },
  defaultVas: { ...initVas },
  kDriver: false,
};

const initialState: State = {
  data: initData,
  detect: false,
  disabled: {
    manual: false,
    custom: true,
  },
};

export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'vas'; payload: AtLeastOne<VAS> }
  | { type: 'defaultVas'; payload: AtLeastOne<VAS> }
  | { type: 'resetVas'; payload: AtLeastOne<VAS> }
  | { type: 'reset' }
  | { type: 'edit'; payload: boolean }
  | { type: 'kDriver'; payload: boolean }
  | { type: 'disabled'; payload: { type: keyof Disabled; value: boolean } };

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case "setData": draft['data'] = {...state.data, ...action.payload};break;
      case 'disabled': draft.disabled[action.payload.type] = action.payload.value; break;
      case 'vas': draft['data']['vas'] = {...state.data.vas, ...action.payload}; break;
      case 'defaultVas': draft['data']['defaultVas'] = {...state.data.vas, ...action.payload}; break;
      case 'reset': draft['data']['vas'] = draft.data.defaultVas; break; 
      case 'kDriver': draft['data']['kDriver'] = action.payload; break; 
    }
  });
};

const GeneralContent: React.FC = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);
    const modules = await getModules();
    const config = await getConfigs();
    const idcardsName = (await getIdcardName('korean-driver')).data.detail;
    const vas = parseConfigs(config, Object.keys(initVas) as Array<keyof VAS>);
    const data: Data = { ...initData, module: modules, vas, defaultVas: vas, kDriver: idcardsName.active };
    setState({ type: 'setData', payload: data });
    if (modules.custom_field) setState({ type: 'disabled', payload: { type: 'custom', value: false } });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-general-content">
      <InfoSubscribe module={state.data.module} vas={state.data.vas} />
      <InfoAdditional vas={state.data.vas} />
      <CtGeneralSetting state={state} setState={setState} loadHandler={loadHandler} />
    </div>
  );
};

export default GeneralContent;
