import React from 'react';
import classNames from 'classnames';
import type { Data as RowData } from '../../../../CtReviewTable';
import { dateFormatter, Ocr } from '../IdCard';

interface Props {
  origin: Ocr;
  modified: Ocr | null;
  rowData: RowData;
  isModified: (keys: (keyof Ocr)[] | keyof Ocr) => boolean;
}

export const ID: React.FC<Props> = ({ origin, modified, rowData, isModified }) => {
  const issueDate = modified && isModified('issueDate') ? `${modified.issueDate}` : `${origin.issueDate}`;

  return (
    <>
      <div>
        <h5>· 이름</h5>
        <p className={classNames({ warning: isModified('userName') })}>{rowData.name}</p>
      </div>

      <div>
        <h5>· 주민등록 번호</h5>
        <p className={classNames({ warning: modified && isModified(['juminNo1', '_juminNo2']) })}>
          {modified && isModified(['juminNo1', '_juminNo2'])
            ? `${modified.juminNo1}-${modified['juminNo2']}`
            : `${origin.juminNo1}-${origin['juminNo2']}`}
        </p>
      </div>

      <div>
        <h5>· 발급 일자</h5>
        <p className={classNames({ warning: modified && isModified('issueDate') })}>{dateFormatter(issueDate)}</p>
      </div>
    </>
  );
};
