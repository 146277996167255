import React from 'react';
import { useRoutes } from 'react-router-dom';
import Unknown from 'pages/404/404';
import FindAccountPage from 'pages/findAccount/FindAccountPage';
import SignUpPage from 'pages/signup/SignUpPage';
import IconsPage from 'pages/icons/IconsPage';
import AdminRoute from './admin';
import ReviewerRoute from './reviewer';
import RootRoute from './root';

const development = process.env.NODE_ENV === 'development';

const router = [
  // Root Pages #############################################################
  { path: '/sign-up/:role', element: <SignUpPage /> },
  { path: '/find-account/:role', element: <FindAccountPage /> },
  RootRoute,
  AdminRoute,
  ReviewerRoute,
  { path: '*', element: <Unknown /> },
];

if (development) router.push({ path: '/icons', element: <IconsPage /> });

export default () => useRoutes(router);
