import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, FlexBox, Form, Loading } from '@usebeauty';
import { Alert } from 'common/components';
import { Layer, modal } from 'layout';
import classNames from 'classnames';
import { api, process } from 'common/functions';
import { excutions } from 'common/validations';
import './Modal_RegistPrivacy.scss';

interface Props {
  closeHandler: () => void;
  refresh: () => void;
}

const defaultValues = { content: '', version: '', check: false, message: '' };

const ModalRegistPrivacy: React.FC<Props> = ({ closeHandler, refresh }) => {
  const { set } = Alert.Context();

  const [loading, setLoading] = useState(false);
  const { control, watch, setValue } = useForm({ mode: 'onChange', defaultValues });
  const field = watch();

  // 버전 중복 확인
  const dupCheckHandler = () => {
    process(
      api.reqData({ url: 'notices/tnc/check/version', data: { version: field.version } }),
      api.post.request,
      api.fullFilled(({ error, response }) => {
        if (response) {
          const { is_available } = response.data;
          setValue('check', !!is_available);
          setValue('message', is_available ? '등록 가능한 버전 입니다.' : '이미 등록된 버전입니다.');
        }

        if (error) {
          const { result_code, result_message } = error.data.api_response;
          if (result_code === 'E535') {
            setValue('message', result_message);
          }
          if (result_code === 'E531') {
            setValue('message', '버전을 입력하세요');
          }
          setValue('check', false);
        }
      })
    );
  };

  const submitHandler = async () => {
    // 버전 중복 체크
    if (!field.check) {
      setValue('message', '중복여부를 확인하세요.');
      return;
    }

    setLoading(true);
    const params = { version: field.version, content: btoa(encodeURIComponent(field.content)) };
    process(
      api.reqData({ url: 'notices/tnc', data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data.api_response;
          if (result_code === 'N100') {
            closeHandler();
            set({ success: '이용약관 개정이 완료되었습니다.' });
            refresh();
          }
        }
      })
    );
    setLoading(false);
  };

  const 중복체크안함 = field.version && !field.check && !field.message;
  const 중복확인메세지 = field.message;
  const error = (중복확인메세지 && !field.check) || 중복체크안함;

  const noDotAtTheEnd = excutions.noDotAtTheEnd.regExp(field.version);
  const versionKey = excutions.versionKey.regExp(field.version);

  const orderedErrorMessage = () => {
    if (versionKey) return '영문 소문자, 숫자, 특수문자(.)만 사용 가능합니다.';
    if (noDotAtTheEnd) return '입력 형식이 올바르지 않습니다.';
    if (중복체크안함) return '중복여부를 확인하세요.';
    if (중복확인메세지) return 중복확인메세지;
    return '';
  };

  return (
    <>
      {loading && <Loading />}
      <Dialog.Base id="root-term-privacy-regist" st={{ width: 720 }}>
        <Dialog.Summary>이용약관 개정</Dialog.Summary>
        <Dialog.Content>
          <modal.Heading>버전</modal.Heading>
          <div className="duplicate-check">
            <FlexBox st={{ gap: 10, alignItems: 'initial' }}>
              <Form.Input
                st={{ width: 546 }}
                control={control}
                name="version"
                maxLength={8}
                rules={{ maxLength: 8, required: '버전을 입력하세요.' }}
                placeholder="버전을 입력하세요"
                className={classNames('basic', { error })}
                onChange={() => {
                  setValue('message', '');
                  setValue('check', false);
                }}
              />
              <Button onClick={dupCheckHandler}>중복확인</Button>
            </FlexBox>
            <div className={classNames('api-check-message', { error: !field.check })}>{orderedErrorMessage()}</div>
          </div>

          <modal.Heading>약관 내용 (HTML)</modal.Heading>
          <Layer styles={{ marginTop: 8, marginBottom: 20 }}>
            <Form.Textarea
              control={control}
              name="content"
              placeholder="약관 내용 (HTML)을 입력하세요."
              st={{ width: '100%', height: 384 }}
              rules={{ required: '약관 내용을 입력하세요.' }}
            />
          </Layer>
        </Dialog.Content>
        <Dialog.Actions>
          <Button className="cancel" onClick={closeHandler}>
            취소
          </Button>
          <Button onClick={submitHandler} disabled={!field.check || !field.content}>
            등록
          </Button>
        </Dialog.Actions>
      </Dialog.Base>
    </>
  );
};

export default ModalRegistPrivacy;
