import styled from 'styled-components';
import type { Styles } from './Button';

export const dynamicCSS = (args: Styles) => {
  const merge: { [key: string]: string } = {};
  if (args.width) merge['width'] = typeof args.width === 'string' ? args.width : `${args.width}px`;
  if (args.height) merge['height'] = typeof args.height === 'string' ? args.height : `${args.height}px`;
  if (args.fontSize) merge['font-size'] = `${args.fontSize}px`;
  if (args.radius) merge['border-radius'] = `${args.radius}px`;
  let string = '';
  for (const key in merge) string += `${key}: ${merge[key]} !important;`;
  return string;
};

export const ButtonElement = styled.button<Styles>`
  ${dynamicCSS}
  .jack__button__context {
    ${({ align }) => {
      if (align) return `justify-content: ${align}`;
      return '';
    }}
  }
`;
