import React, { Dispatch, useState } from 'react';
import { api, process } from 'common/functions';
import { Alert } from 'common/components';
import classNames from 'classnames';
import EditComp from '../edit/EditComp';
import { Action, State } from '../../IdcardContent';
import './DriverType.scss';

interface Props {
  loadHandler: () => void;
  state: State;
  setState: Dispatch<Action>;
}

const DriverType = (props: Props) => {
  const { loadHandler, state, setState } = props;
  const { include_driver_type } = state.data.vas;
  const { set } = Alert.Context();
  const [edit, setEdit] = useState(false);

  const submitHandler = async () => {
    const data = { include_driver_type: String(include_driver_type) };
    await process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled((response) => {
        setEdit(false);
        loadHandler();
        if (response) {
          set({ success: `운전면허증 종류 추가 수집설정이 ${include_driver_type ? '활성화' : '비활성화'}되었습니다.` });
        } else {
          set({ fail: `운전면허증 종류 추가 수집설정에 실패하였습니다.` });
        }
      })
    );
  };
  const handler = { loadHandler, submitHandler };

  return (
    <section id="idcard-driver-type" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: include_driver_type })}>운전면허증 종류 추가 수집</h4>
            <p>운전면허증으로 신분증 인증 시, 운전면허증의 종류를 추가로 수집합니다. ex) 1종 대형, 2종 보통 등등</p>
          </div>
        </div>
        <EditComp
          onSwitch
          setEdit={setEdit}
          edit={edit}
          setState={setState}
          handler={handler}
          data={{ key: 'include_driver_type', value: include_driver_type }}
        />
      </div>
    </section>
  );
};

export default DriverType;
