import React from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { CheckBox, Error } from '@usebeauty';
import type { N_CheckBox } from 'usebeauty/dist/@types';

function F_CheckBox<T extends FieldValues, U extends FieldPath<T>>(props: UseControllerProps<T, U> & N_CheckBox.Props) {
  return (
    <Controller
      rules={props.rules}
      control={props.control}
      name={props.name}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <CheckBox
              disabled={props.disabled}
              checkIcon={props.checkIcon}
              className={props.className}
              label={props.label}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e);
                if (props.onChange) props.onChange(e);
              }}
            />
            <Error>{error?.message}</Error>
          </>
        );
      }}
    />
  );
}

export default F_CheckBox;
