import React, { useEffect } from 'react';
// import { produce } from 'immer';
// import { NullishGuard } from 'hoc';
// import { api, generatePassword, process } from 'common/functions';
import { Button, Dialog } from 'usebeauty';
import { Detail } from '../Expand';
// import ActiveModal from './modal/ActiveModal';
import './LinkedAccountModal.scss';

// export type Account = { id: number; username: string; key?: string; active: boolean; date: string; created_at: string };
// export type Action =
//   | { type: 'loading'; payload: boolean }
//   | { type: 'create'; payload: Partial<Account> }
//   | { type: 'account'; payload: Account[] }
//   | { type: 'modified'; payload: { id: number; active: boolean } }
//   | { type: 'active'; payload: Account };

// interface State {
//   accounts: Account[];
//   account_created: boolean;
//   created_account: Partial<Account> | null;
//   modified: Array<{ id: number; active: boolean }>;
//   loading: boolean;
// }

// const initialState: State = {
//   accounts: [],
//   modified: [],
//   account_created: false,
//   created_account: null,
//   loading: false,
// };

// const reducer = (state: State, action: Action) => {
//   return produce(state, (draft) => {
//     switch (action.type) {
//       case 'loading':
//         draft['loading'] = action.payload;
//         break;
//       case 'create':
//         draft.created_account = action.payload;
//         draft.account_created = true;
//         break;
//       case 'account':
//         draft.accounts = action.payload;
//         break;
//       case 'active':
//         draft.accounts[draft.accounts.findIndex((acc) => acc.id === action.payload.id)] = {
//           ...action.payload,
//           active: !action.payload.active,
//         };
//         break;
//       case 'modified':
//         draft.modified = [...draft.modified, action.payload];
//         break;
//     }
//   });
// };

interface Props {
  detail: Detail;

  closeHandler: () => void;
}

const Modal_UsebToken: React.FC<Props> = ({ closeHandler }) => {
  // const { set } = Alert.Context();

  // const [state, setState] = useReducer(reducer, initialState);
  // const [activeModal, setActiveModal] = useState<Array<boolean>>([]);
  // const defaultData = useRef<Account[] | null>(null);
  // const date = new Date();

  const loadLandler = () => {
    // process(
    //   api.reqData({ url: `customers/${detail.id}/users?count=50` }),
    //   api.get.request,
    //   api.fullFilled(({ response }) => {
    //     setState({ type: 'account', payload: response?.data.list });
    //     setActiveModal(Array(response?.data.list.length).fill(false));
    //     defaultData.current = response?.data.list;
    //     return undefined;
    //   })
    // );
  };

  useEffect(() => {
    loadLandler();
  }, []);

  return (
    <div id="linked_account__modal__wrapper">
      <Dialog.Base st={{ width: 720, height: 800 }}>
        <Dialog.Summary>고객사 토큰 관리</Dialog.Summary>
        <Dialog.Content>
          `useb_basic_auth` 토큰 관리 (TBD)
          {/* <NullishGuard data={[state.accounts.length || state.account_created]}>
            <Button
              className="ok header"
              text="계정 생성"
              disabled={!!state.created_account}
              onClick={createAccountHandler}
            />
            <ul className="linked_account__list">
              {state.created_account && (
                <>
                  <li className="linked_account__list__item created">
                    <span className="date">{state.created_account.date}</span>
                    <span className="secrets">
                      {`ID : ${state.created_account.username} /  KEY : ${state.created_account.key}`}
                    </span>
                    <button type="button" className="copy" onClick={copyHandler}>
                      복사하기
                    </button>
                  </li>
                  <div className="linked_account__label">
                    <span className="description">
                      해당 정보는 최초 1회만 확인이 가능하므로 반드시 안전한 곳에 별도 보관해 주세요.
                    </span>
                  </div>
                </>
              )}
              {state.accounts.map((account, i) => (
                <li key={i} className="linked_account__list__item">
                  // <span className="date">{account.date}</span>
                  <span className="date">{account.created_at.split('T')[0]}</span>
                  <span className="secrets">{`ID : ${account.username}`}</span>
                  <Switch
                    value={account.active}
                    onChange={(value) => {
                      if (value) {
                        submitHandler(!!value, account);
                        // activeHandler(!!value, account);
                      } else {
                        setActiveModal(
                          activeModal.map((item, idx) => {
                            if (idx === i) return true;
                            return item;
                          })
                        );
                      }
                    }}
                  />
                  {activeModal[i] && (
                    <Modal.Portal
                      setToggle={() =>
                        setActiveModal(
                          activeModal.map((item, idx) => {
                            if (idx === i) return false;
                            return item;
                          })
                        )
                      }
                    >
                      {({ closeHandler }) => (
                        <ActiveModal
                          account={account}
                          submitHandler={() => submitHandler(false, account)}
                          setState={setState}
                          closeHandler={closeHandler}
                        />
                      )}
                    </Modal.Portal>
                  )}
                </li>
              ))}
            </ul>
          </NullishGuard> */}
          {/* <NullishGuard data={[!(state.accounts.length || state.account_created)]}>
            <div>
              <div className="detail">
                생성된 계정 정보가 없습니다.
                <br />
                연결 계정 생성이 필요하다면 아래 버튼을 눌러주세요.
              </div>
              <Button className="ok" text="계정 생성" onClick={createAccountHandler} />
            </div>
          </NullishGuard> */}
        </Dialog.Content>
        <Dialog.Actions>
          {/* {!state.account_created && <Button className="cancel" text="취소" onClick={closeHandler} />} */}
          <Button className="ok" onClick={closeHandler}>
            닫기
          </Button>
        </Dialog.Actions>
      </Dialog.Base>
    </div>
  );
};

export default Modal_UsebToken;
