/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { Alert, Button, Modal, TextArea } from 'common/components';
import { api, process } from 'common/functions';
import { NullishGuard } from 'hoc';
import { ContentContext } from 'layout/root/content/Content';
import { values } from 'lodash';
import type { Data as RowData } from '../CtPolicyTable';
import ModalDeletePolicy from './modal/Modal_DeletePolicy';
import ModalModifyPolicy from './modal/Modal_ModifyPolicy';
import './Expand.scss';

type Action = { type: 'setData'; payload: string };
export interface State {
  data: string | null;
}

const initialState: State = { data: null };
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
    }
  });
};

interface Props {
  rowData: RowData;
  handlers: { refresh: () => void };
}

const Expand: React.FC<Props> = ({ rowData, handlers }) => {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState);
  const { data } = state;

  const getExpandData = async (type?: 'update') => {
    if (!type) setLoading(true);
    process(
      api.reqData({ url: `guides/${rowData.key}` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { detail } = response.data;
          const html = JSON.parse(decodeURIComponent(atob(detail.content)));
          let data = html;

          if (typeof html === 'object') {
            const vHTML = values(html);
            data = vHTML[0].content || vHTML;
          }

          setState({ type: 'setData', payload: data });
        }
        if (!type) setLoading(false);
      })
    );
  };

  useEffect(() => {
    getExpandData();
  }, []);

  return (
    <NullishGuard data={[data]}>
      {([data]) => (
        <section className="root-guidance-policy-table-tr-expand">
          <div id="guidance-policy">
            <h3 className="term-subject">약관 가이드 내용</h3>
            <div className="term-content">
              <div className="inner-html" dangerouslySetInnerHTML={{ __html: data }} />
            </div>

            <h3 className="term-subject">
              약관 HTML
              <Button
                text="HTML 복사하기"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(data);
                    set({ success: 'HTML 복사가 완료되었습니다.' });
                  } catch {
                    set({ fail: 'HTML 복사는 실패되었습니다.' });
                  }
                }}
              />
            </h3>
            <div className="term-content">
              <TextArea readonly className="html-textarea" value={data} styles={{ width: '100%', height: 136 }} />
            </div>
          </div>
          <div className="expand-utils">
            <Modal
              content={({ closeHandler }) => (
                <ModalDeletePolicy closeHandler={closeHandler} rowData={rowData} refresh={handlers.refresh} />
              )}
            >
              <Button text="삭제" />
            </Modal>
            <Modal
              content={({ closeHandler }) => (
                <ModalModifyPolicy
                  closeHandler={closeHandler}
                  data={data}
                  rowData={rowData}
                  getExpandData={getExpandData}
                />
              )}
            >
              <Button text="편집" />
            </Modal>
          </div>
        </section>
      )}
    </NullishGuard>
  );
};

export default Expand;
