import React, { Dispatch, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, DualSlider, Input } from 'common/components';
import { Action, State } from '../CtCriterionContent';
import './CriterionSetting.scss';

const CriterionLabel = styled.span<{ $width: number; $minWidth: string }>`
  font-size: 14px;
  font-weight: 700;
  flex-grow: 1;
  text-align: center;
  width: ${({ $width, $minWidth }) => `clamp(${$minWidth}, ${$width}%, calc(100% - 108px))`};
`;

const CriterionInput = styled.div<{ $left: number; $offset: number; $color: string }>`
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  padding-left: ${({ $left, $offset }) => `min(88%, calc(${$left}% - ${$offset}px))`};
  transition: padding 0.2s ease;
  color: ${({ $color }) => $color};
`;

const MIN_THRESHOLD_GAP = 2;

interface Props {
  state: State;
  setState: Dispatch<Action>;
  submitHandler: () => void;
}
function CriterionSetting(props: Props) {
  const { state, setState, submitHandler } = props;
  const { edit, data, defaultData } = state;
  const [lower, upper] = data.threshold;
  const [d_lower, d_upper] = defaultData.threshold;

  const [rangeLower, setRangeLower] = useState(lower);
  const [rangeUpper, setRangeUpper] = useState(upper);

  const [inputLower, setInputLower] = useState(lower);
  const [inputUpper, setInputUpper] = useState(upper);

  useEffect(() => {
    setRangeLower(lower);
    setRangeUpper(upper);
    setInputLower(lower);
    setInputUpper(upper);
  }, [lower, upper]);

  const setLocalValue = (a: number, b: number) => {
    setRangeLower(a);
    setRangeUpper(b);
    setInputLower(a);
    setInputUpper(b);
  };

  const updateHandler = (a: number, b: number) => {
    setLocalValue(a, b);
    setState({ type: 'data', payload: { threshold: [a, b] } });
  };

  const disabled = lower === d_lower && upper === d_upper;

  return (
    <>
      <div className="header">
        <h3>평가기준 설정</h3>
        <div className="button-group">
          {edit ? (
            <>
              <Button
                text="취소"
                onClick={() => {
                  setState({ type: 'data', payload: state.defaultData });
                  setState({ type: 'edit', payload: false });
                }}
              />
              <Button
                text="저장"
                disabled={disabled}
                onClick={() => {
                  submitHandler();
                  setState({ type: 'edit', payload: false });
                }}
              />
            </>
          ) : (
            <Button text="편집" onClick={() => setState({ type: 'edit', payload: true })} />
          )}
        </div>
      </div>
      <section className="ra-criterion-setting">
        <div className="criterion-content">
          <div className="criterion-label">
            <CriterionLabel $width={lower} $minWidth="46px">
              저위험
            </CriterionLabel>
            <CriterionLabel $width={upper - lower} $minWidth="62px">
              중위험
            </CriterionLabel>
            <CriterionLabel $width={100 - upper} $minWidth="46px">
              고위험
            </CriterionLabel>
          </div>
          <div className="criterion-slider">
            <div className="min" />
            <DualSlider
              disabled={!edit}
              max={98}
              areaColor={{ lower: '#AFB2FF', middle: '#878BFF', upper: '#373EFF' }}
              lower={{ value: rangeLower, setValue: (value) => updateHandler(value, rangeUpper) }}
              upper={{ value: rangeUpper, setValue: (value) => updateHandler(rangeLower, value) }}
              gap={MIN_THRESHOLD_GAP}
            />
            <div className="max" />
          </div>
          <div className="criterion-score">
            <div>0점</div>
            <div>
              <CriterionInput $left={lower} $offset={0} $color={edit ? '#363636' : '#afb2ff'}>
                <Input
                  type="text"
                  className="basic"
                  styles={{ width: 48, height: 34 }}
                  disabled={!edit}
                  value={inputLower}
                  onBlur={() => {
                    const nextLower = inputLower >= upper - MIN_THRESHOLD_GAP ? upper - MIN_THRESHOLD_GAP : inputLower;
                    updateHandler(nextLower, rangeUpper);
                  }}
                  onChange={(e) => {
                    const { value } = e.target;
                    let numValue = +value.replace(/[^\d]/g, '');
                    if (numValue < 1) numValue = 1;
                    setInputLower(numValue);
                  }}
                />
                점
              </CriterionInput>
              <CriterionInput $left={upper - lower} $offset={92} $color={edit ? '#363636' : '#878bff'}>
                <Input
                  type="text"
                  className="basic"
                  styles={{ width: 48, height: 34 }}
                  disabled={!edit}
                  value={inputUpper}
                  onBlur={() => {
                    const nextUpper = inputUpper <= lower + MIN_THRESHOLD_GAP ? lower + MIN_THRESHOLD_GAP : inputUpper;
                    updateHandler(lower, nextUpper);
                  }}
                  onChange={(e) => {
                    const { value } = e.target;
                    let numValue = +value.replace(/[^\d]/g, '');
                    if (numValue > 99) numValue = 99;
                    setInputUpper(numValue);
                  }}
                />
                점
              </CriterionInput>
            </div>
            <div>100점</div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CriterionSetting;
