/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { modal } from 'layout';
import { Loading } from 'common/components';
import { api, process } from 'common/functions';
import './Modal_Terms.scss';

const ModalTerms: React.FC<{ closeHandler: () => void }> = ({ closeHandler }) => {
  const [loading, setLoading] = useState(true);
  const [html, setHtml] = useState('');

  useEffect(() => {
    process(
      api.reqData({ url: 'notices/tnc' }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) return response.data.latest_version;
        return false;
      }),
      (version: string) => api.reqData({ url: `notices/tnc/${version}` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { content } = response.data.detail;
          setHtml(decodeURIComponent(atob(content ?? '')));
        }
        setLoading(false);
      })
    );
  }, []);

  return (
    <modal.Container id="kyc-footer-terms-modal" styles={{ width: 640, height: 540 }}>
      <modal.Header>
        <span>이용약관</span>
        <i onClick={closeHandler} />
      </modal.Header>
      <modal.Content>
        {loading && <Loading />}
        <div className="inner-html" dangerouslySetInnerHTML={{ __html: html }} />
      </modal.Content>
    </modal.Container>
  );
};

export default ModalTerms;
