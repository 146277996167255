import React, { Dispatch } from 'react';
import { modal } from 'layout';
import { Account, Action } from '../LinkedAccountModal';
import './ActiveModal.scss';

interface Props {
  closeHandler: () => void;
  submitHandler: () => void;
  setState: Dispatch<Action>;
  account: Account;
}

const ActiveModal: React.FC<Props> = ({ closeHandler, submitHandler, account }) => {
  return (
    <modal.Container styles={{ width: 500 }} id="root-client-linked-account-modal">
      <modal.Header align="center">계정 비활성화</modal.Header>
      <modal.Content>
        <div className="active-modal-info">
          <div className="img" />
          <div className="info">
            <p className="date">{account.date}</p>
            <p className="id">ID : {account.username}</p>
          </div>
          <hr />
          <p className="detail">
            해당 계정을 비활성화하시겠습니까? <br />
            비활성화할 경우 해당 계정을 사용하여
            <br />
            서비스를 제공할 수 없습니다.
          </p>
        </div>
      </modal.Content>
      <modal.Footer
        closeHandler={closeHandler}
        submitHandler={() => {
          submitHandler();
          closeHandler();
        }}
      />
    </modal.Container>
  );
};

export default ActiveModal;
