import React, { useReducer } from 'react';
import { M_Table } from 'common/components';
import { produce } from 'immer';
import TermsTable from './TermsTable';

export type Data = {
  id: string;
  title: string;
  expand: boolean;
  url: { collection: string; policy: string };
};

export type Action = { type: 'expand'; payload: number };

export interface State {
  data: Data[];
  header: M_Table.header[];
}

const initialState: State = {
  data: [
    {
      id: 'idcard',
      title: '신분증 인증',
      expand: false,
      url: { collection: 'guide-pa-idcard', policy: 'guide-pp-idcard' },
    },
    {
      id: 'account',
      title: '계좌 인증',
      expand: false,
      url: { collection: 'guide-pa-account', policy: 'guide-pp-account' },
    },
    {
      id: 'idcard-face',
      title: '신분증 인증 · 얼굴 인증',
      expand: false,
      url: { collection: 'guide-pa-idcard-face', policy: 'guide-pp-idcard-face' },
    },
    {
      id: 'idcard-account',
      title: '신분증 인증 · 계좌 인증',
      expand: false,
      url: { collection: 'guide-pa-idcard-account', policy: 'guide-pp-idcard-account' },
    },
    {
      id: 'idcard-face-account',
      title: '신분증 인증 · 얼굴인증 · 계좌인증',
      expand: false,
      url: { collection: 'guide-pa-idcard-face-account', policy: 'guide-pp-idcard-face-account' },
    },
  ],
  header: [
    { title: '사용기능', property: 'title', flex: 2, align: 'center' },
    { title: '', property: '1' },
    { title: '', property: '2' },
    { title: '', property: '3' },
    { title: '', property: '4' },
    { title: '상세보기', property: 'expand', align: 'center' },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'expand': draft.data[action.payload]['expand'] = !draft.data[action.payload].expand; break;
    }
  });
};

const CtReviewerTable: React.FC = () => {
  const [state, setState] = useReducer(reducer, initialState);

  return <TermsTable state={state} setState={setState} />;
};

export default CtReviewerTable;
