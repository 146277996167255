import React, { Dispatch } from 'react';
import { Pagination, Select, Table } from 'usebeauty';
import classNames from 'classnames';
import { Controller } from 'common/components';
import type * as M from 'models';
import { N_Select, N_Table } from 'usebeauty/dist/@types';
import { InputSearch } from 'common/components/inputSearch/InputSearch';
import type { Action, Data, State } from './CtAdminTable';
import Active from './active/Active';
import Title from './controller/Title';
import AdminSearch from './controller/AdminSearch';
import './AdminTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  isLoadable: boolean;
  isFetchingAdmin: boolean;
}

const ReviewerTable: React.FC<Props> = ({ state, setState, loadHandler, isLoadable, isFetchingAdmin }): JSX.Element => {
  const { header, data, searchKey, pagination, customer_id } = state;

  const handlers = {
    refresh: loadHandler,
    order: (order: N_Table.Sort): void => setState({ type: 'order', payload: order }),
    search: (searchText: string) => setState({ type: 'searchText', payload: Symbol(searchText) }),
    searchKey: (payload: N_Select.Selected<string>): void => setState({ type: 'searchKey', payload }),
    customerId: (selected: N_Select.Selected<number>): void => setState({ type: 'customer_id', payload: selected }),
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
    privacy: (rowData: Data): void => setState({ type: 'privacy', payload: rowData }),
  };

  const order = { sort: state.order, onChange: handlers.order };

  return (
    <section id="root-admin-table" className={classNames({ active: customer_id !== null })}>
      <Controller title={<Title state={state} handlers={handlers} />}>
        <Select selected={searchKey} onChange={handlers.searchKey} disabled={!isLoadable}>
          <Select.Summary />
          <Select.Options className="control-option">
            <Select.Item title="선택" value="all" />
            <Select.Item title="아이디" value="username" />
            <Select.Item title="이름" value="name" />
            <Select.Item title="전화번호" value="phone_number" />
            <Select.Item title="이메일" value="email" />
          </Select.Options>
        </Select>
        <InputSearch
          onChange={handlers.search}
          value={state.searchText.description}
          disabled={!isLoadable || searchKey?.value === 'all'}
        />
      </Controller>
      <Table>
        <Table.Head data={header} order={order} />
        <Table.Body data={data}>
          {({ index, rowData }) => {
            return (
              <Table.Row key={index} st={{ height: 72 }}>
                <>
                  <Table.Tr data={rowData}>
                    {({ value, property }) => {
                      const match = state.privacy?.id === rowData.id;
                      if (property === 'active') {
                        return (
                          <div className="root-page-admin-table-active">
                            <Active index={index} state={state} setState={setState} refresh={handlers.refresh} />
                          </div>
                        );
                      }
                      if (property === 'privacy') {
                        return (
                          <div className={classNames('root-page-admin-table-privacy', { match })}>
                            <i
                              onClick={() =>
                                handlers.privacy(state.privacy?.id === rowData.id ? state.privacy : rowData)
                              }
                            />
                          </div>
                        );
                      }

                      if (match && state.privacy) {
                        const privacy = state.privacy[property as keyof Data] as string | number;
                        return privacy || '-';
                      }

                      return value || '-';
                    }}
                  </Table.Tr>
                  {isFetchingAdmin && state.privacy?.id === rowData.id && (
                    <div className="jack_table__tr loading" key={rowData.id}>
                      LOADING...
                    </div>
                  )}
                </>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      {data.length > 0 && (
        <div className="table__context__page__gear">
          <Pagination data={pagination} onChange={handlers.pagination} />
        </div>
      )}
      {!state.customer_id && <AdminSearch state={state} handlers={handlers} />}
    </section>
  );
};

export default ReviewerTable;
