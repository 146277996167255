import React from 'react';
import { isFunction } from 'lodash';

type FuncEl<T, U, X, Z> = (data: [NonNullable<T>, NonNullable<U>, NonNullable<X>, NonNullable<Z>]) => React.ReactNode;

interface Props<T, U, X, Z> {
  children: FuncEl<T, U, X, Z> | React.ReactNode;
  data: [T] | [T, U] | [T, U, X] | [T, U, X, Z];
}

export function NullishGuard<T, U, X, Z>({ children, data }: Props<T, U, X, Z>) {
  const isHide = data.findIndex((el) => !el);
  const idFunc = isFunction(children);

  if (isHide > -1) return <></>;
  if (idFunc)
    return (
      <>{(children as FuncEl<T, U, X, Z>)(data as [NonNullable<T>, NonNullable<U>, NonNullable<X>, NonNullable<Z>])}</>
    );
  return <>{children}</>;
}
