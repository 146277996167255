import React from 'react';
import { readerForArrayBuffer, readerForDataURL, readerForText } from './file';

export const convertDigit = (epoch: number, digit: number): number => {
  const stringEpoch = Math.floor(epoch).toString();
  if (digit === 10 && stringEpoch.length === 13) return Math.floor(+stringEpoch / 1000);
  if (digit === 13 && stringEpoch.length === 10) return Math.floor(+stringEpoch * 1000);
  return Math.floor(epoch);
};

export const doubleDigit = (n: number): string | number => (n < 10 ? `0${n}` : n);

// epoch 시간을 utc 시간으로 변환
export const timeFormatFromUTCEpoch = (epoch: string | number | null, formatType: number): string => {
  if (!epoch) return '-';
  const epochUTC = convertDigit(epoch ? +epoch : 0, 10);
  const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(epochUTC);
  const yyyy = d.getFullYear();
  const MM = d.getMonth() + 1;
  const dd = d.getDate();
  const hh = d.getHours();
  const mm = d.getMinutes();
  const ss = d.getSeconds();
  if (localStorage.getItem('language') === 'en') {
    // prettier-ignore
    switch(formatType) {
      case 1: return `${doubleDigit(MM)}/${doubleDigit(dd)}/${yyyy} ${doubleDigit(hh)}:${doubleDigit(mm)}:${doubleDigit(ss)}`;
      case 2: return `${doubleDigit(MM)}/${doubleDigit(dd)}/${yyyy} ${doubleDigit(hh)}:${doubleDigit(mm)}`;
      case 3: return `${doubleDigit(MM)}/${doubleDigit(dd)}/${yyyy}`;
      case 10: return `${doubleDigit(hh)}:${doubleDigit(mm)}:${doubleDigit(ss)}`;
    }
  } else {
    // prettier-ignore
    switch(formatType) {
      case 1: return `${yyyy}-${doubleDigit(MM)}-${doubleDigit(dd)} ${doubleDigit(hh)}:${doubleDigit(mm)}:${doubleDigit(ss)}`;
      case 2: return `${yyyy}-${doubleDigit(MM)}-${doubleDigit(dd)} ${doubleDigit(hh)}:${doubleDigit(mm)}`;
      case 3: return `${yyyy}-${doubleDigit(MM)}-${doubleDigit(dd)}`;
      case 4: return `${yyyy}-${doubleDigit(MM)}`;
      case 10: return `${doubleDigit(hh)}:${doubleDigit(mm)}:${doubleDigit(ss)}`;
    }
  }
  return '-';
};

interface props {
  target: HTMLElement | HTMLElement[] | null;
  toggle: boolean;
  close: () => void;
}

const closeHandler = (
  setToggle: () => void,
  target: HTMLElement | HTMLElement[] | null,
  eTarget: EventTarget | null
): void => {
  if (target) {
    if (Array.isArray(target)) {
      for (const t of target as HTMLElement[]) if (t.contains(eTarget as Node)) return;
    } else if ((target as HTMLElement).contains(eTarget as Node)) {
      return;
    }
  }
  setToggle();
};

export const ClickOutsideEffect = ({ target, toggle, close }: props): void => {
  React.useEffect(() => {
    const clickOutsideHandler = (e: MouseEvent) => closeHandler(close, target, e.target);
    document.addEventListener('mousedown', clickOutsideHandler);
    return (): void => document.removeEventListener('mousedown', clickOutsideHandler);
  }, [target, close, toggle]);
};

export const validateLogoImage = async (file: File) => {
  const regExt = new RegExp(`\\.svg$`, 'g').exec(file.name);
  const isSvg = regExt && regExt[0];

  const res = isSvg
    ? await readerForText(file, { extension: ['svg'] })
    : await readerForArrayBuffer(file, { extension: ['png', 'jpeg', 'jpg', 'gif'] });
  return readerForDataURL(file, res.mimeType);
};
