import React from 'react';
import { Button, Dialog } from 'usebeauty';
import './TokenTimeout.scss';

interface Props {
  remain: string;
  closeHandler: () => void;
  submitHandler: () => void;
}
export function Modal_TokenTimeout(props: Props) {
  return (
    <Dialog.Base st={{ width: 500 }} id="token-timeout-modal">
      <Dialog.Summary st={{ textAlign: 'center' }}>
        <i />
        <p>로그아웃 알림</p>
      </Dialog.Summary>
      <Dialog.Content>
        <div className="content">
          <p>로그인 후 30분 동안 서비스 이용이 없어</p>
          <p>자동으로 로그아웃 됩니다.</p>
          <p>계속 이용을 원하시면 로그인을 연장해주세요.</p>
        </div>
        <div className="timeout">
          <span>남은시간 {props.remain}</span>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          className="cancel"
          onClick={() => {
            props.closeHandler();
          }}
        >
          로그아웃
        </Button>
        <Button
          className="submit"
          onClick={() => {
            props.submitHandler();
          }}
        >
          로그인 연장
        </Button>
      </Dialog.Actions>
    </Dialog.Base>
  );
}
