import React from 'react';
import { YearArrow } from '@Icons';
import { useDateFilterContext } from '../popover/Pop_DateFilter';
import './HeadYearComp.scss';

interface Props {
  onChange: (value: string) => void;
  value: string;
  children: JSX.Element;
}

export const HeadYearComp = (props: Props) => {
  const { value, children, onChange } = props;
  const { firstDate } = useDateFilterContext();
  const current = new Date();
  const isLast = +value >= current.getFullYear();
  const isFirst = +value <= (Number(firstDate?.split('-')[0]) || current.getFullYear());

  const prevHandler = () => {
    if (!isFirst) {
      const result = +value - 1;
      onChange(result.toString());
    }
  };

  const nextHandler = () => {
    if (!isLast) {
      const result = +value + 1;
      onChange(result.toString());
    }
  };

  return (
    <div className="head-year-module">
      <div className="head-year-area">
        <div className="prev" onClick={prevHandler}>
          <YearArrow st={{ color: isFirst ? '#cccccc' : '' }} />
        </div>
        <div>{value}년</div>
        <div className="next" onClick={nextHandler}>
          <YearArrow st={{ color: isLast ? '#cccccc' : '' }} />
        </div>
      </div>
      {children}
    </div>
  );
};
