import React, { Dispatch } from 'react';
import { NavLink } from 'react-router-dom';
import { Pagination, Popover, Table } from 'usebeauty';
import { Controller, Pop_DateFilter } from 'common/components';
import type * as M from 'models';
import { getDateFilterLabel } from 'common/functions/time';
import { N_Table } from 'usebeauty/dist/@types';
import { time } from 'common/functions';
import { Action, State } from './CtPrivacyCheck';
import './PrivacyCheck.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const PrivacyCheck = (props: Props) => {
  const { state, setState, loadHandler } = props;

  const handlers = {
    refresh: loadHandler,
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
    order: (order: N_Table.Sort): void => setState({ type: 'order', payload: order }),
  };

  const order: N_Table.Order = { sort: state.order, onChange: handlers.order };

  return (
    <section id="root-privacy-check-info">
      <Controller title={<NavLink to="/root/privacy-check">관리자 로그</NavLink>}>
        <Popover
          className="dashborad-date-filter"
          clickOutside
          position="bottom right"
          content={({ closeHandler }) => (
            <Pop_DateFilter
              closeHandler={closeHandler}
              filter={state.filter}
              filterHandler={(filter) => setState({ type: 'filter', payload: filter })}
            />
          )}
        >
          <div className="select-area">
            {state.filter.day.end ? (
              <>
                <p>{getDateFilterLabel(state.filter)}</p>
                <i
                  className="close"
                  onClick={(e) => {
                    e.stopPropagation();
                    setState({ type: 'filterReset' });
                  }}
                />
              </>
            ) : (
              <div className="date-title">
                <p>기간을 입력하세요</p>
                <i />
              </div>
            )}
          </div>
        </Popover>
      </Controller>
      <Table>
        <Table.Head data={state.header} order={order} />
        <Table.Body data={state.data}>
          {({ rowData }) => {
            return (
              <Table.Row key={rowData.id} st={{ height: 72 }}>
                <Table.Tr data={rowData}>
                  {({ value, property }) => {
                    if (property === 'created_at') {
                      const epoch = new Date(value as string).getTime();
                      return time.timeFormatFromUTCEpoch(epoch, 2);
                    }
                    if (property === 'root_name') return value;
                    if (property === 'client_ip') return value;
                    if (property === 'check_user_name') return value ?? '-';
                    if (property === 'task_id') {
                      // prettier-ignore
                      switch (value) {
                        case 1: return '로그인';
                        case 2: return '로그아웃';
                        case 3: return '개인정보 조회';
                      }
                    }
                    return '-';
                  }}
                </Table.Tr>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination data={state.pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default PrivacyCheck;
