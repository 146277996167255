/* eslint-disable no-plusplus */
import React, { Dispatch } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { Popover } from 'common/components';
import { Action, Data } from '../../../ThemeSettingContent';
import { ChipPiece } from '../../color-chip/static-chip/Chip';
import './CustomChip.scss';

type ColorPickerProps = {
  data: Data;
  setState: Dispatch<Action>;
};

const ColorPicker = ({ setState, data }: ColorPickerProps) => {
  const { theme_color } = data;
  const { fontColor } = theme_color;

  const onChange = (color: ColorResult) => {
    setState({
      type: 'setData',
      payload: { ...data, theme_color: { ...theme_color, fontColor: color.hex.toUpperCase() } },
    });
  };

  return (
    <div className="picker-bgimg">
      <ChromePicker disableAlpha color={fontColor} onChange={onChange} />
    </div>
  );
};

type CustomChipProps = {
  data: Data;
  setState: Dispatch<Action>;
};

const CustomChip = ({ setState, data }: CustomChipProps) => {
  const { theme_color } = data;
  const { fontColor } = theme_color;

  const onClick = () => {
    setState({
      type: 'setData',
      payload: { ...data, theme_color: { ...theme_color, fontColor } },
    });
  };

  return (
    <article>
      <div className="custom-palette">
        <Popover position="top center" clickOutside content={() => <ColorPicker setState={setState} data={data} />}>
          <div className="dynamic-chip" onClick={onClick}>
            <ChipPiece className="checkChip" color={fontColor}>
              <i className="check-icon" />
            </ChipPiece>
          </div>
        </Popover>
      </div>
    </article>
  );
};

export default CustomChip;
