import React from 'react';
import './Promotion.scss';

export const Promotion = () => {
  return (
    <div id="promotion-box">
      <div className="promotion-text">해당 옵션을 구매하시면 사용하실 수 있습니다.</div>
    </div>
  );
};
