import React, { useContext, useEffect, useReducer } from 'react';
import { isObject, pick, pickBy, toPairs } from 'lodash';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { Accordion, Alert, Button, CheckBox, Modal } from 'common/components';
import { NullishGuard } from 'hoc';
import { ContentContext } from 'layout/reviewer/content/Content';
import { getReviewManualsId } from 'common/api';
import classNames from 'classnames';
import type { Data as RowData } from '../CtReviewTable';
import IdCard from './contents/idcard/IdCard';
import Face from './contents/face/Face';
import Account from './contents/account/Account';
import ModalRejectReview from './modal/Modal_RejectReview';
import Custom from './contents/custom/Custom';
import './Expand.scss';

export type Data = typeof getReviewManualsId.res;
export type T_Module = typeof getReviewManualsId.res.module;
export type T_IdCard = typeof getReviewManualsId.res.id_card;
export type T_FaceCheck = typeof getReviewManualsId.res.face_check;
export type T_Account = typeof getReviewManualsId.res.account;
export type T_Custom = typeof getReviewManualsId.res.custom;
export type T_CustomFieldArray = Pick<NonNullable<T_Custom>, 'custom'>['custom'];
export type T_EDDArray = Pick<NonNullable<T_Custom>, 'edd'>['edd'];
export type T_Histories = typeof getReviewManualsId.res.histories;
export type T_RaResult = typeof getReviewManualsId.res.ra_result;

const title = { id_card: '신분증 인증', face_check: '얼굴 인증', account: '기존 계좌 인증', custom: '고객 위험 평가' };
type Key = keyof typeof title;
type List = (['id_card', T_IdCard] | ['face_check', T_FaceCheck] | ['account', T_Account] | ['custom', T_Custom])[];
type Toggle = { id_card: boolean; face_check: boolean; account: boolean; custom: boolean };
export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'toggle'; payload: Partial<Toggle> }
  | { type: 'submitLoading'; payload: boolean }
  | { type: 'isExpired'; payload: boolean }
  | { type: 'confirm'; payload: Key[] };
export interface State {
  data: Data | null;
  toggle: Toggle;
  confirm: Key[];
  submitLoading: boolean;
  isExpired: boolean;
}

const initialState: State = {
  data: null,
  toggle: { id_card: false, face_check: false, account: false, custom: false },
  confirm: [],
  submitLoading: false,
  isExpired: false,
};
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'toggle': draft['toggle'] = { ...draft.toggle, ...action.payload }; break;
      case 'confirm': draft['confirm'] = action.payload; break;
      case 'submitLoading': draft['submitLoading'] = action.payload; break;
      case 'isExpired': draft['isExpired'] = action.payload; break;
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseEddSurvey = (detail: any) => {
  const { edd, custom: respCustom, ra_result } = detail;
  return { ...detail, custom: !edd && !respCustom ? null : { edd, custom: respCustom, ra_result } };
};

interface Props {
  rowData: RowData;
  refresh: () => void;
}

const Expand: React.FC<Props> = ({ rowData, refresh }) => {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState);
  const { toggle, confirm, submitLoading } = state;
  const { id } = rowData;
  const toggleHandler = (payload: Partial<Toggle>) => setState({ type: 'toggle', payload });

  const loadHandler = async () => {
    setLoading(true);
    try {
      const { detail } = await getReviewManualsId(id);
      const res = parseEddSurvey(detail);
      setState({ type: 'setData', payload: res });
      if (detail.name === 'EXPIRED') setState({ type: 'isExpired', payload: true });
    } catch (err) {
      console.log('review expand err', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const submitHandler = async () => {
    setState({ type: 'submitLoading', payload: true });
    process(
      api.reqData({ url: `review/manuals/${id}`, data: { approve: 1, auditor_id: 2 } }),
      api.post.request,
      api.fullFilled(() => {
        refresh();
        set({ success: '심사 승인이 완료되었습니다.' });
      })
    );
  };

  const data = pickBy(pick(state.data, ['id_card', 'face_check', 'account', 'custom']), isObject) as NonNullable<
    Pick<Data, Key>
  >;
  const list = toPairs(data) as List;
  const disabled = list.length !== confirm.length;

  return (
    <NullishGuard data={[state.data]}>
      <section className="reviewer-review-table-tr-expand">
        <div className="content-wrapper">
          <ul>
            {list.map(([k], index) => {
              const find = confirm.find((el) => el === k);
              return (
                <li key={k}>
                  <Accordion
                    toggleOnRow
                    active={toggle[k]}
                    className={classNames({ confirmed: !!find })}
                    onClick={() => {
                      toggleHandler({ [k]: !toggle[k] });
                      if (!find) {
                        const payload = [...confirm, k];
                        setState({ type: 'confirm', payload });
                      }
                    }}
                    title={
                      <div className="title">
                        <CheckBox checked={find ? 1 : 0} className="circular" />
                        <span>{`0${index + 1}`}</span>
                        {title[k]}
                      </div>
                    }
                  >
                    <NullishGuard data={[k === 'id_card', state.data?.id_card, state.data?.module]}>
                      {([, idcard, module]) => (
                        <IdCard rowData={rowData} module={module} content={idcard} isExpired={state.isExpired} />
                      )}
                    </NullishGuard>

                    <NullishGuard data={[k === 'face_check', data.face_check, data.id_card]}>
                      {([, faceCheck, idCard]) => (
                        <Face
                          content={faceCheck}
                          idCard={idCard}
                          module={state.data?.module || null}
                          isExpired={state.isExpired}
                        />
                      )}
                    </NullishGuard>

                    <NullishGuard data={[k === 'account', state.data?.account, state.data?.histories]}>
                      {([, account, histories]) => (
                        <Account content={account} histories={histories} isExpired={state.isExpired} />
                      )}
                    </NullishGuard>
                    <NullishGuard data={[k === 'custom', state.data?.custom]}>
                      {([, custom]) => (
                        <Custom content={custom} ra_result={state.data?.ra_result} isExpired={state.isExpired} />
                      )}
                    </NullishGuard>
                  </Accordion>
                </li>
              );
            })}
          </ul>
        </div>

        <article>
          <div className="decision-buttons">
            <Button
              loading={submitLoading}
              text="승인"
              onClick={submitHandler}
              disabled={disabled || state.isExpired}
            />
            <Modal
              disabled={disabled}
              content={({ closeHandler }) => (
                <ModalRejectReview id={id} closeHandler={closeHandler} refresh={refresh} />
              )}
            >
              <Button loading={submitLoading} text="거부" disabled={disabled} />
            </Modal>
          </div>

          <p>정보 확인 후 심사를 수행하세요.</p>
        </article>
      </section>
    </NullishGuard>
  );
};

export default Expand;
