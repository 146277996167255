import React, { useEffect, useState } from 'react';
import { Input } from 'usebeauty';
import { N_Input } from 'usebeauty/dist/@types';
import classNames from 'classnames';
import './InputSearch.scss';

interface Props extends Omit<N_Input.Props, 'onChange' | 'value'> {
  onChange: (value: string) => void;
  value?: string | symbol;
  immediate?: boolean;
}

export function InputSearch(props: Props) {
  const { value = '', onChange, placeholder = '', className, immediate, ...rest } = props;
  const [search, setSearch] = useState((value as string) || '');

  useEffect(() => {
    setSearch(value as string);
  }, [value]);

  const submitOnEnter = () => {
    onChange(search);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (immediate) onChange(e.target.value);
  };

  return (
    <div className={classNames('search-box', className)}>
      <Input
        value={search}
        onChange={onSearchChange}
        className="filter-search"
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') submitOnEnter();
        }}
        {...rest}
      />
      <button type="button" className="search-button" onClick={submitOnEnter} disabled={rest.disabled}>
        <i />
      </button>
    </div>
  );
}
