import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import type { XYCoord } from 'dnd-core';
import { modal } from 'layout';
import { Accordion, Button, Input, Modal, Switch, Tree } from 'common/components';
import { useControl } from 'common/hooks';
import { EddRa } from '../../context/model.type';
import { FlatTree, flatTreeToTree, treeToFlatTree } from './util';
import Modal_RaDropdown from './modal/Modal_RaDropdown';
import Modal_RaTitleDescGuide from './modal/Modal_RaTitleDescGuide';
import './RiskItem.scss';

export const DragItem = {
  Card: 'Card',
};
type DragItemType = {
  item: EddRa.EddItem;
  index: number;
};

const overMaxScore = (max: number, score: number): boolean => max < score;

interface Props {
  item: EddRa.EddItem;
  index: number;
  edit: boolean;
  active: boolean;
  use_ra: boolean;
  activeComp: JSX.Element;
  updateRaItem: (item: EddRa.EddItem) => void;
  removeRaItem: (item: EddRa.EddItem) => void;
  moveRaItem: (id: number, destIndex: number) => void;
  children?: ({ item, index }: { item: { names: string[]; edd_value: number }; index: number }) => React.ReactNode;
}
function RiskItem(props: Props) {
  const { item, index, edit, active, use_ra, activeComp, updateRaItem, removeRaItem, moveRaItem } = props;
  const { score, tree } = item;
  const [expand, setExpand] = useState(false);
  const [oxAddr, setOxAddr] = useControl({ state: item.type === 'ox_addr' });

  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const [{ opacity }, drag, dragPreview] = useDrag({
    type: DragItem.Card,
    item: () => (edit ? { item, index } : null),
    collect: (monitor) => ({ opacity: monitor.isDragging() ? 0 : 1 }),
    previewOptions: { offsetY: 0 },
  });

  const [, drop] = useDrop<DragItemType, void, { opacity: boolean }>({
    accept: DragItem.Card,
    hover: (dragItem, monitor) => {
      if (!dragRef.current || dragItem.index === index || !edit) {
        return;
      }
      const hoverIndex = index;

      // Determine rectangle on screen
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // console.log({ hoverBoundingRect, hoverMiddleY, clientOffset, hoverClientY });
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragItem.index < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragItem.index > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRaItem(dragItem.index, hoverIndex);
      setExpand(false);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      dragItem.index = hoverIndex;
    },
  });

  drag(dragRef);
  drop(dragPreview(previewRef));

  const flatTree = treeToFlatTree(tree);
  const checkScore = flatTree.every((ft) => ft.score <= score);

  const handleScoreChange = (nextFlatTree: FlatTree[]) => {
    const tree = flatTreeToTree(nextFlatTree);
    updateRaItem({ ...item, tree });
  };

  const submitHandler = (name: string, nextTree: Tree[]) => {
    updateRaItem({ ...item, name, tree: nextTree });
  };

  return (
    <div
      className={classNames('accordion__section', {
        expand,
        inactive: !active,
        error: item.active && use_ra && !checkScore,
      })}
      style={{ opacity }}
      ref={previewRef}
    >
      <Accordion
        active={expand}
        onClick={() => setExpand((prev) => !prev)}
        title={
          <>
            <div className="item__title">
              <div ref={dragRef} className={classNames({ draggable: edit })}>
                {edit && <i className="item__drag__handle" />}
                <b>{item.name}</b>
                {item.deletable && edit ? (
                  <Modal
                    content={({ closeHandler }) => (
                      <div id="modal-ra-item-remove">
                        <modal.Container styles={{ width: 500 }}>
                          <modal.Header align="center">항목 삭제</modal.Header>
                          <modal.Content>
                            <div className="detail">
                              해당 항목을 삭제하시겠습니까?
                              <br />
                              삭제 시 이전에 입력한 선택지는 모두 초기화됩니다.
                            </div>
                          </modal.Content>
                          <modal.Footer
                            submitText="삭제"
                            submitHandler={() => removeRaItem(item)}
                            closeHandler={closeHandler}
                          />
                        </modal.Container>
                      </div>
                    )}
                  >
                    <i className="accordion__remove__icon" />
                  </Modal>
                ) : (
                  <></>
                )}
              </div>
              <div className={classNames({ hide: !edit })}>{activeComp}</div>
              <div className={classNames('max__score', { hide: !use_ra, edit, error: item.active && !checkScore })}>
                <span>최대 점수</span>
                {edit ? (
                  <>
                    <Input
                      className={classNames('basic', { error: item.active && !checkScore })}
                      styles={{ width: 60 }}
                      disabled={!active}
                      type="text"
                      value={score}
                      onChange={(e) => {
                        const { value } = e.target;
                        let numValue = Number(value.replace(/[^\d]/g, ''));
                        numValue = numValue > 100 ? 100 : numValue;
                        updateRaItem({ ...item, score: numValue });
                      }}
                    />
                    점
                  </>
                ) : (
                  <div>{score}점</div>
                )}
              </div>
            </div>
          </>
        }
      >
        <section className={classNames('item__option', 'description', { edit })}>
          {edit ? (
            <>
              <div>
                <h4>제목</h4>
                <span>(선택)</span>
                <Input
                  type="text"
                  className="basic"
                  validType="realtime"
                  maxLength={20}
                  disabled={!edit || !active}
                  rules={['maxLength']}
                  placeholder="최대 20자까지 작성하실 수 있습니다"
                  value={item.display_name}
                  onChange={(e) => {
                    const { value } = e.target;
                    updateRaItem({ ...item, display_name: value });
                  }}
                />
              </div>
              <div>
                <h4>설명</h4>
                <span>(선택)</span>
                <Input
                  type="text"
                  className="basic"
                  validType="realtime"
                  maxLength={50}
                  rules={['maxLength']}
                  disabled={!edit || !active}
                  placeholder="최대 50자까지 작성하실 수 있습니다"
                  value={item.description}
                  onChange={(e) => {
                    const { value } = e.target;
                    updateRaItem({ ...item, description: value });
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div>{item.display_name ? item.display_name : '작성된 제목이 없습니다'}</div>
              <div>{item.description ? item.description : '작성된 설명이 없습니다'}</div>
            </>
          )}

          {edit && (
            <Modal content={({ closeHandler }) => <Modal_RaTitleDescGuide closeHandler={closeHandler} />}>
              <Button type="button" className="description__guide">
                <div>제목 및 설명 작성 가이드</div>
                <i className="description__guide" />
              </Button>
            </Modal>
          )}
        </section>
        {item.name.indexOf('국내 거주') > -1 && (
          <section className={classNames('item__option', { edit, active: oxAddr })}>
            <h4>상세주소 수집</h4>
            <span>고객의 현재 거주 국가 및 상세 주소를 함께 수집합니다</span>
            <Switch
              disabled={!edit || !item.active}
              value={oxAddr}
              onChange={(value) => {
                setOxAddr(!!value);
                const type = value === 1 ? 'ox_addr' : 'ox';
                updateRaItem({ ...item, type });
              }}
            />
          </section>
        )}
        <div className="item__subtree header">
          <span>선택지</span>
        </div>
        <div className="item__subtree header">
          <span>대분류</span>
          <span>중분류</span>
          <span>소분류</span>
          <span className={classNames('score', { hide: !use_ra })}>점수</span>
        </div>
        {flatTree.map((ft, i) => {
          const [c1, c2, c3] = ft.names;
          const error = overMaxScore(score, ft.score) && active;
          return (
            <div className="item__subtree" key={i}>
              <span>{c1}</span>
              <span>{c2}</span>
              <span>{c3}</span>
              <span className={classNames(['score', { hide: !use_ra, error }])}>
                {error && <span className="error__message">최대 점수를 넘을 수 없습니다</span>}
                {edit ? (
                  <>
                    <Input
                      className={classNames('basic', { error })}
                      styles={{ width: 60 }}
                      disabled={!active}
                      type="text"
                      value={ft.score}
                      onChange={(e) => {
                        const { value } = e.target;
                        let numValue = Number(value.replace(/[^\d]/g, ''));
                        numValue = numValue > 100 ? 100 : numValue;
                        const newFlatTree = flatTree.map((t, j) => {
                          if (i === j) return { ...t, score: numValue };
                          return t;
                        });
                        handleScoreChange(newFlatTree);
                      }}
                    />
                    점
                  </>
                ) : (
                  <div>{ft.score}점</div>
                )}
              </span>
            </div>
          );
        })}
      </Accordion>
      {edit && expand && active && item.mutable ? (
        <div className="item__subtree footer">
          <Modal
            content={({ closeHandler }) => (
              <Modal_RaDropdown
                closeHandler={closeHandler}
                submitHandler={submitHandler}
                eddCategory={item.category}
                item={item}
              />
            )}
          >
            <Button type="button" text="선택지 설정" />
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default RiskItem;
