import React, { useReducer } from 'react';
import { getCustomerAdmin, getCustomerAdmins, getCustomers } from 'common/api';
import { KycLoading, M_Table } from 'common/components';
import { useApiCancel } from 'common/hooks';
import { produce } from 'immer';
import { camelCase, isNumber } from 'lodash';
import { N_Pagination, N_Select, N_Table } from 'usebeauty/dist/@types';
import { toSortUpperCase } from 'common/functions';
import { api_response } from 'common/api/model';
import { AxiosResponse } from 'axios';
import { useKycQuery } from 'common/api/usequery';
import AdminTable from './AdminTable';

const initPagination = { info: { current: 1, total: 10 }, format: { limit: 20, offset: 0 } };
export type Data = typeof getCustomerAdmins.res;

export type Resp<K> = AxiosResponse<api_response & K>;

export type Action =
  | { type: 'order'; payload: N_Table.Sort }
  | { type: 'setData'; payload: Data[] }
  | { type: 'searchText'; payload: symbol }
  | { type: 'searchKey'; payload: N_Select.Selected<string> }
  | { type: 'init'; payload?: string }
  | { type: 'pagination'; payload: N_Pagination.Data }
  | { type: 'expand'; payload: number }
  | { type: 'customer_id'; payload: N_Select.Selected<number> }
  | { type: 'customers'; payload: N_Select.Selected<string | number>[] }
  | { type: 'privacy'; payload: Data };

export interface State {
  data: Data[];
  header: M_Table.header[];
  searchKey: N_Select.Selected<string>;
  searchText: symbol;
  order: N_Table.Sort;
  pagination: N_Pagination.Data;
  customer_id: N_Select.Selected<number>;
  customers: N_Select.Selected<string | number>[];
  privacy: Data | null;
}

const initialState: State = {
  data: [],
  searchKey: { title: '선택', value: 'all' },
  order: { id: 'desc' },
  searchText: Symbol(''),
  pagination: { ...initPagination },
  customer_id: null,
  customers: [],
  header: [
    { title: '아이디', property: 'username', width: 200, align: 'center', order: true },
    { title: '이름', property: 'name', width: 200, align: 'center', order: true },
    { title: '전화번호', property: 'phone_number', width: 148, align: 'center', order: true },
    { title: '이메일', property: 'email', width: 288, align: 'center', order: true },
    { title: '활성여부', property: 'active', width: 132, align: 'center' },
    { title: '개인정보 표시', property: 'privacy', width: 112, align: 'center' },
  ],
  privacy: null,
};

// prettier-ignore
const reducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'order': draft['order'] = action.payload; break;
      case 'searchText': 
        draft['searchText'] = action.payload; 
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'searchKey': 
        draft['searchKey'] = action.payload; 
        if(action.payload?.value==='all')draft['searchText'] =  Symbol('')
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'customer_id': draft['customer_id'] = action.payload; break;
      case 'pagination': draft['pagination'] = action.payload; break;
      case 'customers': draft['customers'] = action.payload; break;
      case 'privacy': draft['privacy'] = action.payload; break;
    }
  });
};

const CtAdminTable: React.FC = () => {
  const [state, setState] = useReducer(reducer, initialState);
  useApiCancel([`customers/${state.customer_id}/admins`]);

  const [sortKey, sortValue] = Object.entries(state.order)[0];

  const params = {
    start: state.pagination.format.offset,
    count: state.pagination.format.limit,
    keyword_field: state.searchKey?.value ?? '',
    keyword: state.searchText.description?.trim(),
    order_field: camelCase(sortKey),
    order: toSortUpperCase(sortValue),
  };

  const isLoadable = isNumber(state.customer_id?.value);

  const { refetch, isFetching } = useKycQuery<{ list: (typeof getCustomerAdmins.res)[] }>({
    key: [
      'getCustomerAdmins',
      state.customer_id?.value,
      state.order,
      state.searchText.description,
      state.pagination.info.current,
      state.searchKey,
    ],
    handler: () => getCustomerAdmins(state.customer_id?.value ?? 0, params),
    options: {
      enabled: isLoadable,
      onSuccess: (response) => {
        setState({ type: 'setData', payload: response.data.list });
      },
    },
  });

  const { isFetching: isFetchingAdmin } = useKycQuery<{ account: typeof getCustomerAdmin.res }>({
    key: [state.privacy?.id],
    handler: () => getCustomerAdmin(state.customer_id?.value ?? 0, state.privacy?.id as number),
    options: {
      enabled: !!state.privacy,
      onSuccess: (response) => {
        setState({ type: 'privacy', payload: response.data.account });
      },
    },
  });

  useKycQuery<{ list: (typeof getCustomers.res)[] }>({
    handler: () => getCustomers(),
    options: {
      onSuccess: (response) => {
        const result = response.data.list.map(({ name, id }: typeof getCustomers.res) => ({ title: name, value: id }));
        setState({ type: 'customers', payload: result });
      },
    },
  });

  return (
    <>
      {(isFetching || isFetchingAdmin) && <KycLoading />}
      <AdminTable
        state={state}
        setState={setState}
        loadHandler={refetch}
        isLoadable={isLoadable}
        isFetchingAdmin={isFetchingAdmin}
      />
    </>
  );
};

export default CtAdminTable;
