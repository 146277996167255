/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { useControl } from 'common/hooks';

interface I_DualSlider {
  className?: string;
  lower: { value: number; setValue: Dispatch<number> };
  upper: { value: number; setValue: Dispatch<number> };
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  gap?: number;
  areaColor?: { lower?: string; middle?: string; upper?: string };
  onChange?: (item: { lower: number; upper: number }) => void;
  onMouseUp?: (item: { lower: number; upper: number }) => void;
}

export const DualSlider: React.FC<I_DualSlider> = ({
  className,
  lower,
  upper,
  disabled = false,
  min = 1,
  max = 99,
  step = 1,
  gap = 3,
  areaColor = { lower: '#ededed', middle: '#b68184', upper: '#ededed' },
}): JSX.Element => {
  const [lowerValue, setLowerValue] = useControl({ state: lower.value, dispatcher: lower.setValue });
  const [upperValue, setUpperValue] = useControl({ state: upper.value, dispatcher: upper.setValue });

  const lowerPoint = ((lowerValue * 10) / 100) * 10;
  const upperPoint = ((upperValue * 10) / 100) * 10;

  return (
    <div className={classNames('custom__multi__range__slider', { [className as string]: className, disabled })}>
      <input
        type="range"
        min={min}
        max={max}
        disabled={disabled}
        step={step}
        value={lowerValue}
        className="custom__range__slider__lower"
        onChange={(e) => {
          setLowerValue(+e.target.value + gap >= upperValue ? upperValue - gap : +e.target.value);
        }}
      />
      <input
        type="range"
        min={min}
        max={max}
        disabled={disabled}
        step={step}
        value={upperValue}
        className="custom__range__slider__upper"
        onChange={(e) => {
          setUpperValue(+e.target.value - gap <= lowerValue ? lowerValue + gap : +e.target.value);
        }}
      />
      <div
        className="range"
        style={
          min === 1
            ? {
                background: `-webkit-linear-gradient(left, 
                  ${areaColor.lower} ${lowerPoint}%,
                  ${areaColor.middle} ${lowerPoint}%,
                  ${areaColor.middle} ${upperPoint}%,
                  ${areaColor.upper} ${upperPoint}%)`,
              }
            : { background: `${areaColor.middle}` }
        }
      />
    </div>
  );
};
