import React, { Dispatch, useContext, useState } from 'react';
import { Radio } from 'usebeauty';
import classNames from 'classnames';
import { api, process } from 'common/functions';
import { Alert } from 'common/components';
import { ContentContext } from 'layout/admin/content/Content';
import { Action, State } from '../AuthenticateContent';
import EditComp from '../edit/EditComp';
import './AutoCorrectionBirth.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const AutoCorrectionBirth = (props: Props) => {
  const { state, setState, loadHandler } = props;
  const { auto_correction_birth } = state.vas;
  const { set } = Alert.Context();
  const { setLoading } = useContext(ContentContext);
  const [edit, setEdit] = useState(false);

  const submitHandler = async () => {
    const data = { auto_correction_birth: String(auto_correction_birth) };
    setLoading(true);
    await process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled((response) => {
        setEdit(false);
        setLoading(false);
        loadHandler();
        if (response) {
          set({ success: `생년월일 자동 수정이 ${auto_correction_birth ? '설정' : '중지'}되었습니다.` });
        } else {
          set({ fail: `생년월일 자동 수정 설정에 실패했습니다.` });
        }
      })
    );
  };

  const handler = { submitHandler, loadHandler };

  return (
    <section id="admin-manage-authenticate-auto-correction-birth" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: auto_correction_birth })}>생년월일 자동 수정</h4>
            <p>사용자 필수 정보의 생년월일 값과 OCR 결과가 다르면 고객 확인 후 자동으로 수정합니다.</p>
          </div>
        </div>
        <EditComp
          setEdit={setEdit}
          edit={edit}
          setState={setState}
          handler={handler}
          data={{ key: 'auto_correction_birth', value: auto_correction_birth }}
        />
      </div>
      <div className="description">
        {edit ? (
          <div className="edit-option">
            <Radio.Group
              className="radio"
              value={auto_correction_birth}
              disabled={!edit}
              onChange={(value) => setState({ type: 'vas', payload: { auto_correction_birth: value } })}
            >
              <Radio text="사용함" value>
                <p>생년월일 값과 OCR 결과가 다르면 고객 확인 후 자동으로 수정합니다.</p>
              </Radio>
              <Radio text="사용 안 함" value={false}>
                <p>생년월일 값을 OCR 결과와 비교하지 않습니다</p>
              </Radio>
            </Radio.Group>
          </div>
        ) : (
          <div className="display-option">
            <h6>{auto_correction_birth ? '사용함' : '사용 안 함'}</h6>
            <span>
              {auto_correction_birth
                ? '생년월일 값과 OCR 결과가 다르면 고객 확인 후 자동으로 수정합니다.'
                : '생년월일 값을 OCR 결과와 비교하지 않습니다'}
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default AutoCorrectionBirth;
