import React from 'react';

type Props = {
  element?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  st?: React.CSSProperties;
  justifyContent?: string;
  alignItems?: string;
  className?: string;
  innerRef?: React.RefObject<HTMLElement>;
};

export function Box(props: Props) {
  const { element = 'div', st = {}, children, innerRef } = props;
  return React.createElement(element, { ref: innerRef, style: { ...st }, className: props.className }, children);
}

export function FlexBox(props: Props) {
  const { children, st, className } = props;
  const { justifyContent = 'flex-start', alignItems = 'center' } = { ...st };

  return (
    <div style={{ ...st, display: 'flex', justifyContent, alignItems }} className={className}>
      {children}
    </div>
  );
}
