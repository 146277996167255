import React, { Dispatch } from 'react';
import { Action, State } from '../ExaminationContent';
import IdcardSetting from './idcardSetting/IdcardSetting';
import CommonSetting from './common/CommonSetting';
import './ExaminationSetting.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const ExaminationSetting: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  return (
    <div id="admin-manage-examination-setting">
      <CommonSetting state={state} setState={setState} loadHandler={loadHandler} />
      {state.module.id_card_ocr && <IdcardSetting state={state} setState={setState} loadHandler={loadHandler} />}
    </div>
  );
};

export default ExaminationSetting;
