import React from 'react';
import { Button, Dialog } from 'usebeauty';
import ModalRegistClient from './modal/Modal_RegistClient';
import './Title.scss';

interface Props {
  refresh: () => void;
}
const Title: React.FC<Props> = ({ refresh }) => {
  return (
    <div id="root-client-controller-title">
      <p>고객사 관리</p>
      <Dialog content={({ closeHandler }) => <ModalRegistClient closeHandler={closeHandler} refresh={refresh} />}>
        <Button className="register">+ 등록</Button>
      </Dialog>
    </div>
  );
};

export default Title;
