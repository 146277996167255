import React, { Dispatch, useState } from 'react';
import { Radio } from 'usebeauty';
import classNames from 'classnames';
import { Alert, Input } from 'common/components';
import { api, process } from 'common/functions';
import { Action, State } from '../../AccountContent';
import EditComp from '../edit/EditComp';
import { useInputHook } from './func';
import './AccountSubcode.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const AccountSubcode = ({ state, setState, loadHandler }: Props) => {
  const { set } = Alert.Context();
  const { use_account_subcode, account_subcode, account_subcode_position } = state.data.vas;
  const [edit, setEdit] = useState(false);
  const { setSubcodeLeft, setSubcodeRight, subcodeLeft, subcodeRight } = useInputHook(
    account_subcode_position,
    account_subcode
  );
  const isSubcodeLeft = account_subcode_position === 'left';

  const submitHandler = () => {
    setEdit(false);
    const data = {
      use_account_subcode: String(use_account_subcode),
      account_subcode_position,
      account_subcode: isSubcodeLeft ? subcodeLeft : subcodeRight,
    };
    process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled((response) => {
        setEdit(false);
        loadHandler();
        if (response) {
          set({ success: `인증 문구가 ${use_account_subcode ? '설정' : '중지'}되었습니다.` });
        } else {
          set({ fail: `인증 문구 설정에 실패하였습니다.` });
        }
      })
    );
  };

  const radioChange = (value: string) => {
    if (value === 'left') setSubcodeRight('');
    else if (value === 'right') setSubcodeLeft('');
    setState({ type: 'vas', payload: { account_subcode_position: value, account_subcode: '' } });
  };

  const { use_account_subcode: d_uas, account_subcode_position: d_uasp, account_subcode: d_as } = state.data.defaultVas;

  const isDirty =
    JSON.stringify({ use_account_subcode, account_subcode_position, account_subcode }) !==
    JSON.stringify({ use_account_subcode: d_uas, account_subcode_position: d_uasp, account_subcode: d_as });
  const inputRestHandler = () => {
    setSubcodeLeft(d_uasp === 'left' ? d_as : '');
    setSubcodeRight(d_uasp === 'right' ? d_as : '');
  };
  const isEmpty =
    use_account_subcode &&
    ((account_subcode_position === 'left' && account_subcode.length === 0) ||
      (account_subcode_position === 'right' && account_subcode.length === 0));
  const isCharacter = account_subcode.length > 0 && /^[a-zA-Z가-힣]*$/.test(account_subcode);
  const disabled = !isDirty || isEmpty || !isCharacter;

  const handler = { loadHandler, submitHandler };

  return (
    <section id="account-setting-subcode" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: use_account_subcode })}>인증 문구</h4>
            <p>1원 계좌 인증 시 인증코드와 함께 표시되는 문구를 설정할 수 있습니다. 미사용시 인증코드만 표시됩니다.</p>
          </div>
        </div>
        <EditComp
          disabled={disabled}
          setCancel={() => {
            inputRestHandler();
            setState({ type: 'reset', payload: { key: 'account_subcode_position' } });
            setState({ type: 'reset', payload: { key: 'account_subcode' } });
          }}
          onSwitch
          setEdit={setEdit}
          edit={edit}
          setState={setState}
          handler={handler}
          data={{ key: 'use_account_subcode', value: use_account_subcode }}
        />
      </div>

      {use_account_subcode && (
        <div className="description">
          <div className={classNames('preview-box', { right: !isSubcodeLeft })}>
            <div className={classNames('preview', { left: isSubcodeLeft, right: !isSubcodeLeft })}>
              <span>{use_account_subcode ? account_subcode : ''}</span>
              {!isSubcodeLeft && (
                <span className="info">
                  ※ 금융기관 및 증권사별로 글자 제한수가 있어, 인증문구 일부가 나타나지 않을 수 있습니다. <br />
                  (예: 감사합니다 → 감사합니)
                </span>
              )}
            </div>
          </div>
          {edit ? (
            <div className="edit-option">
              <Radio.Group value={account_subcode_position} disabled={!edit} onChange={radioChange}>
                <Radio text="좌측 표시" value="left">
                  <>
                    {isSubcodeLeft && (
                      <div className="input-area">
                        <Input
                          styles={{ width: 97, height: 40, radius: 8 }}
                          validType="realtime"
                          rules={['character']}
                          disabled={!edit}
                          maxLength={2}
                          placeholder="최대 2글자"
                          value={subcodeLeft}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSubcodeLeft(value);
                            setState({ type: 'vas', payload: { account_subcode: value } });
                          }}
                        />
                        + 인증번호
                      </div>
                    )}
                  </>
                </Radio>
                <Radio text="우측 표시" value="right">
                  <>
                    {!isSubcodeLeft && (
                      <div className="input-area">
                        인증번호 +
                        <Input
                          styles={{ width: 97, height: 40, radius: 8 }}
                          validType="realtime"
                          rules={['character']}
                          disabled={!edit}
                          maxLength={5}
                          placeholder="최대 5글자"
                          value={subcodeRight}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSubcodeRight(value);
                            setState({ type: 'vas', payload: { account_subcode: value } });
                          }}
                        />
                      </div>
                    )}
                  </>
                </Radio>
              </Radio.Group>
            </div>
          ) : (
            <div className="display-option">
              <div className="display-wrapper">
                <h6>{isSubcodeLeft ? '좌측표시' : '우측표시'}</h6>
                <div className="display-container">
                  <div className={classNames('certification', { right: !isSubcodeLeft })}>
                    <div className="value">{account_subcode}</div>
                    <div>+</div>
                    <div>인증번호</div>
                  </div>
                </div>
              </div>
              {!isSubcodeLeft && (
                <p className="subcode-right-info-text">
                  ※ 금융기관 및 증권사별로 글자 제한수가 있어, 인증문구 일부가 나타나지 않을 수 있습니다.
                  <br />
                  (예: 감사합니다 → 감사합니)
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default AccountSubcode;
