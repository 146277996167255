import React, { Dispatch } from 'react';
import type { Action, State } from '../ExaminationContent';
import ExaminationTable from './ExaminationSetting';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const CtExaminationTable: React.FC<Props> = ({ state, setState, loadHandler }) => {
  return <ExaminationTable state={state} setState={setState} loadHandler={loadHandler} />;
};

export default CtExaminationTable;
