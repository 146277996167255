import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from 'usebeauty';
import { authActions } from 'store/actions/admin';
import { useDispatch, useSelector } from 'common/hooks/admin';
import { Modal_DuplicateLogin, Modal_TokenTimeout } from 'common/components';
import { useCountdown, useSSE } from 'common/hooks';

const TIMER = {
  MINUTE_1: 1000 * 60 * 1,
  MINUTE_30: 1000 * 60 * 30,
  HOUR_1: 1000 * 60 * 60,
} as const;

interface Props {
  children: React.ReactNode;
}
export const Authentication: React.FC<Props> = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useSSE(auth.data?.token ?? '', () => {
    // 서버로부터 sse message로 중복로그인 메시지 받으면 store 비우고, 중복로그인 true
    dispatch(authActions.setIsDuplicateLogin(true));
    dispatch(authActions.unMount());
  });
  const { remainTimeString } = useCountdown(
    auth.data?.expire ?? '',
    (time) => dispatch(authActions.timer({ time })),
    () => {
      if (!auth.isDuplicateLogin) dispatch(authActions.logout({ navigate }));
    }
  );

  const isTimeout = auth.timer <= TIMER.MINUTE_1;

  useEffect(() => {
    const data = localStorage.getItem('ADMIN_TOKEN');
    try {
      if (data) {
        const authObj = JSON.parse(data);
        const expire = new Date(authObj.expire).getTime();
        if (expire - Date.now() <= 0) throw new Error('로그인 만료');
        dispatch(authActions.setData(authObj));
        const remain = Math.min(TIMER.MINUTE_30, expire - Date.now());
        dispatch(authActions.timer({ time: remain }));
      }
      if (!data) throw new Error('로그인 필요');
    } catch (err) {
      const { message } = err as { message: string };
      console.warn(message);
      dispatch(authActions.logout({ navigate }));
    }
  }, [location.pathname]);

  return (
    <>
      {auth.data && children}
      <Dialog
        open={(isTimeout && !!remainTimeString) || auth.isDuplicateLogin}
        content={({ closeHandler }) =>
          auth.isDuplicateLogin ? (
            <Modal_DuplicateLogin
              submitHandler={() => {
                dispatch(authActions.logout({ navigate }));
                closeHandler();
              }}
            />
          ) : (
            <Modal_TokenTimeout
              remain={remainTimeString}
              submitHandler={() => {
                dispatch(authActions.refreshToken());
                closeHandler();
              }}
              closeHandler={() => {
                dispatch(authActions.logout({ navigate }));
                closeHandler();
              }}
            />
          )
        }
      />
    </>
  );
};
