import { format } from "date-fns";

export const formetterHandler = ({ start, end }: { start?: Date | null; end?: Date | null }) => {
  const result = { start: "", end: "" };
  if (start) {
    const S = format(start, "yyyy-MM-dd");
    result.start = S;
  }
  if (end) {
    const F = format(end, "yyyy-MM-dd");
    result.end = F;
  }

  return { ...result };
};
