import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { parseConfigs } from 'common/functions';
import { Button, M_Select, Popover } from 'common/components';
import { ContentContext } from 'layout/admin/content/Content';
import { getConfigs, getModules } from 'common/api';
import Promotion from '../promotion/Promotion';
import Forbidden from '../forbidden/Forbidden';
import RaWeightProvider from '../context/WeightContext';
import WeightSetting from './settings/WeightSetting';
import './WeightContent.scss';

type Data = {
  module: { edd_field: boolean };
  configs: {
    allow_ra: boolean;
    use_edd: boolean;
    use_ra: boolean;
    use_custom_field: boolean;
    edd_countries_mode: number;
  };
  template: M_Select.item;
};
export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
};

export type Action = { type: 'edit'; payload: boolean } | { type: 'data'; payload: Partial<Data> };

const initialData = {
  module: { edd_field: false },
  configs: { allow_ra: false, use_edd: false, use_ra: false, use_custom_field: false, edd_countries_mode: 1 },
  template: { title: '기본 템플릿 설정', value: 'default' },
};
const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'edit': draft['edit'] = action.payload; break;
      case 'data': draft['data'] = { ...draft.data, ...action.payload }; break;
    }
  });
};

function WeightContent() {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { module, configs } = state.data;

  const loadHandler = async () => {
    setLoading(true);

    const configsList = await getConfigs();
    const modules = await getModules();
    const configs = parseConfigs(configsList, Object.keys(initialData.configs) as Array<keyof Data['configs']>);

    setState({ type: 'data', payload: { module: modules, configs } });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-edd-weight-content">
      {module.edd_field && configs.allow_ra ? (
        <>
          <RaWeightProvider>
            {configs.use_edd && configs.use_ra ? (
              <>
                <RaWeightProvider.Consumer>
                  {(context) => {
                    const { state: contextState, setState: contextSetState, submitHandler } = context;
                    return (
                      <div className="header">
                        <h3>
                          가중치 설정
                          <div className="tooltip">
                            <Popover
                              position="bottom center"
                              clickOutside
                              content={() => (
                                <div id="admin-edd-setting-ra-weight-popover-content" className="description">
                                  각 위험이 얼만큼의 비중을 차지하는지 설정하는 값입니다.
                                </div>
                              )}
                            >
                              <i className="tooltip-info" />
                            </Popover>
                          </div>
                        </h3>
                        <div className="button-group">
                          {state.edit ? (
                            <>
                              <Button
                                text="취소"
                                onClick={() => {
                                  contextSetState({ type: 'data', payload: contextState.defaultData });
                                  contextSetState({ type: 'extraWeight', payload: null });
                                  setState({ type: 'edit', payload: false });
                                }}
                              />
                              <Button
                                text="저장"
                                disabled={contextState.extraWeight !== 0}
                                onClick={() => {
                                  submitHandler();
                                  setState({ type: 'edit', payload: false });
                                }}
                              />
                            </>
                          ) : (
                            <Button text="편집" onClick={() => setState({ type: 'edit', payload: true })} />
                          )}
                        </div>
                      </div>
                    );
                  }}
                </RaWeightProvider.Consumer>
                <div className="weight-content">
                  <WeightSetting
                    edit={state.edit}
                    toggleEdit={() => setState({ type: 'edit', payload: !state.edit })}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="header">
                  <h3>가중치 설정</h3>
                </div>
                <Forbidden
                  content={
                    <>
                      <p>RA 기능을 활성화 하신 후</p>
                      <p>상세 가중치를 설정하실 수 있습니다</p>
                    </>
                  }
                />
              </>
            )}
          </RaWeightProvider>
        </>
      ) : (
        <Promotion />
      )}
    </div>
  );
}

export default WeightContent;
