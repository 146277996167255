import React, { Dispatch } from 'react';
import { useControl } from 'common/hooks';
import classNames from 'classnames';
import './SingleSlider.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  value: { value: number; setValue: Dispatch<number> };
  min?: number;
  max?: number;
  step?: number;
}

function SingleSlider(props: Props) {
  const { className, disabled, value, min = 1, max = 99, step = 1 } = props;

  const [sliderValue, setSliderValue] = useControl({ state: value.value, dispatcher: value.setValue });

  const sliderThumb = ((sliderValue - min) / (max - min)) * 100;

  const dots = Array.from(new Array(Math.floor(max / step)), (_, i) => i);

  return (
    <div className={classNames('single__slider__container', { [className as string]: className, disabled })}>
      <div className="slider">
        <input
          type="range"
          className="single__slider"
          disabled={disabled}
          value={sliderValue}
          min={min}
          max={max}
          onChange={(e) => {
            const { value } = e.target;
            const numValue = Number(value);
            setSliderValue(numValue);
          }}
        />
        <div
          className="slider__rail"
          style={{
            background: `-webkit-linear-gradient(left, 
                #ffffff 8px,
                #f53c3c 8px,
                #f53c3c ${sliderThumb}%,
                #12b78f ${sliderThumb}%,
                #12b78f calc(100% - 8px),
                #ffffff calc(100% - 8px))`,
          }}
        >
          <div className="dots">
            {dots.map((value, i) => {
              const dot = value + 1 < sliderValue;
              const match = i + 1 === sliderValue;
              return <i key={i} className={classNames({ dot, match })} />;
            })}
          </div>
          <div className="counts">
            {dots.map((value, i) => {
              const count = value + 1 < sliderValue;
              return (
                <span key={i} className={classNames({ count })}>
                  {value + 1}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleSlider;
