import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { pick } from 'lodash';
import { Popover, Switch } from 'common/components';
import { Action, Data, initConfig } from '../../Expand';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

export const AccountComp = (props: Props) => {
  const { data, setState, edit } = props;
  const { module, config } = data;
  return (
    <div className="setting-item">
      <div className={classNames('switch-box', module.account_verification ? 'active' : 'inactive')}>
        계좌 인증
        <Switch
          disabled={!edit}
          className={classNames({ readonly: !edit })}
          value={module.account_verification}
          onChange={(value) => {
            setState({ type: 'module', payload: { account_verification: !!value } });
            setState({
              type: 'config',
              payload: pick(initConfig, ['allow_account_subcode', 'use_name_verification']),
            });
          }}
        />
      </div>
      <div className="setting-item-content">
        <div className="category">
          <div className="cate-title">
            예금주명 조회
            <Switch
              disabled={!edit || !module.account_verification}
              className={classNames({ readonly: !edit })}
              value={config.use_name_verification}
              onChange={(value) => setState({ type: 'config', payload: { use_name_verification: !!value } })}
            />
          </div>
        </div>
        <div className="category">
          <div className="cate-title">
            <div className="flex-box">
              인증 문구
              <Popover
                clickOutside
                position="right bottom"
                content={() => (
                  <div id="root-page-client-expand-popover" className="description">
                    입력한 문구는 1원 송금 시 예금주의 계좌에
                    <br />
                    인증코드와 함께 표시됩니다.
                  </div>
                )}
              >
                <i className="tooltip" />
              </Popover>
            </div>
            <Switch
              disabled={!edit || !module.account_verification}
              className={classNames({ readonly: !edit })}
              value={config.allow_account_subcode}
              onChange={(value) => setState({ type: 'config', payload: { allow_account_subcode: !!value } })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
