import React, { useReducer } from 'react';
import { Dialog, Loading, Popover, Select } from 'usebeauty';
import { produce } from 'immer';
import { useKycQuery } from 'common/api/usequery';
import { getCustomers, getDashboardDataAll, getDashboardDataDaily } from 'common/api';
import { N_Select } from 'usebeauty/dist/@types';
import { NullishGuard } from 'hoc';
import { Pop_DateFilter } from 'common/components';
import type { T_Filter } from 'common/components/datefilter/popover/Pop_DateFilter';
import { getDateFilterLabel } from 'common/functions/time';
import { format } from 'date-fns';
import Doughnut from 'common/components/chart/doughnut/Doughnut';
import { DoughnutNoData, Filter } from '@Icons';
import { Detail } from '../Expand';
import './Modal_Dashboard.scss';

const numberWithComma = (value: number) => Number(value).toLocaleString('ko-KR');

const reportMapper: Record<ReportKey, string> = {
  dropout: '중도 이탈',
  auto_approve: '자동 승인',
  auto_reject: '자동 거부',
  manual_approve: '수동 승인',
  manual_reject: '수동 거부',
  manual_wait: '심사 대기',
};

type DataAll = typeof getDashboardDataAll.res;
type ReportKey = keyof DataAll['report'];

interface Props {
  filter: T_Filter;
  customer_id: number;
}
const DashboardAllContent: React.FC<Props> = (props) => {
  const { filter, customer_id } = props;
  const chartLabels: ReportKey[] = [
    'auto_approve',
    'manual_approve',
    'auto_reject',
    'manual_reject',
    'manual_wait',
    'dropout',
  ];

  const params: typeof getDashboardDataAll.req = {
    customer_id,
    filter_type: filter.type,
    month: `${filter.year}-${filter.month}`,
  };

  switch (filter.type) {
    case 1: {
      params.start_date = filter.day.start;
      params.end_date = filter.day.end;
      break;
    }
    case 2: {
      params.month = `${filter.year}-${filter.month}`;
      break;
    }
  }

  const { data, isFetching } = useKycQuery<DataAll>({
    key: ['getDashboardDataAll', filter, customer_id],
    handler: () => getDashboardDataAll(params),
  });

  return (
    <>
      {isFetching && <Loading />}
      {data?.data.report && data?.data.count_info && (
        <div className="dashboard-all-section">
          <div className="report-info">
            <div className="report-table">
              <div className="grid-container">
                <div className="grid-cell title rowSpan">과금</div>
                <div className="grid-cell title">자동 승인</div>
                <div className="grid-cell">{numberWithComma(data?.data.report.auto_approve)}</div>
                <div className="grid-cell title">수동 승인</div>
                <div className="grid-cell">{numberWithComma(data?.data.report.manual_approve)}</div>
                <div className="grid-cell title">자동 거부</div>
                <div className="grid-cell">{numberWithComma(data?.data.report.auto_reject)}</div>
                <div className="grid-cell title">수동 거부</div>
                <div className="grid-cell">{numberWithComma(data?.data.report.manual_reject)}</div>
                <div className="grid-cell title">심사 대기</div>
                <div className="grid-cell">{numberWithComma(data?.data.report.manual_wait)}</div>
                <div className="grid-cell title unpaid">미과금</div>
                <div className="grid-cell title unpaid">중도 이탈</div>
                <div className="grid-cell">{numberWithComma(data?.data.report.dropout)}</div>
              </div>
            </div>
            <div className="report-chart">
              {data.data.count_info.total > 0 ? (
                <Doughnut
                  chartData={chartLabels.map((key) => ({
                    label: reportMapper[key],
                    value: data?.data.report[key],
                  }))}
                  legendPosition="bottom"
                  chartOptions={{
                    aspectRatio: 1.77,
                    layout: { padding: { top: 30 } },
                    plugins: {
                      legend: { labels: { font: { size: 12, weight: 400 } } },
                      datalabels: { labels: { label: { font: { size: 11 } } } },
                    },
                  }}
                  datasetBackgroundColor={['#71D4BC', '#B8E9DD', '#F98A8A', '#FCC5C5', '#878BFF', '#ABACAD']}
                  datasetLabelColor={['#FFF', '#12B78F', '#FFF', '#F53C3C', '#FFF', '#FFF']}
                />
              ) : (
                <DoughnutNoData />
              )}
            </div>
          </div>
          <div className="total-count-info">
            <div className="total paid">
              <span>총 과금 건수</span>
              <span>{numberWithComma(data?.data.count_info.total_charge)}</span>
            </div>
            <div className="sign">+</div>
            <div className="total unpaid">
              <span>총 미과금 건수</span>
              <span>{numberWithComma(data?.data.count_info.total_unpaid)}</span>
            </div>
            <div className="sign">=</div>
            <div className="total">
              <span>총 사용 건수</span>
              <span>{numberWithComma(data?.data.count_info.total)}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

type DataDaily = typeof getDashboardDataDaily.res;

const DashboardDailyContent = (props: Props) => {
  const { filter, customer_id } = props;

  const params: typeof getDashboardDataDaily.req = {
    customer_id,
    filter_type: filter.type,
  };

  switch (filter.type) {
    case 1: {
      params.start_date = filter.day.start;
      params.end_date = filter.day.end;
      break;
    }
    case 2: {
      params.month = `${filter.year}-${filter.month}`;
      break;
    }
  }

  const { data, isFetching } = useKycQuery<DataDaily>({
    key: ['getDashboardDataDaily', filter, customer_id],
    handler: () => getDashboardDataDaily(params),
  });

  return (
    <>
      {isFetching && <Loading />}
      {data?.data && (
        <div className="dashboard-daily-section">
          <div className="report-info">
            <div className="report-table">
              <div className="grid-container">
                <div className="grid-header">
                  <div className="grid-cell title rowSpan">일시</div>
                  <div className="grid-cell title colSpan">과금</div>
                  <div className="grid-cell title unpaid">미과금</div>
                  <div className="grid-cell title">자동 승인</div>
                  <div className="grid-cell title">수동 승인</div>
                  <div className="grid-cell title">자동 거부</div>
                  <div className="grid-cell title">수동 거부</div>
                  <div className="grid-cell title">심사 대기</div>
                  <div className="grid-cell title unpaid">중도 이탈</div>
                </div>
                <div className="grid-body">
                  {data.data.list.map((d, i) => {
                    return (
                      <div className="grid-row" key={i}>
                        <div className="grid-cell">{d.date.replace(/\./g, '-')}</div>
                        <div className="grid-cell">{numberWithComma(d.auto_approve)}</div>
                        <div className="grid-cell">{numberWithComma(d.manual_approve)}</div>
                        <div className="grid-cell">{numberWithComma(d.auto_reject)}</div>
                        <div className="grid-cell">{numberWithComma(d.manual_reject)}</div>
                        <div className="grid-cell">{numberWithComma(d.manual_wait)}</div>
                        <div className="grid-cell">{numberWithComma(d.dropout)}</div>
                      </div>
                    );
                  })}
                  {data.data.list.length === 0 && (
                    <div className="grid-row">
                      <div className="grid-cell">-</div>
                      <div className="grid-cell">-</div>
                      <div className="grid-cell">-</div>
                      <div className="grid-cell">-</div>
                      <div className="grid-cell">-</div>
                      <div className="grid-cell">-</div>
                      <div className="grid-cell">-</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="total-count-info">
            <div className="total paid">
              <span>총 과금 건수</span>
              <span>{numberWithComma(data?.data.count_info.total_charge)}</span>
            </div>
            <div className="sign">+</div>
            <div className="total unpaid">
              <span>총 미과금 건수</span>
              <span>{numberWithComma(data?.data.count_info.total_unpaid)}</span>
            </div>
            <div className="sign">=</div>
            <div className="total">
              <span>총 사용 건수</span>
              <span>{numberWithComma(data?.data.count_info.total)}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export type Account = { id: number; username: string; key?: string; active: boolean; date: string; created_at: string };
export type Action =
  | { type: 'customers'; payload: Customer[] }
  | { type: 'selected'; payload: N_Select.Selected<number> }
  | { type: 'filter'; payload: T_Filter }
  | { type: 'viewType'; payload: N_Select.Selected<string> };
type Customer = typeof getCustomers.res;

interface State {
  customers: Customer[];
  selected: N_Select.Selected<number>;
  filter: T_Filter;
  viewType: N_Select.Selected<string>;
}

const initialDateFilter: T_Filter = (() => {
  const now = new Date();
  const DAY = 1000 * 60 * 60 * 24; // 1 day
  const weekAgo = new Date(now.getTime() - 7 * DAY); // 7일 전
  return {
    type: 2, // 1.기간 설정, 2.월, 3.분기, 4.반기, 5.년
    year: format(now, 'yyyy'),
    month: format(now, 'MM'),
    day: {
      start: format(weekAgo, 'yyyy-MM-dd'),
      end: format(now, 'yyyy-MM-dd'),
    },
    halfYear: 1, // X
    quarter: 1, // X
  };
})();

const initialState: State = {
  customers: [],
  selected: null,
  filter: { ...initialDateFilter },
  viewType: { title: '전체 통계', value: 'all', id: '전체 통계' },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'customers':
        draft['customers'] = action.payload;
        break;
      case 'selected':
        draft['selected'] = action.payload;
        break;
      case 'filter':
        draft['filter'] = action.payload;
        break;
      case 'viewType':
        draft['viewType'] = action.payload;
        break;
    }
  });
};

interface CtProps {
  detail: Detail;
  closeHandler: () => void;
}
function Modal_Dashboard(props: CtProps) {
  const { detail, closeHandler } = props;
  const [state, setState] = useReducer(reducer, initialState);

  const params = { start: 0, count: 9999 };

  useKycQuery<{ list: (typeof getCustomers.res)[] }>({
    key: ['getCustomers'],
    handler: () => getCustomers(params),
    options: {
      onSuccess: (res) => {
        const payload = res.data.list;
        setState({ type: 'customers', payload });
      },
    },
  });

  const { customers, selected } = state;

  return (
    <>
      <div id="client__dashboard__modal__wrapper">
        <Dialog.Base st={{ width: 1080, minHeight: 400 }}>
          <Dialog.Summary closeHandler={closeHandler}>API 사용량</Dialog.Summary>
          <Dialog.Content>
            <div className="table-control">
              <div className="name">
                <Select
                  className="customers-select"
                  st={{ width: 250, height: 48 }}
                  selected={selected ?? { title: detail.name, value: detail.id }}
                  onChange={(value) => setState({ type: 'selected', payload: value })}
                >
                  <Select.Summary />
                  <Select.Options>
                    {customers.map((c) => (
                      <Select.Item key={c.id} title={c.name} value={c.id} />
                    ))}
                  </Select.Options>
                </Select>
              </div>
              <div className="control">
                <Popover
                  clickOutside
                  position="bottom left"
                  className="client-api-usage"
                  content={({ closeHandler }) => (
                    <Pop_DateFilter
                      type={[1, 2]}
                      filter={state.filter}
                      filterHandler={(filter) => setState({ type: 'filter', payload: filter })}
                      closeHandler={closeHandler}
                    />
                  )}
                >
                  <div className="select-area">
                    <div>
                      <p>{getDateFilterLabel(state.filter)}</p>
                      <Filter />
                    </div>
                  </div>
                </Popover>
                <Select
                  className="display-unit-select"
                  selected={state.viewType}
                  onChange={(value) => setState({ type: 'viewType', payload: value })}
                >
                  <Select.Summary />
                  <Select.Options>
                    <Select.Item title="전체 통계" value="all" />
                    <Select.Item title="일간 통계" value="daily" />
                  </Select.Options>
                </Select>
              </div>
            </div>
            <NullishGuard data={[state.viewType?.value === 'all']}>
              <DashboardAllContent filter={state.filter} customer_id={selected?.value ?? detail.id} />
            </NullishGuard>
            <NullishGuard data={[state.viewType?.value === 'daily']}>
              <DashboardDailyContent filter={state.filter} customer_id={selected?.value ?? detail.id} />
            </NullishGuard>
          </Dialog.Content>
        </Dialog.Base>
      </div>
    </>
  );
}

export default Modal_Dashboard;
