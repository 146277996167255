import React, { Dispatch, useState } from 'react';
import { Radio } from 'usebeauty';
import { api, process } from 'common/functions';
import { Alert } from 'common/components';
import { Action, State } from '../CtSecurityContentGroup';
import EditComp from '../../edit/EditComp';
import './OriginalOCRMasking.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const OriginalOCRMasking = (props: Props) => {
  const { set } = Alert.Context();
  const { state, setState, loadHandler } = props;
  const { idcard_jumin2_masking } = state.vas;
  const [edit, setEdit] = useState(false);

  const disabled = state.defaultVas.idcard_jumin2_masking === idcard_jumin2_masking;

  const submitHandler = () => {
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'configs', data: { idcard_jumin2_masking: String(idcard_jumin2_masking) } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        setState({ type: 'loading', payload: false });
        loadHandler();
        if (response) {
          set({ success: `개인정보 원본 마스킹 설정이 완료되었습니다.` });
        } else {
          set({ fail: `개인정보 원본 마스킹 설정에 실패하였습니다.` });
        }
        return response;
      })
    );
  };

  const changeHandler = (value: boolean) => {
    setState({ type: 'vas', payload: { idcard_jumin2_masking: value } });
  };

  const cancelHandler = () => {
    setState({ type: 'reset' });
  };

  const handler = { submitHandler, loadHandler, changeHandler, cancelHandler };

  return (
    <section id="admin-manage-security-original-ocr-masking" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className="active">개인정보 원본 마스킹</h4>
            <p>개인정보(주민번호 뒷자리)의 원본을 마스킹할지 여부를 설정 가능합니다.</p>
          </div>
        </div>
        <div className="status-label active">사용 중</div>
        <EditComp
          disabled={disabled}
          setEdit={setEdit}
          edit={edit}
          handler={handler}
          data={{ key: 'idcard_jumin2_masking', value: idcard_jumin2_masking }}
        />
      </div>
      <div className="description">
        {edit ? (
          <div className="edit-option">
            <Radio.Group
              className="security-original-ocr-masking"
              value={idcard_jumin2_masking}
              onChange={(checked) => props.setState({ type: 'vas', payload: { idcard_jumin2_masking: checked } })}
            >
              <Radio text="원본 마스킹" value>
                <p>개인정보 원본도 마스킹을 진행합니다.</p>
              </Radio>
              <Radio text="마스킹 안 함" value={false}>
                <p>개인정보 원본은 마스킹을 진행하지 않습니다.</p>
              </Radio>
            </Radio.Group>
          </div>
        ) : (
          <div className="display-option">
            <h6>{idcard_jumin2_masking ? '원본 마스킹' : '마스킹 안 함'}</h6>
            <span>
              {idcard_jumin2_masking
                ? '개인정보 원본도 마스킹을 진행합니다.'
                : '개인정보 원본은 마스킹을 진행하지 않습니다.'}
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default OriginalOCRMasking;
