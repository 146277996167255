import React, { Dispatch, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '@usebeauty';
import { Button } from 'common/components';
import { api, delayed, findCustomer, process } from 'common/functions';
import { produce } from 'immer';
import { InputCheck } from '@Icons';
import type { Action as CtAction } from '../SignUpPage';
import './Client.scss';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'customerLoading'; payload: boolean }
  | { type: 'customerId'; payload: number };

interface State {
  loading: boolean;
  customerLoading: boolean;
  customerId: number;
}

const initialState: State = {
  loading: false,
  customerLoading: false,
  customerId: -1,
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
      case 'customerLoading': draft['customerLoading'] = payload; break;
      case 'customerId': draft['customerId'] = payload; break;
    }
  });
};

interface Props {
  setState: Dispatch<CtAction>;
}

const Client: React.FC<Props> = ({ setState: ctSetState }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const {
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: { customer_name: '' },
  });

  const { customerLoading, customerId } = state;

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'find/customer', data: { customer_name: getValues('customer_name') || '' } }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { customer_id } = response.data;
          ctSetState({ type: 'customer_id', payload: customer_id });
          ctSetState({ type: 'step', payload: 3 });
        }
        setState({ type: 'loading', payload: false });
      })
    );
  };

  const clientCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ type: 'customerLoading', payload: true });
    const { value } = e.target;
    delayed.cancel();
    if (value) {
      delayed(async () => {
        const result = await findCustomer({ customer_name: value });
        setState({ type: 'customerId', payload: result.id });
        if (errors.customer_name) clearErrors('customer_name');

        if (result.id === -1) {
          setError('customer_name', { message: '등록되지 않은 고객사명입니다.' });
          setState({ type: 'customerId', payload: 0 });
        }
        setState({ type: 'customerLoading', payload: false });
      });
    } else {
      setState({ type: 'customerLoading', payload: false });
    }
  };

  const disabledClient = !!errors.customer_name || !getValues('customer_name') || customerLoading;

  return (
    <form className="client" onSubmit={submitHandler}>
      <p className="title">고객사명</p>
      <div className="customer-input">
        <Form.Input
          st={{ marginBottom: 10 }}
          name="customer_name"
          control={control}
          placeholder="고객사명을 입력하세요."
          onChange={clientCheckHandler}
          rules={{
            validate: {
              unRegisteredCustomer: () => {
                if (!getValues('customer_name').length) return true;
                return customerId === 0 ? '등록되지 않은 고객사명입니다.' : true;
              },
            },
          }}
        />
        {!disabledClient && <InputCheck className="check" />}
      </div>

      <div className="next-field">
        <Button text="뒤로" onClick={() => ctSetState({ type: 'step', payload: 1 })} />
        <Button type="submit" loading={state.loading} text="다음" disabled={disabledClient} />
      </div>
    </form>
  );
};

export default Client;
