import React, { Dispatch } from 'react';
import { FlexBox, Radio } from '@usebeauty';
import { Action, State } from 'components/admin/manage/authentication/examination/ExaminationContent';
import './RadioDriverIssueDate.scss';

interface Props {
  state: State;
  loadHandler: () => void;
  setState: Dispatch<Action>;
  isSue?: boolean;
}

const RadioDriverIssueDate: React.FC<Props> = ({ state, setState, isSue }) => {
  const { trust_driver_date, trust_driver_expired_date } = state.data.vas;

  return (
    <div id="idcarddriver__issue__date__wrapper">
      <div className="status-container">
        <Radio.Group
          className="issue__date_group"
          value={isSue ? trust_driver_date : trust_driver_expired_date}
          onChange={(value) => {
            const result = isSue ? { trust_driver_date: value } : { trust_driver_expired_date: value };
            setState({ type: 'vas', payload: result });
          }}
        >
          <Radio text="자동심사 사용" value className="item">
            <div>
              <p>
                OCR결과를 무시하고 <b>사용자 입력 값으로 {isSue ? '발급' : '마감'}일자를 변경</b>합니다.
              </p>
              <p>
                이후 <b>*기본 프로세스</b>로 동작합니다.
              </p>
            </div>
          </Radio>
          <Radio text="수동심사 사용" value={false} className="item">
            <div className="flex-container">
              <div className="flex-row head">
                <div />
                <FlexBox st={{ justifyContent: 'center' }}>
                  <div>
                    OCR 결과와 사용자 입력
                    <br /> 발급일자가 <b>같은</b> 경우
                  </div>
                </FlexBox>
                <FlexBox st={{ justifyContent: 'center' }}>
                  <div>
                    OCR 결과와 사용자 입력
                    <br />
                    발급일자가 <b>다른</b> 경우
                  </div>
                </FlexBox>
              </div>
              <div className="flex-row head">
                <FlexBox st={{ justifyContent: 'center' }}>
                  <div style={{ textAlign: 'left' }}>
                    오늘 날짜보다
                    <br />
                    <b>{isSue ? '과거' : '미래'}</b>로 입력
                  </div>
                </FlexBox>
                <div className="flex">
                  <b>*기본 프로세스</b>
                </div>
                <div className="flex">
                  <b>수동 심사</b>
                </div>
              </div>
              <div className="flex-row head">
                <FlexBox st={{ justifyContent: 'center' }}>
                  <div style={{ textAlign: 'left' }}>
                    오늘 날짜보다
                    <br />
                    <b>{isSue ? '미래' : '과거'}</b>로 입력
                  </div>
                </FlexBox>
                <div className="last-flex">
                  <b>KYC 진행 불가능</b>
                </div>
              </div>
            </div>
          </Radio>
        </Radio.Group>

        <div className="description">
          <div>
            <h4>*기본 프로세스</h4>
          </div>
          <div className="grid-container">
            <div className="grid-item header">
              진위확인 <br />
              성공
            </div>
            <div className="grid-item">신분증 정보 수정 없음</div>
            <div className="grid-item success">자동 승인</div>
            <div className="grid-item">신분증 정보 수정 있음</div>
            <div className="grid-item">
              <b>수동 심사</b>
            </div>
            <div className="grid-item header">
              진위확인 <br />
              3회 이상 실패
            </div>
            <div className="grid-item failed">자동 거부</div>
          </div>
          {!isSue && (
            <p>
              ※ 운전면허 마감일자는
              <br />
              정부기관(경찰청) 진위확인에 사용되지 않습니다.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RadioDriverIssueDate;
