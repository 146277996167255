import React, { useContext, useEffect, useReducer } from 'react';
import { isObject, pick, pickBy, toPairs } from 'lodash';
import { produce } from 'immer';
import classNames from 'classnames';
import { getReviewResultsId } from 'common/api';
import { NullishGuard } from 'hoc';
import { ContentContext } from 'layout/reviewer/content/Content';
import type { Data as RowData } from '../CtResultTable';
import IdCard from './contents/idcard/IdCard';
import Face from './contents/face/Face';
import Account from './contents/account/Account';
import Custom from './contents/custom/Custom';
import './Expand.scss';

export type Data = typeof getReviewResultsId.res;
export type T_IdCard = typeof getReviewResultsId.res.id_card;
export type T_FaceCheck = typeof getReviewResultsId.res.face_check;
export type T_Account = typeof getReviewResultsId.res.account;
export type T_Custom = typeof getReviewResultsId.res.custom;
export type T_CustomFieldArray = Pick<NonNullable<T_Custom>, 'custom'>['custom'];
export type T_EDDArray = Pick<NonNullable<T_Custom>, 'edd'>['edd'];
export type T_Histories = typeof getReviewResultsId.res.histories;
export type T_RaResult = typeof getReviewResultsId.res.ra_result;

const title = { id_card: '신분증 인증', face_check: '얼굴 인증', account: '기존 계좌 인증', custom: '고객 위험 평가' };
type Key = keyof typeof title;
type List = (['id_card', T_IdCard] | ['face_check', T_FaceCheck] | ['account', T_Account] | ['custom', T_Custom])[];
export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'loading'; payload: boolean }
  | { type: 'submitLoading'; payload: boolean }
  | { type: 'isExpired'; payload: boolean };
export interface State {
  data: Data | null;
  customField: T_CustomFieldArray | null;
  loading: boolean;
  submitLoading: boolean;
  isExpired: boolean;
}
const initialState: State = {
  data: null,
  customField: null,
  loading: true,
  submitLoading: false,
  isExpired: false,
};
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'loading': draft['loading'] = action.payload; break;
      case 'isExpired': draft['isExpired'] = action.payload; break;
      case 'submitLoading': draft['submitLoading'] = action.payload; break;
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseEddSurvey = (detail: any) => {
  const { edd, custom: respCustom, ra_result } = detail;

  return { ...detail, custom: !edd && !respCustom ? null : { edd, custom: respCustom, ra_result } };
};

interface Props {
  rowData: RowData;
}

const Expand: React.FC<Props> = ({ rowData }) => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { id, result_type } = rowData;

  const loadHandler = async () => {
    setLoading(true);
    try {
      const { detail } = await getReviewResultsId(id);
      const res = parseEddSurvey(detail);
      setState({ type: 'setData', payload: res });
      if (detail.name === 'EXPIRED') setState({ type: 'isExpired', payload: true });
    } catch (e) {
      console.error('ReviewResultById error occured', { e });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const data = pickBy(pick(state.data, ['id_card', 'face_check', 'account', 'custom']), isObject) as NonNullable<
    Pick<Data, Key>
  >;
  const list = toPairs(data) as List;

  return (
    <NullishGuard data={[state.data]}>
      {([data]) => {
        return (
          <section
            className={classNames('reviewer-result-table-tr-expand', {
              'no-gap': result_type === 1 || result_type === 2,
            })}
          >
            <div className="content-wrapper">
              <ul>
                {list.map(([k], index) => {
                  return (
                    <li key={k}>
                      <div className="title">
                        <span>{`0${index + 1}`}</span>
                        {title[k]}
                      </div>
                      <NullishGuard data={[k === 'id_card', state.data?.id_card, state.data?.module]}>
                        {([, idcard, module]) => (
                          <IdCard rowData={rowData} content={idcard} module={module} isExpired={state.isExpired} />
                        )}
                      </NullishGuard>

                      <NullishGuard data={[k === 'face_check', data.face_check, data.id_card]}>
                        {([, faceCheck, idCard]) => (
                          <Face
                            content={faceCheck}
                            idCard={idCard}
                            module={state.data?.module || null}
                            isExpired={state.isExpired}
                          />
                        )}
                      </NullishGuard>

                      <NullishGuard data={[k === 'account', state.data?.account, state.data?.histories]}>
                        {([, account, histories]) => (
                          <Account content={account} histories={histories} isExpired={state.isExpired} />
                        )}
                      </NullishGuard>

                      <NullishGuard data={[k === 'custom', state.data?.custom]}>
                        {([, custom]) => (
                          <Custom content={custom} ra_result={state.data?.ra_result} isExpired={state.isExpired} />
                        )}
                      </NullishGuard>
                    </li>
                  );
                })}
              </ul>
            </div>

            <article>
              {result_type !== 1 && (
                <div>
                  {result_type !== 2 && (
                    <div>
                      <h4>심사자 정보</h4>
                      <p>
                        이름: <span>{data.reviewer_name}</span>
                      </p>
                      <p>
                        아이디: <span>{data.reviewer_username}</span>
                      </p>
                    </div>
                  )}
                  {data.rejected_reason && (
                    <div className="reject">
                      <h4>거부 사유</h4>
                      <p>
                        <span>{data.rejected_reason}</span>
                      </p>
                    </div>
                  )}
                </div>
              )}
            </article>
          </section>
        );
      }}
    </NullishGuard>
  );
};
// result_type === 1 자동승인
// result_type === 2 자동거부
// result_type === 3 수동승인
// result_type === 4 수동거부
export default Expand;
