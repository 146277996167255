import React, { Dispatch, useState } from 'react';
import { Input } from 'usebeauty';
import { Alert } from 'common/components';
import classNames from 'classnames';
import { api, process } from 'common/functions';
import { Action, State } from '../../AccountContent';
import EditComp from '../edit/EditComp';
import './ChangeAccount.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const ChangeAccount = ({ state, setState, loadHandler }: Props) => {
  const { allow_change_account, max_change_account } = state.data.vas;
  const { set } = Alert.Context();
  const [edit, setEdit] = useState(false);
  const submitHandler = async () => {
    const data = {
      allow_change_account: String(allow_change_account),
      max_change_account: allow_change_account ? String(max_change_account) : undefined,
    };
    await process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled((response) => {
        setEdit(false);
        loadHandler();
        if (response) {
          set({ success: `계좌 변경 횟수가 ${allow_change_account ? '설정' : '중지'}되었습니다.` });
        } else {
          set({ fail: `계좌 변경 횟수 설정에 실패하였습니다.` });
        }
      })
    );
  };
  const handler = { loadHandler, submitHandler };
  return (
    <section id="account-setting-change-account" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: allow_change_account })}>계좌 변경 사용</h4>
            <p>계좌 인증을 위한 인증 코드를 받은 후 계좌번호를 변경하여 새로운 인증 코드를 받을 수 있게 해줍니다.</p>
          </div>
        </div>
        <EditComp
          onSwitch
          setEdit={setEdit}
          edit={edit}
          setState={setState}
          setCancel={() => setState({ type: 'reset', payload: { key: 'max_change_account' } })}
          handler={handler}
          data={{ key: 'allow_change_account', value: allow_change_account }}
          disabled={max_change_account === 0}
        />
      </div>

      {allow_change_account && (
        <div className="description">
          {edit ? (
            <div className="edit-option">
              <div className="option-wrapper">
                <h6>계좌 변경 횟수 설정</h6>
                <span>최소 1회, 최대 99회까지 설정 가능합니다.</span>
                <Input
                  value={max_change_account.toString()}
                  st={{ width: 52, height: 30 }}
                  type="number"
                  min="1"
                  max="99"
                  onChange={(e) => {
                    let { value } = e.target;
                    if (Number(e.target.value) > 99) value = '99';
                    setState({ type: 'vas', payload: { max_change_account: Number(value) } });
                  }}
                />
                <p>회</p>
              </div>
            </div>
          ) : (
            <div className="display-option">
              <h6>계좌 변경 횟수 설정</h6>
              <span>최소 1회, 최대 99회까지 설정 가능합니다.</span>
              <p>{max_change_account} 회</p>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default ChangeAccount;
