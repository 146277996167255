import React, { useEffect, useState, createContext, useContext, useRef } from 'react';
import classNames from 'classnames';
import Portal from './portal/Portal';
import './Alert.scss';

export type Alert = { text: string | JSX.Element | null; result: 'success' | 'fail'; id: number };
export type Position =
  | 'left bottom'
  | 'left center'
  | 'left top'
  | 'top left'
  | 'top center'
  | 'top right'
  | 'right top'
  | 'right center'
  | 'right bottom'
  | 'bottom left'
  | 'bottom center'
  | 'bottom right';

type IContext = {
  alert: Alert[];
  set: (payload: { success?: string | JSX.Element; fail?: string }) => void;
  remove: (payload: number) => void;
  clear: () => void;
};

export const Context = createContext<IContext>({
  alert: [],
  set: () => false,
  remove: () => false,
  clear: () => false,
});
export const AlertContext = () => useContext(Context);

export const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [alert, setAlert] = useState<Alert[]>([]);
  const set = (payload: { success?: string | JSX.Element; fail?: string }) => {
    const { success, fail } = payload;
    let asign: Alert = { text: '', result: 'success', id: new Date().getTime() };
    if (success) asign = { ...asign, text: success };
    if (fail) asign = { ...asign, text: fail, result: 'fail' };
    if (asign.text) setAlert([...alert, asign]);
  };

  const remove = (id: number) => setAlert((prev) => prev.filter((el) => el.id !== id));
  const clear = () => setAlert([]);

  return <Context.Provider value={{ alert, set, remove, clear }}>{children}</Context.Provider>;
};

interface Props {
  children?: JSX.Element;
  position?: Position;
  gap?: number;
}

export const Card: React.FC<Props> = ({ children, position = 'bottom right', gap = 12 }): JSX.Element => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [mount, setMount] = useState(false);
  const { alert, clear } = useContext(Context);

  useEffect(() => {
    if (alert.length) clear();
  }, []);

  const getChild = (): JSX.Element => {
    if (!children) return <></>;
    if (typeof children.type === 'function') {
      const isClass = /^class\s/.test(Function.prototype.toString.call(children.type)) // prettier-ignore
      return isClass ? children.type.prototype.render(children.props) : children.type(children.props);
    }
    return children;
  };

  const CHILDREN = getChild();
  const CLONED_CHILDREN = React.Children.map(CHILDREN, (child: JSX.Element): JSX.Element => {
    const { className } = child.props;
    return React.cloneElement(child, {
      ref: targetRef,
      className: classNames('jack__alert__target', { [className]: className }),
    });
  });

  useEffect(() => {
    setMount(true);
  }, []);

  return (
    <>
      {CLONED_CHILDREN}
      {/* {mount && !!alert.length && <Portal gap={gap} target={targetRef.current} position={position} />} */}
      {true && !!alert.length && <Portal gap={gap} target={targetRef.current} position={position} />}
    </>
  );
};

const Alert = { Provider, Card, Consumer: Context.Consumer, Context: AlertContext };

export default Alert;
