import React, { createContext, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'usebeauty';
import {
  DateFilter,
  HalfYear,
  HeadYearComp,
  MonthComp,
  Quarter,
  SetPeriod,
  YearComp,
} from 'common/components/datefilter';
import './Pop_DateFilter.scss';

type T_DateFilterContext = {
  firstDate: string;
};

export const Context = createContext<T_DateFilterContext>({
  firstDate: '2015-01',
});

export const useDateFilterContext = () => {
  const context = useContext(Context);
  if (!context) throw new Error('dateFilter context is null');
  return context;
};

export type T_Filter = {
  type: number;
  year: string;
  month: string;
  day: { start: string; end: string };
  halfYear: number; // 반기
  quarter: number; // 분기
};

interface Props {
  closeHandler: () => void;
  filter: T_Filter;
  filterHandler: (filter: T_Filter) => void;
  type?: number[];
}

const Pop_DateFilter = (props: Props) => {
  const { closeHandler, filter, filterHandler, type = [1, 2, 3, 4, 5] } = props;
  const { watch, setValue, resetField } = useForm<T_Filter>({ mode: 'onChange', values: filter });
  const [err, setErr] = useState({ start: '', end: '' });
  const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])(?:T[0-9]{2}:[0-9]{2})?$/;

  const field = watch();
  const isHeadYear = field.type === 2 || field.type === 3 || field.type === 4;

  const onSubmitHandler = () => {
    filterHandler(field);
    closeHandler();
  };

  const isPastEndDateThanStartDate = new Date(field.day.end) < new Date(field.day.start); // 시간을 포함한 startDate 날짜가 endDate 보다 과거날짜인지 확
  const dateLenthCheck = regex.test(field.day.start) && regex.test(field.day.end);
  const disabled =
    (field.type === 1 && (!field.day.end || isPastEndDateThanStartDate)) ||
    (field.type === 2 && field.month === '') ||
    (field.type === 3 && field.quarter === 0) ||
    !!err.start ||
    !!err.end ||
    !dateLenthCheck ||
    (field.type === 4 && field.halfYear === 0);

  const onChangeYear = (value: string) => {
    setValue('year', value, { shouldDirty: true });
    setValue('month', '', { shouldDirty: true });
    setValue('quarter', 0, { shouldDirty: true });
    setValue('halfYear', 0, { shouldDirty: true });
  };

  return (
    <div className="popover-date-filter">
      <Context.Provider value={{ firstDate: '2015-01' }}>
        <DateFilter
          type={type}
          onChange={(value) => {
            resetField('year');
            setValue('type', value, { shouldDirty: true });
          }}
          value={field.type}
        >
          <>
            {field.type === 1 && (
              <SetPeriod onChange={(value) => setValue('day', value)} value={field.day} err={err} setErr={setErr} />
            )}
            {isHeadYear && (
              <HeadYearComp value={field.year} onChange={onChangeYear}>
                <>
                  {field.type === 2 && (
                    <MonthComp value={field.month} onChange={(value) => setValue('month', value)} year={field.year} />
                  )}
                  {field.type === 3 && (
                    <Quarter
                      value={field.quarter}
                      onChange={(value) => setValue('quarter', value, { shouldDirty: true })}
                      year={field.year}
                    />
                  )}
                  {field.type === 4 && (
                    <HalfYear
                      value={field.halfYear}
                      onChange={(value) => setValue('halfYear', value, { shouldDirty: true })}
                      year={field.year}
                    />
                  )}
                </>
              </HeadYearComp>
            )}
            {field.type === 5 && <YearComp value={field.year} onChange={onChangeYear} />}
          </>
        </DateFilter>
        <div className="action">
          <Button onClick={closeHandler} className="cancel">
            취소
          </Button>
          <Button onClick={onSubmitHandler} disabled={disabled}>
            적용
          </Button>
        </div>
      </Context.Provider>
    </div>
  );
};

export default Pop_DateFilter;
