import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Switch } from 'common/components';
import { Action, Data } from '../../Expand';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

export const AddSurveyComp = (props: Props) => {
  const { data, setState, edit } = props;
  const { module, config } = data;

  return (
    <div className="setting-item">
      <div className={classNames('switch-box', module.riskAssesment ? 'active' : 'inactive')}>
        고객 위험 평가
        <Switch
          className={classNames({ readonly: !edit })}
          disabled={!edit}
          value={module.riskAssesment}
          onChange={(value) => {
            const payload = { riskAssesment: !!value, edd_field: !!value };
            if (!value) {
              // off 하면 edd, ra, survey 모두 꺼야 함
              setState({ type: 'module', payload: { ...payload, custom_field: !!value } });
              setState({ type: 'config', payload: { allow_ra: !!value } });
            } else {
              setState({ type: 'module', payload });
            }
          }}
        />
      </div>
      <div className="setting-item-content">
        <div className="category">
          <div className="cate-title">
            EDD
            <Switch
              disabled={!edit || !module.riskAssesment}
              className={classNames({ readonly: !edit })}
              value={module.edd_field}
              onChange={(value) => {
                setState({ type: 'module', payload: { edd_field: !!value } });
                if (!value) {
                  setState({ type: 'config', payload: { allow_ra: !!value } });
                }
              }}
            />
          </div>
          <div className="in-category">
            <div className="cate-title">
              RA
              <Switch
                disabled={!edit || !module.edd_field}
                className={classNames({ readonly: !edit })}
                value={config.allow_ra}
                onChange={(value) => setState({ type: 'config', payload: { allow_ra: !!value } })}
              />
            </div>
          </div>
        </div>
        <div className="category border">
          <div className="cate-title">
            추가 설문
            <Switch
              disabled={!edit || !module.riskAssesment}
              className={classNames({ readonly: !edit })}
              value={module.custom_field}
              onChange={(value) => setState({ type: 'module', payload: { custom_field: !!value } })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
