import React, { Dispatch } from 'react';
import { Button } from 'usebeauty';
import { Switch } from 'common/components';
import classNames from 'classnames';
import { VAS } from '../cards/CtSecurityContentGroup';
import './EditComp.scss';

interface Props {
  edit: boolean;
  onSwitch?: boolean;
  setEdit: Dispatch<boolean>;
  data: { key: keyof VAS; value: boolean };
  handler: {
    loadHandler: () => void;
    submitHandler: () => void;
    changeHandler: (value: boolean) => void;
    cancelHandler?: () => void;
  };
  disabled?: boolean;
  isAllow?: boolean;
}

const EditComp = (props: Props) => {
  const { data, setEdit, edit, handler, onSwitch = false, disabled, isAllow = true } = props;
  const { value } = data;

  return (
    <div id="edit-wrap">
      {isAllow && onSwitch && edit && (
        <div className="switch-box">
          <Switch
            disabled={!edit}
            className={classNames({ readonly: !edit })}
            value={data.value}
            onChange={(item) => {
              handler.changeHandler(item === 1);
            }}
          />
          <p className={classNames('switch-text', { active: value })}>{value ? '사용' : '사용안함'}</p>
        </div>
      )}
      {isAllow && onSwitch && !edit && (
        <div className={classNames('use-text', { active: value })}>{value ? '사용중' : '사용안함'}</div>
      )}

      <div className="edit-btn-box">
        {edit ? (
          <>
            <Button
              className="btn-edit btn-cancel"
              ripple={false}
              onClick={() => {
                setEdit(false);
                if (handler.cancelHandler) handler.cancelHandler();
              }}
            >
              취소
            </Button>
            <Button
              className="btn-edit btn-submit"
              onClick={() => {
                setEdit(false);
                handler.submitHandler();
              }}
              ripple={false}
              disabled={disabled}
            >
              저장
            </Button>
          </>
        ) : (
          <Button
            className="btn-edit"
            ripple={false}
            onClick={() => {
              if (isAllow) setEdit(!edit);
            }}
          >
            편집
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditComp;
