import React, { Dispatch, useEffect, useState } from 'react';
import { Radio } from 'usebeauty';
import { Action, Data } from '../../ThemeSettingContent';
import LogoCustom from './LogoCustom';
import './Logo.scss';

type RadioItem = { use_custom_logo: boolean; custom_logo: string; base64String: string };

const getComputedRadioValue = (radio: RadioItem) => {
  if (radio.use_custom_logo) {
    if (radio.custom_logo === '') return 2;
    return 1;
  }
  return 0;
};

interface Props {
  datas: Data;
  setState: Dispatch<Action>;
}
const Logo = ({ datas, setState }: Props) => {
  const [radioValue, setRadioValue] = useState<RadioItem>({
    use_custom_logo: false,
    custom_logo: '',
    base64String: '',
  });
  const computedRadioValue = getComputedRadioValue(radioValue);

  const logoImageExistence = () => {
    if (datas.base64String.length > 0) {
      setState({
        type: 'setData',
        payload: { ...datas, use_custom_logo: true, custom_logo: datas.base64String },
      });
    } else {
      setState({
        type: 'setData',
        payload: { ...datas, use_custom_logo: true, custom_logo: 'custom' },
      });
    }
  };

  useEffect(() => {
    const { use_custom_logo, custom_logo, base64String } = datas;
    setRadioValue({ use_custom_logo, custom_logo, base64String });
  }, [datas]);

  const radioChangeHandler = (value: number) => {
    if (value === 0) setState({ type: 'setData', payload: { ...datas, use_custom_logo: false, custom_logo: '' } });
    if (value === 1) logoImageExistence();
    if (value === 2) setState({ type: 'setData', payload: { ...datas, use_custom_logo: true, custom_logo: '' } });
  };

  return (
    <div id="admin-manage-theme-logo">
      <h4>로고 이미지</h4>
      <div className="theme-logo-options">
        <Radio.Group value={computedRadioValue} st={{ fontSize: 16 }} onChange={radioChangeHandler}>
          <Radio text="기본 로고" value={0} />
          <Radio text="사용자 지정 로고" value={1} className="custom">
            {computedRadioValue === 1 ? <LogoCustom datas={datas} setState={setState} /> : <></>}
          </Radio>
          <Radio text="사용 안 함" value={2} />
        </Radio.Group>
      </div>
    </div>
  );
};

export default Logo;
