import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { getModules, getThresholdLivenessLog } from 'common/api';
import { N_Pagination } from 'usebeauty/dist/@types';
import { NotAllowedPage } from 'common/components';
import LivenessLogContent from './LivenessLogContent';

export type Data = typeof getThresholdLivenessLog.res & { expand: boolean };
export type State = { data: Data[]; module: { liveness: boolean }; pagination: N_Pagination.Data };

const initialState: State = {
  data: [],
  module: { liveness: false },
  pagination: { info: { current: 1, total: 1 }, format: { limit: 10, offset: 0 } },
};

export type Action =
  | { type: 'setData'; payload: Data[] }
  | { type: 'module'; payload: { liveness: boolean } }
  | { type: 'pagination'; payload: N_Pagination.Data }
  | { type: 'expand'; payload: number };

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case "setData": draft['data'] = action.payload; break;
      case "module": draft['module'] = action.payload; break;
      case "pagination": draft['pagination'] = action.payload; break;
      case "expand": draft['data'][action.payload]['expand'] = !draft.data[action.payload]['expand']; break;
    }
  });
};

const CtLivenessLog = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);
    try {
      const modules = await getModules();
      setState({ type: 'module', payload: { liveness: modules.liveness } });

      if (modules.liveness) {
        const params = {
          start: state.pagination.format.offset,
          count: state.pagination.format.limit,
        };
        const { data } = await getThresholdLivenessLog(params);

        setState({ type: 'setData', payload: data.list.map((l) => ({ ...l, expand: false })) });
        setState({
          type: 'pagination',
          payload: {
            ...state.pagination,
            info: { current: data.page_info.current_page, total: data.page_info.total_page },
          },
        });
      }
    } catch (e) {
      console.error('CtLivenessLog error', { e });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, [state.pagination.info.current]);

  return (
    <div id="admin-manage-liveness-log-content">
      {state.module.liveness ? <LivenessLogContent state={state} setState={setState} /> : <NotAllowedPage />}
    </div>
  );
};

export default CtLivenessLog;
