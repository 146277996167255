import React, { Dispatch } from 'react';
import { Button, Dialog } from 'usebeauty';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { Action } from '../CtSecurityContentGroup';
import './Di_DeleteHmac.scss';

interface Props {
  setState: Dispatch<Action>;
  loadHandler: () => void;
  closeHandler: () => void;
}

const Di_DeleteHmac: React.FC<Props> = ({ setState, loadHandler, closeHandler }) => {
  const { set } = Alert.Context();

  const submitHandler = () => {
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'secrets/hmac' }),
      api.delete.request,
      api.fullFilled(({ response }) => {
        loadHandler();
        closeHandler();
        set({ success: 'HMAC Key가 삭제되었습니다.' });
        return response;
      })
    );
  };

  return (
    <Dialog.Base st={{ width: 500 }} id="delet_hmac__modal__wrapper">
      <Dialog.Summary
        st={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <i className="icon" />
        HMAC Key 삭제
      </Dialog.Summary>
      <Dialog.Content>
        <div className="detail">
          <p>HMAC Key를 삭제하시겠습니까?</p>
          <p>삭제 시 해당 Key를 사용한 인증을 이용할 수 없습니다.</p>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={closeHandler} className="cancel">
          취소
        </Button>
        <Button onClick={submitHandler} className="negative">
          삭제
        </Button>
      </Dialog.Actions>
    </Dialog.Base>
  );
};

export default Di_DeleteHmac;
