import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'common/hooks/root';
import { authActions } from 'store/actions/root';
import { postRootLogout } from 'common/api';
import './Popover_MyBox.scss';

const PopoverMyBox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = async () => {
    try {
      const { data } = await postRootLogout();
      if (data.api_response.result_code === 'N100') {
        dispatch(authActions.logout({ navigate }));
      }
    } catch (err) {
      console.log('logout-log', err);
    }
  };

  return (
    <div id="root-mybox-popover-content">
      <div className="top-box">
        <p className="name">루트</p>
        <hr />
        <p className="company-name">UseB</p>
      </div>

      <div className="bottom-box">
        <button
          type="button"
          className="log-out"
          onClick={() => {
            logoutHandler();
          }}
        >
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default PopoverMyBox;
