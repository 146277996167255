import React from 'react';
import classNames from 'classnames';
import { VAS } from '../GeneralContent';
import './InfoAdditional.scss';

interface Props {
  vas: VAS;
}

const InfoAdditional: React.FC<Props> = ({ vas }) => {
  const { sms_notification, email_notification } = vas;

  return (
    <div className="info-additional-wrap">
      <h4>부가 정보</h4>
      <div className="flex-box">
        <div className="column">
          <div className="title">관리자 수</div>
          <div className="numbox">
            <div className="numbox-column">
              <p>Admin</p>
              <span className="active">00명 (TBD)</span>
            </div>
            <div className="numbox-column">
              <p>Reviewer</p>
              <span className="active">00명 (TBD)</span>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="title">고객 신원 확인 결과</div>
          <div className="numbox">
            <div className="numbox-column">
              <p>이메일 발송</p>
              <span className={classNames({ unused: email_notification })}>
                {email_notification ? '사용' : '미사용'}
              </span>
            </div>
            <div className="numbox-column">
              <p>SMS 발송</p>
              <span className={classNames({ unused: sms_notification })}>{sms_notification ? '사용' : '미사용'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoAdditional;
