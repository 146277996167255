import React, { Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'usebeauty';
import { Alert } from 'common/components';
import { FaceConfidence } from 'common/components/faceConfidence/FaceConfidence';
import { putThresholdLiveness } from 'common/api';
import { Chevron } from '@Icons';
import { Action, State } from './CtLivenessSetting';
import './LivenessSettingContent.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const LivenessSettingContent = (props: Props) => {
  const { state, setState, loadHandler } = props;
  const { data, edit } = state;
  const { liveness_auto_approve } = data;
  const navigate = useNavigate();
  const { set } = Alert.Context();

  const submitHandler = async () => {
    try {
      const params = { liveness_auto_approve };
      await putThresholdLiveness(params);
      set({ success: '라이브니스 민감도가 설정되었습니다.' });
    } catch (e) {
      console.error('CompareSetting put', { e });
      set({ fail: '라이브니스 민감도 설정 변경에 실패하였습니다.' });
    }
    setState({ type: 'edit', payload: false });
    loadHandler();
  };

  const disabled = state.data.liveness_auto_approve === state.defaultData.liveness_auto_approve;

  return (
    <div className="admin-manage-face-liveness-setting">
      <section className="admin-manage-section">
        <div className="flex-box">
          <div className="header">
            <div className="title">
              <h4 className="active">라이브니스 민감도 설정</h4>
              <p>각 단계는 높게 설정할 수록 보안성이 좋아지고, 낮게 설정할 수록 사용성이 좋아집니다.</p>
            </div>
          </div>
          <div className="button-group">
            {edit ? (
              <>
                <Button
                  className="cancel"
                  onClick={() => {
                    setState({ type: 'edit', payload: false });
                    setState({ type: 'reset' });
                  }}
                >
                  취소
                </Button>
                <Button
                  className="submit"
                  disabled={disabled}
                  onClick={() => {
                    setState({ type: 'edit', payload: false });
                    submitHandler();
                  }}
                >
                  저장
                </Button>
              </>
            ) : (
              <Button onClick={() => setState({ type: 'edit', payload: true })}>편집</Button>
            )}
          </div>
        </div>
        <div className="description">
          <FaceConfidence
            edit={edit}
            data={[liveness_auto_approve]}
            reference={state.reference}
            onChange={(threshold) => {
              const [liveness_auto_approve] = threshold;
              setState({ type: 'setData', payload: { liveness_auto_approve } });
            }}
          />
        </div>
      </section>
      <div className="setting-area">
        <div className="setting-title">
          <h4>변경 내역</h4>
        </div>
        <div className="navigate-area">
          <Button onClick={() => navigate('../log')}>
            변경 로그
            <Chevron />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LivenessSettingContent;
