import React, { useState } from 'react';
import classNames from 'classnames';
import './Switch.scss';

interface Props {
  value?: number | boolean;
  onChange?: (value: number) => void;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
}

const Switch: React.FC<Props> = ({
  className = '',
  onChange = () => false,
  value,
  readonly = false,
  disabled = false,
}) => {
  const [toggle, setToggle] = useState(value ?? 0);
  const check = value ?? toggle;

  const onChangeHandler = () => {
    if (disabled || readonly) return;
    const changeHandler = onChange ?? setToggle;
    changeHandler(check ? 0 : 1);
    if (!value) setToggle(check ? 0 : 1);
  };

  return (
    <div
      className={classNames('jack__switch__container', { on: check, disabled, [className]: className })}
      onClick={onChangeHandler}
    >
      <div className="switch__text">{value ? 'ON' : 'OFF'}</div>
      <div className="switch__button" />
    </div>
  );
};

export default Switch;
