import styled from 'styled-components';
import { Styles } from './CheckBox';

export const dynamicCSS = (args: Styles) => {
  const merge: { [key: string]: string } = {};
  if (args.width) merge['width'] = typeof args.width === 'string' ? args.width : `${args.width}px`;
  if (args.height) merge['height'] = typeof args.height === 'string' ? args.height : `${args.height}px`;
  if (args.fontSize) merge['font-size'] = `${args.fontSize}px`;
  if (args.color) merge['color'] = `${args.color}px`;
  let string = '';
  for (const key in merge) string += `${key}: ${merge[key]} !important;`;
  return string;
};

export const Container = styled.div<Styles & { checked: number }>`
  ${dynamicCSS}
`;
