import React from 'react';
import { NavLink } from 'react-router-dom';
import { Popover } from 'common/components';
import './Header.scss';

interface Props {
  links: { url: string; text: string }[];
  tokenTimer: React.FC;
  myBox: React.FC<{ closeHandler: () => void }>;
}

const Header = (props: Props) => {
  const { links, myBox: MyBox, tokenTimer: TokenTimer } = props;
  const className = ({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '');
  return (
    <nav id="kyc-page-header">
      <div className="header-content">
        <i className="logo" />
        <ul className="navigator">
          {links.map((el) => {
            return (
              <li key={el.text}>
                <NavLink to={el.url} className={className}>
                  {el.text}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div className="admin-my-box">
          <TokenTimer />
          <Popover
            clickOutside
            position="bottom right"
            content={({ closeHandler }) => <MyBox closeHandler={closeHandler} />}
          >
            <i />
          </Popover>
        </div>
      </div>
    </nav>
  );
};

export default Header;
