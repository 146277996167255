import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { api, process } from 'common/functions';
import { Switch } from 'common/components';
import { useEddConext } from '../../../context/EddContext';
import { EddRa } from '../../../context/model.type';
import NationRiskItem from '../../settings/nationRisk/NationRiskItem';
import './CountryRiskDetail.scss';

const RiskContent = styled.div`
  transition: max-height 0.3s ease, padding 0.3s ease;
  border-radius: 8px;
}}
`;

const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.list) return response?.data.list;
      if (response?.data.value) return response?.data.value;
      return undefined;
    })
  );
};

function CountryRiskDetail() {
  const { edit, state, setState, use_ra } = useEddConext();
  const { country, countries, edd_countries_mode, edd_category } = state.data;
  const countryCategory = edd_category.find((category) => category.edd_type === 'country') as EddRa.EddCategory;

  const updateRaItem = (nextData: EddRa.EddItem) => {
    setState({ type: 'country', payload: [nextData] });
  };

  const updateRaCountries = (nextData: EddRa.Country[]) => {
    setState({ type: 'countries', payload: nextData });
  };

  const handleEddCountryModeChange = async (value: number) => {
    if (value !== edd_countries_mode) {
      setState({ type: 'edd_countries_mode', payload: value });
      const countries = value === 1 ? await get('country?warn_only=true') : await get('edd_countries');
      setState({ type: 'countries', payload: countries });
    }
  };

  const updateRaRisk = (value: boolean) => {
    if (!value) {
      const { edd_countries_mode, country, countries } = state.defaultData;
      setState({ type: 'country', payload: country });
      setState({ type: 'data', payload: { ...state.data, country, edd_countries_mode } });
      setState({ type: 'countries', payload: countries });
    }
    setState({ type: 'eddCategory', payload: { edd_type: countryCategory.edd_type, value } });
  };

  return (
    <article className={classNames('edd-setting-country-risk-detail', { disabled: !countryCategory.active })}>
      <div className="title">
        <i onClick={() => setState({ type: 'detailPage', payload: 'none' })} />
        <h4>국가 위험</h4>
        {use_ra ? (
          <div className="score">
            <>
              <div>
                <span className={classNames({ hide: !edit })}>최대 점수</span>
                <span>100점</span>
              </div>
              {edit && (
                <div>
                  <span>남은 점수</span>
                  <span>0점</span>
                </div>
              )}
            </>
          </div>
        ) : (
          edit && (
            <div className="active_switch">
              <Switch
                disabled={use_ra || !edit}
                value={countryCategory.active}
                onChange={(value) => updateRaRisk(!!value)}
              />
            </div>
          )
        )}
      </div>
      <RiskContent>
        <div className="item-container">
          <div className="item-list">
            <NationRiskItem
              use_ra={use_ra}
              edd_countries_mode={edd_countries_mode}
              item={country[0]}
              active={country[0].active && countryCategory.active}
              countries={countries}
              edit={edit}
              updateRaItem={updateRaItem}
              updateRaCountries={updateRaCountries}
              handleEddCountryModeChange={handleEddCountryModeChange}
            />
          </div>
        </div>
      </RiskContent>
    </article>
  );
}

export default CountryRiskDetail;
