import React, { useRef, useState } from 'react';
import { entries } from 'lodash';
import * as Icons from 'common/icons/Icons';
import { cx } from 'common/functions';
import './IconsPage.scss';

const Frame = (props: {
  name: string;
  component: React.FunctionComponent<{ st?: React.CSSProperties; className?: string }>;
  copyHandler?: (text: string) => void;
}) => {
  const { name, component: Component, copyHandler } = props;
  const ref = useRef(null);
  const [active, setActive] = useState(false);

  // useClickOutSide({
  //   toggle: active,
  //   target: ref.current,
  //   closeHandler: () => setActive(false),
  // });

  return (
    <div
      ref={ref}
      className={cx('kyb__icons__item', { active })}
      onClick={() => {
        if (copyHandler) copyHandler(name);
        setActive(true);
      }}
    >
      <div className="kyb__icons__item__frame">
        <Component st={{ width: '80%' }} />
      </div>
      <div className="kyb__icons__item__name">{name}</div>
    </div>
  );
};

function IconsPage() {
  // const copyHandler = (text: string) => navigator.clipboard.writeText(text);

  return (
    <main id="kyb__icons__page__main">
      <div id="kyb__icons__page__grid">
        {entries(Icons).map(([key, element]) => {
          return (
            <Frame
              key={key}
              name={key}
              component={element}
              // copyHandler={() => copyHandler(key)}
            />
          );
        })}
      </div>
    </main>
  );
}

export default IconsPage;
