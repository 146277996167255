import React, { useContext, useEffect, useReducer } from 'react';
import { M_Table } from 'common/components';
import { toSortUpperCase } from 'common/functions';
import { useApiCancel, useMount } from 'common/hooks';
import { produce } from 'immer';
import { getReviewers } from 'common/api';
import { ContentContext } from 'layout/admin/content/Content';
import type { Pagination } from 'models';
import { N_Select, N_Table } from 'usebeauty/dist/@types';
import ReviewerTable from './ReviewerTable';

const initPagination = { info: { current: 1, total: 10 }, format: { limit: 20, offset: 0 } };
export type Data = typeof getReviewers.res;
export type Action =
  | { type: 'order'; payload: N_Table.Sort }
  | { type: 'setData'; payload: Data[] }
  | { type: 'searchKey'; payload: N_Select.Selected<string> }
  | { type: 'searchText'; payload: symbol }
  | { type: 'init'; payload?: string }
  | { type: 'pagination'; payload: Pagination };

export interface State {
  data: Data[];
  header: M_Table.header[];
  order: N_Table.Sort;
  searchKey: N_Select.Selected<string>;
  searchText: symbol;
  pagination: Pagination;
}

const initialState: State = {
  data: [],
  order: { username: 'desc' },
  searchKey: { title: '선택', value: 'all' },
  searchText: Symbol(''),
  pagination: { ...initPagination },
  header: [
    { title: '아이디', property: 'username', flex: 2, order: true, align: 'center' },
    { title: '이름', property: 'name', flex: 2, order: true, align: 'center' },
    { title: '전화번호', property: 'phone_number', order: true, align: 'center' },
    { title: '이메일', property: 'email', flex: 2, order: true, align: 'center' },
    { title: '활성여부', property: 'active', flex: 2, align: 'center' },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break
      case 'order': draft['order'] = action.payload; break;
      case 'searchKey':
        draft['searchKey'] = action.payload;
        if(action.payload?.value === 'all') draft['searchText'] = Symbol('');
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'searchText': 
        draft['searchText'] = action.payload; 
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'pagination': draft['pagination'] = action.payload; break;
    }
  });
};

const CtReviewerTable: React.FC = () => {
  useApiCancel(['reviewers']);
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    const [key, value] = Object.entries(state.order)[0];

    const params = {
      start: state.pagination.format.offset,
      count: state.pagination.format.limit,
      keyword_field: state.searchKey?.value,
      keyword: state.searchText.description?.trim(),
      order_field: key,
      order: toSortUpperCase(value),
    };

    setLoading(true);
    try {
      const response = await getReviewers(params);
      setState({ type: 'setData', payload: response.data.list });
    } catch (err) {
      console.log('ReviewerTable', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  useMount(() => {
    loadHandler();
  }, [state.order, state.searchText, state.pagination.info.current, state.searchKey]);

  return <ReviewerTable state={state} setState={setState} loadHandler={loadHandler} />;
};

export default CtReviewerTable;
