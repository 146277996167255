import React, { useContext, useEffect, useReducer } from 'react';
import { useApiCancel } from 'common/hooks';
import { getConfigs } from 'common/api';
import { parseConfigs } from 'common/functions';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { Config } from '../../models/model.types';
import Identification from './identification/Identification';
import IdcardMimeType from './idcardMimeType/IdcardMimeType';
import AutoCorrectionBirth from './autoCorrectionBirth/AutoCorrectionBirth';
import './AuthenticateContent.scss';

export type Action =
  | { type: 'vas'; payload: AtLeastOne<VAS> }
  | { type: 'defaultVas'; payload: AtLeastOne<VAS> }
  | { type: 'reset' };
export type VAS = Config.Other.Authenticate;

export interface State {
  vas: VAS;
  defaultVas: VAS;
}

const initialVas: VAS = {
  identification: false,
  auto_correction_birth: true,
  include_mime_type: false,
};
const initialState: State = {
  vas: { ...initialVas },
  defaultVas: { ...initialVas },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'vas':
        draft['vas'] = { ...state.vas, ...action.payload };
        break;
      case 'defaultVas':
        draft['defaultVas'] = { ...state.defaultVas, ...action.payload };
        break;
      case 'reset':
        draft['vas'] = { ...state.defaultVas };
        break;
    }
  });
};

const AuthenticateContent = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  useApiCancel(['configs']);
  useApiCancel(['secrets/hmac']);

  const loadHandler = async () => {
    setLoading(true);
    const configs = await getConfigs();
    const configObj = parseConfigs(configs, Object.keys(initialVas) as Array<keyof Configs>);
    const { identification, auto_correction_birth, include_mime_type } = configObj;

    setState({ type: 'vas', payload: { identification, auto_correction_birth, include_mime_type } });
    setState({ type: 'defaultVas', payload: { identification, auto_correction_birth, include_mime_type } });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <section id="admin-manage-authenticate-content">
      <Identification state={state} loadHandler={loadHandler} />
      <IdcardMimeType state={state} setState={setState} loadHandler={loadHandler} />
      {!state.defaultVas.identification && (
        <AutoCorrectionBirth state={state} setState={setState} loadHandler={loadHandler} />
      )}
    </section>
  );
};

export default AuthenticateContent;
