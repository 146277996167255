/* eslint-disable react/no-danger */
import React from 'react';
import { NullishGuard } from 'hoc';
import { Alert, Button, KycLoading, TextArea } from 'common/components';
import { useKycQuery } from 'common/api/usequery';
import { getNoticesKey } from 'common/api';
import type { Data as RowData } from '../CtServiceTable';
import './Expand.scss';

export type Data = typeof getNoticesKey.res;
interface Props {
  rowData: RowData;
}

const Expand: React.FC<Props> = ({ rowData }) => {
  const { set } = Alert.Context();

  const { data, isFetching } = useKycQuery({
    key: ['getNoticesKey'],
    handler: () => getNoticesKey('tnc', rowData.version),
    options: {
      select: (res) => {
        const { detail } = res.data;
        const html = decodeURIComponent(atob(detail.content));
        return html;
      },
    },
  });

  return (
    <>
      {isFetching && <KycLoading />}
      <NullishGuard data={[data]}>
        {([data]) => (
          <section className="root-term-privacy-table-tr-expand">
            <div className="term-content">
              <div className="term-title">약관 내용</div>
              <div className="inner-html" dangerouslySetInnerHTML={{ __html: data }} />
            </div>

            <div className="term-content">
              <div className="term-title">
                약관 HTML
                <Button
                  text="HTML 복사하기"
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(data);
                      set({ success: 'HTML 복사가 완료되었습니다.' });
                    } catch {
                      set({ fail: 'HTML 복사는 실패되었습니다.' });
                    }
                  }}
                />
              </div>
              <TextArea readonly className="html-textarea" value={data} styles={{ width: '100%', height: 136 }} />
            </div>
          </section>
        )}
      </NullishGuard>
    </>
  );
};

export default Expand;
