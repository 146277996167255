import React from 'react';
import classNames from 'classnames';
import { Group, Item } from './styles';
import './Radio.scss';

export type Styles = {
  width?: number | string;
  height?: number;
  fontSize?: number;
  color?: string;
};

interface I_RadioGroup<T> {
  children: JSX.Element[];
  value: T;
  onChange?: (value: T) => void;
  className?: string;
  disabled?: boolean;
  styles?: Styles;
  error?: boolean;
  errorMessage?: string;
}

function RadioGroup<T>(props: I_RadioGroup<T>): JSX.Element {
  const { children, value, onChange, className, disabled, styles, error, errorMessage } = props;
  const CLONE_ELEMENT = React.Children.map(children, (child: JSX.Element): JSX.Element => {
    return React.cloneElement(child, {
      className: classNames({
        checked: child.props.value === value,
        [child.props.className]: child.props.className,
      }),
      onClick: (value: T) => {
        if (!disabled && onChange) onChange(value);
      },
    });
  });

  return (
    <div className="kyc__radio__group__container">
      <Group
        {...styles}
        className={classNames('jack__radio__group', {
          [className as string]: className,
          disabled,
        })}
      >
        {CLONE_ELEMENT}
      </Group>
      {error && <span className="kyc__radio__error">{errorMessage}</span>}
    </div>
  );
}

interface I_Radio<T> {
  text?: string | JSX.Element;
  className?: string;
  value: T;
  onClick?: (value: T) => void;
  onChange?: (value: T) => void;
  disabled?: boolean;
  children?: JSX.Element;
}

function RadioItem<T>(props: I_Radio<T>): JSX.Element {
  const { text, className, value, onClick, disabled } = props;
  return (
    <Item
      className={classNames('jack__radio__item', { [className as string]: className, disabled })}
      onClick={(): void => {
        if (onClick && !disabled) onClick(value);
      }}
    >
      <i className={classNames('jack__radio__box', { [className as string]: className })}>
        <div className="radio-box" />
        <span className="radio-box-text">{text}</span>
      </i>
      {props.children}
    </Item>
  );
}

const Radio = { Group: RadioGroup, Item: RadioItem };

export default Radio;
