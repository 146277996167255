import React from 'react';
import { cx } from 'common/functions';
import { getQuarterList } from 'common/functions/time';
import { useDateFilterContext } from '../popover/Pop_DateFilter';
import './Quarter.scss';

interface Props {
  value: number;
  onChange: (value: number) => void;
  year: string;
}

export const Quarter = (props: Props) => {
  const { value, onChange, year } = props;
  const { firstDate } = useDateFilterContext();
  const firstYear = Number(firstDate?.split('-')[0]);
  const current = new Date();
  const currentYear = current.getFullYear();
  const currentMonth = current.getMonth() + 1;
  const month = Number(year) < currentYear ? 12 : currentMonth;

  const startDate = Number(year) === firstYear ? `${firstDate}-01` : `${year}-01-01`;
  const endDate =
    Number(year) === currentYear ? `${year}-${month < 10 ? `0${month}` : `${month}`}-01` : `${year}-12-01`;

  const quarterList = getQuarterList(startDate, endDate);
  const onClickHandler = (value: number) => onChange(value);

  return (
    <div className="quarter-content">
      <div className="quarter-list">
        {quarterList.map((el) => {
          return (
            <div
              key={el}
              onClick={() => onClickHandler(el)}
              className={cx({ active: value === el })}
            >{`${el}분기`}</div>
          );
        })}
      </div>
    </div>
  );
};
