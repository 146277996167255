import React from 'react';
import { Button, Dialog } from 'usebeauty';
import { authSlice } from 'store/reducers/admin';
import { useDispatch } from 'common/hooks/admin';
import './Di_AccountLock.scss';

const Di_AccountLock = () => {
  const dispatch = useDispatch();

  return (
    <Dialog.Base st={{ width: 500 }} id="dialog-account-lock-content">
      <Dialog.Summary st={{ textAlign: 'center', fontSize: 24, lineHeight: '36px' }}>계정 잠금 처리</Dialog.Summary>
      <Dialog.Content>
        <div className="description">
          <p>로그인 오류가 5회 이상 발생되어</p>
          <p>로그인을 시도할 수 없습니다.</p>
          <br />
          <p>해당 계정은 잠금 처리되었으니</p>
          <p>
            <b>관리자(Root)에게 문의하시기 바랍니다.</b>
          </p>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={() => dispatch(authSlice.actions.showLocked(false))}>확인</Button>
      </Dialog.Actions>
    </Dialog.Base>
  );
};

export default Di_AccountLock;
