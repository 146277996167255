import React from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { Box, Error, Switch } from '@usebeauty';

function F_Switch<T extends FieldValues, U extends FieldPath<T>>(
  props: UseControllerProps<T, U> & {
    st?: React.CSSProperties;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }
) {
  const { rules, control, name, st } = props;

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <Box className="form__switch__element" st={st}>
            <Switch
              checked={field.value}
              onChange={(value) => {
                field.onChange(value);
                if (props.onChange) props.onChange(value);
              }}
            />
            <Error>{error?.message}</Error>
          </Box>
        );
      }}
    />
  );
}

export default F_Switch;
