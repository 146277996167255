/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Select } from 'usebeauty';
import { modal } from 'layout';
import { Loading } from 'common/components';
import { N_Select } from 'usebeauty/dist/@types';
import { api, process, time } from 'common/functions';
import './Modal_Policy.scss';

const ModalPolicy: React.FC<{ closeHandler: () => void }> = ({ closeHandler }) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<N_Select.Selected<string>>(null);
  const [options, setOptions] = useState<N_Select.Selected<string>[]>([]);
  const [html, setHtml] = useState('');

  useEffect(() => {
    process(
      api.reqData({ url: 'notices/pp' }),
      api.get.request,
      api.fullFilled(({ response }) => {
        type Data = { created_at: string; id: number; version: string };
        if (response) {
          const options = response.data.list.map((el: Data) => {
            const epoch = new Date(el.created_at).getTime();
            const created_at = time.timeFormatFromUTCEpoch(epoch, 3);
            return {
              ...el,
              title: `${el.version} (${created_at})`,
              value: el.version,
            };
          });
          setOptions(options);
          if (options.length) setSelected(options[0]);
          else setLoading(false);
        }
      })
    );
  }, []);

  useEffect(() => {
    if (selected) {
      setLoading(true);
      process(
        api.reqData({ url: `notices/pp/${selected.value}` }),
        api.get.request,
        api.fullFilled(({ response }) => {
          if (response) {
            const { content } = response.data.detail;
            setHtml(decodeURIComponent(atob(content ?? '')));
          }
          setLoading(false);
        })
      );
    }
  }, [selected]);

  return (
    <modal.Container id="kyc-footer-policy-modal" styles={{ width: 640, height: 540 }}>
      <modal.Header>
        <span>개인정보처리방침</span>
        <i onClick={closeHandler} />
      </modal.Header>
      <modal.Content>
        {loading && <Loading />}
        <div className="policy-select">
          <h4>㈜유스비 개인정보처리방침</h4>
          <Select onChange={setSelected} selected={selected} className="footer-policy-select">
            <Select.Summary />
            <Select.Options>
              {options.map((el, i) => {
                return (
                  <React.Fragment key={i}>
                    <Select.Item title={el?.title} value={el?.value} />
                  </React.Fragment>
                );
              })}
            </Select.Options>
          </Select>
        </div>
        <div className="inner-html" dangerouslySetInnerHTML={{ __html: html }} />
      </modal.Content>
    </modal.Container>
  );
};

export default ModalPolicy;
