import React, { Dispatch, useState } from 'react';
import { Loading } from '@usebeauty';
import { putReviewerCustomerUnlock } from 'common/api';
import { Alert } from 'common/components';
import { Data } from '../../CtReviewerTable';
import './Di_UnlockAccount.scss';
import Unlock from './step/Unlock';
import UnlockConfirm from './step/UnlockConfirm';

interface Props {
  setOpen: Dispatch<boolean>;
  rowData: Data;
  loadHandler: () => void;
}

const Di_UnlockAccount = (props: Props) => {
  const { setOpen, rowData, loadHandler } = props;
  const { set } = Alert.Context();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const submitHandler = async () => {
    setLoading(true);
    const { id } = rowData;
    const params = { reviewer_id: id };
    try {
      await putReviewerCustomerUnlock(params);
      setLoading(false);
      set({ success: '계정이 잠금 해제되었습니다.' });
      loadHandler();
      setStep(1);
    } catch (err) {
      console.log('dialog unlock account err', err);
    }
  };

  return (
    <>
      {loading && <Loading />}
      {step === 0 && <Unlock rowData={rowData} setOpen={setOpen} submitHandler={submitHandler} />}
      {step === 1 && <UnlockConfirm rowData={rowData} setOpen={setOpen} />}
    </>
  );
};

export default Di_UnlockAccount;
