import React, { Dispatch } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { Action, ColorChipType, Data } from '../../../ThemeSettingContent';
import './ColorPicker.scss';

type ColorPickerProps = {
  data: Data;
  chipValue: ColorChipType;
  setState: Dispatch<Action>;
};

const ColorPicker = ({ chipValue, setState, data }: ColorPickerProps) => {
  const { id, color } = chipValue;
  const { theme_color } = data;

  const onChange = (color: ColorResult) => {
    setState({
      type: 'setData',
      payload: {
        ...data,
        theme_color: {
          ...theme_color,
          type: theme_color.type,
          index: theme_color.index,
          color: color.hex.toUpperCase(),
        },
        customId: id,
      },
    });
    setState({ type: 'plusChipChangeColor', payload: { id, color: color.hex.toUpperCase() } });
  };

  return (
    <div className="picker-bgimg">
      <ChromePicker disableAlpha color={color} onChange={onChange} />
    </div>
  );
};

export default ColorPicker;
