import React from 'react';
import classNames from 'classnames';
import { Proccessing } from '../loading/Loading';
import { ButtonElement } from './styled';

export type Styles = {
  width?: number | string;
  height?: number;
  radius?: number;
  fontSize?: number;
  align?: string;
};
export interface Props {
  type?: 'button' | 'submit';
  text?: string;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[];
  onClick?: (e: React.UIEvent) => void;
  styles?: Styles;
}

const Button: React.FC<Props> = (props): JSX.Element => {
  const { type = 'button', text = 'Button', disabled = false, onClick = () => false, className = '' } = props;
  const onClickHandler = (e: React.UIEvent) => {
    if (!props.loading) onClick(e);
  };
  return (
    <ButtonElement
      {...props.styles}
      type={type}
      disabled={disabled}
      onClick={onClickHandler}
      className={classNames('jack__button', { [className]: !!className, disabled })}
    >
      {props.loading ? <Proccessing /> : <div className="jack__button__context"> {props.children || text} </div>}
    </ButtonElement>
  );
};

export default Button;
