import React, { Dispatch } from 'react';
import { Radio } from 'usebeauty';
import { Action, State } from '../../../../ExaminationContent';
import './IdcardSsaRadio.scss';

interface Props {
  setState: Dispatch<Action>;
  setEdit: Dispatch<boolean>;
  edit: boolean;
  state: State;
}

// prettier-ignore
const ssaJudgeType = (type: number) => {
  switch(type) {
    case 1 :return { title: "자동 심사", value: "사본탐지 결과가 FAKE일 경우 자동 거부, Real일 경우 자동 승인 대상으로 설정합니다." };
    case 2 :return { title: "수동 심사", value: "사본탐지 결과가 FAKE일 경우 수동 심사 대상, Real일 경우 자동 승인 대상으로 설정합니다." };
    case 3 :return { title: "심사 반영 안함", value: "사본 탐지 여부만 확인하고, 심사에는 영향을 주지 않도록 설정합니다." };
  }
  return { title: "", value: "" };
};

const IdcardSsaRadio = (props: Props) => {
  const { state, setState, edit } = props;
  const { ssa_judge_type } = state.data.vas;

  return (
    <>
      {edit ? (
        <div id="ssa-edit-option" className="edit-option">
          <p className="ssa-description">사본탐지 결과에 따라 어떻게 심사 처리할지 설정할 수 있습니다.</p>
          <Radio.Group
            className="ssa-radio-group"
            value={ssa_judge_type}
            onChange={(value) => setState({ type: 'vas', payload: { ssa_judge_type: value } })}
          >
            <Radio text="자동 심사" value={1}>
              <p>Fake일 때는 자동 거부, Real일 때는 자동 승인 대상 처리</p>
            </Radio>
            <Radio text="수동 심사" value={2}>
              <p>Fake일 때는 수동 심사 대상, Real일 때는 자동 승인 대상 처리</p>
            </Radio>
            <Radio text="심사 반영 안함" value={3}>
              <p>사본 탐지 결과는 확인하되, 심사에는 영향을 주지 않음</p>
            </Radio>
          </Radio.Group>
        </div>
      ) : (
        <div className="display-option">
          <h6 className="title">{ssaJudgeType(ssa_judge_type).title}</h6>
          <span>{ssaJudgeType(ssa_judge_type).value}</span>
        </div>
      )}
    </>
  );
};

export default IdcardSsaRadio;
