import React from 'react';
import classNames from 'classnames';
import { Button, Input, Popover } from 'common/components';
import { Risk, useRaWeightContext } from '../../context/WeightContext';
import './WeightSetting.scss';

interface Props {
  edit: boolean;
  toggleEdit: () => void;
}
function WeightSetting(props: Props) {
  const { edit } = props;
  const { state, setState } = useRaWeightContext();
  const { data: raWeight, extraWeight } = state;

  const handleWeightButtonChange = (item: Risk, signed: boolean) => {
    let nextWeight = 0;
    if (signed) {
      nextWeight = item.weight < 1 ? Number((item.weight + 0.1).toFixed(1)) : item.weight;
    } else {
      nextWeight = item.weight > 0 ? Number((item.weight - 0.1).toFixed(1)) : item.weight;
    }
    const nextData = state.data.map((el) => (el.id === item.id ? { ...el, weight: nextWeight } : el));
    setState({ type: 'data', payload: nextData });
    setState({ type: 'extraWeight', payload: null });
  };

  const handleWeightInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: Risk) => {
    const { value: val } = e.target;
    const num = val.length ? val : '0';
    const value = Math.min(1, Math.floor(parseFloat(num) * 10) / 10);
    const st = state.data.map((el) => (el.id === item.id ? { ...el, weight: Number(value) } : el));
    setState({ type: 'data', payload: st });
    setState({ type: 'extraWeight', payload: null });
  };

  const totalScore = raWeight.reduce((acc, cur) => acc + 100 * cur.weight, 0);

  return (
    <div className="edd-setting-ra-weight">
      {edit && (
        <div className={classNames(['extra'], { hide: !edit })}>
          <span>
            남은 가중치
            <Popover
              position="right top"
              clickOutside
              content={() => (
                <div id="admin-edd-setting-ra-extra-weight-popover-content" className="description">
                  '고객위험, 상품 및 서비스 위험, 국가 위험' 총 3가지에 대해
                  <br /> 각 위험이 얼만큼의 비중을 차지하는지 설정하는 값입니다.
                </div>
              )}
            >
              <i className="tooltip-info" />
            </Popover>
          </span>
          <span className={classNames({ error: extraWeight !== 0 })}>{extraWeight}</span>
        </div>
      )}
      <section>
        {raWeight.map((item, i) => (
          <article key={i}>
            <div className="title">
              <span>{item.name}</span>
            </div>
            <div className={classNames(['weight'], { hide: !edit })}>
              <Button
                text=""
                disabled={!edit || !item.weight}
                className={classNames({ hide: !edit })}
                type="button"
                onClick={() => handleWeightButtonChange(item, false)}
              />
              <Input
                styles={{ width: 48 }}
                disabled={!edit}
                type="number"
                min="0"
                max="1"
                value={item.weight}
                onChange={(e) => handleWeightInputChange(e, item)}
              />
              <Button
                text=""
                disabled={!edit || !extraWeight}
                className={classNames({ hide: !edit })}
                type="button"
                onClick={() => handleWeightButtonChange(item, true)}
              />
            </div>
            <div className="score">
              <div className={classNames({ hide: edit })}>
                <span>적용 가중치</span>
                <span>{item.weight}</span>
              </div>
              <div>
                <span>계산값</span>
                <span>{100 * item.weight}점</span>
              </div>
            </div>
          </article>
        ))}
        <div className="footer">
          {totalScore !== 100 && <div className="error error__message">총점은 100점이 되어야 합니다.</div>}
          <div>
            <span>총 점</span>
            <span className={classNames({ error: totalScore !== 100 })}>{totalScore}점</span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WeightSetting;
