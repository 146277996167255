/* eslint-disable react/no-danger */
import React from 'react';
import { Select } from 'usebeauty';
import { N_Select } from 'usebeauty/dist/@types';
import type { State } from '../CtAdminTable';
import './Title.scss';

interface Props {
  state: State;
  handlers: {
    customerId: (selected: N_Select.Selected<number>) => void;
  };
}

const Title: React.FC<Props> = ({ state, handlers }) => {
  return (
    <div id="root-admin-controller-title">
      <p>어드민 관리</p>
      <Select st={{ width: 200 }} selected={state.customer_id} onChange={handlers.customerId}>
        <Select.Summary placeholder="고객사 선택" />
        <Select.Options className="control-option">
          {state.customers.map((el, i) => {
            return <Select.Item title={el?.title as string} value={el?.value as number} key={i} />;
          })}
        </Select.Options>
      </Select>
    </div>
  );
};

export default Title;
