import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Switch } from 'common/components';
import { Action, State } from '../ra/RaContent';
import './ConfigController.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  toggleEdit?: (type: 'config' | 'risk') => void;
  raRiskActiveHandler: (value: boolean) => void;
}
function ConfigController(props: Props) {
  const { state, setState, raRiskActiveHandler } = props;
  const { module, configs } = state.data;
  const { allow_ra, use_edd, use_ra } = configs;

  return (
    <section className="edd-ra-config-controller">
      <article>
        <div>
          <h4>EDD 사용</h4>
          <span>강화된 고객 확인</span>
          <div className="content">
            <Switch
              value={use_edd}
              disabled={!state.edit}
              onChange={(value) => {
                let payload = { module: { ...module }, configs: { ...configs, use_edd: !!value } };

                if (!value) {
                  payload = { ...payload, configs: { ...payload.configs, use_ra: false } };
                }
                setState({ type: 'setData', payload });
              }}
            />
          </div>
        </div>
      </article>
      <article className={classNames({ disabled: !configs.allow_ra || !use_edd || !use_ra })}>
        <div>
          <h4>RA 사용</h4>
          <span>고객 위험 평가</span>
          <div className="content">
            <Switch
              value={allow_ra && use_ra}
              disabled={!state.edit || !configs.allow_ra || !use_edd}
              onChange={(value) => {
                if (value) {
                  raRiskActiveHandler(true);
                }
                setState({ type: 'setData', payload: { configs: { ...configs, use_ra: !!value } } });
              }}
            />
          </div>
        </div>
      </article>
    </section>
  );
}

export default ConfigController;
