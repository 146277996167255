import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { getModules, getThresholdCompareLog } from 'common/api';
import { N_Pagination } from 'usebeauty/dist/@types';
import { NotAllowedPage } from 'common/components';
import CompareLogContent from './CompareLogContent';

export type Data = typeof getThresholdCompareLog.res & { expand: boolean };
export type State = {
  data: Data[];
  module: { face_authentication: boolean };
  pagination: N_Pagination.Data;
};

const initialState: State = {
  data: [],
  module: { face_authentication: false },
  pagination: { info: { current: 1, total: 1 }, format: { limit: 10, offset: 0 } },
};

export type Action =
  | { type: 'setData'; payload: Data[] }
  | { type: 'module'; payload: { face_authentication: boolean } }
  | { type: 'pagination'; payload: N_Pagination.Data }
  | { type: 'expand'; payload: number };

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case "setData": draft['data'] = action.payload; break;
      case "module": draft['module'] = action.payload; break;
      case "pagination": draft['pagination'] = action.payload; break;
      case "expand": draft['data'][action.payload]['expand'] = !draft.data[action.payload]['expand']; break;
    }
  });
};

const CtCompareLog = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);
    try {
      const params = {
        start: state.pagination.format.offset,
        count: state.pagination.format.limit,
      };
      const modules = await getModules();
      const { face_authentication } = modules;
      setState({ type: 'module', payload: { face_authentication } });

      if (face_authentication) {
        const { data } = await getThresholdCompareLog(params);

        setState({ type: 'setData', payload: data.list.map((l) => ({ ...l, expand: false })) });
        setState({
          type: 'pagination',
          payload: {
            ...state.pagination,
            info: { current: data.page_info.current_page, total: data.page_info.total_page },
          },
        });
      }
    } catch (e) {
      console.error('CtCompareLog error', { e });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, [state.pagination.info.current]);

  return (
    <div id="admin-manage-compare-log-content">
      {state.module.face_authentication ? <CompareLogContent state={state} setState={setState} /> : <NotAllowedPage />}
    </div>
  );
};

export default CtCompareLog;
