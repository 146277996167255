import React from 'react';
import classNames from 'classnames';
import { useEddConext } from '../../context/EddContext';
import CustomerRisk from './customerRisk/CustomerRisk';
import ProductRisk from './productRisk/ProductRisk';
import NationRisk from './nationRisk/NationRisk';
import './RaSettings.scss';

function RaSettings() {
  const { use_ra, state } = useEddConext();
  const { detailPage } = state;
  return (
    <section id="ra-setting-content" className={classNames({ detail: detailPage !== 'none' })}>
      <CustomerRisk use_ra={use_ra} />
      <ProductRisk use_ra={use_ra} />
      <NationRisk use_ra={use_ra} />
    </section>
  );
}

export default RaSettings;
