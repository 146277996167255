import React, { useState } from 'react';
import classNames from 'classnames';
import { Accordion, Button, Modal } from 'common/components';
import { NationRisk, WarnLabel } from './label/CtNationLabel';
import Modal_Nation from './modal/Modal_Nation';

interface Props {
  nations: NationRisk[];
  warnLabel: WarnLabel;
  edit: boolean;
  updateNation: (args: { score: number; nations: NationRisk[] }) => void;
  children?: ({ item, index }: { item: { names: string[]; edd_value: number }; index: number }) => React.ReactNode;
}
function Nation(props: Props) {
  const { nations, warnLabel, edit, updateNation } = props;
  const { active, score, name_en } = warnLabel;
  const [expand, setExpand] = useState(true);

  return (
    <div className={classNames('accordion__section', { expand, inactive: !active })}>
      <Accordion
        active={expand}
        onClick={() => setExpand((prev) => !prev)}
        title={
          <div className="item__title">
            <div>
              <b>국가 목록</b>
            </div>
          </div>
        }
      >
        <div className="item__subtree header">
          <span>국가명</span>
        </div>
        {nations.map((nation) => {
          return (
            <div className="item__subtree" key={nation.id}>
              <span>{nation.name_kr}</span>
            </div>
          );
        })}
      </Accordion>
      {edit && expand && active ? (
        <div className="item__subtree footer">
          <Modal
            content={({ closeHandler }) => (
              <Modal_Nation
                closeHandler={closeHandler}
                submitHandler={updateNation}
                warnLabel={name_en}
                nations={nations}
                score={score}
              />
            )}
          >
            <Button type="button" text="국가 설정" />
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Nation;
