import React from 'react';
import DatePicker from 'react-datepicker';
import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import { isValidDate } from 'common/functions/time';
// import { useSelector } from "common/hooks/selector";
import { formetterHandler } from './resource/Resource';
import './Daily.scss';

function DatePickerCustomHeader(params: ReactDatePickerCustomHeaderProps) {
  const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled } = params;
  return (
    <div className="react-datepicker__current-month">
      <button
        type="button"
        aria-label="Previous Month"
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        style={{ visibility: prevMonthButtonDisabled ? 'hidden' : 'visible' }}
        onClick={decreaseMonth}
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous" />
      </button>
      <span className="react-datepicker__current-month">
        {date.toLocaleString('ko', { month: 'long', year: 'numeric' })}
      </span>
      <button
        type="button"
        aria-label="Next Month"
        className="react-datepicker__navigation react-datepicker__navigation--next"
        style={{ visibility: nextMonthButtonDisabled ? 'hidden' : 'visible' }}
        onClick={increaseMonth}
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next" />
      </button>
    </div>
  );
}

type T_Time = [Date | null, Date | null];

interface Props {
  onChange: (item: { start: string; end: string }) => void;
  value: { start: string | null; end: string | null };
}

const Daily = (props: Props) => {
  const { value, onChange } = props;
  const current = new Date();
  // const currentYear = current.getFullYear();
  // const month = current.getMonth() + 1;
  // const currentMonth = month < 10 ? `0${month}` : month;
  // const firstDate = useSelector((state) => state.firstDate.first) || `${currentYear}-${currentMonth}`;

  const pickHandler = (dates: T_Time) => {
    const { start, end } = formetterHandler({ start: dates[0], end: dates[1] });
    if (onChange) onChange({ start, end });
  };

  const startDatePicker = () => {
    if (value.start) {
      return isValidDate(value.start) ? new Date(value.start) : null;
    }
    return null;
  };

  const endDatePicker = () => {
    if (value.end) {
      return isValidDate(value.end) ? new Date(value.end) : null;
    }
    return null;
  };

  return (
    <DatePicker
      locale={ko}
      onChange={pickHandler}
      selected={startDatePicker()}
      startDate={startDatePicker()}
      endDate={endDatePicker()}
      selectsRange
      inline
      maxDate={current}
      renderCustomHeader={DatePickerCustomHeader}
    />
  );
};

export default Daily;
