import React, { useEffect, useState } from 'react';
import { Select } from 'usebeauty';
import { N_Select } from 'usebeauty/dist/@types';
import './TimeInput.scss';

const HOURS = Array.from({ length: 24 }, (_, k) => `${k < 10 ? `0${k}` : k}`);
const MINUTES = Array.from({ length: 60 }, (_, k) => `${k < 10 ? `0${k}` : k}`);

function HourMinutePicker(props: {
  type: 'hour' | 'minute';
  value: N_Select.Selected<string>;
  onChange: (value: NonNullable<N_Select.Selected<string>>) => void;
}) {
  const { type, value, onChange } = props;
  const items = type === 'hour' ? HOURS : MINUTES;
  return (
    <Select
      className="time-select"
      selected={value}
      onChange={(value) => {
        if (value) onChange(value);
      }}
    >
      <Select.Summary />
      <Select.Options className="time-option">
        {items.map((h, i) => (
          <Select.Item key={i} title={h} value={h} />
        ))}
      </Select.Options>
    </Select>
  );
}

export function TimeInput(props: { value: string; onChange: (time: { hour: string; minute: string }) => void }) {
  const { value, onChange } = props;

  const [time, setTime] = useState(value);
  const [hour, minute] = time.split(':');

  useEffect(() => {
    onChange({ hour, minute });
  }, [time]);

  return (
    <div className="date-time-input">
      <HourMinutePicker
        type="hour"
        value={{ title: hour, value: hour }}
        onChange={(value) => setTime(`${value.value}:${minute}`)}
      />
      :
      <HourMinutePicker
        type="minute"
        value={{ title: minute, value: minute }}
        onChange={(value) => setTime(`${hour}:${value.value}`)}
      />
    </div>
  );
}
