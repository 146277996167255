import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'common/components';
import { useDispatch } from 'common/hooks/root';
import { modal } from 'layout';
import { authActions } from 'store/actions/root';
import './InterCeptor.scss';

interface Props {
  children: React.ReactNode;
}
export const InterCeptor: React.FC<Props> = ({ children }) => {
  const [prompt, setPrompt] = useState<string | boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { result_code } = error.response.data.api_response;
        if (result_code === 'E300') dispatch(authActions.logout({ navigate })); // 인증 에러
        else if (error.response.status >= 500) setPrompt(result_code);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      {children}
      {prompt && (
        <Modal.Portal setToggle={() => setPrompt(false)}>
          {({ closeHandler }) => {
            return (
              <modal.Container styles={{ width: 500 }} id="root-system-error-modal">
                <modal.Header align="center">시스템 에러</modal.Header>
                <modal.Content>
                  <div className="blue-text">
                    <p>시스템 에러가 발생하였습니다.</p>
                    <p>잠시 후 다시 이용해 주세요.</p>
                  </div>

                  <div className="gray-text">
                    <p>계속해서 문제가 발생한다면 고객센터로 문의해주세요.</p>
                  </div>

                  <div className="info">
                    <p>[전화] 070-4849-1000</p>
                    <p>[이메일] cs@useb.co.kr</p>
                  </div>

                  <div className="error-code">에러코드 : {prompt}</div>
                </modal.Content>
                <div onClick={closeHandler} className="button">
                  확인
                </div>
              </modal.Container>
            );
          }}
        </Modal.Portal>
      )}
    </>
  );
};
