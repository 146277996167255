import React, { Dispatch } from 'react';
import type { Action, Data } from '../Expand';
import { AccountComp, AddSurveyComp, FaceComp, IdcardComp, KybComp } from './comp';
import './Settings.scss';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

const Settings: React.FC<Props> = (props: Props) => {
  return (
    <article className="root-page-client-expand-functions">
      <h5>인증 설정</h5>
      <div className="setting-container">
        <IdcardComp {...props} />
        <FaceComp {...props} />
        <AccountComp {...props} />
        <AddSurveyComp {...props} />
        <KybComp {...props} />
      </div>
    </article>
  );
};

export default Settings;
