import React, { Dispatch, useContext } from 'react';
import { Button, Switch } from 'usebeauty';
import classNames from 'classnames';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import styled, { css } from 'styled-components';
import { Action, State } from '../ThemeSettingContent';
import Preview from './color-chip/theme-preview/Preview';
import LogoOption from './logo/Logo';
import ThemeColorOption from './themeColor/ThemeColorOption';
import './ThemeSetting.scss';

type Styles = {
  $primaryColor: string;
};
const PreviewColor = styled.span<Styles>`
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  ${({ $primaryColor }) =>
    css`
      background-color: ${$primaryColor};
    `}
`;

export interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const ThemeSetting = ({ state, setState, loadHandler }: Props) => {
  const { set } = Alert.Context();
  const { edit, data, defaultData } = state;
  const { setLoading } = useContext(ContentContext);

  const isDirty =
    data.theme_color.color !== defaultData.theme_color.color ||
    data.theme_color.fontColor !== defaultData.theme_color.fontColor ||
    data.custom_colors !== defaultData.custom_colors ||
    data.custom_logo !== defaultData.custom_logo ||
    data.use_custom_logo !== defaultData.use_custom_logo ||
    data.use_custom_theme !== defaultData.use_custom_theme;
  const customLogoUpload = data.custom_logo !== 'custom';

  const submitHandler = async () => {
    setState({ type: 'setEdit', payload: false });
    const theme_color = [data.theme_color.color.toString(), data.theme_color.fontColor.toString()].join(',');

    const params = {
      custom_colors: JSON.stringify(state.data['custom_colors'].map((el) => el.color)),
      custom_logo: state.data['custom_logo'].toString(),
      theme_color,
      use_custom_logo: state.data['use_custom_logo'].toString(),
      use_custom_theme: state.data['use_custom_theme'].toString(),
    };

    setLoading(true);
    process(
      api.reqData({ url: 'configs', data: params }),
      api.put.request,
      api.fullFilled(({ error }) => {
        setLoading(false);
        set({ success: '테마 설정이 완료되었습니다.' });
        loadHandler();
        if (!error) setState({ type: 'setDefaultData', payload: { ...state.data } });
      })
    );
  };

  return (
    <section id="admin-manage-theme-setting" className={classNames('admin-manage-section', { edit })}>
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: data.use_custom_theme })}>테마 설정</h4>
            <p>고객의 ekyc 화면에 아래 설정한 테마의 적용 여부를 결정합니다.</p>
          </div>
        </div>
        {edit ? (
          <div className={classNames('status-toggle', { active: data.use_custom_theme })}>
            <Switch
              className={classNames({ readonly: !edit })}
              disabled={!edit}
              checked={data.use_custom_theme}
              onChange={(e) => setState({ type: 'setData', payload: { ...data, use_custom_theme: e.target.checked } })}
            />
            <span className={classNames('status-toggle-label', { active: data.use_custom_theme })}>
              {data.use_custom_theme ? '사용' : '사용 안함'}
            </span>
          </div>
        ) : (
          <div className={classNames('status-label', { active: data.use_custom_theme })}>
            {data.use_custom_theme ? '사용 중' : '사용 안함'}
          </div>
        )}
        <div className="button-group">
          {edit ? (
            <>
              <Button
                onClick={() => {
                  setState({ type: 'setData', payload: defaultData });
                  setState({ type: 'setEdit', payload: false });
                }}
              >
                취소
              </Button>

              <Button className="submit" disabled={!isDirty || !customLogoUpload} onClick={submitHandler}>
                저장
              </Button>
            </>
          ) : (
            <Button ripple={false} className="cancel" onClick={() => setState({ type: 'setEdit', payload: true })}>
              편집
            </Button>
          )}
        </div>
      </div>
      {data.use_custom_theme && (
        <div className="description">
          {edit ? (
            <div className="edit-option">
              <section>
                <LogoOption datas={data} setState={setState} />
                <ThemeColorOption state={state} setState={setState} />
              </section>
              <section>
                <div className={classNames('theme-controller', { active: edit })}>
                  <div className="theme-preview">
                    <Preview data={data} />
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <div className="display-option">
              <div className="option-container">
                <div className="option">
                  <div className="label">
                    <span>현재 로고 이미지</span>
                  </div>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {!data.use_custom_logo ? (
                    <div>
                      <span>기본 로고</span>
                    </div>
                  ) : data.custom_logo ? (
                    <div className="image">
                      <img src={data.custom_logo} alt="logo" />
                    </div>
                  ) : (
                    <div>
                      <span>사용 안함</span>
                    </div>
                  )}
                </div>
                <div className="option">
                  <div className="label">
                    <span>현재 색상</span>
                  </div>
                  <div className="theme-color">
                    <span>{data.theme_color.color}</span>
                    <PreviewColor $primaryColor={data.theme_color.color} />
                  </div>
                </div>
                <div className="option">
                  <div className="label">
                    <span>폰트 색상</span>
                  </div>
                  <div className="font-color">
                    {data.theme_color.fontColor ? (
                      <>
                        <span>{data.theme_color.fontColor}</span>
                        <PreviewColor $primaryColor={data.theme_color.fontColor} />
                      </>
                    ) : (
                      <span>기본 색상</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default ThemeSetting;
