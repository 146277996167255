/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosResponse } from 'axios';
import { useQuery as UQ, UseQueryOptions } from '@tanstack/react-query';
import { api_response } from '../model';

export type Resp<K> = AxiosResponse<api_response & K>;
export type RespError<Result> = AxiosError<api_response & Result>;

type Query_Config<RPD, SD, ED> = {
  key?: Array<string | number | boolean | any | undefined>;
  options?: UseQueryOptions<Resp<RPD>, RespError<ED>, SD>;
  handler: () => Promise<Resp<RPD>>;
};

export const useKycQuery = <RPD = unknown, SD = Resp<RPD>, ED = unknown>(config: Query_Config<RPD, SD, ED>) => {
  const { key = [], options, handler } = config;
  return UQ<Resp<RPD>, RespError<ED>, SD>(key, handler, options);
};
