import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { SubController } from 'common/components';

function NationManage() {
  return (
    <div id="root-eddra-nation-sub-navigator">
      <SubController
        title={
          <>
            <NavLink to="/root/eddra-management/nation/labels">라벨 및 국가 설정</NavLink>
            <NavLink to="/root/eddra-management/nation/history">변경 로그</NavLink>
          </>
        }
      />
      <Outlet />
    </div>
  );
}

export default NationManage;
