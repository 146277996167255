import React, { useContext, useEffect, useReducer } from 'react';
import type { M_Table } from 'common/components';
import { api, toSortUpperCase } from 'common/functions';
import { useApiCancel, useMount } from 'common/hooks';
import { produce } from 'immer';
import { ContentContext } from 'layout/reviewer/content/Content';
import type { Pagination } from 'models';
import { getReviewResults } from 'common/api';
import { N_Select, N_Table } from 'usebeauty/dist/@types';
import ResultTable from './ResultTable';

const initPagination = { info: { current: 1, total: 0 }, format: { limit: 20, offset: 0 } };
export type Data = typeof getReviewResults.res;
export type Action =
  | { type: 'order'; payload: N_Table.Sort }
  | { type: 'setData'; payload: Data[] }
  | { type: 'totalCount'; payload: number }
  | { type: 'searchKey'; payload: N_Select.Selected<string> }
  | { type: 'searchText'; payload: symbol }
  | { type: 'pagination'; payload: Pagination }
  | { type: 'result_type'; payload: N_Select.Selected<number> }
  | { type: 'expand'; payload: number };

export interface State {
  data: Data[];
  totalCount: number;
  searchKey: N_Select.Selected<string>;
  searchText: symbol;
  order: N_Table.Sort;
  header: M_Table.header[];
  pagination: Pagination;
  result_type: N_Select.Selected<number>;
}

const initialState: State = {
  data: [],
  totalCount: 0,
  pagination: { ...initPagination },
  order: { request_time: 'desc' },
  searchText: Symbol(''),
  searchKey: { title: '선택', value: 'all' },
  result_type: { title: '심사구분', value: 0 },
  header: [
    { title: '제출일시', property: 'request_time', flex: 2, align: 'center', order: true },
    { title: '성명', property: 'name', flex: 2, align: 'center', order: true },
    { title: '전화번호', property: 'phone_number', align: 'center', order: true },
    { title: '생년월일', property: 'birthday', align: 'center', order: true },
    { title: '심사구분', property: 'result_type', align: 'center' },
    { title: '상세보기', property: 'expand', align: 'center' },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break
      case 'order': draft['order'] = action.payload; break;
      case 'totalCount': draft['totalCount'] = action.payload; break;
      case 'searchKey':
        draft['searchKey'] = action.payload;
        if(action.payload?.value === 'all') draft['searchText'] = Symbol('');
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'searchText':
        draft['searchText'] = action.payload;
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'result_type': draft['result_type'] = action.payload; break;
      case 'expand': draft.data[action.payload]['expand'] = !draft.data[action.payload].expand; break;
      case 'pagination': draft['pagination'] = action.payload; break;
    }
  });
};

const CtResultTable: React.FC = () => {
  useApiCancel(['review/results']);
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);

    const [sortKey, sortValue] = Object.entries(state.order)[0];

    const params = {
      start: state.pagination.format.offset,
      count: state.pagination.format.limit,
      result_type: state.result_type?.value,
      keyword_field: state.searchKey?.value,
      keyword: state.searchText.description?.trim(),
      order_field: sortKey,
      order: toSortUpperCase(sortValue),
    };

    const { list, total_count } = await getReviewResults(params);
    const payload = list.map((el: Omit<Data, 'expand'>) => ({ ...el, expand: false }));
    setState({ type: 'setData', payload });
    setState({ type: 'totalCount', payload: total_count });
    setState({ type: 'pagination', payload: api.setTotal(state.pagination, total_count) });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  useMount(() => {
    loadHandler();
  }, [state.order, state.searchText, state.pagination.info.current, state.searchKey, state.result_type]);

  return <ResultTable state={state} setState={setState} />;
};

export default CtResultTable;
