import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { getConfigs } from 'common/api';
import Forbidden from './Forbidden/Forbidden';
import ThemeSetting from './setting/ThemeSetting';
import { initialData, parseData, staticChips } from './utils';
import './ThemeSettingContent.scss';

export type ColorChipType = { id: number; color: string };

export type Data = {
  allow_custom_theme: boolean;
  use_custom_theme: boolean;
  use_custom_logo: boolean;
  custom_logo: string;
  base64String: string;
  theme_color: { type: string; index: number; color: string; fontColor: string };
  custom_colors: ColorChipType[];
  static_colors: ColorChipType[];
  customId: number;
};

export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
};

export type Action =
  | { type: 'setEdit'; payload: boolean }
  | { type: 'plusChipChangeColor'; payload: ColorChipType }
  | { type: 'setData'; payload: Data }
  | { type: 'setDefaultData'; payload: Data }
  | { type: 'setFontColor'; payload: { fontColor: string } };

const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'setEdit': draft['edit'] = action.payload; break;
      case 'setData': draft['data'] = {...draft.data, ...action.payload}; break;
      case 'setFontColor': draft['data']['theme_color'] = { ...draft.data.theme_color, ...action.payload}; break;
      case 'setDefaultData': draft['defaultData'] = {...draft.defaultData, ...action.payload}; break;
      case 'plusChipChangeColor':
        draft['data'].custom_colors = state['data'].custom_colors.map((v) => {
          if (action.payload.id === v.id) return { ...v, color: action.payload.color};
          return v;
        })
        break;
    }
  });
};

const ThemeSettingContent = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);

    const configs = await getConfigs();
    const data: Data = { ...parseData(configs), static_colors: staticChips, customId: 0 };
    data.custom_colors.some((chip, index) => {
      const find = data.theme_color.color === chip.color;
      if (find) data.theme_color = { ...data.theme_color, type: 'custom', index, color: chip.color };
      return find;
    });

    setState({ type: 'setData', payload: { ...data, base64String: data.custom_logo } });
    setState({ type: 'setDefaultData', payload: { ...data, base64String: data.custom_logo } });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-theme-content">
      <div className="admin-manage-theme-container">
        {state.data.allow_custom_theme ? (
          <ThemeSetting state={state} setState={setState} loadHandler={loadHandler} />
        ) : (
          <Forbidden />
        )}
      </div>
    </div>
  );
};

export default ThemeSettingContent;
