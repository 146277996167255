import React from 'react';
import './Controller.scss';

interface Props {
  title: React.ReactNode;
  children?: React.ReactNode;
}

export const Controller: React.FC<Props> = (props) => {
  return (
    <div className="alchera-content-controller">
      <div className="control-field">
        <h2>{props.title}</h2>
        <div className="addon-field">{props.children}</div>
      </div>
    </div>
  );
};
