/* eslint-disable react/no-danger */
import React from 'react';
import { Alert, Button, KycLoading, Modal, TextArea } from 'common/components';
import { NullishGuard } from 'hoc';
import { values } from 'lodash';
import { getGuidesKey } from 'common/api';
import { useKycQuery } from 'common/api/usequery';
import type { Data as RowData } from '../CtPrivacyTable';
import ModalDeletePrivacy from './modal/Modal_DeletePrivacy';
import ModalModifyPrivacy from './modal/Modal_ModifyPrivacy';
import './Expand.scss';

export type Data = typeof getGuidesKey.res;
interface Props {
  rowData: RowData;
  refresh: () => void;
}

const Expand: React.FC<Props> = ({ rowData, refresh }) => {
  const { set } = Alert.Context();

  const { refetch, isFetching, data } = useKycQuery({
    key: ['getGuidesKey'],
    handler: () => getGuidesKey(rowData.key),
    options: {
      select: (res) => {
        const { detail } = res.data;
        const html = JSON.parse(decodeURIComponent(atob(detail.content)));
        return values(html);
      },
    },
  });

  return (
    <>
      {isFetching && <KycLoading />}
      <NullishGuard data={[data]}>
        {([data]) => (
          <section className="root-guidance-privacy-table-tr-expand">
            <div id="guidance-privacy">
              <h3 className="term-subject">약관 내용</h3>
              {data.map((el) => {
                return (
                  <React.Fragment key={el.title}>
                    <div className="term-content">
                      <div className="term-title">{el.title}</div>
                      <div className="inner-html" dangerouslySetInnerHTML={{ __html: el.content }} />
                    </div>
                  </React.Fragment>
                );
              })}
              <h3 className="term-subject">약관 HTML</h3>
              {data.map((el) => {
                return (
                  <React.Fragment key={el.title}>
                    <div className="term-content">
                      <div className="term-title">
                        {el.title} HTML
                        <Button
                          text="HTML 복사하기"
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(el.content);
                              set({ success: 'HTML 복사가 완료되었습니다.' });
                            } catch {
                              set({ fail: 'HTML 복사는 실패되었습니다.' });
                            }
                          }}
                        />
                      </div>
                      <TextArea
                        readonly
                        className="html-textarea"
                        value={el.content}
                        styles={{ width: '100%', height: 136 }}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="expand-utils">
              <Modal
                content={({ closeHandler }) => (
                  <ModalDeletePrivacy closeHandler={closeHandler} rowData={rowData} refresh={refresh} />
                )}
              >
                <Button text="삭제" />
              </Modal>
              <Modal
                content={({ closeHandler }) => (
                  <ModalModifyPrivacy closeHandler={closeHandler} data={data} rowData={rowData} refetch={refetch} />
                )}
              >
                <Button text="편집" />
              </Modal>
            </div>
          </section>
        )}
      </NullishGuard>
    </>
  );
};

export default Expand;
