import React, { Dispatch, useState } from 'react';
import { api, process } from 'common/functions';
import { Alert } from 'common/components';
import classNames from 'classnames';
import RadioDriverIssueDate from '../radio/RadioDriverIssueDate';
import { Action, State } from '../../../../ExaminationContent';
import EditComp from '../../../edit/EditComp';
import './DriverExpiryDate.scss';

interface Props {
  state: State;
  loadHandler: () => void;
  setState: Dispatch<Action>;
}

const DriverExpiryDate = ({ state, loadHandler, setState }: Props) => {
  const { vas } = state.data;
  const { use_ocr_driver_expired_date, trust_driver_expired_date } = vas;
  const { set } = Alert.Context();
  const [edit, setEdit] = useState(false);

  const submitHandler = async () => {
    const data = {
      use_ocr_driver_expired_date: String(use_ocr_driver_expired_date),
      trust_driver_expired_date: !use_ocr_driver_expired_date ? String(trust_driver_expired_date) : undefined,
    };
    await process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled((response) => {
        setEdit(false);
        loadHandler();
        if (response) {
          const toastMessese = !use_ocr_driver_expired_date ? '설정' : '중지';
          set({ success: `운전면허증 마감일자 사용이 ${toastMessese}되었습니다.` });
        } else {
          set({ fail: `운전면허증 마감일자 사용 설정이 실패하였습니다.` });
        }
      })
    );
  };

  const handler = { loadHandler, submitHandler };

  return (
    <section id="idcard-driver-expiry-date" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: !use_ocr_driver_expired_date })}>운전면허증 마감일자 사용</h4>
            <p>고객 신원 확인의 정확도를 높이기 위해 운전면허 마감 일자를 고객에게 직접 받을 수 있습니다.</p>
          </div>
        </div>
        <EditComp
          onSwitch
          setEdit={setEdit}
          edit={edit}
          data={{ key: 'use_ocr_driver_expired_date', value: !use_ocr_driver_expired_date }}
          setState={setState}
          handler={handler}
        />
      </div>

      {!use_ocr_driver_expired_date && (
        <div className="description">
          {edit ? (
            <div className="edit-option">
              <RadioDriverIssueDate state={state} setState={setState} loadHandler={loadHandler} />
            </div>
          ) : (
            <div className="display-option">
              <h6 className="title">{trust_driver_expired_date ? '자동' : '수동'}심사</h6>
              {trust_driver_expired_date ? (
                <span>
                  OCR결과를 무시하고 사용자 입력 값으로 마감일자를 변경합니다. 이후 기본프로세스로 동작합니다.
                </span>
              ) : (
                <span>
                  OCR결과와 사용자 입력값이 같을 경우, 기본 프로세스로 진행합니다. 다른 경우, 수동 심사로 진행됩니다.
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default DriverExpiryDate;
