import React from 'react';
import { NullishGuard } from 'hoc';
import type { T_Custom, T_RaResult } from '../../Expand';
import * as Info from './info';
import './Custom.scss';

interface Props {
  content: NonNullable<T_Custom>;
  ra_result: T_RaResult | undefined | null;
  isExpired: boolean;
}

const Custom: React.FC<Props> = ({ content, ra_result, isExpired }) => {
  const { edd, custom } = content;

  return (
    <div id="reviewer-review-table-expand-content-custom">
      <NullishGuard data={[edd]}>{([edd]) => <Info.Edd edd={edd} isExpired={isExpired} />}</NullishGuard>
      <NullishGuard data={[ra_result]}>
        {([ra_result]) => <Info.RaResult ra_result={ra_result} isExpired={isExpired} />}
      </NullishGuard>
      <NullishGuard data={[custom]}>{([custom]) => <Info.Survey survey={custom} isExpired={isExpired} />}</NullishGuard>
    </div>
  );
};

export default Custom;
