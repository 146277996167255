import React, { useState } from 'react';
import { Input, LinkedList, Tree, Validator } from 'common/components';
import { modal } from 'layout';
import { raCategoryMap } from '../util';
import { EddRa } from '../../../context/model.type';
import './Modal_RaDropdown.scss';

interface Props {
  closeHandler: () => void;
  submitHandler: (name: string, nextTree: Tree[]) => void;
  eddCategory: string;
  item: EddRa.EddItem;
}
function Modal_RaDropdown(props: Props) {
  const { closeHandler, submitHandler, eddCategory, item } = props;
  const [originName, setOriginName] = useState(item.name);
  const [originTree, setOriginTree] = useState<Tree[]>(item.tree);

  const raName = raCategoryMap[eddCategory as keyof typeof raCategoryMap];

  const onSubmit = () => {
    submitHandler(originName, originTree);
    closeHandler();
  };

  return (
    <Validator.Provider onSubmit={onSubmit}>
      <modal.Container id="modal-ra-item-tree-setting" styles={{ width: 1016 }}>
        <modal.Header>선택지 설정</modal.Header>
        <modal.Content>
          <div className="condition">
            <div className="item">
              <h4>위험 종류</h4>
              {raName}
            </div>
            <div className="item">
              <h4>항목</h4>
              {item.isAppend ? (
                <Input
                  type="text"
                  className="basic"
                  value={originName}
                  placeholder="항목을 입력하세요"
                  maxLength={25}
                  onChange={(e) => {
                    const { value } = e.target;
                    setOriginName(value);
                  }}
                />
              ) : (
                item.name
              )}
            </div>
          </div>
          <div className="category__container">
            <LinkedList.Provider
              tree={item.tree}
              next={[{ title: '대분류' }, { title: '중분류' }, { title: '소분류' }]}
              onChange={(data) => setOriginTree(data)}
            >
              <LinkedList.Consumer>
                {({ tree }) => {
                  const [root] = tree;
                  return <LinkedList tree={root.tree} parent={root} />;
                }}
              </LinkedList.Consumer>
            </LinkedList.Provider>
          </div>
        </modal.Content>
        <modal.Footer closeHandler={closeHandler} submitText="설정" />
      </modal.Container>
    </Validator.Provider>
  );
}

export default Modal_RaDropdown;
