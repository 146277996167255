import type { Tree } from 'common/components';
// import { Data } from './customerRisk/CustomerRisk';
import { EddRa } from '../../context/model.type';

export const raCategoryMap = {
  customer: '고객 위험',
  service: '상품 및 서비스 위험',
  country: '국가 위험',
} as const;

export type FlatTree = { names: string[]; score: number };
type TreeNode = Tree;

export const treeToFlatTree = (trees: TreeNode[], parentName = '') => {
  const flatten: FlatTree[] = [];

  const dfs = (trees: TreeNode[], parentName: string[]) => {
    for (const tree of trees) {
      if (tree.tree.length) {
        dfs(tree.tree, [...parentName, tree.name]);
      } else {
        flatten.push({ names: [...parentName, tree.name], score: tree.score ?? 0 });
      }
    }
  };

  dfs(trees, parentName ? [parentName] : []);

  return flatten;
};

export const flatTreeToTree = (flatTree: FlatTree[]) => {
  const initialTree: TreeNode[] = [];
  const addNode = (node: TreeNode, names: string[]): TreeNode => {
    if (names.length === 0) {
      return node;
    }
    const [name, ...rest] = names;
    const childNode = node.tree.find((n) => n.name === name);
    if (childNode) {
      return addNode(childNode, rest);
    }
    const newNode = { name, score: 0, tree: [] };
    node.tree.push(newNode);
    return addNode(newNode, rest);
  };

  const createTree = (initialTree: TreeNode[], flatTree: FlatTree[]) => {
    return flatTree.reduce((tree, item) => {
      const names = item.names.slice();
      const lastName = names.pop() || '';
      const parentNode = addNode({ name: '', score: 0, tree }, names);
      parentNode.tree.push({ name: lastName, score: item.score, tree: [] });
      return tree;
    }, initialTree);
  };

  const tree = createTree(initialTree, flatTree);

  return tree;
};

export const checkActiveRaScore = (data: EddRa.EddItem[]) => {
  return data
    .filter((el) => el.active)
    .every((el) => {
      const flatTree = treeToFlatTree(el.tree);
      return flatTree.every((ft) => ft.score <= el.score);
    });
};

export const addRaData: (len: number, category: string) => EddRa.EddItem = (len, category) => ({
  id: 99 + len,
  name: '',
  type: 'tree',
  active: true,
  isAppend: true,
  category,
  score: 0,
  deletable: true,
  mutable: true,
  order: 99,
  display_name: '',
  description: '',
  tree: [],
});

type PayloadItem = { name: string; score: number; order: number; tree: Tree[]; display_name: string; description: string } // prettier-ignore
type Payload = {
  add: PayloadItem[];
  update: (PayloadItem & { id: number })[];
};
type fnPayload = { (data: EddRa.EddItem[]): Payload };
export const makePayload: fnPayload = (data) => {
  return data.reduce(
    (acc, cur, index) => {
      const { add, update } = acc;
      const d = { ...cur, order: index + 1 };
      return d.isAppend ? { update, add: [...add, d] } : { add, update: [...update, d] };
    },
    { add: [], update: [] } as Payload
  );
};
