import React, { useState } from 'react';
import classNames from 'classnames';
import { Container } from './styled';

export type Styles = {
  width?: number;
  height?: number;
  fontSize?: number;
  color?: string;
};

export interface Props {
  text?: string | JSX.Element;
  checked?: number;
  className?: string;
  disabled?: boolean;
  onChange?: (check: number) => void;
  styles?: Styles;
}
const CheckBox: React.FC<Props> = (props): JSX.Element => {
  const { onChange = () => false, disabled = false } = props;
  const [toggle, setToggle] = useState(props.checked ?? 0);
  const check = props.checked ?? toggle;

  const checkHandler = (e: React.UIEvent): void => {
    e.stopPropagation();
    if (disabled) return;
    const changeHandler = onChange ?? setToggle;
    changeHandler(check === 1 ? 0 : 1);
    if (!props.checked) setToggle(check === 1 ? 0 : 1);
  };

  const className = classNames('jack__checkbox__container', {
    [props.className as string]: props.className,
    checked: check === 1,
    indeter: check === 2,
    disabled,
  });

  return (
    <Container className={className} {...props.styles} onClick={checkHandler} checked={check}>
      <div className="jack__checkbox">
        {check === 1 && <div className="react-checkbox-check-icon" />}
        {check === 2 && <div className="react-checkbox-indeterminate-icon" />}
      </div>
      {props.text && <div className="jack__checkbox__label">{props.text}</div>}
    </Container>
  );
};

export default CheckBox;
