import React, { useState } from 'react';
import classNames from 'classnames';
import { Input, LinkedList, Tree, Validator } from 'common/components';
import { modal } from 'layout';
import { raCategoryMap } from '../util';
import { EddRa } from '../../../context/model.type';
import './Modal_RaDropdown.scss';

interface Props {
  closeHandler: () => void;
  submitHandler: (name: string, nextTree: Tree[]) => void;
  eddCategory: string;
  item: EddRa.EddItem;
  raItemNames: string[];
}
function Modal_RaAddItemDropdown(props: Props) {
  const { closeHandler, submitHandler, eddCategory, item, raItemNames } = props;
  const [originName, setOriginName] = useState('');
  const [originTree, setOriginTree] = useState<Tree[]>(item.tree);

  const raName = raCategoryMap[eddCategory as keyof typeof raCategoryMap];
  const isDuplicatedName = raItemNames.includes(originName);

  const onSubmit = () => {
    submitHandler(originName, originTree);
    closeHandler();
  };

  return (
    <Validator.Provider onSubmit={onSubmit}>
      <modal.Container id="modal-ra-item-tree-setting" styles={{ width: 1016 }}>
        <modal.Header>항목 추가</modal.Header>
        <modal.Content>
          <div className="condition add__item">
            <div className="item">
              <h4>위험 종류</h4>
              {raName}
            </div>
            <div className="item">
              <h4>항목</h4>
              <Input
                type="text"
                className="basic"
                value={originName}
                placeholder="항목명을 입력해주세요"
                maxLength={25}
                error={isDuplicatedName ? '이미 동일한 항목명이 존재합니다.' : undefined}
                onChange={(e) => {
                  const { value } = e.target;
                  setOriginName(value);
                }}
              />
            </div>
          </div>
          <div className={classNames('category__container', { disabled: !originName.length })}>
            <LinkedList.Provider
              tree={item.tree}
              next={[{ title: '대분류' }, { title: '중분류' }, { title: '소분류' }]}
              onChange={(data) => {
                setOriginTree(data);
              }}
            >
              <LinkedList.Consumer>
                {({ tree }) => {
                  const [root] = tree;
                  return <LinkedList tree={root.tree} parent={root} />;
                }}
              </LinkedList.Consumer>
            </LinkedList.Provider>
          </div>
        </modal.Content>
        <modal.Footer
          closeHandler={closeHandler}
          disabled={!originName.length || !originTree.length || isDuplicatedName}
          submitText="추가"
        />
      </modal.Container>
    </Validator.Provider>
  );
}

export default Modal_RaAddItemDropdown;
