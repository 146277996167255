import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { getModules, getThresholdCompare, getThresholdCompareReference } from 'common/api';
import { NotAllowedPage } from 'common/components';
import CompareSettingContent from './CompareSettingContent';

type Data = typeof getThresholdCompare.res;
type T_CompareReference = typeof getThresholdCompareReference.res;
export type State = {
  data: Data;
  defaultData: Data;
  module: { face_authentication: boolean };
  edit: boolean;
  reference: T_CompareReference[];
};

const initialData: Data = {
  match_manual_review: 12,
  match_auto_approve: 14,
};

const initialState: State = {
  data: { ...initialData },
  defaultData: { ...initialData },
  module: { face_authentication: false },
  reference: [],
  edit: false,
};

export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'setDefaultData'; payload: Data }
  | { type: 'module'; payload: { face_authentication: boolean } }
  | { type: 'reference'; payload: T_CompareReference[] }
  | { type: 'loading'; payload: boolean }
  | { type: 'edit'; payload: boolean }
  | { type: 'reset' };

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case "setData": draft['data'] = {...state.data, ...action.payload }; break;
      case "module": draft['module'] = action.payload; break;
      case "setDefaultData": draft['defaultData'] = { ...action.payload };  break;
      case "reference": draft['reference'] = action.payload ;  break;
      case "edit": draft['edit'] = action.payload;  break;
      case 'reset': draft['data'] = { ...draft.data, ...draft.defaultData }; break; 
    }
  });
};

const CtCompareSetting = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);
    try {
      const modules = await getModules();
      const { face_authentication } = modules;
      setState({ type: 'module', payload: { face_authentication } });

      if (face_authentication) {
        const [compare, reference] = await Promise.all([getThresholdCompare(), getThresholdCompareReference()]);
        const { match_manual_review, match_auto_approve } = compare.data;
        const { list: compareReference } = reference.data;

        setState({ type: 'setData', payload: { match_manual_review, match_auto_approve } });
        setState({ type: 'setDefaultData', payload: { match_manual_review, match_auto_approve } });
        setState({ type: 'reference', payload: compareReference });
      }
    } catch (e) {
      console.error('CtCompareSetting', { e });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-compare-setting-content">
      {state.module.face_authentication ? (
        <CompareSettingContent state={state} setState={setState} loadHandler={loadHandler} />
      ) : (
        <NotAllowedPage />
      )}
    </div>
  );
};

export default CtCompareSetting;
