import F_Input from './F_Input';
import F_CheckBox from './F_CheckBox';
import F_Select from './F_Select';
import F_Toggle from './F_Toggle';
import F_Radio from './F_Radio';
import F_Switch from './F_Switch';
import F_Textarea from './F_Textarea';

export const Form = {
  Input: F_Input,
  CheckBox: F_CheckBox,
  Select: F_Select,
  Toggle: F_Toggle,
  Radio: F_Radio,
  Switch: F_Switch,
  Textarea: F_Textarea,
};
