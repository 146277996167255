import React, { Dispatch } from 'react';
import { Radio } from 'usebeauty';
import { Switch } from 'common/components';
import classNames from 'classnames';
import { Action, Config, Data } from '../../../../Expand';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

export const ManualShot = (props: Props) => {
  const { data, setState, edit } = props;
  const { module, config } = data;
  const { manual_upload_id_pc, use_qrcode_pairing } = config;

  return (
    <>
      <div className="category border">
        <div className="cate-title">
          [PC] 모드 지원
          <Switch
            disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
            className={classNames({ readonly: !edit })}
            value={manual_upload_id_pc || use_qrcode_pairing}
            onChange={(value) => {
              const payload = { manual_upload_id_pc: !!value, ...(!value && { use_qrcode_pairing: !!value }) };
              setState({ type: 'config', payload });
            }}
          />
        </div>
        {(manual_upload_id_pc || use_qrcode_pairing) && module.id_card_ocr && (
          <div className="qrcode-pairing">
            <Radio.Group
              disabled={!edit || !(manual_upload_id_pc || use_qrcode_pairing) || !module.id_card_ocr}
              value={use_qrcode_pairing}
              onChange={(value) =>
                setState({ type: 'config', payload: { use_qrcode_pairing: !!value, manual_upload_id_pc: !value } })
              }
            >
              <Radio value={false} text="[PC] 사진첨부" />
              <Radio value text="[PC] QR코드 모바일 연동" />
            </Radio.Group>
          </div>
        )}
      </div>

      <div className="category">
        <div className="cate-title">
          [Mobile] 사진 첨부
          <Switch
            disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
            className={classNames({ readonly: !edit })}
            value={config.manual_upload_id_mobile}
            onChange={(value) => {
              const payload: Partial<Config> = { manual_upload_id_mobile: !!value };
              if (!value) payload.manual_input_mobile = !!value;
              setState({ type: 'config', payload });
            }}
          />
        </div>
        <div className="in-category">
          <div className="cate-title">
            직접 입력 허용
            <Switch
              disabled={!edit || !config.manual_upload_id_mobile}
              className={classNames({ readonly: !edit })}
              value={config.manual_input_mobile}
              onChange={(value) => setState({ type: 'config', payload: { manual_input_mobile: !!value } })}
            />
          </div>
        </div>
      </div>
    </>
  );
};
