import React, { useContext, useEffect, useReducer } from 'react';
import { getConfigs, getIdcardName, getModules } from 'common/api';
import { parseConfigs } from 'common/functions';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { Config } from '../../models/model.types';
import CtExaminationTable from './setting/CtExaminationTable';
import './ExaminationContent.scss';

export type Configs = { key: string; value: string };
export type VAS = Config.Authentication.Examination;
export type Data = { vas: VAS; defaultVas: VAS; kDriver: boolean };
export type State = { data: Data; module: { id_card_ocr: boolean } };

const initVas: VAS = {
  allow_auto_approve: true,
  use_ocr_driver_date: true,
  trust_driver_date: true,
  use_ocr_driver_expired_date: true,
  trust_driver_expired_date: true,
  allow_ssa: false,
  use_ssa: false,
  ssa_judge_type: 1,
};
const initData: Data = {
  vas: { ...initVas },
  defaultVas: { ...initVas },
  kDriver: false,
};

const initialState: State = {
  data: initData,
  module: { id_card_ocr: false },
};

export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'module'; payload: { id_card_ocr: boolean } }
  | { type: 'vas'; payload: AtLeastOne<VAS> }
  | { type: 'defaultVas'; payload: AtLeastOne<VAS> }
  | { type: 'resetVas'; payload: AtLeastOne<VAS> }
  | { type: 'reset'; payload: { key: keyof VAS } }
  | { type: 'edit'; payload: boolean }
  | { type: 'kDriver'; payload: boolean };

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case "setData": draft['data'] = {...state.data, ...action.payload};break;
      case "module": draft['module'] = action.payload; break;
      case 'vas': draft['data']['vas'] = {...state.data.vas, ...action.payload}; break;
      case 'defaultVas': draft['data']['defaultVas'] = {...state.data.vas, ...action.payload}; break;
      case 'reset': draft['data']['vas'] = { ...draft.data.vas, [action.payload.key]: draft.data.defaultVas[action.payload.key] }; break; 
      case 'kDriver': draft['data']['kDriver'] = action.payload; break; 
    }
  });
};

const ExaminationContent: React.FC = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);
    const [modules, config] = await Promise.all([getModules(), getConfigs()]);

    const idcardsName = (await getIdcardName('korean-driver')).data.detail;
    const vas = parseConfigs(config, Object.keys(initVas) as Array<keyof VAS>);
    const data: Data = { ...initData, vas, defaultVas: vas, kDriver: idcardsName.active };
    setState({ type: 'module', payload: { id_card_ocr: modules.id_card_ocr } });
    setState({ type: 'setData', payload: data });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-examination-content">
      <CtExaminationTable state={state} setState={setState} loadHandler={loadHandler} />
    </div>
  );
};

export default ExaminationContent;
