import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from '@usebeauty';
import { Footer, Validator } from 'common/components';
import { authActions } from 'store/actions/root';
import { useDispatch } from 'common/hooks/root';
import { Layer } from 'layout';
import { keys } from 'lodash';
import { Lock, UnLock } from '@Icons';
import './LoginPage.scss';

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    control,
    setError,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues: { id: '', password: '' } });
  const field = watch();

  const submitHandler = async () => {
    setLoading(true);
    const params = { root_name: field.id, password: field.password, navigate };
    const { payload: login } = await dispatch(authActions.login(params));
    // 로그인 에러 발생
    if (login) setError('id', { message: login });
    setLoading(false);
  };

  // 로그인 체크 후 자동 로그인
  useEffect(() => {
    const data = localStorage.getItem('ROOT_TOKEN');
    if (data) {
      const authObj = JSON.parse(data);
      const expire = new Date(authObj.expire).getTime();
      if (expire - Date.now() > 0) navigate('/root/client-management');
      else localStorage.removeItem('ROOT_TOKEN');
    }
  }, []);

  const errorLength = keys(errors).length;

  return (
    <>
      <div id="root-login">
        <div id="root-login-form-field">
          <div className="signin-title">
            <i />
          </div>
          <section className="form-section">
            <Validator.Provider form onSubmit={submitHandler}>
              <div className="login-text">Root Login</div>
              <Layer styles={{ marginBottom: 16 }}>
                <Form.Input className="basic" control={control} name="id" placeholder="아이디" />
              </Layer>
              <Layer styles={{ marginBottom: 16 }}>
                <Form.Input
                  className="basic"
                  type="password"
                  control={control}
                  name="password"
                  placeholder="비밀번호"
                  lockCloseIcon={<Lock />}
                  lockOpenIcon={<UnLock />}
                />
              </Layer>
              <Validator.Submit
                text="로그인"
                loading={loading}
                disabled={!field.id || !field.password || !!errorLength}
                styles={{ width: '100%', height: 60 }}
              />
            </Validator.Provider>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};
