import React from 'react';
import { Button, Dialog } from 'usebeauty';
import classNames from 'classnames';
import { State } from '../AuthenticateContent';
import Di_Identification from './modal/Di_Identification';
import './Identification.scss';

interface Props {
  state: State;
  loadHandler: () => void;
}
const Identification = (props: Props) => {
  const { state, loadHandler } = props;
  const { identification } = state.vas;

  return (
    <section id="admin-manage-authenticate-identification" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: !identification })}>사용자 필수 정보 수신</h4>
            <p>사용자 필수 정보들을 수신합니다. 사용 여부를 전환할 경우 서비스에 바로 반영됩니다.</p>
          </div>
        </div>
        <div className={classNames('status-label', { active: !identification })}>
          {!identification ? '사용 중' : '사용 안함'}
        </div>
        <div className="button-group">
          <>
            <Dialog
              content={({ closeHandler }) => (
                <Di_Identification state={state} loadHandler={loadHandler} closeHandler={closeHandler} />
              )}
            >
              <Button>편집</Button>
            </Dialog>
          </>
        </div>
      </div>
      <div className="description">
        <div className="display-option">
          <h6>필수 정보 항목</h6>
          <span>이름 / 생년월일 / 전화번호 / 이메일</span>
        </div>
      </div>
    </section>
  );
};

export default Identification;
