import React, { Dispatch, useState } from 'react';
import { api, process } from 'common/functions';
import { Alert, Promotion } from 'common/components';
import classNames from 'classnames';
import EditComp from '../../edit/EditComp';
import { Action, State } from '../../../ExaminationContent';
import IdcardSsaRadio from './radio/IdcardSsaRadio';
import './SsaComp.scss';

interface Props {
  loadHandler: () => void;
  state: State;
  setState: Dispatch<Action>;
}

const SsaComp = (props: Props) => {
  const { loadHandler, state, setState } = props;
  const { use_ssa, ssa_judge_type, allow_ssa } = state.data.vas;
  const { set } = Alert.Context();
  const [edit, setEdit] = useState(false);

  const submitHandler = async () => {
    const data = { use_ssa: String(use_ssa), ssa_judge_type: use_ssa ? String(ssa_judge_type) : undefined };
    await process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled((response) => {
        setEdit(false);
        loadHandler();
        if (response) {
          set({ success: `사본탐지가 ${use_ssa ? '설정' : '중지'}되었습니다.` });
        } else {
          set({ fail: `사본탐지 설정 변경에 실패하였습니다.` });
        }
      })
    );
  };
  const handler = { loadHandler, submitHandler };

  return (
    <section id="idcard-ocr-ssa" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: use_ssa })}>사본탐지 사용</h4>
            <p>촬영된 신분증의 사본 유무를 체크합니다.</p>
          </div>
        </div>
        <EditComp
          isAllow={allow_ssa}
          onSwitch
          setEdit={setEdit}
          edit={edit}
          setState={setState}
          handler={handler}
          data={{ key: 'use_ssa', value: use_ssa }}
        />
      </div>
      {use_ssa && (
        <div className="description">
          <IdcardSsaRadio edit={edit} setEdit={setEdit} state={state} setState={setState} />
        </div>
      )}
      {!allow_ssa && <Promotion />}
    </section>
  );
};

export default SsaComp;
