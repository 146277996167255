import React from 'react';
import { modal } from 'layout';
import './Portal_Fail.scss';

interface Props {
  closeHandler: () => void;
  fail: { current_count: number; max_count: number };
  approval?: boolean;
}

const PortalFail: React.FC<Props> = ({ closeHandler, fail, approval }) => {
  const currentCount = String(fail.current_count).padStart(2, '0');
  const maxCount = String(fail.max_count).padStart(2, '0');
  return (
    <modal.Container styles={{ width: 500 }} id="root-admin-page-modify-fail">
      <modal.Header align="center">{approval ? '수락' : '활성화'} 실패</modal.Header>
      <modal.Content>
        <div className="modify-fail-info">
          <p>{approval ? '최대 심사자 수를 초과하여 수락할 수 없습니다.' : '최대 활성 가능한 수를 초과하였습니다.'}</p>
          <p>활성 가능한 심사자 수를 확인해 주세요.</p>
          <p>{`현재 활성화 된 수 ${currentCount} / ${maxCount}`}</p>
        </div>
      </modal.Content>
      <modal.Footer submitHandler={closeHandler} />
    </modal.Container>
  );
};

export default PortalFail;
