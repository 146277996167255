import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as admin from 'common/hooks/admin';
import * as reviewer from 'common/hooks/reviewer';

import { authActions as adminActions } from 'store/actions/admin';
import { authActions as reviewerActions } from 'store/actions/reviewer';

import { NullishGuard } from 'hoc';
import { Modal } from 'common/components';
import ModalModifyMy from './modal/Modal_ModifyMy';
import './Popover_MyBox.scss';

interface Props {
  closeHandler: () => void;
}

const PopoverMyBox = ({ closeHandler }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = pathname.split('/')[1];

  const selector = (location === 'reviewer' && reviewer.useSelector) || (location === 'admin' && admin.useSelector);
  if (!selector) return <></>;

  const adminDispatch = admin.useDispatch();
  const reviewerDispatch = reviewer.useDispatch();

  const { data, my } = selector((state) => state.auth);

  return (
    <div id="kyc-admin-reviewer-popover-content">
      <div className="top-box">
        <NullishGuard data={[data, my]}>
          {([data, my]) => (
            <>
              <p className="name">{my.name}</p>
              <p className="my-name">{my.username}</p>
              <hr />
              <p className="company-name">{data.company.name}</p>
            </>
          )}
        </NullishGuard>
      </div>

      <div className="bottom-box">
        <NullishGuard data={[data, my]}>
          {([data, my]) => {
            return (
              <Modal content={({ closeHandler }) => <ModalModifyMy closeHandler={closeHandler} auth={{ data, my }} />}>
                <button type="button" className="change-my-info" onClick={closeHandler}>
                  정보수정
                </button>
              </Modal>
            );
          }}
        </NullishGuard>

        <button
          type="button"
          className="log-out"
          onClick={() => {
            if (location === 'admin') adminDispatch(adminActions.logout({ navigate }));
            if (location === 'reviewer') reviewerDispatch(reviewerActions.logout({ navigate }));
          }}
        >
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default PopoverMyBox;
