/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { tint } from 'tint-shade-color';
import classNames from 'classnames';
import { previewContainer } from '../../preview/svg-jsfiles';
import { Data } from '../../../ThemeSettingContent';
import { getFontColorByPrimaryColor } from './getFontColorByPrimaryColor';
import './Preview.scss';

interface PreviewType {
  data: Data;
}

export type ColorListType = {
  id: number;
  color: string;
  data: Data;
};

type ImgsType = {
  id: number;
  img: JSX.Element;
};

const Preview = ({ data }: PreviewType) => {
  const { color, fontColor } = data.theme_color;
  const [clickedId, setClickedId] = useState(1); // small preview

  const slideRef = useRef<HTMLDivElement>(null);

  const [currentImgIndex, setCurrentImgIndex] = useState(1); // big preview
  const transitionTime = 0.2;
  const transitionStyle = `all ${transitionTime}s ease-in-out`;
  const [slideTransition, setTransition] = useState(transitionStyle);
  const imgWidth = 508;
  const slideRange = currentImgIndex * imgWidth;

  const ImgIndex = 6;

  const [slideEventController, setSlideEventController] = useState<boolean>(true);

  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.style.transition = slideTransition;
      slideRef.current.style.transform = `translateX(-${slideRange}px)`;
    }
  }, [currentImgIndex]);

  const replaceSlide = (index: number) => {
    setTimeout(() => {
      setTransition('');
      setCurrentImgIndex(index);
    }, 200);
  };

  const handleSlide = (index: number) => {
    setCurrentImgIndex(index);

    if (index < 1) {
      index += ImgIndex;

      replaceSlide(index);
    } else if (index >= ImgIndex + 1) {
      index -= ImgIndex;

      replaceSlide(index);
    }

    setTransition(transitionStyle);
  };

  const handleSwipe = (direction: number) => {
    if (slideEventController) {
      setSlideEventController(false);
      const nextImgIndex = currentImgIndex + direction;
      let nextSmallImgIndex = nextImgIndex;

      if (nextImgIndex < 1) nextSmallImgIndex = 6;
      if (nextImgIndex >= ImgIndex + 1) nextSmallImgIndex = 1;
      handleSlide(nextImgIndex);
      setClickedId(() => nextSmallImgIndex);
      setTimeout(() => {
        setSlideEventController(true);
      }, 200);
    }
  };

  const moveToSelectIndex: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const id = parseInt(e.currentTarget.id, 10);

    setCurrentImgIndex(id);
    setTransition(transitionStyle);
  };

  const Thumbnail_Click = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    moveToSelectIndex(e);
    const id = parseInt(e.currentTarget.id, 10);
    setClickedId(id);
  };

  const Tint_80 = tint(`${color}`, 0.2);
  const Tint_60 = tint(`${color}`, 0.4);
  const Tint_40 = tint(`${color}`, 0.6);
  const Tint_20 = tint(`${color}`, 0.8);
  const Tint_10 = tint(`${color}`, 0.9);
  const Tint_5 = tint(`${color}`, 0.95);

  const colorList: ColorListType[] = [
    { id: 1, color: `${color}`, data },
    { id: 2, color: `${Tint_80}`, data },
    { id: 3, color: `${Tint_60}`, data },
    { id: 4, color: `${Tint_40}`, data },
    { id: 5, color: `${Tint_20}`, data },
    { id: 6, color: `${Tint_10}`, data },
    { id: 7, color: `${Tint_5}`, data },
    { id: 8, color: `${fontColor || getFontColorByPrimaryColor(color)}`, data },
  ];

  const Previews: ImgsType[] = previewContainer.Preview.map((img, index) => ({
    id: index + 1,
    img: img({ colors: colorList }),
  }));
  const Thumbnails: ImgsType[] = previewContainer.Thumbnail.map((img, index) => ({
    id: index + 1,
    img: img({ colors: colorList }),
  }));

  return (
    <article className="Preview">
      <div className="slide-content">
        <div className="prev">
          <i className="prev-btn" onClick={() => handleSwipe(-1)} />
        </div>
        <div className="next">
          <i className="next-btn" onClick={() => handleSwipe(1)} />
        </div>

        <div className="slide-wrapper" ref={slideRef}>
          {Previews.map((element) => (
            <div key={element.id}>
              <div className="preview-img">{element.img}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="thumbnail-list">
        {Thumbnails.map((element) => (
          <div
            className={classNames({ active: clickedId === element.id })}
            key={element.id}
            id={element.id.toString()}
            onClick={Thumbnail_Click}
          >
            {element.img}
          </div>
        ))}
      </div>
    </article>
  );
};

export default React.memo(Preview);
