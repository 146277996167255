import React, { useContext, useEffect, useReducer } from 'react';
import { getConfigs, getModules } from 'common/api';
import { parseConfigs } from 'common/functions';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { NotAllowedPage } from 'common/components';
import { Config } from '../../models/model.types';
import IdcardSetting from './setting/IdcardSetting';
import './IdcardContent.scss';

export type VAS = Config.Authentication.Idcard;
export type Data = { vas: VAS; defaultVas: VAS };
export type State = { data: Data; module: { id_card_ocr: boolean } };

const initVas: VAS = {
  server_ocr_type: 1,
  allow_wasm_ocr: false,
  use_wasm_ocr: false,
  ocr_auto_switching: false,
  use_wasm_image_warping: false,
  allow_wasm_face: false,
  use_wasm_face: false,
  allow_idcard_face_detect: false,
  use_idcard_face_detect: false,
  use_qrcode_pairing: false,
  include_driver_type: false,
};
const initData: Data = {
  vas: initVas,
  defaultVas: { ...initVas },
};

const initialState: State = {
  data: initData,
  module: { id_card_ocr: false },
};

export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'module'; payload: { id_card_ocr: boolean } }
  | { type: 'vas'; payload: AtLeastOne<VAS> }
  | { type: 'defaultVas'; payload: AtLeastOne<VAS> }
  | { type: 'resetVas'; payload: AtLeastOne<VAS> }
  | { type: 'reset'; payload: { key: keyof VAS } }
  | { type: 'edit'; payload: boolean }
  | { type: 'kDriver'; payload: boolean };

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case "setData": draft['data'] = {...state.data, ...action.payload};break;
      case "module": draft['module'] = action.payload; break;
      case 'vas': draft['data']['vas'] = {...state.data.vas, ...action.payload}; break;
      case 'defaultVas': draft['data']['defaultVas'] = {...state.data.vas, ...action.payload}; break;
      case 'reset': draft['data']['vas'] = { ...draft.data.vas, [action.payload.key]: draft.data.defaultVas[action.payload.key] }; break; 
    }
  });
};

const IdcardContent: React.FC = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);
    try {
      const modules = await getModules();
      setState({ type: 'module', payload: { id_card_ocr: modules.id_card_ocr } });
      if (modules.id_card_ocr) {
        const config = await getConfigs();
        const vas = parseConfigs(config, Object.keys(initVas) as Array<keyof VAS>);
        const data: Data = { ...initData, vas, defaultVas: vas };
        setState({ type: 'setData', payload: data });
      }
    } catch (e) {
      console.error('IdcardContent', { e });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-idcard-content">
      {state.module.id_card_ocr ? (
        <IdcardSetting state={state} setState={setState} loadHandler={loadHandler} />
      ) : (
        <NotAllowedPage />
      )}
    </div>
  );
};

export default IdcardContent;
