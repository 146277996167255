import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 0;
`;

type Props = {
  st?: React.CSSProperties;
  id?: string;
  className?: string;
  onClick?: (e: React.UIEvent) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const InputCheck = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#363636', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 20 20" fill="none">
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m 3.479,10 5,5 8.334,-10"
          id="path1276"
        />
      </svg>
    </Div>
  );
};

export const Lock = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#cccccc', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
        <g id="g1524" transform="translate(-215,-196)" fill="none">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m 220.636,210.346 c -1.846,-1.311 -3.305,-3.014 -4.131,-4.09 -0.23,-0.297 -0.355,-0.663 -0.355,-1.039 0,-0.376 0.125,-0.742 0.355,-1.039 1.494,-1.949 5.076,-5.962 9.258,-5.962 1.875,0 3.629,0.807 5.129,1.874"
            id="path1516"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m 227.894,203.104 c -0.277,-0.283 -0.608,-0.507 -0.973,-0.661 -0.365,-0.154 -0.758,-0.234 -1.153,-0.235 -0.395,-0.001 -0.788,0.075 -1.155,0.226 -0.365,0.15 -0.698,0.373 -0.979,0.652 -0.281,0.279 -0.502,0.613 -0.652,0.979 -0.151,0.366 -0.229,0.758 -0.227,1.154 0.002,0.396 0.082,0.789 0.235,1.153 0.153,0.365 0.378,0.696 0.661,0.974"
            id="path1518"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m 217.763,213.217 16,-16"
            id="path1520"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m 223.763,211.921 c 0.649,0.193 1.322,0.293 2,0.296 4.182,0 7.764,-4.014 9.257,-5.962 0.231,-0.298 0.356,-0.663 0.355,-1.039 0,-0.376 -0.126,-0.742 -0.356,-1.039 -0.524,-0.685 -1.088,-1.339 -1.686,-1.96"
            id="path1522"
          />
        </g>
      </svg>
    </Div>
  );
};

export const UnLock = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#cccccc', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
        <g id="g4447" transform="translate(-1,-3)" fill="none">
          <path
            d="m 21.257,10.962 c 0.474,0.62 0.474,1.457 0,2.076 C 19.764,14.987 16.182,19 12,19 7.81801,19 4.23601,14.987 2.74301,13.038 2.51239,12.7411 2.38721,12.3759 2.38721,12 c 0,-0.3759 0.12518,-0.7411 0.3558,-1.038 C 4.23601,9.013 7.81801,5 12,5 c 4.182,0 7.764,4.013 9.257,5.962 z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path4435"
          />
          <path
            d="m 12,15 c 1.6569,0 3,-1.3431 3,-3 0,-1.6569 -1.3431,-3 -3,-3 -1.6569,0 -3,1.3431 -3,3 0,1.6569 1.3431,3 3,3 z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path4437"
          />
        </g>
      </svg>
    </Div>
  );
};

export const FillLock = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#868686', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="2.4em" height="2.4em" viewBox="0 0 24 24" fill="none">
        <g id="Lock" clipPath="url(#clip0_2283_25056)">
          <path
            id="Vector"
            d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9  22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13C13.1 13 14 13.9 14 15C14 16.1 13.1 17 12 17ZM15.1 8H8.9V6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2283_25056">
            <rect width="2.4em" height="2.4em" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Div>
  );
};

export const User2 = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#868686', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
        <path
          d="M8 10.75C4 10.75 0 12.09 0 14.75V18.25C0 18.8 0.45 19.25 1 19.25H15C15.55 19.25 16 18.8 16 18.25V14.75C16 12.09 12 10.75 8 10.75Z"
          fill="#ABACAD"
        />
        <path
          d="M12 4.75C12 6.95914 10.2091 8.75 8 8.75C5.79086 8.75 4 6.95914 4 4.75C4 2.54086 5.79086 0.75 8 0.75C10.2091 0.75 12 2.54086 12 4.75Z"
          fill="#ABACAD"
        />
      </svg>
    </Div>
  );
};

export const DownArrow = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#cccccc', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g id="icon / Arrow">
          <path
            id="Rectangle 104"
            d="M12.1516 15.0618L17.1424 10.071M12.1516 5.08026L17.1424 10.071M17.1424 10.071L3.00021 10.071"
            stroke="#363636"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Div>
  );
};

export const Info = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#363636', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 20 20" fill="none">
        <circle cx="10" cy="10" r="7.5" stroke="currentColor" strokeWidth="1.25" />
        <circle cx="10.0001" cy="6.66634" r="0.833333" fill="currentColor" />
        <rect x="9.16675" y="8.33301" width="1.66667" height="5.83333" rx="0.833333" fill="currentColor" />
      </svg>
    </Div>
  );
};

export const Time = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#5F65FF', ...st }} {...rest}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="6.5" stroke="currentColor" />
        <path d="M10 6V10L12.5 12.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Div>
  );
};

export const Chevron = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#363636', ...st }} {...rest}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.36394 4.29292C8.75446 3.9024 9.38763 3.9024 9.77815 4.29292L16.8492 11.364C17.2397 11.7545 17.2397 12.3877 16.8492 12.7782L9.77815 19.8493C9.38763 20.2398 8.75446 20.2398 8.36394 19.8493C7.97341 19.4587 7.97341 18.8256 8.36394 18.4351L14.7279 12.0711L8.36394 5.70714C7.97341 5.31661 7.97341 4.68345 8.36394 4.29292Z"
          fill="currentColor"
        />
      </svg>
    </Div>
  );
};

export const YearArrow = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#363636', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g id="icon / Chevron">
          <path
            id="Rectangle 71"
            d="M9.38074 3.33337L4.66669 8.04742L9.38074 12.7615"
            stroke="currentColor"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Div>
  );
};

export const Filter = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#ced1d2', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g id="Filter list" clipPath="url(#clip0_676_17794)">
          <path
            id="Vector"
            d="M9.16667 15H10.8333C11.2917 15 11.6667 14.625 11.6667 14.1667C11.6667 13.7083 11.2917 13.3333 10.8333 13.3333H9.16667C8.70833 13.3333 8.33333 13.7083 8.33333 14.1667C8.33333 14.625 8.70833 15 9.16667 15ZM2.5 5.83333C2.5 6.29167 2.875 6.66667 3.33333 6.66667H16.6667C17.125 6.66667 17.5 6.29167 17.5 5.83333C17.5 5.375 17.125 5 16.6667 5H3.33333C2.875 5 2.5 5.375 2.5 5.83333ZM5.83333 10.8333H14.1667C14.625 10.8333 15 10.4583 15 10C15 9.54167 14.625 9.16667 14.1667 9.16667H5.83333C5.375 9.16667 5 9.54167 5 10C5 10.4583 5.375 10.8333 5.83333 10.8333Z"
            fill="#868686"
          />
        </g>
        <defs>
          <clipPath id="clip0_676_17794">
            <rect width="2.0em" height="2.0em" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Div>
  );
};

export const DoughnutNoData = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#363636', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20.1em" height="20em" viewBox="0 0 201 200" fill="none">
        <g id="Group 424">
          <g id="Group 422">
            <g id="Ellipse 487">
              <mask id="path-1-inside-1_1700_14772" fill="white">
                <path d="M100.5 1.27594e-05C155.728 1.1379e-05 200.5 44.7715 200.5 100C200.5 155.228 155.728 200 100.5 200C45.2715 200 0.499997 155.228 0.499992 100C0.499988 44.7715 45.2715 1.41398e-05 100.5 1.27594e-05ZM100.5 150C128.114 150 150.5 127.614 150.5 100C150.5 72.3858 128.114 50 100.5 50C72.8857 50 50.5 72.3858 50.5 100C50.5 127.614 72.8858 150 100.5 150Z" />
              </mask>
              <path
                d="M100.5 1.27594e-05C155.728 1.1379e-05 200.5 44.7715 200.5 100C200.5 155.228 155.728 200 100.5 200C45.2715 200 0.499997 155.228 0.499992 100C0.499988 44.7715 45.2715 1.41398e-05 100.5 1.27594e-05ZM100.5 150C128.114 150 150.5 127.614 150.5 100C150.5 72.3858 128.114 50 100.5 50C72.8857 50 50.5 72.3858 50.5 100C50.5 127.614 72.8858 150 100.5 150Z"
                fill="#CCCCCC"
                stroke="white"
                strokeWidth="3.05533"
                mask="url(#path-1-inside-1_1700_14772)"
              />
            </g>
          </g>
        </g>
      </svg>
    </Div>
  );
};
