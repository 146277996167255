import React, { Dispatch, useReducer } from 'react';
import { Dialog } from 'usebeauty';
import { Modal, Switch, ToolTip } from 'common/components';
import { produce } from 'immer';
import { FillLock } from '@Icons';
import type { Action as TableAction, State as TableState } from '../CtReviewerTable';
import PortalConfirm from './modal/Portal_Confirm';
import PortalFail from './modal/Portal_Fail';
import Approval from './approval/Approval';
import Di_UnlockAccount from './modal/Di_UnlockAccount';

type Action =
  | { type: 'close' }
  | { type: 'confirm'; payload: null | boolean }
  | { type: 'lock'; payload: boolean }
  | { type: 'fail'; payload: null | { current_count: number; max_count: number } };

interface State {
  confirm: null | boolean;
  fail: null | { current_count: number; max_count: number };
  lock: boolean;
}

const initialState: State = { confirm: null, fail: null, lock: false };

const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch (action.type) {
      case 'confirm': draft['confirm'] = action.payload; break;
      case 'lock': draft['lock'] = action.payload; break;
      case 'fail': draft['fail'] = action.payload; break;
      case 'close':
        draft['fail'] = null;
        draft['confirm'] = null;
        break;
    }
  });
};

interface Props {
  state: TableState;
  index: number;
  setState: Dispatch<TableAction>;
  refresh: () => void;
}

const Active: React.FC<Props> = (props) => {
  const [prompt, setPrompt] = useReducer(reducer, initialState);
  const rowData = props.state.data[props.index];

  const value = props.state.data[props.index].active;
  const isConfirm = prompt.confirm !== null && prompt.confirm !== value;
  const isOpen = isConfirm || prompt.fail;

  const closeHandler = () => setPrompt({ type: 'close' });

  return (
    <>
      {rowData.is_approve ? (
        <div className="active-box">
          {rowData.locked ? (
            <>
              <ToolTip
                position="top center"
                backgroundColor="initial"
                className="warnlabel-tooltip-root"
                content={() => (
                  <div>
                    해당 계정은 로그인 5회 오류로 인해 계정 잠금 되었습니다. <br />
                    활성화 후 로그인 할 수 있습니다.
                  </div>
                )}
              >
                <FillLock className="fill-lock" />
              </ToolTip>
              <Switch value={false} onChange={(value) => setPrompt({ type: 'lock', payload: !!value })} />
            </>
          ) : (
            <Switch value={value} onChange={(value) => setPrompt({ type: 'confirm', payload: !!value })} />
          )}
        </div>
      ) : (
        <Approval {...props} />
      )}

      {isOpen && (
        <Modal.Portal setToggle={closeHandler}>
          {() => {
            return (
              <div>
                {isConfirm && (
                  <PortalConfirm
                    {...props}
                    closeHandler={() => setPrompt({ type: 'confirm', payload: null })}
                    setFail={(payload) => setPrompt({ type: 'fail', payload })}
                  />
                )}
                {prompt.fail && <PortalFail {...props} fail={prompt.fail} closeHandler={closeHandler} />}
              </div>
            );
          }}
        </Modal.Portal>
      )}

      <Dialog
        open={prompt.lock}
        content={() => (
          <Di_UnlockAccount
            loadHandler={props.refresh}
            rowData={rowData}
            setOpen={(value) => setPrompt({ type: 'lock', payload: value })}
          />
        )}
      />
    </>
  );
};

export default Active;
