import React from 'react';
import { modal } from 'layout';
import { Button } from 'common/components';
import './Modal_RaTitleDescGuide.scss';

interface Props {
  closeHandler: () => void;
}
function Modal_RaTitleDescGuide({ closeHandler }: Props) {
  return (
    <modal.Container id="admin-edd-ra-description-popover-content" styles={{ width: 440 }}>
      <modal.Header align="center">작성 가이드</modal.Header>
      <modal.Content>
        <h3>제목과 설명은 고객에게 다음과 같이 보여집니다.</h3>
        <div>
          <i />
        </div>
      </modal.Content>
      <div className="modal__footer">
        <Button type="button" text="확인" styles={{ radius: 0 }} className="footer__submit" onClick={closeHandler} />
      </div>
    </modal.Container>
  );
}

export default Modal_RaTitleDescGuide;
