import React, { Dispatch } from 'react';
import { Pagination, Select, Table } from 'usebeauty';
import { Controller } from 'common/components';
import type * as M from 'models';
import { time } from 'common/functions';
import classNames from 'classnames';
import { N_Select, N_Table } from 'usebeauty/dist/@types';
import { InputSearch } from 'common/components/inputSearch/InputSearch';
import type { Action, State } from './CtResultTable';
import Expand from './expand/Expand';
import './ResultTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
}

const ReviewerTable: React.FC<Props> = ({ state, setState }): JSX.Element => {
  const { header, data, pagination, searchKey, totalCount } = state;

  const handlers = {
    order: (order: N_Table.Sort): void => setState({ type: 'order', payload: order }),
    search: (searchText: string) => setState({ type: 'searchText', payload: Symbol(searchText) }),
    searchKey: (payload: N_Select.Selected<string>): void => setState({ type: 'searchKey', payload }),
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  const order = { sort: state.order, onChange: handlers.order };

  return (
    <section id="reviewer-result-table">
      <Controller title={`심사 결과 (${totalCount})`}>
        <Select
          className="select-resilt-type"
          selected={state.result_type}
          onChange={(payload) => setState({ type: 'result_type', payload })}
        >
          <Select.Summary />
          <Select.Options className="control-option">
            <Select.Item title="전체" value={0} />
            <Select.Item title="자동승인" value={1} />
            <Select.Item title="자동거부" value={2} />
            <Select.Item title="수동승인" value={3} />
            <Select.Item title="이메일" value={4} />
          </Select.Options>
        </Select>

        <Select onChange={handlers.searchKey} selected={searchKey}>
          <Select.Summary />
          <Select.Options className="control-option">
            <Select.Item title="선택" value="all" />
            <Select.Item title="이름" value="name" />
            <Select.Item title="전화번호" value="phone_number" />
            <Select.Item title="생년월일" value="birthday" />
          </Select.Options>
        </Select>

        <InputSearch
          onChange={handlers.search}
          value={state.searchText.description}
          disabled={state.searchKey?.value === 'all'}
        />
      </Controller>
      <Table>
        <Table.Head data={header} order={order} />
        <Table.Body data={data}>
          {({ index, rowData }) => {
            return (
              <Table.Row key={rowData.id} st={{ height: rowData.expand ? 'auto' : 72 }}>
                <>
                  <Table.Tr data={rowData}>
                    {({ value, property }) => {
                      if (property === 'request_time') {
                        const epoch = new Date(value as string).getTime();
                        return time.timeFormatFromUTCEpoch(epoch, 2);
                      }
                      if (property === 'result_type') {
                        if (value === 1) return <span className="approved">자동승인</span>;
                        if (value === 2) return <span className="fail">자동거부</span>;
                        if (value === 3) return <span className="approved">수동승인</span>;
                        if (value === 4) return <span className="fail">수동거부</span>;
                      }
                      if (property === 'expand')
                        return (
                          <i
                            className={classNames('expand-icon', { expanded: rowData.expand })}
                            onClick={() => setState({ type: 'expand', payload: index })}
                          />
                        );
                      return value === 'EXPIRED' ? '만료됨' : value || '-';
                    }}
                  </Table.Tr>
                  {rowData.expand && <Expand rowData={rowData} />}
                </>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination data={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default ReviewerTable;
