import React from 'react';
import { Module, VAS } from '../GeneralContent';
import './InfoSubscribe.scss';

interface Props {
  module: Module;
  vas: VAS;
}

const InfoSubscribe: React.FC<Props> = ({ module, vas }) => {
  const {
    id_card_ocr,
    account_verification,
    custom_field,
    face_authentication,
    id_card_verification,
    liveness,
    edd_field,
  } = module;

  const { allow_ra } = vas;

  const RaTitle = (() => {
    const eddRa = edd_field && allow_ra;
    const eddRaCustomField = eddRa && custom_field;
    const eddCustomField = edd_field && custom_field;
    if (eddRaCustomField) return 'EDD + RA + 추가 설문';
    if (eddRa) return 'EDD + RA';
    if (eddCustomField) return 'EDD + 추가 설문';
    if (custom_field) return '추가 설문';
    return 'EDD';
  })();

  return (
    <div className="new-info-wrap">
      <h4 className="">구독 정보</h4>
      <div className="flex-box">
        {id_card_ocr && (
          <div className="column">
            <div className="title">
              <h5>신분증 인증</h5>
              <i className="id-icon" />
            </div>
            <p>{`신분증 OCR ${id_card_verification ? '/ 진위확인' : ''}`}</p>
          </div>
        )}
        {account_verification && (
          <div className="column">
            <div className="title">
              <h5>계좌 인증</h5>
              <i className="account-icon" />
            </div>
            <p>1원 계좌인증</p>
          </div>
        )}
        {face_authentication && (
          <div className="column">
            <div className="title">
              <h5>안면 인증</h5>
              <i className="facial-icon" />
            </div>
            <p>{`얼굴 인증 ${liveness ? '+ Liveness' : ''}`} </p>
          </div>
        )}
        {(edd_field || allow_ra) && (
          <div className="column">
            <div className="title">
              <h5>고객 위험 평가</h5>
              <i className="customerrisk-icon" />
            </div>
            <p>{RaTitle}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoSubscribe;
