/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { Canceler } from 'axios';
import { cloneDeep, isArray, isNumber, transform } from 'lodash';
import type { Api, Order, Pagination } from 'models';
import { admin, reviewer, root } from 'store';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.baseURL = 'http://localhost:8081';
export const api = {
  token: {} as { [key: string]: () => void },
  order: (order: Order) => {
    return transform(
      order,
      (result: Partial<{ key: string; value: string }>, value, key) => {
        result.key = key;
        result.value = value;
        return result;
      },
      {}
    );
  },
  setTotal: (pagination: Pagination, totalCount: number) => {
    const result = cloneDeep(pagination);
    result.info.total = Math.ceil(totalCount / pagination.format.limit) || 1;
    return result;
  },
  // 수정시 기존 오브젝트와 변경된 오브젝트 비교 함수 #####################
  differ<T>(data: T, defaultData: T): false | Partial<T> {
    const result: Partial<T> = {};
    for (const key in data) {
      const KEY = key as keyof T;
      const isObj = typeof data[KEY] === 'object';
      if (isObj) {
        const differ = JSON.stringify(data[KEY]) !== JSON.stringify(defaultData[KEY]); // stringfy 로 비교
        if (differ) Object.assign(result, { [KEY]: data[KEY] });
      }
      if (!isObj && data[KEY] !== defaultData[KEY]) Object.assign(result, { [KEY]: data[KEY] });
    }
    return Object.keys(result).length ? result : false;
  },
  // REQUEST ######################################################
  request: (
    method: 'post' | 'get' | 'delete' | 'put',
    url: string,
    params?: { [key: string]: any },
    data?: { [key: string]: any; form?: boolean },
    onUploadProgress?: ({ loaded, total }: { loaded: number; total: number }) => void
  ): { call: () => Promise<any>; cancel: Canceler } => {
    const source = axios.CancelToken.source();
    // array param json stringify
    if (params) {
      for (const key in params) {
        if (isArray(params[key])) params[key] = JSON.stringify(params[key]);
      }
    }

    // Headers Authorization 세팅
    const Authorization = (() => {
      const location = window.location.pathname.split('/').filter((el) => el)[0];
      let auth = null;
      if (location === 'root') auth = root.store.getState().auth;
      if (location === 'admin') auth = admin.store.getState().auth;
      if (location === 'reviewer') auth = reviewer.store.getState().auth;
      return auth?.data?.token;
    })();

    return {
      call: () => {
        // 폼데이터 일 경우
        if (data?.form) {
          const formData = new FormData();
          for (const key in data) {
            if (key !== 'form') {
              if (isArray(data[key]) || isNumber(data[key])) {
                formData.append(key, JSON.stringify(data[key]));
              } else formData.append(key, data[key]);
            }
          }
          data = formData;
        }
        return axios({
          method,
          url,
          headers: {
            'Content-Type': data?.form ? 'multipart/form-data' : 'application/json;charset=UTF-8',
            Authorization: `Bearer ${Authorization}`,
          },
          data,
          params,
          onUploadProgress,
          cancelToken: source.token,
        } as any);
      },
      cancel: () => source.cancel('abort'),
    };
  },
  get: {
    // 초기화 #########################################################
    initialize(
      paging: Api.Paging
    ): (payload: { reqData: Api.ReqData }) => { reqData: Api.ReqData; paging: Api.Paging } {
      return (payload: { reqData: Api.ReqData }): any => {
        const { params } = payload.reqData;
        if (paging.loadType === 'init') {
          // parameter 초기화
          if (params) {
            params.limit = 30;
            params.offset = 0;
          }
          // pagination 초기화
          if (paging.pagination) {
            paging.pagination = { format: { limit: 30, offset: 0 }, info: { current: 0, total: 0 } };
          }
        }
        return { reqData: payload.reqData, paging };
      };
    },
    // GET 요청 ######################################################
    async request(payload: {
      reqData: Api.ReqData;
      paging: Api.Paging;
    }): Promise<(Api.Fullfilled & { paging: Api.Paging }) | boolean> {
      const { reqData, paging } = payload;
      const { url, params } = reqData;
      try {
        const { call, cancel } = api.request('get', url, params);
        if (api.token[url]) api.token[url]();
        api.token[url] = cancel;
        const response = await call();
        if (api.token[url]) delete api.token[url];
        return { response, error: null, reqData, paging };
      } catch (error: any) {
        if (error.message === 'abort') return false;
        console.log(`request ${url} asyncLoad \n${error}`);
        return { response: null, error: error.response, reqData, paging };
      }
    },
    paging({ response, error, reqData, paging }: Api.InfiFullfilled): Api.InfiFullfilled {
      const { pagination } = paging;
      const clone = cloneDeep(pagination);
      if (clone) {
        // pagination 업데이트
        const totalCount = clone.info.total;
        clone.info.current += 1;
        clone.info.total = Math.ceil(totalCount / clone.format.limit) || 1;
        clone.format.offset = clone.info.current * clone.format.limit;
      }
      return { response, error, reqData, paging: { ...paging, pagination: clone } };
    },
    // 무한로딩 페이지 정보 리턴 ###########################################
    // infinite({ response, error, reqData, paging }: Api.InfiFullfilled): Api.InfiFullfilled {
    //   const { loadType = 'init', pagination } = paging;
    //   const init = loadType === 'init';
    //   const clone = cloneDeep(pagination);
    //   if (clone) {
    //     if (init && response?.data?.list?.length) clone.rnum = response.data.list[0].rnum;
    //     // pagination 업데이트
    //     const totalCount = clone.rnum;
    //     clone.info.current += 1;
    //     clone.info.total = Math.ceil(totalCount / clone.format.limit) || 1;
    //     clone.format.offset = clone.info.current * clone.format.limit;
    //   }
    //   return { response, error, reqData, paging: { ...paging, pagination: clone } };
    // },
  },
  post: {
    request: (payload: { reqData: Api.ReqData }): Promise<Api.Fullfilled | false> => api.req('post', payload),
  },
  put: {
    request: (payload: { reqData: Api.ReqData }): Promise<Api.Fullfilled | false> => api.req('put', payload),
  },
  delete: {
    request: (payload: { reqData: Api.ReqData }): Promise<Api.Fullfilled | false> => api.req('delete', payload),
  },
  req: async (
    type: 'post' | 'put' | 'delete',
    payload: { reqData: Api.ReqData; alert?: boolean }
  ): Promise<Api.Fullfilled | false> => {
    const { reqData } = payload;
    const { url, params, data, onUploadProgress } = reqData;

    try {
      const response = await api.request(type, url, params, data, onUploadProgress).call();
      return { response, error: null, reqData: payload.reqData };
    } catch (error: any) {
      if (error?.response?.status >= 500) return false;
      console.log(`request ${type} ${url} asyncLoad \n${error}`);
      // const message = error?.response?.data?.message;
      // if (alert && message) store.dispatch(globalActions.alertSet({ fail: message }));
      return { response: null, error: error.response, reqData: payload.reqData };
    }
  },
  reqData(reqData: Api.ReqData): { reqData: Api.ReqData } {
    return { reqData };
  },
  fullFilled(f: (payload: Api.InfiFullfilled) => any) {
    return f;
  },
};
