import React, { Dispatch } from 'react';
import { Action, State } from '../../ExaminationContent';
import AutoScreening from './autoscreening/AutoScreening';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const CommonSetting = ({ state, setState, loadHandler }: Props) => {
  return (
    <div className="setting-area">
      <div className="setting-title">
        <h3>공통</h3>
      </div>
      <AutoScreening state={state} setState={setState} loadHandler={loadHandler} />
    </div>
  );
};

export default CommonSetting;
