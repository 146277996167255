import React, { Dispatch, useState } from 'react';
import { Radio, ToolTip } from 'usebeauty';
import ChipPlus from '../color-chip/dynamic-chip/chip-plus/ChipPlus';
import FontCustomChip from '../fontColor/customChip/CustomChip';
import ChipList from '../color-chip/static-chip/ChipList';
import { Action, State } from '../../ThemeSettingContent';
import './ThemeColorOption.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
}
const ThemeColorOption = (props: Props) => {
  const { state, setState } = props;
  const { data } = state;
  const [radio, setRadio] = useState<0 | 1>(data.use_custom_theme && data.theme_color.fontColor !== '' ? 1 : 0);

  const handleFontColorChange = (value: 0 | 1) => {
    setRadio(() => value);
    if (value === 0) {
      setState({ type: 'setFontColor', payload: { fontColor: '' } });
    } else {
      setState({ type: 'setFontColor', payload: { fontColor: data.theme_color.fontColor || '#ffffff' } });
    }
  };

  return (
    <>
      <div id="admin-manage-theme-color">
        <h4>색상</h4>
        <div className="theme-palette">
          <div className="palette-item">
            <h4>기본 색상</h4>
            <ChipList data={data} setState={setState} />
          </div>

          <div className="palette-item">
            <h4>
              사용자 지정 색상
              <ToolTip
                content={
                  <div id="admin-theme-setting-popover-content" className="description">
                    사용자 지정 색상은 최대 5개까지 표시됩니다.
                  </div>
                }
              >
                <i className="info-icon" />
              </ToolTip>
            </h4>
            <ChipPlus setState={setState} data={data} />
          </div>
        </div>

        <h4>폰트 색상</h4>
        <div className="theme-palette">
          <Radio.Group value={radio} st={{ fontSize: 16 }} onChange={handleFontColorChange}>
            <Radio text="기본 색상" value={0}>
              <ToolTip
                content={
                  <div id="admin-theme-setting-font-color-popover-content" className="description">
                    <p>색상테마에서 설정하신 색상에 맞추어</p>
                    <p>자동으로 흰색 또는 검은색으로 적용됩니다.</p>
                  </div>
                }
              >
                <i className="info-icon font" />
              </ToolTip>
            </Radio>
            <Radio text="사용자 지정" value={1} className="custom">
              {radio === 1 ? <FontCustomChip setState={setState} data={data} /> : <></>}
            </Radio>
          </Radio.Group>
        </div>
      </div>
    </>
  );
};

export default ThemeColorOption;
