import React from 'react';
import classNames from 'classnames';
import { Promotion } from 'common/components';
import { State } from '../../../GeneralContent';
import './ContinueSetting.scss';

interface Props {
  state: State;
}

const ContinueSetting = ({ state }: Props) => {
  const { allow_reuse, reuse_limit_minute } = state.data.vas;

  return (
    <section id="subscribe-management-setting-coutinue">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4>이어하기 설정</h4>
            {!allow_reuse && <p>고객이 작성하던 개인 정보를 지정한 일 수 만큼 보관합니다. </p>}
          </div>
        </div>
        <div className={classNames('used-text', { active: allow_reuse })}>{allow_reuse ? '사용중' : '사용 안함'}</div>
      </div>
      {allow_reuse && (
        <>
          <div className="judge-type">
            <div className="flex-box">
              <div className="title">이어하기 일 수</div>
              <p>고객이 작성하던 개인 정보를 지정한 일 수 만큼 보관합니다.</p>
            </div>
            <span>{reuse_limit_minute / 1440} 일</span>
          </div>
          <p className="ex">
            ※ 해당 기능을 사용하고 싶지 않으시거나, 기간 변경을 희망하실 경우 유스비 고객센터 (cs@useb.co.kr) 으로 연락
            부탁드립니다.
          </p>
        </>
      )}
      {!allow_reuse && <Promotion />}
    </section>
  );
};

export default ContinueSetting;
