import { doubleDigit } from 'common/components/module/lib/functions';

type T_Period = 'now' | 'oneDayBefore' | 'oneWeekBefore' | 'sixMonthBefore' | 'oneYearBefore';
export const pointEpoch = (point: T_Period): string => {
  const periodObj = {
    now: 0,
    oneDayBefore: 86400,
    oneWeekBefore: 604800,
    sixMonthBefore: 15552000,
    oneYearBefore: 31536000,
  };

  return Math.floor(new Date().getTime() / 1000 - periodObj[point]).toString();
};

export const convertDigit = (epoch: number, digit: number): number => {
  const stringEpoch = Math.floor(epoch).toString();
  if (digit === 10 && stringEpoch.length === 13) return Math.floor(+stringEpoch / 1000);
  if (digit === 13 && stringEpoch.length === 10) return Math.floor(+stringEpoch * 1000);
  return Math.floor(epoch);
};

// 몇째주 및 해당 요일 리턴
export const getDayAndWeek = (epoch: number | string): { order: number; day: number } => {
  const EPOCH = convertDigit(+epoch, 13);
  const firstOfMonth = new Date(new Date(EPOCH).getFullYear(), new Date(EPOCH).getMonth(), 1);

  const firstDateObj = {
    day: firstOfMonth.getDay(),
    epoch: firstOfMonth.getTime(),
  };

  const epochObj = {
    date: new Date(EPOCH).getDate(),
    day: new Date(EPOCH).getDay(),
  };
  return { order: Math.floor((epochObj.date + firstDateObj.day - 1) / 7) + 1, day: epochObj.day };
};

// 해당하는 달에 몇주 있는지 확인 weekCount
export const weeksCountInMonth = (epoch: number): number => {
  const epoch_13 = epoch.toString().length === 10 ? epoch * 1000 : epoch;
  const year = new Date(epoch_13).getFullYear();
  const month = new Date(epoch_13).getMonth() + 1;
  const firstOfMonth = new Date(new Date(epoch_13).getFullYear(), new Date(epoch_13).getMonth(), 1);
  const date = new Date(year, month, 0).getDate();
  return Math.ceil((firstOfMonth.getDay() + date) / 7);
};

// 초단위 일/시간/분/초 변환 ex)
export const secondToSimpleFormat = (seconds: number): string => {
  if (!seconds) return '-';
  const intSecond = Number(seconds.toFixed(0));
  const diff = [];

  const day = Math.floor(intSecond / 86400);
  const hour = Math.floor((intSecond % 86400) / 3600);
  const min = Math.floor((intSecond % 3600) / 60);
  const sec = intSecond % 60;

  if (day > 0) diff.push(`${day}일`);
  if (hour > 0) diff.push(`${hour}시간`);
  if (min > 0) diff.push(`${min}분`);
  if (sec >= 0) diff.push(`${sec}초`);
  return diff.join(' ');
};

// 시간을 epoch시간으로 변환
export const epochFromDate = (dateString: string | Date): number | string => {
  const epoch = Math.floor(new Date(dateString).getTime() / 1000);
  if (!epoch) return '-';
  if (epoch < 0) return '-';
  return epoch;
};

export const getDateBeforeFromDays = (days: number): number => {
  const date = new Date();
  const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  // second
  return Number(last) / 1000;
};

// 현재 epoch 시간
export const nowEpoch = (): string => {
  const now = new Date(new Date().toLocaleDateString()).getTime() / 1000;
  return now.toString();
};
// #################################   사용하는 것들    ########################################

// 시간차이로 보기
export const timeDiffFromUTCEpoch = (epochUTC: string | number): string => {
  const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(Number(epochUTC));
  epochUTC = Number(d.getTime() / 1000).toFixed(0);
  if (!epochUTC) return '-';
  const nowEpoch = (new Date().getTime() / 1000).toFixed(0);
  const absDiff = Number(nowEpoch) - Number(epochUTC);
  return secondToSimpleFormat(absDiff);
};

// epoch 시간을 utc 시간으로 변환
export const timeFormatFromUTCEpoch = (epoch: string | number | null, formatType: number): string => {
  if (!epoch) return '-';
  const epochUTC = convertDigit(epoch ? +epoch : 0, 10);
  const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(epochUTC);
  const yyyy = d.getFullYear();
  const MM = d.getMonth() + 1;
  const dd = d.getDate();
  const hh = d.getHours();
  const mm = d.getMinutes();
  const ss = d.getSeconds();
  if (localStorage.getItem('language') === 'en') {
    // prettier-ignore
    switch(formatType) {
      case 1: return `${doubleDigit(MM)}/${doubleDigit(dd)}/${yyyy} ${doubleDigit(hh)}:${doubleDigit(mm)}:${doubleDigit(ss)}`;
      case 2: return `${doubleDigit(MM)}/${doubleDigit(dd)}/${yyyy} ${doubleDigit(hh)}:${doubleDigit(mm)}`;
      case 3: return `${doubleDigit(MM)}/${doubleDigit(dd)}/${yyyy}`;
      case 10: return `${doubleDigit(hh)}:${doubleDigit(mm)}:${doubleDigit(ss)}`;
    }
  } else {
    // prettier-ignore
    switch(formatType) {
      case 1: return `${yyyy}-${doubleDigit(MM)}-${doubleDigit(dd)} ${doubleDigit(hh)}:${doubleDigit(mm)}:${doubleDigit(ss)}`;
      case 2: return `${yyyy}-${doubleDigit(MM)}-${doubleDigit(dd)} ${doubleDigit(hh)}:${doubleDigit(mm)}`;
      case 3: return `${yyyy}-${doubleDigit(MM)}-${doubleDigit(dd)}`;
      case 4: return `${yyyy}-${doubleDigit(MM)}`;
      case 10: return `${doubleDigit(hh)}:${doubleDigit(mm)}:${doubleDigit(ss)}`;
    }
  }
  return '-';
};
export const getEpoch = (props?: {
  type: 'year' | 'month' | 'date' | 'hour';
  count: number;
  format?: 'dd' | 'hh' | 'mm' | 'ss';
  direction?: 'back' | 'foward';
}): number => {
  if (!props) return convertDigit(new Date().getTime(), 10);
  const { type, count, format = 'ss', direction = 'back' } = props;
  const d = new Date();
  const yyyy = d.getFullYear();
  const MM = d.getMonth();
  const dd = d.getDate();
  const hh = d.getHours();
  const mm = d.getMinutes();
  const ss = d.getSeconds();

  const operate = (time: number) => {
    if (direction === 'back') return time - count;
    return time + count;
  };

  const hour = (() => {
    if (format === 'dd') return new Date(yyyy, MM, dd).getTime();
    if (format === 'hh') return new Date(yyyy, MM, dd, operate(hh)).getTime();
    if (format === 'mm') return new Date(yyyy, MM, dd, operate(hh), mm).getTime();
    if (format === 'ss') return new Date(yyyy, MM, dd, operate(hh), mm, ss).getTime();
    return 0;
  })();
  const date = (() => {
    if (format === 'dd') return new Date(yyyy, MM, operate(dd)).getTime();
    if (format === 'hh') return new Date(yyyy, MM, operate(dd), hh).getTime();
    if (format === 'mm') return new Date(yyyy, MM, operate(dd), hh, mm).getTime();
    if (format === 'ss') return new Date(yyyy, MM, operate(dd), hh, mm, ss).getTime();
    return 0;
  })();
  const month = (() => {
    if (format === 'dd') return new Date(yyyy, operate(MM), dd).getTime();
    if (format === 'hh') return new Date(yyyy, operate(MM), dd, hh).getTime();
    if (format === 'mm') return new Date(yyyy, operate(MM), dd, hh, mm).getTime();
    if (format === 'ss') return new Date(yyyy, operate(MM), dd, hh, mm, ss).getTime();
    return 0;
  })();
  const year = (() => {
    if (format === 'dd') return new Date(operate(yyyy), MM, dd).getTime();
    if (format === 'hh') return new Date(operate(yyyy), MM, dd, hh).getTime();
    if (format === 'mm') return new Date(operate(yyyy), MM, dd, hh, mm).getTime();
    if (format === 'ss') return new Date(operate(yyyy), MM, dd, hh, mm, ss).getTime();
    return 0;
  })();

  let epochUTC = 0;
  if (type === 'hour') epochUTC = hour;
  if (type === 'date') epochUTC = date;
  if (type === 'year') epochUTC = year;
  if (type === 'month') epochUTC = month;
  return convertDigit(epochUTC, 10);
};

// 반복 횟수 로직 ##############################
interface I_Date {
  year: number;
  month: number;
  date: number;
  hours: number;
  mimutes: number;
  seconds: number;
  week: { order: number; day: number };
  epoch: number;
  firstOfMonth: Date;
  lastOfMonth: Date;
}

const timeCheck = (props: { start: I_Date; end: I_Date; result: number }): number => {
  const { start, end, result } = props;
  const sameHours = start.hours === end.hours;
  const sameMimutes = start.mimutes === end.mimutes;
  if (
    start.hours <= end.hours ||
    (sameHours && start.mimutes <= end.mimutes) ||
    (sameHours && sameMimutes && start.seconds <= end.seconds)
  ) {
    return result + 1;
  }
  return result;
};

// 날짜(초까지 포함)한 기간내 반복횟수 리턴
const repeatCountByMonth = (props: { start: I_Date; end: I_Date; repeat: number }): number => {
  const { start, end, repeat } = props;
  const yearGap = end.year - start.year;
  let division = (end.month - start.month) / repeat;
  let remainder = (end.month - start.month) % repeat; // 나머지가 없다면 반복주기에 해당하는 달

  if (yearGap) {
    division = (12 - start.month + end.month + (yearGap - 1) * 12) / repeat;
    remainder = (12 - start.month + end.month + (yearGap - 1) * 12) % repeat;
  }

  const result = Math.floor(division);
  const sameDate = start.date === end.date;
  const sameHours = start.hours === end.hours;
  const sameMimutes = start.mimutes === end.mimutes;

  if (
    remainder === 0 &&
    (start.date > end.date ||
      (sameDate && start.hours > end.hours) ||
      (sameDate && sameHours && start.mimutes > end.mimutes) ||
      (sameDate && sameHours && sameMimutes && start.seconds > end.seconds))
  ) {
    if (end.lastOfMonth.getDate() < start.date && end.date === end.lastOfMonth.getDate())
      return timeCheck({ start, end, result });
    return result;
  }

  return result + 1;
};

const countBySameDayAndWeek = (props: { start: I_Date; end: I_Date }): number => {
  const { start, end } = props;
  const yearGap = end.year - start.year;
  let result = Math.floor(end.month - start.month);
  if (yearGap) result = Math.floor(12 - start.month + end.month + (yearGap - 1) * 12);

  // 같은주차일 경우
  if (end.week.order === start.week.order) {
    const endMonthWeekCount = weeksCountInMonth(end.epoch);
    // 요일이 같을때
    if (end.week.day === start.week.day) return timeCheck({ start, end, result });
    // 종료요일이 시작 요일보다 같을때
    if (end.week.day > start.week.day) {
      const emptyDayCount = end.week.day + 1 - end.date;
      if (start.week.day >= emptyDayCount) return result + 1;
    }
    // 종료 마지막일이 시작일보다 작을경우
    if (end.week.order === endMonthWeekCount && end.lastOfMonth.getDay() < start.week.day) return result + 1;
  }

  // 종료 주차가 더 클때
  if (end.week.order > start.week.order) {
    // 종료일 첫째주 요일이 시작일 첫째주 요일보다 클경우
    if (end.firstOfMonth.getDay() > start.week.day) {
      if (end.week.order === 2) {
        if (end.week.day > start.week.day) return result + 1;
        if (end.week.day === start.week.day) return timeCheck({ start, end, result });
        if (end.week.day < start.week.day) return result;
      }
    }
    return result + 1;
  }

  // 시작 주차가 더 클때
  if (end.week.order < start.week.order) {
    const endMonthWeekCount = weeksCountInMonth(end.epoch);
    // 종료일이 마지막 주차일 경우
    if (end.week.order === endMonthWeekCount) {
      if (end.lastOfMonth.getDay() < start.week.day) return result + 1;
      if (end.week.day === start.week.day) return timeCheck({ start, end, result });
      if (end.week.day > start.week.day) return result + 1;
    }

    // 종료일이 마지막 전 주 일 경우
    if (end.week.order === endMonthWeekCount - 1) {
      if (end.lastOfMonth.getDay() < start.week.day) {
        if (end.week.day > start.week.day) return result + 1;
        if (end.week.day === start.week.day) return timeCheck({ start, end, result });
      }
    }
  }

  return result;
};

export const getRepeatCount = (props: { startEpoch: number; period: number; endEpoch: number }): number => {
  const { startEpoch, endEpoch, period } = props;
  const START_EPOCH = convertDigit(startEpoch, 13); // 13자리수로 치환후 작업
  const END_EPOCH = convertDigit(endEpoch, 13);

  const extractData = (epoch: number) => {
    return {
      year: new Date(epoch).getFullYear(),
      month: new Date(epoch).getMonth() + 1,
      date: new Date(epoch).getDate(),
      hours: new Date(epoch).getHours(),
      mimutes: new Date(epoch).getMinutes(),
      seconds: new Date(epoch).getSeconds(),
      week: getDayAndWeek(epoch), // 주차와 요일 리턴 ex) { order: 1, day: 0 }
      epoch,
      firstOfMonth: new Date(new Date(epoch).getFullYear(), new Date(epoch).getMonth(), 1),
      lastOfMonth: new Date(new Date(epoch).getFullYear(), new Date(epoch).getMonth() + 1, 0),
    };
  };
  // prettier-ignore
  switch(period) {
    case 0: return 1; 
    case 1440: return Math.floor((END_EPOCH - START_EPOCH) / 86400000) + 1;  // 매일 같은시간
    case 10080: return Math.floor((END_EPOCH - START_EPOCH) / 604800000) + 1;  // 매주 같은시간
    case 1: return repeatCountByMonth({ start: extractData(START_EPOCH), end: extractData(END_EPOCH), repeat: 1 }); // 매월 같은 일 같은 시간
    case 3: return repeatCountByMonth({ start: extractData(START_EPOCH), end: extractData(END_EPOCH), repeat: 3 }); // 3개월마다 반복
    case 6: return repeatCountByMonth({ start: extractData(START_EPOCH), end: extractData(END_EPOCH), repeat: 6 }); // 6개월마다 반복
    default: return countBySameDayAndWeek({ start: extractData(START_EPOCH), end: extractData(END_EPOCH) }); // 매월 같은주 같은 요일
  }
};

export const isValidDate = (dateObject: string) => {
  return new Date(dateObject).toString() !== 'Invalid Date';
};

export function getQuarterList(startDate: string, endDate: string) {
  const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  if (!(regex.test(startDate) && regex.test(endDate))) throw new Error('Not Date Format');

  const result: number[] = [];
  const curDate = new Date(startDate);
  const last = new Date(endDate);

  const sQuarter = Math.floor(curDate.getMonth() / 3) + 1;
  const eQuarter = Math.floor(last.getMonth() / 3) + 1;
  const startMonthOfSQuarter = (sQuarter - 1) * 3;
  const startMonthOfEQuarter = (eQuarter - 1) * 3;

  curDate.setMonth(startMonthOfSQuarter, 1); // 말일이 다를 수 있기 때문에 해당 월의 1일로 통일
  last.setMonth(startMonthOfEQuarter, 1); // 말일이 다를 수 있기 때문에 해당 월의 1일로 통일

  while (curDate <= last) {
    const month = curDate.getMonth() + 1;
    result.push(Math.floor(month / 3) + 1);
    curDate.setMonth(curDate.getMonth() + 3, 1);
  }

  return result;
}

export function getMonthList(startDate: string, endDate: string) {
  const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  if (!(regex.test(startDate) && regex.test(endDate))) throw new Error('Not Date Format');

  const result: string[] = [];
  const curDate = new Date(startDate);
  const last = new Date(endDate);

  curDate.setMonth(curDate.getMonth(), 1); // 말일이 다를 수 있기 때문에 해당 월의 1일로 통일
  last.setMonth(last.getMonth(), 1); // 말일이 다를 수 있기 때문에 해당 월의 1일로 통일

  while (curDate <= last) {
    const year = curDate.getFullYear();
    let month: string | number = curDate.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    result.push(`${year}-${month}`);
    curDate.setMonth(+month, 1);
  }

  return result;
}

export function getHalfYearList(startDate: string, endDate: string) {
  const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  if (!(regex.test(startDate) && regex.test(endDate))) throw new Error('Not Date Format');

  const result: string[] = [];
  const curDate = new Date(startDate);
  const last = new Date(endDate);

  curDate.setMonth(curDate.getMonth(), 1); // 말일이 다를 수 있기 때문에 해당 월의 1일로 통일
  last.setMonth(last.getMonth(), 1); // 말일이 다를 수 있기 때문에 해당 월의 1일로 통일

  if (curDate.getMonth() / 6 < 1) result.push('상반기');
  if (last.getMonth() / 6 >= 1) result.push('하반기');

  return result;
}

export function getYearList(startDate: string, endDate: string) {
  const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  if (!(regex.test(startDate) && regex.test(endDate))) throw new Error('Not Date Format');

  const firstYear = new Date(startDate).getFullYear();
  const currentYear = new Date(endDate).getFullYear();

  return Array.from({ length: currentYear - firstYear + 1 }).map((_, i) => String(currentYear - i));
}

export type T_Filter = {
  type: number;
  year: string;
  month: string;
  day: { start: string; end: string };
  halfYear: number; // 반기
  quarter: number; // 분기
};

export function getDateFilterLabel(filter: T_Filter) {
  switch (filter.type) {
    case 1:
      return `${filter.day.start} ~ ${filter.day.end}`;
    case 2:
      return `${filter.year}년 ${+filter.month}월`;
    case 3:
      return `${filter.year}년 ${filter.quarter}분기`;
    case 4:
      return `${filter.year}년 ${filter.halfYear === 1 ? '전반기' : '하반기'}`;
    case 5:
      return `${filter.year}년`;
  }
  return '';
}
