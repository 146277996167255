import React, { Dispatch, useState } from 'react';
import { Alert, Radio, TextArea } from 'common/components';
import { api } from 'common/functions';
import { Action, State } from '../../../GeneralContent';
import EditComp from '../../edit/EditComp';
import './Message.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const Message: React.FC<Props> = ({ state, setState, loadHandler }) => {
  const { use_custom_end_message, custom_end_message, custom_end_title } = state.data.vas;
  const [edit, setEdit] = useState<boolean>(false);
  const { set } = Alert.Context();

  const isUseMessage = use_custom_end_message !== state.data.defaultVas.use_custom_end_message;
  const isCustomMessge =
    custom_end_title !== state.data.defaultVas.custom_end_title ||
    custom_end_message !== state.data.defaultVas.custom_end_message;

  const isDirty = isCustomMessge || isUseMessage;

  const submitHandler = async () => {
    const data = {
      use_custom_end_message: use_custom_end_message.toString(),
      custom_end_message: use_custom_end_message ? custom_end_message.replace(/\n/g, '<br/>') : undefined,
      custom_end_title: use_custom_end_message ? custom_end_title.replace(/\n/g, '') : undefined,
    };
    await api.put.request(api.reqData({ url: 'configs', data }));
    setEdit(false);
    loadHandler();
    set({ success: `인증 완료 메세지가 설정되었습니다.` });
  };

  const messageLength = (custom_end_message.match(/\n/g)?.length ?? 0) * 4 + custom_end_message.length;

  const handler = { loadHandler, submitHandler };
  const disabled = use_custom_end_message && (!custom_end_title || !custom_end_message);

  return (
    <>
      <section id="subscribe-management-setting-message">
        <div className="flex-box">
          <div className="header">
            <div className="title">
              <h4>인증완료 메시지 설정</h4>
              <p>해당 문구는 고객이 인증단계를 완료한 후의 최종화면에서 보여지며, 작성한 모습대로 표시됩니다.</p>
            </div>
          </div>
          <EditComp
            disabled={disabled || !isDirty}
            setEdit={setEdit}
            edit={edit}
            setState={setState}
            handler={handler}
            data={{ key: 'use_custom_end_message', value: use_custom_end_message }}
          />
        </div>

        {edit ? (
          <div className="content">
            <Radio.Group
              className="radio"
              value={use_custom_end_message}
              disabled={!edit}
              onChange={(value) => {
                setState({ type: 'vas', payload: { use_custom_end_message: value } });
                if (value) {
                  const textarea = document.querySelector<HTMLTextAreaElement>('.input-container');
                  if (textarea) textarea.focus();
                }
              }}
            >
              <Radio.Item text="기본 설정" value={false}>
                <>
                  {!use_custom_end_message && (
                    <>
                      <dl>
                        <dt>제목</dt>
                        <dd>KYC 인증 완료</dd>
                      </dl>
                      <dl>
                        <dt>내용</dt>
                        <dd>
                          입력하신 KYC 인증 정보 최종 검토 후<br />
                          이메일을 통해 승인 여부를 안내드리겠습니다.
                        </dd>
                      </dl>
                    </>
                  )}
                </>
              </Radio.Item>
              <Radio.Item text="사용자 설정" value>
                <>
                  {use_custom_end_message && (
                    <>
                      <dl>
                        <dt>
                          <h5>제목</h5>
                          <p>{custom_end_title?.length ?? 0}/50</p>
                        </dt>
                        <dd>
                          <TextArea
                            autoScroll
                            className="text-area"
                            maxLength={50}
                            placeholder="인증 완료 후 표시할 제목을 입력해 주세요."
                            value={custom_end_title}
                            onChange={(e) => setState({ type: 'vas', payload: { custom_end_title: e.target.value } })}
                          />
                        </dd>
                      </dl>
                      <dl>
                        <dt>
                          <h5>내용</h5>
                          <p>{messageLength ?? 0}/85</p>
                        </dt>
                        <dd>
                          <TextArea
                            autoScroll
                            className="text-area"
                            maxLength={85}
                            styles={{ height: 24 }}
                            placeholder="인증 완료 후 표시할 내용을 입력해 주세요."
                            value={custom_end_message.replace(/<br\/>/g, '\n')}
                            onChange={(e) => setState({ type: 'vas', payload: { custom_end_message: e.target.value } })}
                          />
                        </dd>
                      </dl>
                    </>
                  )}
                </>
              </Radio.Item>
            </Radio.Group>
          </div>
        ) : (
          <div className="result-message">
            <div className="title">{use_custom_end_message ? '사용자 설정' : '기본설정'}</div>
            <dl>
              <dt>제목</dt>
              <dd>{use_custom_end_message ? custom_end_title : 'KYC 인증 완료'}</dd>
            </dl>
            <dl>
              <dt>내용</dt>
              {/* eslint-disable-next-line react/no-danger */}
              {use_custom_end_message && <dd dangerouslySetInnerHTML={{ __html: custom_end_message }} />}
              {!use_custom_end_message && (
                <dd>
                  입력하신 KYC 인증 정보 최종 검토 후 <br /> 이메일을 통해 승인 여부를 안내드리겠습니다
                </dd>
              )}
            </dl>
          </div>
        )}
      </section>
    </>
  );
};

export default Message;
