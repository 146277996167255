import React, { Dispatch, useState } from 'react';
import { api, process } from 'common/functions';
import { Alert, Promotion } from 'common/components';
import classNames from 'classnames';
import EditComp from '../edit/EditComp';
import { Action, State } from '../../IdcardContent';
import './FaceExtract.scss';

interface Props {
  loadHandler: () => void;
  state: State;
  setState: Dispatch<Action>;
}

const FaceExtract = (props: Props) => {
  const { loadHandler, state, setState } = props;
  const { use_idcard_face_detect, allow_idcard_face_detect } = state.data.vas;
  const { set } = Alert.Context();
  const [edit, setEdit] = useState(false);

  const submitHandler = async () => {
    const data = { use_idcard_face_detect: String(use_idcard_face_detect) };
    await process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled((response) => {
        setEdit(false);
        loadHandler();
        if (response) {
          set({ success: `신분증 얼굴 추출이 ${use_idcard_face_detect ? '설정' : '중지'}되었습니다.` });
        } else {
          set({ fail: `신분증 얼굴 추출 설정 변경에 실패하였습니다.` });
        }
      })
    );
  };
  const handler = { loadHandler, submitHandler };

  return (
    <section id="idcard-face-extract" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: use_idcard_face_detect })}>신분증 얼굴 추출</h4>
            <p>신분증 상의 얼굴 사진을 추출하여 심사 시 제공받습니다.</p>
          </div>
        </div>
        <EditComp
          isAllow={allow_idcard_face_detect}
          onSwitch
          setEdit={setEdit}
          edit={edit}
          setState={setState}
          handler={handler}
          data={{ key: 'use_idcard_face_detect', value: use_idcard_face_detect }}
        />
      </div>
      {!allow_idcard_face_detect && <Promotion />}
    </section>
  );
};

export default FaceExtract;
