import React, { useState } from 'react';
import { modal } from 'layout';
import { api, process } from 'common/functions';
import { Alert } from 'common/components';
import type { Data } from '../../CtPrivacyTable';
import './Modal_DeletePrivacy.scss';

interface Props {
  rowData: Data;
  closeHandler: () => void;
  refresh: () => void;
}

const ModalDeletePrivacy: React.FC<Props> = ({ closeHandler, rowData, refresh }) => {
  const { set } = Alert.Context();
  const [loading, setLoading] = useState(false);

  const deleteHandler = () => {
    setLoading(true);
    process(
      api.reqData({ url: `guides/${rowData.key}` }),
      api.delete.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data.api_response;
          if (result_code === 'N100') set({ success: '약관이 삭제되었습니다.' });
          closeHandler();
          refresh();
        }
      })
    );
  };

  return (
    <modal.Container id="root-page-guidance-delete-privacy" styles={{ width: 500 }}>
      <modal.Header align="center">약관 항목 삭제</modal.Header>
      <modal.Content>
        <p>guide-pa-pww</p>
        <p>해당 약관 항목을 삭제하시겠습니까?</p>
      </modal.Content>
      <modal.Footer loading={loading} closeHandler={closeHandler} submitHandler={deleteHandler} />
    </modal.Container>
  );
};

export default ModalDeletePrivacy;
