import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Popover, Switch, Validator } from 'common/components';
import Forbidden from '../../forbidden/Forbidden';
import { useCustomContext } from '../context/CustomFieldContext';
import CustomFieldDirect from './direct/Direct';
import './Custom.scss';

const Custom = () => {
  const { state, setState, submitHandler } = useCustomContext();
  const { configs, defaultConfigs, data, defaultData } = state;
  const [edit, setEdit] = useState(false);

  const onSubmit = () => {
    submitHandler();
    setEdit(false);
  };

  const requiredFulfilled =
    data.customFields.filter(
      (cf) =>
        (cf.isAppend && (cf.type === '' || cf.name === '' || cf.required === null)) ||
        cf.error.type ||
        cf.error.name ||
        cf.error.active ||
        cf.error.duplicate
    ).length === 0;

  const disabled =
    configs.use_custom_field && (!data.customFields.filter((cf) => cf.active).length || !requiredFulfilled);

  return (
    <>
      <Validator.Provider onSubmit={onSubmit}>
        <section className="customfield-config-controller">
          <div>
            <div className="title">
              <h4>추가 설문 활성화</h4>
              <Popover
                position="right center"
                clickOutside
                content={() => (
                  <div id="admin-manage-customfield-popover-content" className="description">
                    고객 신원 확인의 정확도를 높이기 위한 추가 절차이며 자금 출처, 주소, 증명서 등을 요청할 수 있습니다.
                  </div>
                )}
              >
                <i className="title-info" />
              </Popover>
            </div>
            <div className="button-group">
              {edit ? (
                <>
                  <Button
                    text="취소"
                    onClick={() => {
                      setState({ type: 'configs', payload: { use_custom_field: defaultConfigs.use_custom_field } });
                      setState({ type: 'data', payload: defaultData });
                      setEdit(false);
                    }}
                  />
                  <Validator.Submit disabled={disabled} text="저장" />
                </>
              ) : (
                !edit && <Button text="편집" onClick={() => setEdit(true)} />
              )}
            </div>
          </div>
          <div className={classNames('controller', { active: configs.use_custom_field, edit })}>
            <div>
              <h4>추가설문 사용</h4>
              <div>고객의 ekyc 인증 단계에 아래 설정한 항목의 적용 여부를 결정합니다.</div>
            </div>
            <Switch
              className={classNames({ readonly: !edit })}
              disabled={!edit}
              value={configs.use_custom_field}
              onChange={(value) => setState({ type: 'configs', payload: { use_custom_field: !!value } })}
            />
          </div>
        </section>
        <section id="admin-manage-customfield-setting">
          <div className="title">
            <h4>직접 등록 항목</h4>
          </div>
          {configs.use_custom_field ? (
            <div className={classNames('customfield-wrapper', { active: edit })}>
              <CustomFieldDirect edit={edit} />
            </div>
          ) : (
            <Forbidden
              content={
                <>
                  <p>추가 설문을 활성화 하신 후</p>
                  <p>직접 등록항목을 설정하실 수 있습니다.</p>
                </>
              }
            />
          )}
        </section>
      </Validator.Provider>
    </>
  );
};

export default Custom;
