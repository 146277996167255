/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';
import { validator } from 'common/components';
import { excutions } from 'common/validations';
import Router from 'router/Router';

import 'react-datepicker/dist/react-datepicker.css';
import 'usebeauty/dist/styles.css';
import 'common/scss/reset.scss';
import 'common/components/module/module.scss';
import 'common/scss/index.scss';

const style = require('scss-extract-loader!./common/scss/utils.scss');

const theme: { [key: string]: string } = {};
for (const key in style.global) theme[key] = style.global[key].declarations[0].expression;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // suspense: true,
      retry: false, // 요청 에러발생시 요청 다시시도 default true
      refetchOnWindowFocus: false, // window에 포커스시 요청시도
    },
  },
});

function App() {
  validator.extensions(excutions); // Validator config
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
