export const getMimeType = (mimeType: string) => {
  switch (mimeType) {
    case '89504e47':
      return 'png';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffee':
    case 'ffd8ffd8':
      return 'jpe?g';
    case '3c737667':
      return 'svg';
    case '47494638':
      return 'gif';
    default:
      return 'invalid';
  }
};

export const readerForText = (
  file: File,
  option: { extension: string[]; size?: number }
): Promise<{ type: string; message: string; mimeType?: string }> => {
  const instance = new FileReader();
  const { name, size } = file;
  const { extension: ext, size: MAX_FILE_SIZE = 500 * 1024 } = option;

  return new Promise((resolve, reject) => {
    instance.readAsText(file);

    let res = { type: '', message: '' };
    const regExt = new RegExp(`\\.(${ext.join('|')})$`, 'g').exec(name);
    const extension = regExt && regExt[1];
    // ext: 허용할 확장자
    // extension: 파일명에 있는 확장자
    // mimeType: array buffer로 읽어들인 파일의 실제 mime-type

    instance.onloadend = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        const { result } = e.target;
        // const firstLine = (result as string).trim().split('\n')[0]; // Extracting the first line
        // const hasXmlDeclaration = /^<\?xml/.test(firstLine); // Match the <?xml declaration at the start
        // const hasSvgTags = /<svg/i.test(result as string);

        if (extension && !/<svg/i.test(result as string)) {
          res = { type: 'FE002', message: '확장자와 형식이 일지하지 않음' };
          reject(res);
        }
        if (size > MAX_FILE_SIZE) {
          res = { type: 'FE003', message: `파일 용량이 너무 큼. ${Math.ceil(MAX_FILE_SIZE) / 1024}KB 이하만.` };
          reject(res);
        }
        resolve({ type: 'DONE', message: '', mimeType: 'svg' });
      }
    };
  });
};

export const readerForArrayBuffer = (
  file: File,
  option: { extension: string[]; size?: number }
): Promise<{ type: string; message: string; mimeType?: string }> => {
  const instance = new FileReader();
  const { name, size } = file;
  const { extension: ext, size: MAX_FILE_SIZE = 500 * 1024 } = option;

  return new Promise((resolve, reject) => {
    instance.readAsArrayBuffer(file);

    const regExt = new RegExp(`\\.(${ext.join('|')})$`, 'g').exec(name);
    const extension = regExt && regExt[1];

    let res = { type: '', message: '' };
    // ext: 허용할 확장자
    // extension: 파일명에 있는 확장자
    // mimeType: array buffer로 읽어들인 파일의 실제 mime-type

    instance.onloadend = (e: ProgressEvent<FileReader>) => {
      let mimeType = '';
      if (e.target && e.target.result) {
        const { result } = e.target;
        const fileMimeTypeHeader = new Uint8Array(result as ArrayBuffer)
          .subarray(0, 4)
          .reduce((acc, cur) => acc + cur.toString(16), '');
        mimeType = getMimeType(fileMimeTypeHeader);

        if (extension === null) {
          res = { type: 'FE001', message: '지원하지 않는 파일형식. PNG, JPG, GIF, SVG만 가능' };
          reject(res);
        }
        if (extension && !new RegExp(`${mimeType}`, 'gi').test(extension)) {
          res = { type: 'FE002', message: '확장자와 형식이 일지하지 않음' };
          reject(res);
        }
        if (size > MAX_FILE_SIZE) {
          res = {
            type: 'FE003',
            message: `파일 용량이 너무 큼. ${Math.ceil(MAX_FILE_SIZE) / 1024}KB 이하만.`,
          };
          reject(res);
        }
      }
      resolve({ type: 'DONE', message: '', mimeType });
    };
  });
};

export const readerForDataURL = (file: File, mimeType?: string): Promise<{ type: string; message: string }> => {
  const instance = new FileReader();

  return new Promise((resolve, reject) => {
    instance.readAsDataURL(file);

    let res = { type: '', message: '' };

    instance.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        const image = new Image();
        const base64String = e.target.result as string;
        image.src = base64String;

        image.onload = () => {
          if (mimeType !== 'svg') {
            const { width, height } = image;
            if (width > 100 || height > 20) {
              res = { type: 'FE004', message: '이미지 크기 초과' };
              reject(res);
            }
          }

          resolve({ type: 'DONE', message: base64String });
        };
      }
    };
  });
};
