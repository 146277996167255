import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Controller } from 'common/components';
import './ManageOther.scss';

function OtherManage() {
  return (
    <div id="admin-manage-content-navigator">
      <Controller
        title={
          <>
            <NavLink to="/admin/manage/other/general">서비스 설정</NavLink>
            <NavLink to="/admin/manage/other/authenticate">개인 정보 옵션</NavLink>
            <NavLink to="/admin/manage/other/security">보안 설정</NavLink>
            <NavLink to="/admin/manage/other/theme">테마 설정</NavLink>
          </>
        }
      />
      <Outlet />
    </div>
  );
}

export default OtherManage;
/* 
other
  general
  authenticate
  security
  theme
 */
