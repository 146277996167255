import React, { useEffect, useReducer } from 'react';
import { produce } from 'immer';
import classNames from 'classnames';
import { api, process } from 'common/functions';
import { Button, CheckBox, Search, ToolTip, Validator } from 'common/components';
import { modal } from 'layout';
import { EddRa } from '../../../context/model.type';
import { raCategoryMap } from '../util';
import './Modal_RaNation.scss';

interface NationTableProps {
  nations: Nation[];
  className?: string;
  title: string;
  checkedLen: number;
  noDataText?: string;
  checkAllHandler: (checked: boolean) => void;
  children: (nation: Nation) => JSX.Element;
}
function NationTable(props: NationTableProps) {
  const { nations, title, children, checkedLen, checkAllHandler, noDataText } = props;
  const checkAll = !!checkedLen && checkedLen === nations.length;
  return (
    <div className={classNames('nations__table', props.className)}>
      <div className="head">
        <h4>{title}</h4>
        <div>
          <CheckBox
            text="전체 선택"
            checked={checkAll ? 1 : 0}
            onChange={(check) => {
              if (nations.length > 0) {
                checkAllHandler(!!check);
              }
            }}
          />
          <span className={classNames({ disabled: !checkedLen })} onClick={() => checkAllHandler(false)}>
            선택 해제
          </span>
        </div>
      </div>
      <div className="content">
        {nations.length ? nations.map((nation) => children(nation)) : <div>{noDataText}</div>}
      </div>
    </div>
  );
}

type Action = { type: 'searchText'; payload: string } | { type: 'nations'; payload: Nation[] };
type Nation = EddRa.Country & { selected: boolean; checked: boolean };
type State = { searchText: string; nations: Nation[] };

const initialState: State = {
  searchText: '',
  nations: [],
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'searchText':
        draft['searchText'] = action.payload;
        break;
      case 'nations':
        draft['nations'] = action.payload;
        break;
      default:
        break;
    }
  });
};

const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.list) return response?.data.list;
      return undefined;
    })
  );
};

interface Props {
  closeHandler: () => void;
  submitHandler: (nextNationData: EddRa.Country[]) => void;
  eddCategory: string;
  item: EddRa.Country[];
}
function Modal_RaNation(props: Props) {
  const { closeHandler, submitHandler, eddCategory, item } = props;
  const [state, setState] = useReducer(reducer, initialState);
  const raName = raCategoryMap[eddCategory as keyof typeof raCategoryMap];

  const loadHandler = async () => {
    // const nationsAll = getEddCountries({ warn_only: false });
    const countries: Nation[] = await get('country?warn_only=false');

    const nations = countries.map((nation) => {
      if (item.filter((el) => el.iso_code3 === nation.iso_code3).length) {
        return { ...nation, checked: false, selected: true };
      }
      return { ...nation, checked: false, selected: false };
    });

    setState({ type: 'nations', payload: nations });
  };
  useEffect(() => {
    loadHandler();
  }, []);

  const onSubmit = () => {
    const selectedNations = state.nations.filter((nation) => nation.selected);
    submitHandler(selectedNations);
    closeHandler();
  };

  const searchHandler = (searchText: string) => {
    setState({ type: 'searchText', payload: searchText });
  };

  const checkAllHandler = (selected: boolean) => (checked: boolean) => {
    const nations = state.nations.map((nation) => (nation.selected === selected ? { ...nation, checked } : nation));
    setState({ type: 'nations', payload: nations });
  };

  const checkEachHandler = (id: number, checked: boolean) => {
    const nations = state.nations.map((nation) => (nation.id === id ? { ...nation, checked } : nation));
    setState({ type: 'nations', payload: nations });
  };

  const appendHandler = () => {
    const nations = state.nations.map((nation) =>
      !nation.selected && nation.checked ? { ...nation, selected: true, checked: false } : nation
    );
    setState({ type: 'nations', payload: nations });
  };

  const removeHandler = () => {
    const nations = state.nations.map((nation) =>
      nation.selected && nation.checked ? { ...nation, selected: false, checked: false } : nation
    );
    setState({ type: 'nations', payload: nations });
  };

  const filteredNations = state.nations.filter((nation) => nation.name_kr.includes(state.searchText));

  const availableNations = filteredNations.filter((nation) => !nation.selected);
  const selectedNations = filteredNations.filter((nation) => nation.selected);
  const availableNationsCheckedLen = availableNations.filter((nation) => nation.checked).length;
  const selectedNationsCheckedLen = selectedNations.filter((nation) => nation.checked).length;

  return (
    <Validator.Provider onSubmit={onSubmit}>
      <modal.Container id="modal-ra-item-nations-setting" styles={{ width: 1016 }}>
        <modal.Header>선택지 설정</modal.Header>
        <modal.Content>
          <div className="condition">
            <div className="item">
              <h4>위험 종류</h4>
              {raName}
            </div>
            <div className="item">
              <h4>항목</h4>
              국적
            </div>
          </div>
          <div className="searchbox__filter">
            <h4>국가 검색</h4>
            <Search
              submitHandler={searchHandler}
              styles={{ width: '100%' }}
              onChange={searchHandler}
              placeholder="국가명을 검색해주세요"
            />
          </div>
          <div className="nations__container">
            <NationTable
              nations={availableNations}
              className="available"
              title="선택 가능"
              noDataText="선택 가능한 국가가 없습니다"
              checkedLen={availableNationsCheckedLen}
              checkAllHandler={checkAllHandler(false)}
            >
              {(nation) => {
                const { warn_labels } = nation;
                const [label, ...restLabels] = warn_labels;

                return (
                  <div
                    key={`${nation.id}_${nation.iso_code3}`}
                    className={classNames('nation', { checked: nation.checked })}
                  >
                    <span>
                      <CheckBox
                        checked={nation.checked ? 1 : 0}
                        onChange={(check) => checkEachHandler(nation.id, !!check)}
                        text={nation.name_kr}
                      />
                    </span>
                    <span>
                      {label && <span>{label}</span>}
                      {!!restLabels.length && (
                        <ToolTip
                          position="bottom center"
                          arrow={false}
                          backgroundColor="initial"
                          className="warnlabel-tooltip-root"
                          content={() => (
                            <div id="modal-ra-item-nations-setting-warnlabel-tooltip">
                              {warn_labels.map((label, i) => (
                                <span key={`${label}_${i}`}>{label}</span>
                              ))}
                            </div>
                          )}
                        >
                          <span>{`+ ${restLabels.length}`}</span>
                        </ToolTip>
                      )}
                    </span>
                  </div>
                );
              }}
            </NationTable>
            <div className="control">
              <Button type="button" className="append" disabled={!availableNationsCheckedLen} onClick={appendHandler}>
                <>
                  추가
                  <i />
                </>
              </Button>
              <Button type="button" className="remove" disabled={!selectedNationsCheckedLen} onClick={removeHandler}>
                <>
                  <i />
                  삭제
                </>
              </Button>
            </div>
            <NationTable
              nations={selectedNations}
              className="selected"
              title="선택 됨"
              noDataText="선택된 국가가 없습니다"
              checkedLen={selectedNationsCheckedLen}
              checkAllHandler={checkAllHandler(true)}
            >
              {(nation) => {
                const { warn_labels } = nation;
                const [label, ...restLabels] = warn_labels;

                return (
                  <div
                    key={`${nation.id}_${nation.iso_code3}`}
                    className={classNames('nation', { checked: nation.checked })}
                  >
                    <span>
                      <CheckBox
                        checked={nation.checked ? 1 : 0}
                        onChange={(check) => checkEachHandler(nation.id, !!check)}
                        text={nation.name_kr}
                      />
                    </span>
                    <span>
                      {label && <span>{label}</span>}
                      {!!restLabels.length && (
                        <ToolTip
                          position="bottom center"
                          arrow={false}
                          backgroundColor="initial"
                          className="warnlabel-tooltip-root"
                          content={() => (
                            <div id="modal-ra-item-nations-setting-warnlabel-tooltip">
                              {warn_labels.map((label, i) => (
                                <span key={`${label}_${i}`}>{label}</span>
                              ))}
                            </div>
                          )}
                        >
                          <span>{`+ ${restLabels.length}`}</span>
                        </ToolTip>
                      )}
                    </span>
                  </div>
                );
              }}
            </NationTable>
          </div>
          <div className="info">※ 라벨이 포함된 국가는 삭제를 권장드리지 않습니다.</div>
        </modal.Content>
        <modal.Footer disabled={!selectedNations.length} closeHandler={closeHandler} submitText="설정" />
      </modal.Container>
    </Validator.Provider>
  );
}

export default Modal_RaNation;
