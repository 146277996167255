import React, { createContext, useContext, useState } from 'react';

type RealScoreContextProp = {
  shouldUpdate: boolean;
  updater: () => void;
};
const RealScoreContext = createContext<RealScoreContextProp | null>(null);

interface RealScoreProviderProps {
  children: JSX.Element;
}
function RealScoreProvider(props: RealScoreProviderProps) {
  const { children } = props;
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const updater = () => {
    setShouldUpdate((prev) => !prev);
  };

  return <RealScoreContext.Provider value={{ shouldUpdate, updater }}>{children}</RealScoreContext.Provider>;
}

export default RealScoreProvider;

export const useRealScoreContext = () => {
  const context = useContext(RealScoreContext);
  if (!context) throw new Error('context is null');
  return context;
};
