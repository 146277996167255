/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ChartDataset, ChartTypeRegistry } from "chart.js";

export function getChartDataset(arg: {
  type: keyof ChartTypeRegistry;
  label: string;
  data: any[];
  options: any;
}): ChartDataset {
  const { data, type, label, options } = arg;
  return {
    type,
    label,
    data: data?.map((d: any) => {
      const { label, value, ...rest } = d;
      return {
        ...rest,
        x: label,
        // y: type === "line" ? value + 500 : value,
        y: value,
      };
    }),
    ...options,
  };
}

export function findPointAtDistance(cx: number, cy: number, x1: number, y1: number, r: number) {
  // 두 점을 잇는 직선의 기울기
  const slope = (y1 - cy) / (x1 - cx);

  // 기울기로 각도를 구함
  const theta = Math.atan(slope);

  // (x1, y1) 로부터 'r'만큼 떨어진 좌표
  const x = x1 + r * Math.cos(theta);
  const y = y1 + r * Math.sin(theta);

  return { x, y };
}
