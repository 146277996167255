import React, { Dispatch } from 'react';
import { Pagination, Select, Table } from 'usebeauty';
import { Controller } from 'common/components';
import { time } from 'common/functions';
import type * as M from 'models';
import classNames from 'classnames';
import { InputSearch } from 'common/components/inputSearch/InputSearch';
import { N_Select, N_Table } from 'usebeauty/dist/@types';
import Expand from './expand/Expand';
import type { Action, State } from './CtReviewTable';
import './ReviewTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const ReviewTable: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  const { header, data, pagination, searchKey, totalCount } = state;

  const handlers = {
    refresh: loadHandler,
    order: (order: N_Table.Sort): void => setState({ type: 'order', payload: order }),
    search: (searchText: string) => setState({ type: 'searchText', payload: Symbol(searchText) }),
    searchKey: (payload: N_Select.Selected<string>): void => setState({ type: 'searchKey', payload }),
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  const order = { sort: state.order, onChange: handlers.order };

  return (
    <section id="reviewer-review-table">
      <Controller title={`심사 수행 (${totalCount})`}>
        <Select onChange={handlers.searchKey} selected={searchKey}>
          <Select.Summary />
          <Select.Options className="control-option">
            <Select.Item title="선택" value="all" />
            <Select.Item title="이름" value="name" />
            <Select.Item title="전화번호" value="phone_number" />
            <Select.Item title="생년월일" value="birthday" />
          </Select.Options>
        </Select>
        <InputSearch
          onChange={handlers.search}
          value={state.searchText.description}
          disabled={state.searchKey?.value === 'all'}
        />
      </Controller>
      <Table>
        <Table.Head data={header} order={order} />
        <Table.Body data={data}>
          {({ index, rowData }) => {
            return (
              <Table.Row key={rowData.id} st={{ height: 'auto' }}>
                <>
                  <Table.Tr data={rowData}>
                    {({ value, property }) => {
                      if (property === 'request_time') {
                        const epoch = new Date(value as string).getTime();
                        return time.timeFormatFromUTCEpoch(epoch, 2);
                      }

                      if (property === 'expand') {
                        return (
                          <i
                            className={classNames('expand-icon', { expanded: rowData.expand })}
                            onClick={() => setState({ type: 'expand', payload: index })}
                          />
                        );
                      }
                      return value === 'EXPIRED' ? '만료됨' : value || '-';
                    }}
                  </Table.Tr>
                  {rowData.expand && <Expand rowData={rowData} refresh={handlers.refresh} />}
                </>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination data={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default ReviewTable;
