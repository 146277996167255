import React, { useReducer } from 'react';
import { Pagination } from 'models';
import { Loading, M_Table } from 'common/components';
import { produce } from 'immer';
import { getAccessLogRoot } from 'common/api';
import { toSortUpperCase } from 'common/functions';
import { values } from 'lodash';
import { N_Table } from 'usebeauty/dist/@types';
import { useKycQuery } from 'common/api/usequery';
import PrivacyCheck from './PrivacyCheck';

const initPagination = { info: { current: 1, total: 10 }, format: { limit: 20, offset: 0 } };
export type Data = typeof getAccessLogRoot.res;
export type Action =
  | { type: 'setData'; payload: Data[] }
  | { type: 'pagination'; payload: Pagination }
  | { type: 'order'; payload: N_Table.Sort }
  | { type: 'filterReset' }
  | { type: 'filter'; payload: T_Filter };
export type T_Filter = {
  type: number;
  year: string;
  month: string;
  day: { start: string; end: string };
  halfYear: number; // 반기
  quarter: number; // 분기
};

export interface State {
  data: Data[];
  header: M_Table.header[];
  pagination: Pagination;
  filter: T_Filter;
  order: N_Table.Sort;
}

const initialState: State = {
  data: [],
  pagination: { ...initPagination },
  order: { created_at: 'desc' },
  header: [
    { title: '조회일시', property: 'created_at', align: 'center', order: true },
    { title: '요청자 ID', property: 'root_name', align: 'center' },
    { title: '요청자 IP', property: 'client_ip', align: 'center' },
    { title: '조회 대상', property: 'check_user_name', align: 'center' },
    { title: '수행 업무', property: 'task_id', align: 'center' },
  ],
  filter: {
    type: 1, // 1.기간 설정, 2.월, 3.분기, 4.반기, 5.년
    year: '2023',
    month: '01',
    day: { start: '', end: '' },
    halfYear: 0,
    quarter: 0,
  },
};

// prettier-ignore
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'pagination': draft['pagination'] = action.payload; break;
      case "filter" : draft["filter"] = action.payload; break;
      case "filterReset" : draft["filter"] = initialState.filter; break;
      case 'order': draft['order'] = action.payload; break;
    }
  });
};

const CtPrivacyCheck = () => {
  const [state, setState] = useReducer(reducer, initialState);
  const order = toSortUpperCase(values(state.order)[0]);

  const params = {
    start: state.pagination.format.offset,
    count: state.pagination.format.limit,
    order_field: 'created_at',
    order,
    start_date: state.filter.day.start ? state.filter.day.start : undefined,
    end_date: state.filter.day.end ? state.filter.day.end : undefined,
  };

  const { refetch, isRefetching } = useKycQuery<{ list: (typeof getAccessLogRoot.res)[]; total_count: number }>({
    key: ['getAccessLogRoot'],
    handler: () => getAccessLogRoot(params),
    options: {
      onSuccess: (response) => {
        const { list, total_count } = response.data;
        setState({ type: 'setData', payload: list });
        setState({
          type: 'pagination',
          payload: {
            ...state.pagination,
            info: { ...state.pagination.info, total: Math.ceil(total_count / state.pagination.format.limit) },
          },
        });
      },
    },
  });

  return (
    <>
      {isRefetching && <Loading />}
      {!isRefetching && <PrivacyCheck state={state} setState={setState} loadHandler={refetch} />}
    </>
  );
};

export default CtPrivacyCheck;
