import React from 'react';
import { authActions } from 'store/actions/root';
import { useDispatch, useSelector } from 'common/hooks/root';
import { doubleDigit } from 'common/components/module/lib/functions';
import { Time } from '@Icons';
import './TokenTimer.scss';

function TokenTimer() {
  const { timer } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const remainMinutes = Math.floor(timer / (1000 * 60));
  const remainSeconds = Math.floor((timer % (1000 * 60)) / 1000);
  const formattedRemainTime = `${doubleDigit(remainMinutes)}:${doubleDigit(remainSeconds)}`;

  const timerExtendHandler = () => {
    dispatch(authActions.refreshToken());
  };

  return (
    <div id="kyc-page-token-timer">
      <div className="timer">
        <Time />
        {formattedRemainTime}
      </div>
      <div className="divide" />
      <div className="extend">
        <span onClick={timerExtendHandler}>연장</span>
      </div>
    </div>
  );
}
export default TokenTimer;
