/* eslint-disable react/no-danger */
import React, { useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Dialog, Form } from '@usebeauty';
import { produce } from 'immer';
import { authActions } from 'store/actions/admin';
import { Footer, Validator } from 'common/components';
import { delayed, findCustomer } from 'common/functions';
import { useDispatch, useSelector } from 'common/hooks/admin';
import { InputCheck, Lock, UnLock } from '@Icons';
import './LoginPage.scss';
import Di_AccountLock from './dialog/Di_AccountLock';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'customerLoading'; payload: boolean }
  | { type: 'customerId'; payload: number }
  | { type: 'loginError'; payload: { submit: string } };

interface State {
  loading: boolean;
  customerLoading: boolean;
  customerId: number;
  loginError: { submit: string };
}

const initialState: State = {
  loading: false,
  customerLoading: false,
  customerId: -1,
  loginError: { submit: '' },
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
      case 'customerLoading': draft['customerLoading'] = payload; break;
      case 'customerId': draft['customerId'] = payload; break;
      case 'loginError': draft['loginError'] = { ...state.loginError, ...payload }; break;
    }
  });
};

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locked = useSelector((state) => state.auth.showLocked);

  const [state, setState] = useReducer(reducer, initialState);

  const { customerLoading, customerId, loginError } = state;

  const {
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: { customer_name: '', id: '', password: '' },
  });

  const submitHandler = async () => {
    // 고객사가 존재할때
    if (customerId > -1) {
      setState({ type: 'loading', payload: true });
      const params = { customer_id: customerId, username: getValues('id'), password: getValues('password'), navigate };
      const { payload: error } = await dispatch(authActions.login(params));
      // 로그인 에러 발생
      if (error) setState({ type: 'loginError', payload: { submit: error } });
      setState({ type: 'loading', payload: false });
    }
  };

  const clientCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ type: 'customerLoading', payload: true });
    const { value } = e.target;
    delayed.cancel();
    if (value) {
      delayed(async () => {
        const result = await findCustomer({ customer_name: value });
        setState({ type: 'customerId', payload: result.id });
        if (errors.customer_name) clearErrors('customer_name');

        if (result.id === -1) {
          setError('customer_name', { message: '등록되지 않은 고객사명입니다.' });
          setState({ type: 'customerId', payload: 0 });
        }
        setState({ type: 'customerLoading', payload: false });
      });
    } else {
      setState({ type: 'customerLoading', payload: false });
    }
  };

  // 로그인 체크 후 자동 로그인
  useEffect(() => {
    const data = localStorage.getItem('ADMIN_TOKEN');
    if (data) {
      const authObj = JSON.parse(data);
      const expire = new Date(authObj.expire).getTime();
      if (expire - Date.now() > 0 && !authObj.is_temp_password) navigate('/admin/reviewer-management');
      else localStorage.removeItem('ADMIN_TOKEN');
    }
  }, []);

  const disabledClient = !!errors.customer_name || !getValues('customer_name') || customerLoading;

  return (
    <>
      <div id="admin-login">
        <div id="admin-login-form-field">
          <div className="signin-title">
            <i />
          </div>

          <section className="form-section">
            <Validator.Provider form onSubmit={submitHandler}>
              <div className="login-text">Admin Login</div>
              <div className="customer-input">
                <Form.Input
                  st={{ marginBottom: 10 }}
                  name="customer_name"
                  control={control}
                  placeholder="고객사명을 입력하세요."
                  onChange={clientCheckHandler}
                  rules={{
                    validate: {
                      unRegisteredCustomer: () => {
                        if (!getValues('customer_name').length) return true;
                        return customerId === 0 ? '등록되지 않은 고객사명입니다.' : true;
                      },
                    },
                  }}
                />
                {!disabledClient && <InputCheck className="check" />}
              </div>

              <Box st={{ marginTop: 16 }}>
                <Form.Input
                  st={{ marginBottom: 10 }}
                  name="id"
                  control={control}
                  placeholder="아이디"
                  onChange={() => setState({ type: 'loginError', payload: { submit: '' } })}
                />
              </Box>

              <Box st={{ marginTop: 16 }}>
                <Form.Input
                  st={{ marginBottom: 10 }}
                  name="password"
                  type="password"
                  control={control}
                  placeholder="비밀번호"
                  lockOpenIcon={<UnLock st={getValues('password') ? { color: '#5f65ff' } : { color: '#cccccc' }} />}
                  lockCloseIcon={<Lock st={getValues('password') ? { color: '#868686' } : { color: '#cccccc' }} />}
                  onChange={() => setState({ type: 'loginError', payload: { submit: '' } })}
                />
              </Box>

              {loginError.submit && (
                <div className="error-message" dangerouslySetInnerHTML={{ __html: loginError.submit }} />
              )}

              <Validator.Submit
                text="로그인"
                loading={state.loading}
                styles={{ width: '100%', height: 60 }}
                disabled={disabledClient || !getValues('id') || !getValues('password') || !!loginError.submit}
              />

              <div className="utils-field">
                <Link to="/find-account/admin">로그인에 문제가 있나요?</Link>
                <Link to="/sign-up/admin">회원가입</Link>
              </div>
            </Validator.Provider>
          </section>
        </div>
      </div>
      <Footer />
      <Dialog open={locked} content={() => <Di_AccountLock />} />
    </>
  );
};
