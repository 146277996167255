import React, { Dispatch } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dialog, Table } from 'usebeauty';
import { Controller } from 'common/components';
import { time } from 'common/functions';
import classNames from 'classnames';
import type { Action, State } from './CtPolicyTable';
import Expand from './expand/Expand';
import ModalRegistPrivacy from './modal/Modal_RegistPolicy';
import './PolicyTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const ServiceTable: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  const { header, data } = state;

  const handlers = { refresh: loadHandler };

  return (
    <section id="root-guidance-policy-table">
      <Controller
        title={
          <>
            <NavLink to="/root/terms-guidance-management/privacy">개인정보수집동의서</NavLink>
            <NavLink to="/root/terms-guidance-management/service">개인정보처리방침</NavLink>
          </>
        }
      >
        <Dialog
          content={({ closeHandler }) => <ModalRegistPrivacy closeHandler={closeHandler} refresh={loadHandler} />}
        >
          <Button className="register">+ 등록</Button>
        </Dialog>
      </Controller>
      <Table>
        <Table.Head data={header} />
        <Table.Body data={data}>
          {({ index, rowData }) => {
            return (
              <Table.Row key={rowData.id} st={{ height: rowData.expand ? 'auto' : 72 }}>
                <>
                  <Table.Tr data={rowData}>
                    {({ value, property }) => {
                      if (property === 'created_at') {
                        const epoch = new Date(value as string).getTime();
                        return time.timeFormatFromUTCEpoch(epoch, 3);
                      }
                      if (property === 'expand')
                        return (
                          <i
                            className={classNames('expand-icon', { expanded: rowData.expand })}
                            onClick={() => setState({ type: 'expand', payload: index })}
                          />
                        );
                      return value;
                    }}
                  </Table.Tr>
                  {rowData.expand && <Expand rowData={rowData} handlers={handlers} />}
                </>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
    </section>
  );
};

export default ServiceTable;
