import React, { useContext, useReducer } from 'react';
import { Button, Dialog } from 'usebeauty';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { getWarnLabels } from 'common/api';
import { KycLoading } from 'common/components';
import { ContentContext } from 'layout/root/content/Content';
import { useKycQuery } from 'common/api/usequery';
import Modal_AddLabel from '../modal/Modal_AddLabel';
import NationLabel from './NationLabel';

export type NationRisk = {
  id: number;
  score: number;
  iso_code2: string;
  iso_code3: string;
  name_en: string;
  name_kr: string;
  warn_labels: string[];
};
export type WarnLabel = typeof getWarnLabels.res;

export type Action =
  | { type: 'data'; payload: WarnLabel[] }
  | { type: 'defaultData'; payload: WarnLabel[] }
  | { type: 'edit'; payload: boolean };
export type State = { data: WarnLabel[]; defaultData: WarnLabel[]; edit: boolean };

const initialState = {
  data: [],
  defaultData: [],
  edit: false,
};

// prettier-ignore
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'data': draft['data'] = action.payload; break;
      case 'defaultData': draft['defaultData'] = action.payload; break;
      case 'edit': draft['edit'] = action.payload; break;
    }
  });
};

const parseWarnLabels = (warnLabels: WarnLabel[]) => {
  const active = warnLabels.filter((f) => f.active);
  const inactive = warnLabels.filter((f) => !f.active);
  return [...active, ...inactive];
};

function CtNationLabel() {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const { refetch, isFetching } = useKycQuery<{ list: (typeof getWarnLabels.res)[] }>({
    key: ['getWarnLabels'],
    handler: () => getWarnLabels(),
    options: {
      onSuccess: (response) => {
        const list = parseWarnLabels(response?.data.list);
        setState({ type: 'data', payload: list });
        setState({ type: 'defaultData', payload: list });
      },
    },
  });

  const submitHandler = (labelName: string, nations: NationRisk[], score: number) => {
    setLoading(true);

    const data = { name_en: labelName, score, country_ids: nations.map((n) => n.id) };
    process(
      api.reqData({ url: 'warn_labels', data }),
      api.post.request,
      api.fullFilled(() => {
        setLoading(false);
        refetch();
      })
    );
  };

  const warnLabelChangeHandler = (id: number, warnLabel: WarnLabel) => {
    const newWarnLabels = state.data.map((l) => (l.id === id ? { ...l, ...warnLabel } : l));
    setState({ type: 'data', payload: newWarnLabels });
  };

  return (
    <>
      {isFetching && <KycLoading />}
      {state.data.map((label) => (
        <NationLabel
          key={label.id}
          warnLabel={label}
          state={state}
          setState={setState}
          loadHandler={refetch}
          warnLabelChangeHandler={warnLabelChangeHandler}
        />
      ))}
      <Dialog
        content={({ closeHandler }) => (
          <Modal_AddLabel
            submitHandler={submitHandler}
            closeHandler={closeHandler}
            nations={[]}
            warnLabelNames={state.data.map((l) => l.name_en)}
          />
        )}
      >
        <article className="nation-label">
          <Button type="button" st={{ width: '100%', height: 16 }} className="label-append">
            <>
              <i />
              라벨 추가하기
            </>
          </Button>
        </article>
      </Dialog>
    </>
  );
}

export default CtNationLabel;
