import React from 'react';
import classNames from 'classnames';
import type { Data as RowData } from '../../../../CtReviewTable';
import { dateFormatter, Ocr } from '../IdCard';

interface Props {
  origin: Ocr;
  modified: Ocr | null;
  rowData: RowData;
  isModified: (keys: (keyof Ocr)[] | keyof Ocr) => boolean;
  idType: string;
}

export const ForeignID: React.FC<Props> = ({ origin, modified, rowData, isModified, idType }) => {
  const issueDate = modified && isModified('issueDate') ? `${modified.issueDate}` : `${origin.issueDate}`;
  return (
    <>
      <div>
        <h5>· 이름</h5>
        <p className={classNames({ warning: isModified('userName') })}>{rowData.name}</p>
      </div>

      <div className={classNames({ 'manual-input': idType === '5' })}>
        {idType === '5' && (
          <>
            <h5 className="manual-input">
              <div>· 외국인등록번호</div>
              <div>(거소신고번호)</div>
            </h5>
            <p className={classNames({ warning: modified && isModified(['juminNo1', 'juminNo2']) })}>
              {`${modified?.juminNo1}-${modified?.juminNo2}`}
            </p>
          </>
        )}
        {idType === '5-2' && <h5>· 거소신고번호</h5>}
        {['5-1', '5-3'].includes(idType) && <h5>· 외국인등록번호</h5>}
        {['5-1', '5-2', '5-3'].includes(idType) && (
          <p className={classNames({ warning: modified && isModified(['issueNo1', '_issueNo2']) })}>
            {modified && isModified(['issueNo1', 'issueNo2'])
              ? `${modified.issueNo1}-${modified['issueNo2']}`
              : `${origin.issueNo1}-${origin['issueNo2']}`}
          </p>
        )}
      </div>

      <div>
        <h5>· 발급 일자</h5>
        <p className={classNames({ warning: modified && isModified('issueDate') })}>{dateFormatter(issueDate)}</p>
      </div>
    </>
  );
};
