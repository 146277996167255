import React, { useState } from 'react';
import classNames from 'classnames';
import { Accordion, Button, Input, Modal } from 'common/components';
import { EddRa } from '../../../context/model.type';
import Modal_RaNation from '../modal/Modal_RaNation';
import Modal_RaTitleDescGuide from '../modal/Modal_RaTitleDescGuide';
import Modal_RaNationMode from '../modal/Modal_RaNationMode';

interface Props {
  item: EddRa.EddItem;
  active: boolean;
  countries: EddRa.Country[];
  use_ra: boolean;
  edd_countries_mode: number;
  edit: boolean;
  updateRaItem: (item: EddRa.EddItem) => void;
  updateRaCountries: (item: EddRa.Country[]) => void;
  handleEddCountryModeChange: (value: number) => void;
}
function NationRiskItem(props: Props) {
  const {
    item,
    active,
    countries,
    use_ra,
    edd_countries_mode,
    edit,
    updateRaItem,
    updateRaCountries,
    handleEddCountryModeChange,
  } = props;
  const [expand, setExpand] = useState(false);

  const handleScoreChange = (nation: EddRa.Country) => {
    const nextItem = countries.map((el) => (el.id === nation.id ? nation : el));
    updateRaCountries(nextItem);
  };

  const submitHandler = (nations: EddRa.Country[]) => {
    updateRaCountries(nations);
  };

  return (
    <div className={classNames('accordion__section', { expand, inactive: !active })}>
      <Accordion
        active={expand}
        onClick={() => setExpand((prev) => !prev)}
        title={
          <>
            <div className="item__title">
              <div>
                <b>{item.name}</b>
              </div>
              <div />
              <div className={classNames('max__score', { hide: !use_ra })}>
                <span>최대 점수</span>
                <div>{item.score}점</div>
              </div>
            </div>
          </>
        }
      >
        <section className={classNames('item__option', 'description', { edit })}>
          {edit ? (
            <>
              <div>
                <h4>제목</h4>
                <span>(선택)</span>
                <Input
                  type="text"
                  className="basic"
                  validType="realtime"
                  maxLength={20}
                  disabled={!edit || !active}
                  rules={['maxLength']}
                  placeholder="최대 20자까지 작성하실 수 있습니다"
                  value={item.display_name}
                  onChange={(e) => {
                    const { value } = e.target;
                    updateRaItem({ ...item, display_name: value });
                  }}
                />
              </div>
              <div>
                <h4>설명</h4>
                <span>(선택)</span>
                <Input
                  type="text"
                  className="basic"
                  validType="realtime"
                  maxLength={50}
                  rules={['maxLength']}
                  disabled={!edit || !active}
                  placeholder="최대 50자까지 작성하실 수 있습니다"
                  value={item.description}
                  onChange={(e) => {
                    const { value } = e.target;
                    updateRaItem({ ...item, description: value });
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div>{item.display_name ? item.display_name : '작성된 제목이 없습니다'}</div>
              <div>{item.description ? item.description : '작성된 설명이 없습니다'}</div>
            </>
          )}

          {edit && (
            <Modal content={({ closeHandler }) => <Modal_RaTitleDescGuide closeHandler={closeHandler} />}>
              <Button type="button" className="description__guide">
                <div>제목 및 설명 작성 가이드</div>
                <i className="description__guide" />
              </Button>
            </Modal>
          )}
        </section>

        {edit && active && (
          <section className="nation-risk-type">
            <div className="radio__group">
              <Button
                type="button"
                disabled={!edit || !active}
                className={classNames('radio__item', { checked: edd_countries_mode === 1 })}
                onClick={() => handleEddCountryModeChange(1)}
              >
                <div>
                  <i />
                  <span>자동 갱신</span>
                </div>
                <p>최신 글로벌 기준에 맞춰 자동으로 국가와 점수가 갱신됩니다</p>
              </Button>

              <Modal
                content={({ closeHandler }) => (
                  <Modal_RaNationMode
                    closeHandler={closeHandler}
                    cancelHandler={() => handleEddCountryModeChange(1)}
                    submitHandler={() => handleEddCountryModeChange(2)}
                  />
                )}
              >
                <Button
                  type="button"
                  disabled={!edit || !active}
                  className={classNames('radio__item', { checked: edd_countries_mode === 2 })}
                >
                  <div>
                    <i />
                    <span>직접 설정</span>
                  </div>
                  <p>위험국가를 추가/삭제 할 수 있고, 개별 점수를 설정할 수 있습니다</p>
                </Button>
              </Modal>
            </div>
          </section>
        )}
        <div className="item__subtree header">
          <span>국가명</span>
          <span className={classNames({ hide: !use_ra })}>점수</span>
        </div>

        {countries.map((nation) => {
          const { id, iso_code3, name_kr, score, warn_labels } = nation;

          return (
            <div className="item__subtree" key={`${id}_${iso_code3}`}>
              <div className="info">
                <span className="name">{name_kr}</span>
                <div className="warn__labels">
                  {warn_labels.map((label, index) => (
                    <span key={`${label}_${index}`}>{label}</span>
                  ))}
                </div>
              </div>
              <span className={classNames('score', { hide: !use_ra })}>
                {edit ? (
                  <>
                    <Input
                      type="text"
                      className="basic"
                      styles={{ width: 60 }}
                      value={score}
                      onChange={(e) => {
                        const { value } = e.target;
                        let numValue = Number(value.replace(/[^\d]/g, ''));
                        numValue = numValue > 100 ? 100 : numValue;
                        handleScoreChange({ ...nation, score: numValue });
                      }}
                    />
                    점
                  </>
                ) : (
                  <>{score}점</>
                )}
              </span>
            </div>
          );
        })}
      </Accordion>
      {edd_countries_mode === 2 && edit && active && expand ? (
        <div className="item__subtree footer">
          <Modal
            content={({ closeHandler }) => (
              <Modal_RaNation
                closeHandler={closeHandler}
                submitHandler={submitHandler}
                eddCategory="country"
                item={countries}
              />
            )}
          >
            <Button type="button" text="선택지 설정" />
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default NationRiskItem;
