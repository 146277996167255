import React, { Dispatch } from 'react';
import { Radio } from 'usebeauty';
import { Action, State } from '../../../../IdcardContent';
import './WamsManualShotOption.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
}

const WamsManualShotOption = (props: Props) => {
  const { setState, state } = props;
  const { ocr_auto_switching } = state.data.vas;

  return (
    <div id="wasm-manual-shot">
      <p className="wasm-radio-text">
        <b>OCR 방식 (WASM / Server) 선택 방식</b>을 설정합니다.
      </p>
      <Radio.Group
        className="wasm-radio-group"
        value={ocr_auto_switching ? 1 : 2}
        onChange={(value) => setState({ type: 'vas', payload: { ocr_auto_switching: value === 1 } })}
      >
        <Radio text="OCR 방식 자동선택" value={1}>
          <p>저사양 기기로 예상되는 경우, 자동으로 Server OCR을 사용합니다.</p>
        </Radio>
        <Radio text="OCR 방식 수동선택" value={2}>
          <p>Server 수동촬영 전환버튼이 제공됩니다. 사용자가 촬영방식을 선택할 수 있습니다.</p>
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default WamsManualShotOption;
