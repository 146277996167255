import React from 'react';
import { cx } from 'common/functions';
import { getMonthList } from 'common/functions/time';
import { useDateFilterContext } from '../popover/Pop_DateFilter';
import './MonthComp.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
  year: string;
}

export const MonthComp = (props: Props) => {
  const { value, onChange, year } = props;
  const { firstDate } = useDateFilterContext();
  const firstYear = Number(firstDate?.split('-')[0]);
  const current = new Date();
  const currentYear = current.getFullYear();
  const currentMonth = current.getMonth() + 1;
  const month = Number(year) < currentYear ? 12 : currentMonth;

  const startDate = Number(year) === firstYear ? `${firstDate}-01` : `${year}-01-01`;
  const endDate =
    Number(year) === currentYear ? `${year}-${month < 10 ? `0${month}` : `${month}`}-01` : `${year}-12-01`;

  const monthList = getMonthList(startDate, endDate);

  const onClickHandler = (value: string) => onChange(value);

  return (
    <div className="month-content">
      <div className="month-list">
        {monthList.map((month) => {
          const m = month.split('-')[1];
          return (
            <div key={m} onClick={() => onClickHandler(m)} className={cx({ active: value === m })}>
              {`${Number(m)}월`}
            </div>
          );
        })}
      </div>
    </div>
  );
};
