import { reduce } from 'lodash';
import { checkActiveRaScore } from '../ra/settings/util';
import { Data } from './EddContext';
import { EddRa } from './model.type';

export const isDisabled = (data: Data, use_ra: boolean) => {
  const { customer, service, edd_category } = data;

  const customerCategory = edd_category.find((el) => el.edd_type === 'customer') as EddRa.EddCategory;
  const serviceCategory = edd_category.find((el) => el.edd_type === 'service') as EddRa.EddCategory;

  const getTotalScore = (acc: number, cur: EddRa.EddItem) => acc + cur.score;
  const customerTotalScore = reduce(customer, getTotalScore, 0);
  const serviceTotalScore = reduce(service, getTotalScore, 0);

  const checkCustomerScore = checkActiveRaScore(customer);
  const checkServiceScore = checkActiveRaScore(service);

  const satisfiedCustomerScore = checkCustomerScore && customerTotalScore === 100;
  const satisfiedServiceScore = checkServiceScore && serviceTotalScore === 100;

  const customerAtLeastOneActive =
    (customerCategory.active && customer.some((el) => el.active)) || !customerCategory.active;
  const serviceAtLeastOneActive =
    (serviceCategory.active && service.some((el) => el.active)) || !serviceCategory.active;
  const eachAtLeastOneActive = customerAtLeastOneActive && serviceAtLeastOneActive;

  const errorMessage: string = (() => {
    if (use_ra) {
      if (!checkCustomerScore || !checkServiceScore) {
        return '항목 내 선택지 점수를 확인해주세요.';
      }
      if (customerTotalScore !== 100 || serviceTotalScore !== 100) {
        return '각 위험별로 남은 점수는 0점이 되어야 합니다.';
      }
    }

    return '';
  })();

  const disabled = (use_ra && (!satisfiedCustomerScore || !satisfiedServiceScore)) || !eachAtLeastOneActive;
  return { disabled, errorMessage };
};
