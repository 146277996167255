import axios from 'axios';
import { Resp } from './model';
import { authorization } from './index';

type T_GetReviewManuals = {
  email: string;
  id: number;
  is_approve: boolean;
  name: string;
  phone_number: string;
  username: string;
  expand: boolean;
};

export const getReviewManuals = async (params?: { start: number; count: number; [x: number]: string | undefined }) => {
  const { data } = await axios.get<Resp<{ list: T_GetReviewManuals[]; total_count: number }>>('review/manuals', {
    params,
    headers: authorization(),
  });
  return data;
};
getReviewManuals.res = {} as T_GetReviewManuals;

// ##############################################################################################################################

export type RaResult = {
  application_id: number;
  ra_score: number;
  ra_threshold: [number, number];
  customer_score: number;
  customer_std_score: number;
  svc_score: number;
  svc_std_score: number;
  country_score: number;
  country_std_score: number;
  ra_grade: 'low' | 'middle' | 'high';
};

export type EddField = {
  key: string;
  type: 'tree' | 'ox' | 'ox_addr';
  value: string;
};

export type CustomField = {
  key: string;
  type: 'text' | 'file' | 'tree' | 'ox' | 'addr';
  value: string;
  attachment: { id: number; name: string } | null;
};

export type Custom = {
  edd: EddField[];
  custom: CustomField[];
};

export type Account = {
  account_holder: string;
  account_number: string;
  finance_code: string;
  finance_company: string;
  mod_account_holder: null;
  verified: boolean;
};

type HistoryAccount = Pick<Account, 'account_holder' | 'account_number' | 'finance_code'> & {
  created_at: string;
};
export type Histories = {
  accounts: HistoryAccount[];
};
/**
 * face_check.is_live: 1 자동승인 / 2 자동거절
 * face_check.is_masked: 0 마스크 안씀 / 1 마스크 착용
 * face_check.is_same_person: 1 자동승인 / 2 수동심사 / 3 자동거절
 */
export type FaceCheck = {
  is_live: 1 | 2; // 1 자동승인 / 2 자동거절
  is_masked: 0 | 1; // 0 마스크 안씀 / 1 마스크 착용
  is_same_person: 1 | 2 | 3; // 1 자동승인 / 2 수동심사대상 / 3 자동거절
  selfie_image: string;
};

export type IdCard = {
  id_card_image: string;
  id_card_origin: string;
  id_crop_image: string;
  is_manual_input: boolean;
  is_uploaded: boolean;
  modified: boolean;
  modified_ocr_data: null;
  original_ocr_data: string;
  uploaded_type: string;
  verified: boolean;
};

export type Module = {
  account_verification: boolean;
  face_authentication: boolean;
  id_card_ocr: boolean;
  id_card_verification: boolean;
  liveness: boolean;
};

export type Data = {
  account: Account | null;
  face_check: FaceCheck | null;
  id_card: IdCard | null;
  custom: { edd: EddField[] | null; custom: CustomField[] | null } | null;
  module: Module | null;
  birthday: string;
  id: number;
  name: string;
  phone_number: string;
  request_time: string;
  histories: Histories;
  ra_result: RaResult | null;
};

type T_GetReviewManualsId = {
  account: Account | null;
  face_check: FaceCheck | null;
  id_card: IdCard | null;
  custom: { edd: EddField[] | null; custom: CustomField[] | null } | null;
  module: Module | null;
  birthday: string;
  id: number;
  name: string;
  phone_number: string;
  request_time: string;
  histories: Histories;
  ra_result: RaResult | null;
};

export const getReviewManualsId = async (id: number) => {
  const { data } = await axios.get<
    Resp<{
      detail: T_GetReviewManualsId;
      hmac: { timestamp: string; value: string };
    }>
  >(`review/manuals/${id}`, { headers: authorization() });
  return data;
};
getReviewManualsId.res = {} as T_GetReviewManualsId;

// ##############################################################################################################################

type T_GetReviewResults = {
  birthday: string;
  id: number;
  name: string;
  phone_number: string;
  request_time: string;
  result_type: number;
  expand: boolean;
};

export const getReviewResults = async (params?: { start: number; count: number; [x: number]: string | undefined }) => {
  const { data } = await axios.get<Resp<{ list: T_GetReviewResults[]; total_count: number }>>('review/results', {
    params,
    headers: authorization(),
  });
  return data;
};
getReviewResults.res = {} as T_GetReviewResults;

// ##############################################################################################################################

interface T_GetReviewResultsId {
  account: {
    account_holder: string;
    account_number: string;
    finance_code: string;
    finance_company: string;
    mod_account_holder: null;
    verified: boolean;
  } | null;
  face_check: FaceCheck | null;
  id_card: {
    id_card_image: string;
    id_card_origin: string;
    id_crop_image: string;
    is_manual_input: boolean;
    is_uploaded: boolean;
    modified: boolean;
    modified_ocr_data: null;
    original_ocr_data: string;
    uploaded_type: string;
    verified: boolean;
  } | null;
  custom: { edd: EddField[] | null; custom: CustomField[] | null } | null;
  module: {
    account_verification: boolean;
    face_authentication: boolean;
    id_card_ocr: boolean;
    id_card_verification: boolean;
    liveness: boolean;
  } | null;
  birthday: string;
  id: number;
  name: string;
  phone_number: string;
  request_time: string;
  rejected_reason: string;
  reviewer_name: string;
  reviewer_username: string;
  histories: {
    accounts: { account_holder: string; account_number: string; finance_code: string; created_at: string }[];
  };
  ra_result: RaResult | null;
}

export const getReviewResultsId = async (id: number) => {
  const { data } = await axios.get<
    Resp<{
      detail: T_GetReviewResultsId;
      hmac: { timestamp: string; value: string };
    }>
  >(`review/results/${id}`, { headers: authorization() });
  return data;
};
getReviewResultsId.res = {} as T_GetReviewResultsId;

// ########################################################################################################################################################################
