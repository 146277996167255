import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Input } from 'common/components';
import { useTreeContext, TreeNode } from './TreeViewContainer';
import './TreeView.scss';

type TreeNodeComp = { item: TreeNode; updateHandler: (value: string, blur?: boolean) => void };

const TreeNodeComp = ({ item, updateHandler }: TreeNodeComp) => {
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { activeNode, selectedNode, selectHandler, expandHandler } = useTreeContext();

  const active = activeNode?.id === item.id;
  const selected = selectedNode?.id === item.id;

  useEffect(() => {
    if (inputRef.current && active) {
      inputRef.current.focus();
    }
  }, [inputRef, active]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    updateHandler(e.target.value);
  };

  const onKeyPress = () => {
    updateHandler(value, true);
  };

  const onExpandChange = () => {
    expandHandler(item);
  };

  return (
    <div className={classNames('treeview__node', { active, selected })}>
      <label className={classNames(['icon-expand', { expandable: !!item.children.length, expand: item.expand }])}>
        {!!item.children.length && <input type="checkbox" onChange={onExpandChange} />}
      </label>
      <div onClick={() => selectHandler(item)} className="treeview__node__label">
        <Input
          className="basic"
          placeholder={active ? '최대 25자 입력가능' : ''}
          type="text"
          disabled={!active}
          innerRef={inputRef}
          onChange={onChange}
          value={item.name}
          keyEnter={onKeyPress}
        />
      </div>
    </div>
  );
};

const Depth = styled.div<{ depth: number }>`
  padding-left: ${({ depth }) => `${depth * 24}px`};
`;

type TreeView = {
  item: TreeNode;
  children: (args: { item: TreeNode }) => JSX.Element;
};
function TreeView(props: TreeView) {
  const { item, children } = props;
  const { childComp } = useTreeContext();
  const { depth } = item;

  return (
    <Depth className="treeview__list" depth={depth}>
      <div className={classNames(['treeview__group', { expand: item.expand, isLast: !item.children.length }])}>
        {children({ item })}
      </div>
      {item.expand &&
        item.children.map((el, i) => {
          return (
            <React.Fragment key={i}>
              <TreeView item={el}>{childComp}</TreeView>
            </React.Fragment>
          );
        })}
    </Depth>
  );
}

TreeView.Node = TreeNodeComp;

export default TreeView;
