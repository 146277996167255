import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Controller } from 'common/components';

function Manage() {
  return (
    <div id="root-eddra-nation-navigator">
      <Controller
        title={
          <>
            <NavLink to="/root/eddra-management/nation">국가위험 관리</NavLink>
            <NavLink to="/root/eddra-management/template">템플릿 관리</NavLink>
          </>
        }
      />
      <Outlet />
    </div>
  );
}

export default Manage;
