import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Button, Dialog } from 'usebeauty';
import { Alert, Modal, Switch } from 'common/components';
import { api, generatePassword, process } from 'common/functions';
import { NullishGuard } from 'hoc';
import { produce } from 'immer';
import { Detail } from '../Expand';
import './LinkedAccountModal.scss';
import ActiveModal from './modal/ActiveModal';

export type Account = { id: number; username: string; key?: string; active: boolean; date: string; created_at: string };
export type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'create'; payload: Partial<Account> }
  | { type: 'account'; payload: Account[] }
  | { type: 'modified'; payload: { id: number; active: boolean } }
  | { type: 'active'; payload: Account };

interface State {
  accounts: Account[];
  account_created: boolean;
  created_account: Partial<Account> | null;
  modified: Array<{ id: number; active: boolean }>;
  loading: boolean;
}

const initialState: State = {
  accounts: [],
  modified: [],
  account_created: false,
  created_account: null,
  loading: false,
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading':
        draft['loading'] = action.payload;
        break;
      case 'create':
        draft.created_account = action.payload;
        draft.account_created = true;
        break;
      case 'account':
        draft.accounts = action.payload;
        break;
      case 'active':
        draft.accounts[draft.accounts.findIndex((acc) => acc.id === action.payload.id)] = {
          ...action.payload,
          active: !action.payload.active,
        };
        break;
      case 'modified':
        draft.modified = [...draft.modified, action.payload];
        break;
    }
  });
};

interface Props {
  detail: Detail;

  closeHandler: () => void;
}

const LinkedAccountModal: React.FC<Props> = ({ detail, closeHandler }) => {
  const { set } = Alert.Context();

  const [state, setState] = useReducer(reducer, initialState);
  const [activeModal, setActiveModal] = useState<Array<boolean>>([]);
  const defaultData = useRef<Account[] | null>(null);
  const date = new Date();
  const dateString = date.toISOString().split('T')[0];

  const createAccountHandler = () => {
    const id = generatePassword({ length: 10, number: true });
    const key = generatePassword({ length: 15, number: true, symbol: true });
    process(
      api.reqData({
        url: `customers/${detail.id}/users`,
        data: {
          username: id,
          password: key,
        },
      }),
      api.post.request,
      api.fullFilled(({ response }) => {
        // TODO need to change date parameter after api update

        const new_account = { username: id, key, active: true, date: dateString };
        setState({ type: 'create', payload: new_account });
        set({ success: '연결 계정이 생성되었습니다.' });
        return response;
      })
    );
  };

  const copyHandler = () => {
    if (state.created_account && state.created_account.key) {
      navigator.clipboard
        .writeText(`ID : ${state.created_account.username} /  KEY : ${state.created_account.key}`)
        .then(() => {
          set({ success: '계정 정보 복사가 완료되었습니다.' });
        });
    }
  };

  const loadLandler = () => {
    // TODO need to consider case that uesr accounts exceeds 50
    process(
      api.reqData({ url: `customers/${detail.id}/users?count=50` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        setState({ type: 'account', payload: response?.data.list });
        setActiveModal(Array(response?.data.list.length).fill(false));
        defaultData.current = response?.data.list;
        return undefined;
      })
    );
  };

  const submitHandler = (value: boolean, account: Account) => {
    process(
      api.reqData({ url: `customers/${detail.id}/users/${account.id}/active`, data: { active: value } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        loadLandler();
        return response;
      })
    );
  };

  useEffect(() => {
    loadLandler();
  }, []);

  return (
    <div id="linked_account__modal__wrapper">
      <Dialog.Base st={{ width: 720, height: 800 }}>
        <Dialog.Summary>연결 계정 관리</Dialog.Summary>
        <Dialog.Content>
          <NullishGuard data={[state.accounts.length || state.account_created]}>
            <Button className="ok header" disabled={!!state.created_account} onClick={createAccountHandler}>
              계정 생성
            </Button>
            <ul className="linked_account__list">
              {state.created_account && (
                <>
                  <li className="linked_account__list__item created">
                    <span className="date">{state.created_account.date}</span>
                    <span className="secrets">
                      {`ID : ${state.created_account.username} /  KEY : ${state.created_account.key}`}
                    </span>
                    <button type="button" className="copy" onClick={copyHandler}>
                      복사하기
                    </button>
                  </li>
                  <div className="linked_account__label">
                    <span className="description">
                      해당 정보는 최초 1회만 확인이 가능하므로 반드시 안전한 곳에 별도 보관해 주세요.
                    </span>
                  </div>
                </>
              )}
              {state.accounts.map((account, i) => (
                <li key={i} className="linked_account__list__item">
                  {/* <span className="date">{account.date}</span> */}
                  <span className="date">{account.created_at.split('T')[0]}</span>
                  <span className="secrets">{`ID : ${account.username}`}</span>
                  <Switch
                    value={account.active}
                    onChange={(value) => {
                      if (value) {
                        submitHandler(!!value, account);
                        // activeHandler(!!value, account);
                      } else {
                        setActiveModal(
                          activeModal.map((item, idx) => {
                            if (idx === i) return true;
                            return item;
                          })
                        );
                      }
                    }}
                  />
                  {activeModal[i] && (
                    <Modal.Portal
                      setToggle={() =>
                        setActiveModal(
                          activeModal.map((item, idx) => {
                            if (idx === i) return false;
                            return item;
                          })
                        )
                      }
                    >
                      {({ closeHandler }) => (
                        <ActiveModal
                          account={account}
                          submitHandler={() => submitHandler(false, account)}
                          setState={setState}
                          closeHandler={closeHandler}
                        />
                      )}
                    </Modal.Portal>
                  )}
                </li>
              ))}
            </ul>
          </NullishGuard>

          <NullishGuard data={[!(state.accounts.length || state.account_created)]}>
            <div>
              <div className="detail">
                생성된 계정 정보가 없습니다.
                <br />
                연결 계정 생성이 필요하다면 아래 버튼을 눌러주세요.
              </div>
              <Button className="ok" onClick={createAccountHandler}>
                계정 생성
              </Button>
            </div>
          </NullishGuard>
        </Dialog.Content>
        <Dialog.Actions>
          <Button className="ok" onClick={closeHandler}>
            닫기
          </Button>
        </Dialog.Actions>
      </Dialog.Base>
    </div>
  );
};

export default LinkedAccountModal;
