import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { omit, pick, some, toPairs, values } from 'lodash';
import { Alert, CheckBox, Popover, Switch } from 'common/components';
import { Action, Data, initConfig, Module } from '../../../Expand';
import WasmOcr from './ocr/WasmOcr';
import ServerOcr from './ocr/ServerOcr';
import { ManualShot } from './manualshot/ManualShot';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

export const IdcardComp = (props: Props) => {
  const { data, setState, edit } = props;
  const { module, idCard, config } = data;
  const { set } = Alert.Context();

  return (
    <div className="setting-item">
      <div className={classNames('switch-box', module.id_card_ocr ? 'active' : 'inactive')}>
        신분증 인증
        <Switch
          disabled={!edit}
          className={classNames({ readonly: !edit })}
          value={module.id_card_ocr}
          onChange={(v) => {
            const value = !!v;
            const payload: Partial<Module> = { id_card_ocr: value };
            if (!value) {
              payload.liveness = value;
              payload.face_authentication = value;
              setState({
                type: 'config',
                payload: pick(initConfig, [
                  'save_original_id_image',
                  'manual_upload_id_mobile',
                  'manual_input_mobile',
                  'manual_upload_id_pc',
                  'use_idcard_face_detect',
                  'allow_wasm_ocr',
                  'server_ocr_type',
                  'allow_ssa',
                  'use_ssa',
                  'ssa_judge_type',
                  'server_ocr_type',
                  'allow_idcard_face_detect',
                ]),
              });

              setState({ type: 'module', payload: { id_card_verification: value } });
            }
            const cards = Object.fromEntries(toPairs(idCard).map(([k, v]) => [k, { ...v, active: value }]));
            setState({ type: 'idCard', payload: cards });
            setState({ type: 'module', payload });
          }}
        />
      </div>
      <div className="setting-item-content">
        <div className="category">
          <div className="cate-title">
            원본 사진 제공
            <Switch
              disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
              className={classNames({ readonly: !edit })}
              value={config.save_original_id_image}
              onChange={(value) => setState({ type: 'config', payload: { save_original_id_image: !!value } })}
            />
          </div>
        </div>
        <div className="category">
          <div className="cate-title">
            신분증 진위확인
            <Switch
              disabled={!edit || !module.id_card_ocr}
              className={classNames({ readonly: !edit })}
              value={module.id_card_verification}
              onChange={(value) => setState({ type: 'module', payload: { id_card_verification: !!value } })}
            />
          </div>
        </div>

        <div className="category">
          <div className="cate-title">
            신분증 사본판별
            <Switch
              disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
              className={classNames({ readonly: !edit })}
              value={config.allow_ssa}
              onChange={(value) => setState({ type: 'config', payload: { allow_ssa: !!value } })}
            />
          </div>
        </div>
        <div className="category">
          <div className="cate-title">
            <div className="flex-box">
              <p>신분증 얼굴 추출</p>
              <Popover
                clickOutside
                position="right bottom"
                content={() => (
                  <div id="root-page-client-expand-popover" className="description">
                    얼굴 인증이 활성 된 경우 항상 사용, <br />
                    신분증 인증만 활성 된 경우 선택사용이 가능합니다.
                  </div>
                )}
              >
                <i className="tooltip" />
              </Popover>
            </div>

            <Switch
              disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
              className={classNames({ readonly: !edit })}
              value={config.allow_idcard_face_detect}
              onChange={(value) => setState({ type: 'config', payload: { allow_idcard_face_detect: !!value } })}
            />
          </div>
        </div>

        <ServerOcr data={data} edit={edit} setState={setState} />
        <WasmOcr data={data} edit={edit} setState={setState} />
        <ManualShot data={data} edit={edit} setState={setState} />

        <div className="category border">
          <div className="cate-title">신분증 설정</div>
          <div className="checkbox-list">
            {toPairs(idCard).map(([key, value]) => {
              return (
                <CheckBox
                  key={key}
                  disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
                  text={value.description}
                  checked={value.active ? 1 : 0}
                  className={classNames({ readonly: !edit })}
                  onChange={(check) => {
                    const rest = some(values(omit(idCard, key)), ['active', true]);
                    if (!rest) set({ fail: '최소 1개 이상은 필수 선택입니다.' });
                    else setState({ type: 'idCard', payload: { [key]: { ...value, active: !!check } } });
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
