import React from 'react';
import './SubController.scss';

interface Props {
  title: React.ReactNode;
  children?: React.ReactNode;
}

export const SubController = (props: Props) => {
  return (
    <div className="alchera-content-sub-controller">
      <div className="control-field">
        <h4>{props.title}</h4>
        <div className="addon-field">{props.children}</div>
      </div>
    </div>
  );
};
