import React from 'react';
import classNames from 'classnames';
import { Button, Loading, Validator } from 'common/components';
import styled, { css } from 'styled-components';
import { margin } from 'common/functions';
import './Layout.scss';

type Margin = { margin?: string; marginTop?: number; marginBottom?: number; marginLeft?: number; marginRight?: number };
type Styles = { optional?: boolean; styles?: { fontSize?: number; fontWeight?: number } };

export const Heading = styled.h3
  .attrs(() => ({ className: 'modal-heading' }))
  .withConfig({ shouldForwardProp: (prop) => !['optional'].includes(prop) })<Styles & Margin>`
  display: flex;
  align-items: center;
  font-size: ${({ styles }) => `${styles?.fontSize ?? 14}px`};
  margin-bottom: 10px;
  font-weight: ${({ styles }) => `${styles?.fontWeight ?? 400}`};
  color: ${({ theme }) => theme['$surface-medium']};

  ${(props) => {
    return margin(props);
  }}

  ${({ optional }) => {
    if (optional) {
      return css`
        &::after {
          display: block;
          content: '(선택)';
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #868686;
          padding-left: 6px;
        }
      `;
    }
    return '';
  }}
`;

export const Header = styled.div
  .attrs(() => ({ className: 'modal__header' }))
  .withConfig({ shouldForwardProp: (prop) => !['align'].includes(prop) })<{
  align?: string;
  fontSize?: number;
  fontWeight?: number;
}>`
  justify-content: ${({ align }) => align || 'initial'};
  font-size: ${({ fontSize }) => `${fontSize || 24}px`};
  font-weight: ${({ fontWeight }) => fontWeight || 700};
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 92px;
  padding: 40px 40px 16px;
`;

export const ModalContainer = styled.div<{
  width?: number | string;
  height?: number;
  maxHeight?: number;
  overflow?: string;
}>`
  width: ${({ width }) => {
    if (typeof width === 'number') return `${width}px`;
    return width;
  }};

  height: ${({ height }) => {
    if (typeof height === 'number') return `${height}px`;
    return height;
  }};

  .modal__content__form {
    position: relative;
    overflow: ${({ overflow }) => overflow ?? 'auto'};
    ${({ height }) => {
      if (height && height - 152 > 0) {
        return css`
          height: ${height - 152}px;
        `;
      }
      return '';
    }}

    ${({ maxHeight }) => {
      if (maxHeight) {
        return css`
          max-height: ${maxHeight}px;
        `;
      }
      return '';
    }}
  }
`;

interface Container {
  children: React.ReactNode;
  id?: string;
  className?: string;
  styles?: { width?: number | string; height?: number; maxHeight?: number; overflow?: string };
}

export const Container: React.FC<Container> = ({ children, id, className = '', styles: propsStyles }) => {
  const styles = { width: 500, ...propsStyles };
  return (
    <ModalContainer id={id} {...styles} className={classNames('modal__basic__wrapper', { [className]: className })}>
      {children}
    </ModalContainer>
  );
};

interface Content {
  loading?: boolean;
  children?: React.ReactNode;
}

export const Content: React.FC<Content> = ({ loading, children }) => {
  return (
    <div className="modal__content__form">
      {loading && <Loading />}
      <div className="modal__content">{children}</div>
    </div>
  );
};

interface Footer {
  disabled?: boolean;
  loading?: boolean;
  submitText?: string;
  cancelText?: string;
  closeHandler?: () => void;
  submitHandler?: (e: React.UIEvent) => void;
}

export const Footer: React.FC<Footer> = ({
  disabled = false,
  loading = false,
  submitText = '확인',
  cancelText = '취소',
  closeHandler = () => false,
  submitHandler,
}) => {
  return (
    <div className="modal__footer">
      <Button
        type="button"
        text={cancelText}
        styles={{ radius: 0 }}
        className="footer__cancel"
        onClick={closeHandler}
      />
      {!submitHandler ? (
        <Validator.Submit
          className="footer__submit"
          styles={{ radius: 0 }}
          disabled={disabled}
          text={submitText}
          loading={loading}
        />
      ) : (
        <Button
          styles={{ radius: 0 }}
          className="footer__submit"
          type="button"
          text={submitText}
          loading={loading}
          disabled={disabled}
          onClick={(e: React.UIEvent): void => {
            if (!disabled) submitHandler(e);
          }}
        />
      )}
    </div>
  );
};
