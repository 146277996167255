import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Modal, Popover } from 'common/components';
import { isString } from 'lodash';
import { getReviewResultsId } from 'common/api';
import type { T_IdCard } from '../../Expand';
import type { Data as RowData } from '../../../CtResultTable';
import * as Info from './info';
import './IdCard.scss';

export type Ocr = {
  expiryDate: string;
  expiryDate2: string;
  driverNo: string;
  idType: string;
  issueDate: string;
  juminNo1: string;
  juminNo2: string;
  _juminNo2: string;
  issueNo1: string;
  issueNo2: string;
  _issueNo2: string;
  transaction_id: string;
  passportNo: string;
  userName: string;
  nationality: string;
  birthDate: string;
  id_real: boolean;
  id_confidence: string;
};

interface Props {
  content: NonNullable<T_IdCard>;
  rowData: RowData;
  module: typeof getReviewResultsId.res.module;
  isExpired: boolean;
}

export const dateFormatter = (date: string): string => {
  if (date.length !== 8) {
    return date;
  }
  return `${date.slice(0, 4)}.${date.slice(4, 6)}.${date.slice(6, 8)}`;
};

const IdCard: React.FC<Props> = ({ content, rowData, module, isExpired }) => {
  const [mask, setMask] = useState(true);
  const { is_uploaded, is_manual_input, original_ocr_data, modified_ocr_data, verified, id_card_image, id_card_origin, uploaded_type, id_crop_image } = content; // prettier-ignore

  let origin: Ocr = {
    expiryDate: '',
    expiryDate2: '',
    driverNo: '',
    idType: '9',
    issueDate: '',
    juminNo1: '',
    juminNo2: '',
    _juminNo2: '',
    issueNo1: '',
    issueNo2: '',
    _issueNo2: '',
    transaction_id: '',
    passportNo: '',
    userName: '',
    nationality: '',
    birthDate: '',
    id_real: false,
    id_confidence: '',
  };
  let modified: Ocr | null = null;
  if (!isExpired) {
    origin = JSON.parse(`${original_ocr_data}`.replaceAll('\\', ''));
    modified = JSON.parse(`${modified_ocr_data}`.replaceAll('\\', ''));
  }
  const idType = modified ? modified.idType : origin.idType;

  const stringTypes = (idType: string): string => {
    // prettier-ignore
    switch (String(idType)) {
      case '1': return '주민등록증';
      case '2': return '운전면허증';
      case '3': return '한국 여권';
      case '4': return '외국 여권';
      case '5' : return '외국인등록증(국내거소증/영주증)';
      case '5-1': return '외국인등록증';
      case '5-2': return '거소신고증';
      case '5-3': return '영주증';
      case '9': return '저장 기간 만료';
      default: return '미등록';
    }
  };

  // ['주민등록증', '주민등록번호']
  // ['운전면허증', '주민등록번호']
  // ['한국 여권', '주민등록번호']
  // ['외국 여권', '주민등록번호']
  // ['외국인등록증', '외국인등록번호']
  // ['거소신고증', '거소신고번호']
  // ['영주증', '외국인등록번호']
  // ['미등록', '미등록']

  // 변경한 데이터인지 확인
  const isModified = (keys: (keyof Ocr)[] | keyof Ocr): boolean => {
    if (!modified) return false;
    if (isString(keys)) return origin[keys] !== modified[keys];
    return !!keys.some((k) => modified && origin[k] !== modified[k]);
  };

  const uploadType = (uploaded_type: string) => {
    if (uploaded_type === 'mobile') return '모바일';
    if (uploaded_type === 'pc') return uploaded_type.toUpperCase();
    if (uploaded_type === 'camera') return '카메라';
    return uploaded_type;
  };

  const showOriginalImage = () => {
    if (is_manual_input) return false; // 직접 입력은 원본이미지 제공X
    if (!id_card_origin) return false; // 신분증 OCR 원본이미지가 없으면 제공X
    if (idType === '4') return false; // 해외 여권은 뒷번호가 없기 때문에 제공X
    if (idType === '3' && modified !== null && !modified.juminNo2) return false; // 국내 여권 중 주민번호 뒷자리가 없는 신여권은 제공X
    return true;
  };

  const idcardMimeType = (base64: string) => {
    const regMimeType = /data:image\/(jpe?g|png|gif);base64,/i;
    const result = regMimeType.exec(base64);
    const mimeType = `data:image/${result ? result[1] : 'jpeg'};base64,`;
    if (result) return base64;
    return mimeType + base64;
  };

  const props = { origin, modified, rowData, isModified };

  return (
    <div id="reviewer-result-table-expand-content-idcard">
      <ul className="info-section">
        <li className="sub-title">심사 세부정보</li>
        <li>
          <h4>신분증 정보</h4>
          {idType === '5' ? (
            <div>
              <p>외국인등록증</p>
              <p>(국내거소증/영주증)</p>
            </div>
          ) : (
            <p>{stringTypes(modified ? modified.idType : origin.idType)}</p>
          )}
        </li>
        <li>
          <h4>사진 첨부 여부</h4>
          <p className={classNames({ warning: is_uploaded })}>
            {is_uploaded ? 'YES' : 'NO'}
            {is_uploaded && uploaded_type ? <span className="upload-type">{uploadType(uploaded_type)}</span> : <></>}
          </p>
        </li>
        <li>
          <h4>직접 입력 여부</h4>
          <p className={classNames({ warning: is_manual_input })}> {is_manual_input ? 'YES' : 'NO'}</p>
        </li>
        <li>
          <h4>정보 수정 여부</h4>
          <p className={classNames({ warning: modified })}>{modified ? 'YES' : 'NO'}</p>
        </li>

        {isExpired ? (
          <li className="id-info">
            <p className="id-expired">저장 기간 만료로 인해 개인 정보가 삭제되었습니다.</p>
          </li>
        ) : (
          <li className="id-info">
            {idType === '1' && <Info.ID {...props} />}
            {idType === '2' && <Info.DriverLicense {...props} />}
            {idType === '3' && <Info.PassPort {...props} />}
            {idType === '4' && <Info.Foreign.PassPort {...props} />}
            {['5', '5-1', '5-2', '5-3'].includes(idType) && <Info.Foreign.ID {...props} idType={idType} />}
          </li>
        )}

        <li>
          <h4>신분증 진위확인</h4>
          {(!module?.id_card_verification && <p className={classNames({ warning: !verified })}>미사용</p>) || (
            <p className={classNames({ warning: !verified })}>{verified ? '확인' : '실패'}</p>
          )}
        </li>
        {!!origin.id_confidence && (
          <li className="ssa-info">
            <h4>신분증 사본판별</h4>
            <div className={classNames('ssa-info-value', { real: origin.id_real, fake: !origin.id_real })}>
              <div className="ssa-info-value-flex">
                <p>신뢰도 </p>
                <Popover
                  className="tooltip-ssa-example"
                  content={() => (
                    <div className="ssa-popover-white">
                      신뢰도 구간은 0.0 ~ 1.0 이며, 1.0에 가까울수록 원본과 부합하다고 판단할 수 있습니다.
                    </div>
                  )}
                >
                  <i className="icon-ex-mark" />
                </Popover>
              </div>
              <p>{origin.id_confidence}</p>
              <p>{origin.id_real ? 'REAL' : 'FAKE'}</p>
            </div>
          </li>
        )}
      </ul>

      <section className="image-section">
        <h5 className="image-title">신분증 사진</h5>
        {isExpired ? (
          <div className="image-expired">
            <i />
          </div>
        ) : (
          <>
            <Modal
              content={({ closeHandler }) => {
                return (
                  <div className="reviewer-image-section-modal">
                    <i className="close-btn" onClick={closeHandler} />
                    <img
                      style={{ width: '100%' }}
                      src={idcardMimeType(mask ? id_card_image : id_card_origin)}
                      alt="id-card"
                    />
                  </div>
                );
              }}
            >
              <div className="image-field">
                <img src={idcardMimeType(mask ? id_card_image : id_card_origin)} alt="id-card" />
                <i />
              </div>
            </Modal>

            {showOriginalImage() && (
              <Button text={mask ? '원본 사진 보기' : '마스킹 사진 보기'} onClick={() => setMask(!mask)} />
            )}
          </>
        )}
        {id_crop_image && (
          <>
            <h5 className="image-title">얼굴 사진</h5>
            {props.rowData.name === 'EXPIRED' ? (
              <div className="id-crop-img-expired">
                <i />
              </div>
            ) : (
              <div className="id-crop-img">
                <img src={idcardMimeType(id_crop_image)} alt="id-card" />
              </div>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default IdCard;
