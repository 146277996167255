import React, { Dispatch, useState } from 'react';
import { api, process } from 'common/functions';
import { Alert } from 'common/components';
import classNames from 'classnames';
import { Action, State } from '../../../ExaminationContent';
import EditComp from '../../edit/EditComp';
import './AutoScreening.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const AutoScreening = ({ state, setState, loadHandler }: Props) => {
  const { vas } = state.data;
  const { allow_auto_approve } = vas;
  const { set } = Alert.Context();
  const [edit, setEdit] = useState(false);

  const submitHandler = async () => {
    await process(
      api.reqData({ url: 'configs', data: { allow_auto_approve: String(allow_auto_approve) } }),
      api.put.request,
      api.fullFilled((response) => {
        setEdit(false);
        loadHandler();
        if (response) {
          set({ success: allow_auto_approve ? '자동심사가 설정되었습니다.' : '자동심사가 중지되었습니다.' });
        } else {
          set({ fail: '자동심사가 설정 변경에 실패하였습니다.' });
        }
      })
    );
  };

  const handler = { loadHandler, submitHandler };

  return (
    <section className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: allow_auto_approve })}>자동심사 사용</h4>
            <p>자동심사를 사용하지 않으면 모든 고객 신원 확인심사는 수동으로 진행합니다.</p>
          </div>
        </div>
        <EditComp
          onSwitch
          setEdit={setEdit}
          edit={edit}
          setState={setState}
          handler={handler}
          data={{ key: 'allow_auto_approve', value: allow_auto_approve }}
        />
      </div>
    </section>
  );
};

export default AutoScreening;
