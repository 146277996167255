import React, { ChangeEvent, Dispatch, useEffect, useState } from 'react';
import { CheckBox, Input } from 'usebeauty';
import Daily from 'common/components/time/Daily';
import { cx, dateFormatter } from 'common/functions';
import { useDateFilterContext } from '../popover/Pop_DateFilter';
import { TimeInput } from './TimeInput';
import './SetPeriod.scss';

type DateValue = { start: string; end: string };

interface Props {
  onChange: (value: DateValue) => void;
  value: DateValue;
  err: { start: string; end: string };
  setErr: Dispatch<{ start: string; end: string }>;
}

export const SetPeriod = (props: Props) => {
  const { value, onChange, err, setErr } = props;
  const [start, setStart] = useState(value.start);
  const [end, setEnd] = useState(value.end);
  const { firstDate } = useDateFilterContext();

  const [startDate, startDateTime] = start.split('T');
  const [endDate, endDateTime] = end.split('T');

  const [includeTime, setIncludeTime] = useState(!!(startDateTime && endDateTime));

  const repalaceValue = (value: string) => value.replace(/[^0-9]/g, '').replace(/(.*)/g, '$1');
  const firstDateText = `${firstDate.split('-')[0]}년 ${firstDate.split('-')[1]}월 01일`;

  const isDate = (date: string, range: 'start' | 'end') => {
    const first = new Date(firstDate);
    const current = new Date();
    const value = new Date(date);
    if (date.length === 10) {
      const isPast = value < first ? `${firstDateText} 보다 지나야 합니다.` : '';
      const isFuture = value > current ? '현재 날짜보다 전이여야 합니다.' : '';
      setErr({ ...err, [range]: isPast || isFuture });
    }
    if (date.length === 0) setErr({ ...err, [range]: '' });
  };
  const startInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedDate = dateFormatter(repalaceValue(value));
    isDate(formattedDate, 'start');
    setStart(includeTime ? `${formattedDate}T${startDateTime}` : formattedDate);
  };

  const endInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedDate = dateFormatter(repalaceValue(value));
    isDate(formattedDate, 'end');
    setEnd(includeTime ? `${formattedDate}T${endDateTime}` : formattedDate);
  };

  useEffect(() => {
    onChange({ start, end });
  }, [start, end]);

  return (
    <div className="dashboard-set-period">
      <Daily
        onChange={(time) => {
          const { start, end } = time;
          setStart(includeTime ? `${start}T${startDateTime}` : start);
          setEnd(includeTime ? `${end}T${endDateTime}` : end);
        }}
        value={{ start, end }}
      />
      <div className={cx('date-input-row', { time: includeTime })}>
        <div className="start-date">
          <div className="date">
            <span className="label">시작일</span>
            <Input
              value={startDate}
              onChange={startInputHandler}
              className={cx({ error: err.start })}
              maxLength={10}
              placeholder="YYYY-MM-DD"
            />
            {!!err.start && (
              <div className="err">
                <p>{err.start}</p>
              </div>
            )}
          </div>
          {includeTime && (
            <div className="time">
              <span className="label">시작시간</span>
              <TimeInput
                value={startDateTime ?? '00:00'}
                onChange={(time) => {
                  setStart(`${startDate}T${time.hour}:${time.minute}`);
                }}
              />
            </div>
          )}
        </div>
        <div className="end-date">
          <div className="date">
            <span className="label">종료일</span>
            <Input
              value={endDate}
              className={cx({ error: err.end })}
              onChange={endInputHandler}
              maxLength={10}
              placeholder="YYYY-MM-DD"
            />
            {!!err.end && (
              <div className="err">
                <p>{err.end}</p>
              </div>
            )}
          </div>
          {includeTime && (
            <div className="time">
              <span className="label">종료시간</span>
              <TimeInput
                value={endDateTime ?? '23:59'}
                onChange={(time) => {
                  setEnd(`${endDate}T${time.hour}:${time.minute}`);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="time-input-row">
        <CheckBox
          className="time-input-checkbox"
          label="시간포함"
          checked={includeTime}
          onChange={(e) => {
            setIncludeTime(e.target.checked);
            if (!e.target.checked) {
              setStart(startDate);
              setEnd(endDate);
            }
          }}
        />
      </div>
    </div>
  );
};
