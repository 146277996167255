import React from 'react';
import './Forbidden.scss';

interface Props {
  content: JSX.Element;
}
const Forbidden = (props: Props) => {
  const { content } = props;
  return (
    <div className="admin-manage-edd-forbidden">
      <i className="admin-manage-edd-forbidden-icon" />
      {content}
    </div>
  );
};

export default Forbidden;
