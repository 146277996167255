import { useState } from 'react';
import { cloneDeep } from 'lodash';
import uuid from 'react-uuid';
import type { TreeNode } from './TreeViewContainer';

const MAX_DEPTH = 2;
const newItem: Omit<TreeNode, 'id'> = { name: '', edd_value: 0, edit: false, depth: 0, expand: true, children: [] };

const DFS = (arg: {
  self: TreeNode;
  parent?: TreeNode;
  find: (arg: { self: TreeNode; parent?: TreeNode }) => boolean;
  then: (arg: { target: TreeNode; parent?: TreeNode }) => void | TreeNode;
}): TreeNode | null => {
  const { self, find, then, parent } = arg;
  const target = find({ self, parent });
  if (target) return then({ target: self, parent }) ?? null;

  for (const child of self.children) {
    const treeNode = DFS({ self: child, find, then, parent: self });
    if (treeNode) return treeNode;
  }
  return null;
};

export const useTreeView = (data: TreeNode[] = []) => {
  const [treeData, setTreeData] = useState<TreeNode[]>(data);
  const [selectedNode, setSelectedNode] = useState<TreeNode | null>(null);
  const [activeNode, setActiveNode] = useState<TreeNode | null>(null);

  const treeAddDisabled = selectedNode?.depth === MAX_DEPTH || selectedNode?.edit;
  const treeDeleteDisabled = !selectedNode || selectedNode?.edit;

  return {
    treeData,
    setTreeData,
    selectedNode,
    setSelectedNode,
    activeNode,
    setActiveNode,
    // #############################
    treeAddDisabled,
    treeDeleteDisabled,
  };
};

type Args = { data: TreeNode[]; target: TreeNode | null };
export const tree = {
  add: (args: Args) => {
    const newNode = { ...newItem, id: uuid() };
    const newTreeData = cloneDeep(args.data);

    // 새로운 노드를 추가할 대상이 있는지 확인
    for (const item of newTreeData) {
      const result = DFS({
        self: item,
        find: ({ self }) => self.id === args.target?.id,
        then: ({ target }) => {
          newNode.depth = target.depth + 1;
          target.children.push(newNode);
        },
      });
      // result 가 있다면 새로운 노드를 추가할 대상이 있음
      if (result) args.target = result;
    }

    // 새로운 노드를 추가할 대상이 없다면 최상단에 node추가
    if (!args.target) newTreeData.push(newNode);

    return { treeData: newTreeData, node: newNode };
  },
  delete: (args: { data: TreeNode[]; target: TreeNode }) => {
    const newTreeData = cloneDeep(args.data);

    let parentNode: TreeNode | null = null;

    if (args.target.depth === 0) {
      const removeIndex = newTreeData.findIndex((el) => el.id === args.target.id);
      newTreeData.splice(removeIndex, 1);
    } else {
      // 새로운 노드를 제거할 대상이 있는지 확인
      for (const item of newTreeData) {
        DFS({
          self: item,
          find: ({ self }) => self.id === args.target?.id,
          // eslint-disable-next-line no-loop-func
          then: ({ target, parent }) => {
            if (parent) {
              parent.children = parent.children.filter((el) => el.id !== target.id);
              parentNode = parent ?? null;
            }
          },
        });
      }
    }

    return { treeData: newTreeData, parent: parentNode };
  },
  update: (args: { data: TreeNode[]; target: TreeNode; value: string }) => {
    const newTreeData = cloneDeep(args.data);

    for (const item of newTreeData) {
      DFS({
        self: item,
        find: ({ self }) => self.id === args.target.id,
        then: ({ target }) => {
          // target.title = args.value;
          // target.value = args.value;
          target.name = args.value;
          return target;
        },
      });
    }

    return { treeData: newTreeData, node: args.target };
  },
  expand: (args: { data: TreeNode[]; target: TreeNode }) => {
    const newTreeData = cloneDeep(args.data);

    for (const item of newTreeData) {
      DFS({
        self: item,
        find: ({ self }) => self.id === args.target.id,
        then: ({ target }) => {
          target.expand = !target.expand;
          return target;
        },
      });
    }

    return { treeData: newTreeData };
  },
};
