import React, { useContext, useEffect, useReducer } from 'react';
import { getConfigs, getModules } from 'common/api';
import { parseConfigs } from 'common/functions';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { NotAllowedPage } from 'common/components';
import { Config } from '../../models/model.types';
import AccountSetting from './setting/AccountSetting';
import './AccountContent.scss';

export type VAS = Config.Authentication.Account;
export type Data = { vas: VAS; defaultVas: VAS };
export type State = { data: Data; module: { account_verification: boolean } };

const initVas: VAS = {
  allow_change_account: false,
  max_change_account: 1,
  allow_account_subcode: false,
  use_account_subcode: false,
  account_subcode: '',
  account_subcode_position: 'right',
};
const initData: Data = {
  vas: { ...initVas },
  defaultVas: { ...initVas },
};

const initialState: State = {
  data: initData,
  module: { account_verification: false },
};

export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'module'; payload: { account_verification: boolean } }
  | { type: 'vas'; payload: AtLeastOne<VAS> }
  | { type: 'defaultVas'; payload: AtLeastOne<VAS> }
  | { type: 'resetVas'; payload: AtLeastOne<VAS> }
  | { type: 'reset'; payload: { key: keyof VAS } }
  | { type: 'edit'; payload: boolean }
  | { type: 'kDriver'; payload: boolean };

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case "setData": draft['data'] = {...state.data, ...action.payload};break;
      case "module": draft['module'] = action.payload; break;
      case 'vas': draft['data']['vas'] = {...state.data.vas, ...action.payload}; break;
      case 'defaultVas': draft['data']['defaultVas'] = {...state.data.vas, ...action.payload}; break;
      case 'reset': draft['data']['vas'] = { ...draft.data.vas, [action.payload.key]: draft.data.defaultVas[action.payload.key] }; break; 
    }
  });
};

const AccountContent: React.FC = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);
    try {
      const modules = await getModules();
      setState({ type: 'module', payload: { account_verification: modules.account_verification } });
      if (modules.account_verification) {
        const config = await getConfigs();
        const vas = parseConfigs(config, Object.keys(initVas) as Array<keyof VAS>);
        const data: Data = { ...initData, vas, defaultVas: vas };
        setState({ type: 'setData', payload: data });
      }
    } catch (e) {
      console.error('AccountContent error', { e });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-account-content">
      {state.module.account_verification ? (
        <AccountSetting state={state} setState={setState} loadHandler={loadHandler} />
      ) : (
        <NotAllowedPage />
      )}
    </div>
  );
};

export default AccountContent;
