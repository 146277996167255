import React from 'react';
import { Controller, FieldPath, FieldValues, PathValue, UseControllerProps } from 'react-hook-form';
import { Box, Error, Toggle } from '@usebeauty';
import { N_Toggle } from 'usebeauty/dist/@types';

function F_Toggle<T extends FieldValues, U extends FieldPath<T>>(
  props: UseControllerProps<T, U> &
    N_Toggle.Props & {
      onChange?: (value: PathValue<T, U>) => void;
    }
) {
  return (
    <Controller
      rules={props.rules}
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Box className="form__multitoggle__element" st={props.st}>
            <Toggle
              label={props.label}
              st={props.st}
              disabled={props.disabled}
              selected={field.value}
              className={props.className}
              gridAutoRows={props.gridAutoRows}
              columns={props.columns}
              onChange={(value) => {
                const v = value as PathValue<T, U>;
                field.onChange(v);
                if (props.onChange) props.onChange(v);
              }}
            >
              {props.children}
            </Toggle>
            <Error>{error?.message}</Error>
          </Box>
        );
      }}
    />
  );
}

F_Toggle.Item = Toggle.Item;

export default F_Toggle;
