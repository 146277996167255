import React from 'react';
import { NullishGuard } from 'hoc';
import { Data } from '../CtLabelHistoryTable';

interface Props {
  rowData: Data;
}
function Expand(props: Props) {
  const { rowData } = props;
  const { con_add_log, con_del_log, active_log, label_name_before, label_name, score_log } = rowData;

  return (
    <article className="root-eddra-label-history-expand">
      <NullishGuard data={[con_add_log]}>
        {([con_add_log]) => (
          <div className="rowData country__add">
            <span className="label">국가 추가</span>
            <span className="value">{con_add_log.map((c) => c.name_kr).join(', ')}</span>
          </div>
        )}
      </NullishGuard>
      <NullishGuard data={[con_del_log]}>
        {([con_del_log]) => (
          <div className="rowData country__delete">
            <span className="label">국가 삭제</span>
            <span className="value">{con_del_log.map((c) => c.name_kr).join(', ')}</span>
          </div>
        )}
      </NullishGuard>
      <NullishGuard data={[active_log]}>
        {([active_log]) => (
          <div className="rowData">
            <span className="label">활성 여부</span>
            <span className="value">{active_log === 1 ? '비활성 → 활성' : '활성 → 비활성'}</span>
          </div>
        )}
      </NullishGuard>
      <NullishGuard data={[label_name_before, label_name]}>
        {([label_name_before, label_name]) => (
          <div className="rowData">
            <span className="label">라벨명 수정</span>
            <span className="value">
              {label_name_before} → {label_name}
            </span>
          </div>
        )}
      </NullishGuard>
      <NullishGuard data={[score_log]}>
        {([score_log]) => (
          <div className="rowData">
            <span className="label">점수 수정</span>
            <span className="value">{score_log.map((s) => `${s}점`).join(' → ')}</span>
          </div>
        )}
      </NullishGuard>
    </article>
  );
}

export default Expand;
