import { ExaminationPage } from './manage/authentication/ExaminationPage';
import { IdcardPage } from './manage/authentication/IdcardPage';
import { AccountPage } from './manage/authentication/AccountPage';

import { CompareSettingPage } from './manage/authentication/face/CompareSettingPage';
import { CompareLogPage } from './manage/authentication/face/CompareLogPage';
import { LivenessSettingPage } from './manage/authentication/face/LivenessSettingPage';
import { LivenessLogPage } from './manage/authentication/face/LivenessLogPage';

import { RaPage } from './manage/edd/RaPage';
import { WeightPage } from './manage/edd/WeightPage';
import { RealScorePage } from './manage/edd/RealScorePage';
import { CriterionPage } from './manage/edd/CriterionPage';
import { CustomFieldPage } from './manage/edd/CustomFieldPage';

import { GeneralPage } from './manage/other/GeneralPage';
import { AuthenticatePage } from './manage/other/AuthenticatePage';
import { SecurityPage } from './manage/other/SecurityPage';
import { ThemeSettingPage } from './manage/other/ThemeSettingPage';

const Face = { CompareSettingPage, CompareLogPage, LivenessSettingPage, LivenessLogPage };
const Edd = { RaPage, WeightPage, CriterionPage, CustomFieldPage, RealScorePage };

export { LoginPage } from './login/LoginPage';
export { ReviewerPage } from './reviewer/ReviewerPage';
export { TermsPage } from './terms/TermsPage';

const Authentication = { ExaminationPage, IdcardPage, AccountPage, Face, Edd };
const Other = { GeneralPage, AuthenticatePage, SecurityPage, ThemeSettingPage };

export const Manage = { Authentication, Other };
