import React from 'react';
import './404.scss';

const Unknown: React.FC = () => {
  return (
    <div id="card-result-fail-root">
      <div className="card-result-fail-info-container">
        {/* <img src="@/assets/얼굴_실패.svg" width="103px" /> */}
        <i className="error-icon" />
        <div className="error-message">일시적 오류 발생</div>

        <div className="description">
          잘못된 경로 또는 비정상적 접근이 감지되어
          <br />
          고객 신원 확인을 종료합니다.
          <br />
          잠시 후 다시 시도해주세요.
        </div>

        <div className="contact">
          동일한 문제가 지속적으로 발생한다면
          <br />
          아래 메일로 문의주시기 바랍니다.
          <br />
          <a href="mailto:cs@useb.co.kr" className="mail">
            cs@sample.com
          </a>
        </div>
      </div>
      {/* <div className="button-container" onClick={window.close}>
        확인
      </div> */}
    </div>
  );
};

export default Unknown;
