import React, { Dispatch } from 'react';
import { Table } from 'usebeauty';
import { Controller } from 'common/components';
import classNames from 'classnames';
import type { Action, State } from './CtTermsTable';
import Expand from './expand/Expand';
import './TermsTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
}

const TermsTable: React.FC<Props> = ({ state, setState }): JSX.Element => {
  const { header, data } = state;

  return (
    <section id="admin-terms-table">
      <Controller title="약관 가이드" />
      <Table>
        <Table.Head data={header} />
        <Table.Body data={data}>
          {({ index, rowData }) => {
            return (
              <Table.Row key={rowData.id} st={{ height: rowData.expand ? 'auto' : 72 }}>
                <>
                  <Table.Tr data={rowData}>
                    {({ value, property }) => {
                      if (property === 'expand')
                        return (
                          <i
                            className={classNames('expand-icon', { expanded: rowData.expand })}
                            onClick={() => setState({ type: 'expand', payload: index })}
                          />
                        );
                      return value as string;
                    }}
                  </Table.Tr>
                  {rowData.expand && <Expand rowData={rowData} />}
                </>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
    </section>
  );
};

export default TermsTable;
