import React, { Dispatch, useState } from 'react';
import { api, process } from 'common/functions';
import { Alert, Promotion } from 'common/components';
import classNames from 'classnames';
import { Action, State } from '../../../IdcardContent';
import EditComp from '../../edit/EditComp';
import WamsManualShotOption from './option/WamsManualShotOption';
import WasmWarpingOpion from './option/WasmWarpingOpion';
import './WasmOcr.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const WasmOcr = ({ state, setState, loadHandler }: Props) => {
  const { vas } = state.data;
  const { use_wasm_ocr, allow_wasm_ocr, ocr_auto_switching, use_wasm_image_warping } = vas;
  const { set } = Alert.Context();
  const [edit, setEdit] = useState(false);

  const submitHandler = async () => {
    const data = {
      use_wasm_ocr: String(use_wasm_ocr),
      ocr_auto_switching: use_wasm_ocr ? String(ocr_auto_switching) : undefined,
      use_wasm_image_warping: use_wasm_ocr ? String(use_wasm_image_warping) : undefined,
    };
    await process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled((response) => {
        setEdit(false);
        loadHandler();
        if (response) {
          set({ success: `WASM OCR이 ${use_wasm_ocr ? '설정' : '중지'}되었습니다.` });
        } else {
          set({ fail: `WASM OCR 설정 변경에 실패하였습니다.` });
        }
      })
    );
  };

  const handler = { loadHandler, submitHandler };

  return (
    <section id="idcard-setting-wasm-ocr" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className={classNames({ active: use_wasm_ocr })}>WASM OCR 사용</h4>
            <p>
              WebAssembly(웹어셈블리) 방식입니다.
              <br />
              모바일 앱에서만 가능했던 기능들을 웹에서도 제공함으로써 고객의 사용성을 향상시킬 수 있습니다.
            </p>
          </div>
        </div>
        <EditComp
          isAllow={allow_wasm_ocr}
          onSwitch
          setEdit={setEdit}
          edit={edit}
          setState={setState}
          handler={handler}
          data={{ key: 'use_wasm_ocr', value: use_wasm_ocr }}
        />
      </div>
      {use_wasm_ocr && allow_wasm_ocr && (
        <div className="description">
          {edit ? (
            <div className="edit-option">
              <WamsManualShotOption state={state} setState={setState} />
              <WasmWarpingOpion state={state} setState={setState} />
            </div>
          ) : (
            <div className="display-option">
              <div className="judge-type-box">
                <div className="flex-box">
                  <div className="title">{ocr_auto_switching ? 'OCR 방식 자동선택' : 'OCR 방식 수동선택'}</div>
                  <p>
                    {ocr_auto_switching
                      ? '저사양 기기로 예상되는 경우, 자동으로 Server OCR을 사용합니다'
                      : 'Server 수동촬영 전환버튼이 제공됩니다. 사용자가 촬영방식을 선택할 수 있습니다.'}
                  </p>
                </div>
              </div>
              <div className="judge-type-box">
                <div className="flex-box">
                  <div className="title">신분증 촬영 왜곡 보정 {use_wasm_image_warping ? '사용' : '사용 안 함'}</div>
                  <p>
                    {use_wasm_image_warping
                      ? '신분증 원본 사진이 기울어져 찍힌 경우 왜곡 보정 (Warping) 하여 저장합니다.'
                      : '원본 사진 그대로 저장합니다.'}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!allow_wasm_ocr && <Promotion />}
    </section>
  );
};

export default WasmOcr;
