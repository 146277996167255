import React, { useReducer } from 'react';
import { produce } from 'immer';
import { KycLoading, M_Table } from 'common/components';
import { getWarnLabelLogs } from 'common/api';
import { useKycQuery } from 'common/api/usequery';
import HistoryTable from './HistoryTable';

export type Data = typeof getWarnLabelLogs.res;
export type Action = { type: 'data'; payload: Data[] } | { type: 'expand'; payload: number };
export type State = {
  data: Data[];
  header: M_Table.header[];
};

const initialState: State = {
  data: [],
  header: [
    { title: '라벨', property: 'label_name', flex: 2 },
    { title: '변경 내역', property: 'log_type', flex: 3 },
    { title: 'ID', property: 'root_name', flex: 1 },
    { title: '변경 일시', property: 'created_at', flex: 1 },
    { title: '', property: 'expand', flex: 0.5 },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'data': draft['data'] = action.payload; break;
      case 'expand': draft['data'][action.payload]['expand'] = !draft['data'][action.payload]['expand']; break;
    }
  });
};

function CtLabelHistoryTable() {
  const [state, setState] = useReducer(reducer, initialState);

  const { isFetching } = useKycQuery<{ list: (typeof getWarnLabelLogs.res)[]; total_count: number }>({
    key: ['getWarnLabelLogs'],
    handler: () => getWarnLabelLogs(),
    options: {
      onSuccess: (response) => {
        const { list } = response.data;

        if (list) {
          const payload = list.map((el: Omit<Data, 'expand'>) => ({ ...el, expand: false }));
          setState({ type: 'data', payload });
        }
      },
    },
  });

  return (
    <>
      {isFetching && <KycLoading />}
      <HistoryTable state={state} setState={setState} />
    </>
  );
}

export default CtLabelHistoryTable;
