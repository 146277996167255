import React, { Dispatch } from 'react';
import { Action, Data } from '../../../ThemeSettingContent';
import Chip from './Chip';

interface ChipListProps {
  data: Data;
  setState: Dispatch<Action>;
}

const ChipList = ({ data, setState }: ChipListProps) => {
  return (
    <article style={{ display: 'flex' }}>
      {data.static_colors.map((chipValue) => (
        <Chip key={chipValue.id} chipValue={chipValue} setState={setState} data={data} />
      ))}
    </article>
  );
};

export default ChipList;
