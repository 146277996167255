import React, { Dispatch } from 'react';
import { Radio } from 'usebeauty';
import { Action, State } from '../../../../IdcardContent';
import './WasmWarpingOpion.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
}

const WasmWarpingOpion = (props: Props) => {
  const { setState, state } = props;
  const { use_wasm_image_warping } = state.data.vas;

  return (
    <div id="wasm-warping">
      <p className="wasm-radio-text">
        <b>신분증 촬영 왜곡 보정 사용</b> 방식을 설정합니다.
      </p>
      <Radio.Group
        className="wasm-radio-group"
        value={use_wasm_image_warping ? 2 : 1}
        onChange={(value) => setState({ type: 'vas', payload: { use_wasm_image_warping: value === 2 } })}
      >
        <Radio text="사용 안 함" value={1}>
          <p>원본 사진 그대로 저장합니다.</p>
        </Radio>
        <Radio text="사용함" value={2}>
          <p>신분증 원본 사진이 기울어져 찍힌 경우 왜곡 보정(Warping) 하여 저장합니다. </p>
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default WasmWarpingOpion;
