import React from 'react';
import './ServerOcr.scss';

const ServerOcr = () => {
  return (
    <section id="idcard-setting-ocr-server" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className="active">Server OCR 사용</h4>
            <p>
              기본으로 제공되는 방식입니다. 사용자가 서버에 이미지를 업로드하면, 서버에서 텍스트를 추출하여 사용자에게
              반환합니다.
            </p>
          </div>
        </div>
        <div className="status-label active">사용중</div>
      </div>
    </section>
  );
};

export default ServerOcr;
