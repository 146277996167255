import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Switch } from 'common/components';
import { Action, Data } from '../../Expand';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

export const KybComp = (props: Props) => {
  const { data, setState, edit } = props;
  const { config } = data;
  return (
    <div className="setting-item">
      <div className={classNames('switch-box', config.allow_kyb ? 'active' : 'inactive')}>
        KYB
        <Switch
          className={classNames({ readonly: !edit })}
          disabled={!edit}
          value={config.allow_kyb}
          onChange={(value) => setState({ type: 'config', payload: { allow_kyb: !!value } })}
        />
      </div>
    </div>
  );
};
