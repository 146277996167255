import React from 'react';
import { cx } from 'common/functions';
import { useDateFilterContext } from '../popover/Pop_DateFilter';
import './YearComp.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export function YearComp(props: Props) {
  const { value, onChange } = props;
  const { firstDate } = useDateFilterContext();
  const current = new Date();
  const currentYear = current.getFullYear();
  const firstYear = Number(firstDate?.split('-')[0]) || currentYear;

  const yearList = Array.from({ length: currentYear - firstYear + 1 }).map((_, i) => String(currentYear - i));

  const onClickHandler = (value: string) => onChange(value);

  return (
    <div className="year-comp-content">
      <div className="year-comp-list">
        {yearList.map((el) => {
          return (
            <div key={el} onClick={() => onClickHandler(el)} className={cx({ active: value === el })}>{`${el}년`}</div>
          );
        })}
      </div>
    </div>
  );
}
