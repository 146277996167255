import React, { useEffect, useRef } from 'react';
import { api } from 'common/functions';
import { doubleDigit } from 'common/components/module/lib/functions';

export const useApiCancel = (urls: string[]) => {
  return React.useEffect(() => {
    return () => {
      const tokens = urls.map((url: string) => api.token[url]);
      for (const token of tokens) {
        if (token) token();
      }
    };
  }, []);
};

export const useUnmount = (f: () => void): void => {
  React.useEffect(() => {
    return f;
  }, []);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMount = (f: () => void, dep: any[]): void => {
  const isFirstRun = React.useRef(false);

  React.useEffect(() => {
    if (isFirstRun.current) f();
    if (!isFirstRun.current) isFirstRun.current = true;
  }, dep);
};

// controlled 와 uncontrolled 상태를 분석해서 사용할 상태와 함수를 리턴
type TUseContorl<T> = {
  state?: T;
  dispatcher?: (state: T) => void;
  default?: T;
};
export const useControl = <T>(props: TUseContorl<T>): [T, (state: T) => void] => {
  const state = props.state as NonNullable<T>;
  const d = props.default as NonNullable<T>;
  const unControlled = React.useState<T>(state ?? d);

  return [
    props.state === undefined ? unControlled[0] : props.state,
    (arg: T) => {
      if (!props.state || !props.dispatcher) unControlled[1](arg);
      if (props.dispatcher) props.dispatcher(arg);
    },
  ];
};

export const useCountdown = (expire: string, callback: (remain: number) => void, timeoutCallback: () => void) => {
  const [remain, setRemain] = React.useState(1000 * 60 * 30);
  const [remainTimeString, setRemainTimeString] = React.useState('');
  const timerId = React.useRef<NodeJS.Timeout>();

  useEffect(() => {
    function countDown() {
      timerId.current = setTimeout(() => {
        const exp = new Date(expire).getTime();
        const now = Date.now();
        const remain = exp - now;
        const remainMinutes = Math.floor(remain / (1000 * 60));
        const remainSeconds = Math.floor((remain % (1000 * 60)) / 1000);
        const formattedRemainTime = `${doubleDigit(remainMinutes)}:${doubleDigit(remainSeconds)}`;
        setRemain(remain);
        setRemainTimeString(formattedRemainTime);

        if (remain > 0) {
          callback(remain);
          countDown();
        } else {
          timeoutCallback();
        }
      }, 1000);
    }
    countDown();

    return () => {
      clearTimeout(timerId.current);
    };
  }, [expire]);

  return { remain, remainTimeString };
};

const disableSSE = process.env.REACT_APP_USE_SSE === 'false';

export const useSSE = (token: string, callback: () => void) => {
  const DUPLICATED_MESSAGE = "Another user's login has been detected";
  const eventSource = useRef<EventSource | null>(null);

  useEffect(() => {
    if (token && !disableSSE) {
      eventSource.current = new EventSource(`${process.env.REACT_APP_API_URL}concurrent-session?token=${token}`);
      eventSource.current.onopen = (e) => {
        console.debug('eventSource #onopen', { e, readyState: eventSource.current?.readyState });
      };
      eventSource.current.onmessage = (stream) => {
        console.debug('eventSource #onmessage', { stream, readyState: eventSource.current?.readyState });
        if (stream.data === DUPLICATED_MESSAGE) {
          callback();
          eventSource.current?.close();
          eventSource.current = null;
        }
      };
      eventSource.current.onerror = (e) => {
        console.debug('eventSource #onerror', { e, readyState: eventSource.current?.readyState });
        eventSource.current?.close();
        eventSource.current = null;
      };
    }

    return () => {
      if (eventSource.current) {
        eventSource.current.close();
        eventSource.current = null;
      }
    };
  }, [token]);
};
