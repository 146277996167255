import React, { useEffect, useContext } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { Context } from '../Alert';
import type { Alert } from '../Alert';

interface Props {
  item: Alert;
}
const Text: React.FC<Props> = ({ item }) => {
  const { remove } = useContext(Context);
  const { text, result } = item;

  const removeHandler = (e: React.MouseEvent): void => {
    e.stopPropagation();
    remove(item.id);
  };

  useEffect(() => {
    const timer = debounce(() => remove(item.id), 5000);
    timer();
    return (): void => timer.cancel();
  }, []);

  return (
    <div className={classNames('text__alarm', { success: result === 'success', fail: result === 'fail' })}>
      <div className="alert-info">
        <i className="result__icon" />
        {text}
        <i className="close__alarm__icon" onClick={removeHandler} />
      </div>
    </div>
  );
};

export default Text;
