/* eslint-disable consistent-return */
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FlexBox, Form } from '@usebeauty';
import { useDispatch } from 'common/hooks/admin';
import { Layer, modal } from 'layout';
import { Alert, Input, Validator } from 'common/components';
import { authActions as adminAuthActions } from 'store/actions/admin';
import { authActions as reviewerAuthActions } from 'store/actions/reviewer';
import { api, checkEmail, process } from 'common/functions';
import classNames from 'classnames';
import { keys } from 'lodash';
import { regExp } from 'common/validations';
import { Lock, UnLock } from '@Icons';
import { Auth } from '../Modal_ModifyMy';
import './UserModify.scss';

interface Props {
  closeHandler: () => void;
  password: string;
  auth: Auth;
}

const UserModify: React.FC<Props> = ({ auth, password: prop_password, closeHandler }) => {
  const { data: authData, my } = auth;
  const [loading, setLoding] = useState(false);
  const { pathname } = useLocation();
  const location = pathname.split('/')[1];
  const contentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { set } = Alert.Context();

  const {
    control,
    watch,
    setValue,
    clearErrors,
    setError,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'all',
    values: {
      password: '',
      confirmPassword: '',
      email: { value: my.email, check: false, pass: '' },
      phone_number: my.phone_number,
      name: my.name,
    },
  });

  const { name: d_name, confirmPassword: d_confirmPassword } = dirtyFields;
  const { password, confirmPassword, email, name, phone_number } = watch();
  const detact = d_name || my.phone_number !== phone_number || email.check || d_confirmPassword;

  const disabled = !!detact && !keys(errors).length;

  const styles = { marginTop: 8, marginBottom: 20 };

  // 이메일 중복 확인
  const emailCheckHandler = async () => {
    const result = await checkEmail({ customer_id: authData.customer_id, value: email.value });
    setValue('email', { ...email, pass: result.message, check: !result.duplicate });
    // dispatch({type:""})
  };

  const submitHandler = async () => {
    setLoding(true);
    const params = {
      customer_id: authData.customer_id,
      username: my.username,
      password: prop_password,
      new_password: password,
      name,
      email: email.value,
      phone_number,
      prop_password,
    };
    process(
      api.reqData({ url: 'mypage', data: params }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          closeHandler();
          set({ success: '계정 정보 수정이 완료되었습니다.' });
          const actions = location === 'reviewer' ? reviewerAuthActions : adminAuthActions;
          dispatch(actions.my());
        }
      })
    );
  };

  return (
    <Validator.Provider onSubmit={submitHandler} id="admin-user-modify-provider">
      <modal.Header>정보 수정</modal.Header>
      <modal.Content>
        <div className="content" ref={contentRef}>
          <modal.Heading>고객사명</modal.Heading>
          <Layer styles={styles}>
            <Input className="basic" readonly value={authData.company.name} />
          </Layer>

          <modal.Heading>아이디</modal.Heading>
          <Layer styles={styles}>
            <Input className="basic" readonly value={my.username} />
          </Layer>

          <modal.Heading>이름</modal.Heading>
          <Layer styles={styles}>
            <Form.Input
              control={control}
              name="name"
              rules={{
                required: '필수 정보입니다',
                pattern: {
                  value: regExp.name,
                  message: '한글, 영문 대/소문자, 빈칸만 사용 가능합니다.',
                },
              }}
            />
          </Layer>

          <modal.Heading>전화번호</modal.Heading>
          <Layer styles={styles}>
            <Form.Input
              control={control}
              name="phone_number"
              maxLength={13}
              onChange={(e) => {
                const { value } = e.target;
                let result = value.replace(/[^0-9]/g, '');
                if (result.length < 4) {
                  result = value.replace(/[^0-9]/g, '');
                } else if (result.length < 8) {
                  result = result.replace(/^(\d{3})(\d{0,4})?$/, `$1-$2`);
                } else if (result.length < 12) {
                  result = result.replace(/^(\d{3})(\d{0,4})?(\d{4})?$/, `$1-$2-$3`);
                }
                setValue('phone_number', result);
              }}
              rules={{
                maxLength: 20,
                required: '필수 정보입니다.',
                pattern: {
                  value: regExp.phone,
                  message: '숫자,특수문자(+-)만 사용 가능합니다.',
                },
              }}
            />
          </Layer>

          <modal.Heading>이메일</modal.Heading>
          <Validator.Provider onSubmit={emailCheckHandler}>
            <div className="email-check">
              <FlexBox st={{ gap: 8 }}>
                <Form.Input
                  className={classNames('basic', { error: errors.email && !email.check })}
                  onChange={(e) => {
                    const { value } = e.target;
                    const result = value === my.email;
                    setValue('email', { ...email, check: false, pass: result ? '' : '중복 여부를 확인하세요.' });
                  }}
                  control={control}
                  name="email.value"
                  rules={{
                    maxLength: 20,
                    required: '필수 정보입니다.',
                    pattern: {
                      value: regExp.email,
                      message: '입력 형식이 올바르지 않습니다.',
                    },
                  }}
                />
                <Validator.Submit text="중복확인" />
              </FlexBox>

              {!errors.email && email.pass && (
                <div className={classNames('api-check-message', { error: !errors.email && !email.check })}>
                  {email.pass}
                </div>
              )}
            </div>
          </Validator.Provider>

          <modal.Heading optional>새 비밀번호</modal.Heading>
          <Layer styles={styles}>
            <Form.Input
              onChange={(e) => {
                const { value } = e.target;
                if (value !== confirmPassword) {
                  setError('confirmPassword', { message: '입력하신 비밀번호가 일치하지 않습니다.' });
                } else {
                  clearErrors('confirmPassword');
                }
              }}
              control={control}
              name="password"
              type="password"
              lockOpenIcon={<UnLock st={password ? { color: '#5f65ff' } : { color: '#cccccc' }} />}
              lockCloseIcon={<Lock st={password ? { color: '#868686' } : { color: '#cccccc' }} />}
              onBlur={(e) => {
                const { value } = e.target;
                if (!value.length && confirmPassword) {
                  setError('confirmPassword', { message: '새 비밀번호를 먼저 입력해주세요.' });
                }
              }}
              rules={{
                validate: {
                  notExist: (value) => {
                    return prop_password !== value || '기존 비밀번호와 동일합니다. 확인 후 수정해주세요.';
                  },
                },
                pattern: {
                  value: regExp.accountPassword,
                  message: '9~20자로 영문 대/소문자, 숫자, 특수문자(!@#$%^&)를 포함하세요.',
                },
              }}
            />
          </Layer>

          <modal.Heading optional>새 비밀번호 확인</modal.Heading>
          <Layer styles={styles}>
            <Form.Input
              control={control}
              name="confirmPassword"
              type="password"
              lockOpenIcon={<UnLock st={confirmPassword ? { color: '#5f65ff' } : { color: '#cccccc' }} />}
              lockCloseIcon={<Lock st={confirmPassword ? { color: '#868686' } : { color: '#cccccc' }} />}
              rules={{
                validate: {
                  notEquals: (value) => {
                    return password === value || '입력하신 비밀번호가 일치하지 않습니다';
                  },
                },
              }}
            />
          </Layer>
        </div>
      </modal.Content>
      <modal.Footer loading={loading} closeHandler={closeHandler} disabled={!disabled} />
    </Validator.Provider>
  );
};

export default UserModify;
