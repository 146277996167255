import React from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFormStateReturn,
} from 'react-hook-form';
import { Box, Error, Radio } from '@usebeauty';
import { N_Radio } from 'usebeauty/dist/@types';
import { omit, pick } from 'lodash';
import { cx } from 'common/functions';

const exclusive = [
  'marginTop',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'paddingTop',
  'paddingLeft',
  'paddingBottom',
  'paddingRight',
];

function F_RadioGroup<T extends FieldValues, U extends FieldPath<T>, Z>(
  props: UseControllerProps<T, U> & {
    label?: string;
    st?: React.CSSProperties;
    className?: string;
    onChange?: (item: Z) => void;
    value?: Z;
    children:
      | ((form: {
          field: ControllerRenderProps<T, U>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<T>;
        }) => JSX.Element | JSX.Element[])
      | JSX.Element
      | JSX.Element[];
  }
) {
  const { className = '' } = props;

  return (
    <Controller
      rules={props.rules}
      control={props.control}
      name={props.name}
      render={(form) => {
        const {
          field,
          field: { onChange },
          fieldState: { error },
        } = form;
        const children = typeof props.children === 'function' ? props.children(form) : props.children;

        const boxStyle = pick(props.st, exclusive);
        const radioStyle = omit(props.st, exclusive);

        return (
          <Box className="form__radio__element" st={boxStyle}>
            <Radio.Group
              st={radioStyle}
              value={field.value}
              label={props.label}
              className={cx({ [className]: className, error })}
              onChange={(value) => {
                onChange(value);
                if (props.onChange) props.onChange(value);
              }}
            >
              {children}
            </Radio.Group>
            <Error>{error?.message}</Error>
          </Box>
        );
      }}
    />
  );
}

export function F_Radio<T>(props: N_Radio.Props<T>) {
  return <Radio {...props} />;
}

F_Radio.Group = F_RadioGroup;
F_Radio.displayName = Radio.displayName;

export default F_Radio;
