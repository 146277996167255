import React from 'react';
import { Controller, FieldPath, FieldValues, PathValue, UseControllerProps } from 'react-hook-form';
import { Box, Error } from '@usebeauty';
import { Select } from 'usebeauty';
import classNames from 'classnames';

function F_Select<T extends FieldValues, U extends FieldPath<T>>(
  props: UseControllerProps<T, U> & {
    label?: string | JSX.Element;
    st?: React.CSSProperties;
    className?: string;
    clickOutside?: () => void;
    onChange?: (value: PathValue<T, U>) => void;
    children: React.ReactElement[];
    disabled?: boolean;
  }
) {
  const { className = '', control, rules, children, label, name, st, clickOutside, ...rest } = props;
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <Box className="form__select__element" st={st}>
            <Select
              {...rest}
              readOnly
              className={classNames({ [className]: className, error })}
              label={label}
              clickOutside={() => {
                if (clickOutside) clickOutside();
                field.onBlur();
              }}
              selected={field.value}
              onChange={(value) => {
                const v = value as PathValue<T, U>;
                field.onChange(v);
                if (props.onChange) props.onChange(v);
              }}
            >
              {children}
            </Select>
            <Error>{error?.message}</Error>
          </Box>
        );
      }}
    />
  );
}

F_Select.Summary = Select.Summary;
F_Select.AutoComplete = Select.AutoComplete;

F_Select.Options = Select.Options;
F_Select.Item = Select.Item;

export default F_Select;
