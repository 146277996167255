import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { SubController } from 'common/components';
import './Manage.scss';

function Manage() {
  return (
    <div id="admin-manage-face-content-navigator">
      <SubController
        title={
          <>
            <NavLink to="/admin/manage/authentication/face/compare">얼굴 비교 판정 민감도 설정</NavLink>
            <NavLink to="/admin/manage/authentication/face/liveness">라이브니스 민감도 설정</NavLink>
          </>
        }
      />
      <Outlet />
    </div>
  );
}

export default Manage;
