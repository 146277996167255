import React, { Dispatch, useContext, useState } from 'react';
import { Radio } from 'usebeauty';
import { api, process } from 'common/functions';
import { Alert } from 'common/components';
import { ContentContext } from 'layout/admin/content/Content';
import { Action, State } from '../AuthenticateContent';
import EditComp from '../edit/EditComp';
import './IdcardMimeType.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const IdcardMimeType = (props: Props) => {
  const { state, setState, loadHandler } = props;
  const { set } = Alert.Context();
  const { setLoading } = useContext(ContentContext);
  const { include_mime_type } = state.vas;
  const [edit, setEdit] = useState(false);

  const disabled = state.defaultVas.include_mime_type === include_mime_type;

  const submitHandler = () => {
    setLoading(true);
    process(
      api.reqData({ url: 'configs', data: { include_mime_type: String(include_mime_type) } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        setLoading(false);
        loadHandler();
        if (response) {
          set({ success: `파일 형식 식별 설정이 완료되었습니다.` });
        } else {
          set({ fail: `파일 형식 식별 설정에 실패하였습니다.` });
        }
        return response;
      })
    );
  };

  const handler = { submitHandler, loadHandler };

  return (
    <section id="admin-manage-authenticate-use-idcard-mime-type" className="admin-manage-section">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4 className="active">파일 형식 식별 설정</h4>
            <p>PostMessage로 전송되는 base64 파일에 MIME type의 포함 여부를 선택할 수 있습니다.</p>
          </div>
        </div>
        <EditComp
          disabled={disabled}
          setEdit={setEdit}
          edit={edit}
          setState={setState}
          handler={handler}
          data={{ key: 'include_mime_type', value: include_mime_type }}
        />
      </div>
      <div className="description">
        {edit ? (
          <div className="edit-option">
            <Radio.Group
              className="authenticate-use-idcard-mime-type"
              value={include_mime_type}
              onChange={(checked) => props.setState({ type: 'vas', payload: { include_mime_type: checked } })}
            >
              <Radio text="MIME type 포함" value>
                <p>데이터 형식을 식별하고 처리할 수 있도록 도와주는 MIME type을 포함하고 전송합니다.</p>
              </Radio>
              <Radio text="MIME type 미포함" value={false}>
                <p>데이터 형식을 식별하고 처리할 수 있도록 도와주는 MIME type을 제외하고 전송합니다.</p>
              </Radio>
            </Radio.Group>
          </div>
        ) : (
          <div className="display-option">
            <h6>{include_mime_type ? 'MIME type 포함' : 'MIME type 미포함'}</h6>
            <span>
              데이터 형식을 식별하고 처리할 수 있도록 도와주는 MIME type을 {include_mime_type ? '포함' : '제외'}하고
              전송합니다.
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default IdcardMimeType;
