import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { useControl } from 'common/hooks';
import './RangeSlider.scss';

const MIN_THRESHOLD_GAP = 2;

interface Props {
  className?: string;
  disabled?: boolean;
  lower: { value: number; setValue: Dispatch<number> };
  upper: { value: number; setValue: Dispatch<number> };
  min?: number;
  max?: number;
  gap?: number;
  step?: number;
}

function RangeSlider(props: Props) {
  const { className, disabled, lower, upper, min = 1, max = 99, gap = MIN_THRESHOLD_GAP, step = 1 } = props;

  const [lowerValue, setLowerValue] = useControl({ state: lower.value, dispatcher: lower.setValue });
  const [upperValue, setUpperValue] = useControl({ state: upper.value, dispatcher: upper.setValue });

  const lowerThumb = ((lowerValue - min) / (max - min)) * 100;
  const upperThumb = ((upperValue - min) / (max - min)) * 100;

  const dots = Array.from(new Array(Math.floor(max / step)), (_, i) => i);

  return (
    <div className={classNames('range__slider__container', { [className as string]: className, disabled })}>
      <div className="slider">
        <input
          type="range"
          className="range__slider__lower"
          disabled={disabled}
          value={lowerValue}
          min={min}
          max={max}
          onChange={(e) => {
            const { value } = e.target;
            let numValue = Number(value);
            if (numValue > upperValue - gap) {
              numValue = upperValue - gap;
            }
            setLowerValue(numValue);
          }}
        />
        <input
          type="range"
          className="range__slider__upper"
          disabled={disabled}
          value={upperValue}
          min={min}
          max={max}
          onChange={(e) => {
            const { value } = e.target;
            let numValue = Number(value);
            if (numValue < lowerValue + gap) {
              numValue = lowerValue + gap;
            }
            setUpperValue(numValue);
          }}
        />
        <div
          className="slider__rail"
          style={{
            background: `-webkit-linear-gradient(left, 
                #ffffff 8px,
                #f53c3c 8px,
                #f53c3c ${lowerThumb}%,
                #5755dd ${lowerThumb}%,
                #5755dd ${upperThumb}%,
                #12b78f ${upperThumb}%,
                #12b78f calc(100% - 8px),
                #ffffff calc(100% - 8px))`,
          }}
        >
          <div className="dots">
            {dots.map((value, i) => {
              const lower = value + 1 <= lowerValue;
              const upper = value + 1 >= upperValue;
              const match = i + 1 === lowerValue || i + 1 === upperValue;
              return <i key={i} className={classNames({ lower, upper, match })} />;
            })}
          </div>
          <div className="counts">
            {dots.map((value, i) => {
              const lower = value + 1 <= lowerValue;
              const upper = value + 1 >= upperValue;
              const match = i + 1 === lowerValue || i + 1 === upperValue;
              return (
                <span key={i} className={classNames({ lower, upper, match })}>
                  {value + 1}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RangeSlider;
