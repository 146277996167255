/* eslint-disable import/no-named-default */
import { default as Preview1 } from './ColorTheme_Preview1';
import { default as Preview2 } from './ColorTheme_Preview2';
import { default as Preview3 } from './ColorTheme_Preview3';
import { default as Preview4 } from './ColorTheme_Preview4';
import { default as Preview5 } from './ColorTheme_Preview5';
import { default as Preview6 } from './ColorTheme_Preview6';

import { default as Thumbnail1 } from './ColorTheme_Thumbnail1';
import { default as Thumbnail2 } from './ColorTheme_Thumbnail2';
import { default as Thumbnail3 } from './ColorTheme_Thumbnail3';
import { default as Thumbnail4 } from './ColorTheme_Thumbnail4';
import { default as Thumbnail5 } from './ColorTheme_Thumbnail5';
import { default as Thumbnail6 } from './ColorTheme_Thumbnail6';

export const previewContainer = {
  Preview: [Preview6, Preview1, Preview2, Preview3, Preview4, Preview5, Preview6, Preview1],
  Thumbnail: [Thumbnail1, Thumbnail2, Thumbnail3, Thumbnail4, Thumbnail5, Thumbnail6],
};
