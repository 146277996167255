import React, { Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Pagination, Table } from 'usebeauty';
import { N_Table } from 'usebeauty/dist/@types';
import { time } from 'common/functions';
import classNames from 'classnames';
import { Chevron } from '@Icons';
import { ExpandLiveness } from '../../resource/Resource';
import { Action, State } from './CtLivenessLog';
import './LivenessLogContent.scss';

const header: N_Table.Header[] = [
  { title: '아이디', property: 'user_name', flex: 1 },
  { title: '이름', property: 'name', flex: 1 },
  { title: '변경일시', property: 'created_at', flex: 1 },
  { title: '상세보기', property: 'expand', width: 76, align: 'center' },
];

interface Props {
  state: State;
  setState: Dispatch<Action>;
}

const LivenessLogContent = (props: Props) => {
  const { state, setState } = props;
  const { data } = state;
  const navigate = useNavigate();

  return (
    <div className="admin-manage-face-liveness-log">
      <div className="setting-area">
        <div className="navigate-area">
          <Button onClick={() => navigate('../setting')}>
            <Chevron st={{ rotate: '180deg' }} />
            변경 로그
          </Button>
        </div>
      </div>
      <section className="admin-manage-section">
        <Table className="face-liveness-log-table">
          <Table.Head data={header} />
          <Table.Body data={data}>
            {({ index, rowData }) => {
              return (
                <Table.Row
                  key={index}
                  st={{ height: rowData.expand ? 'auto' : 56 }}
                  className={classNames({ expand: rowData.expand })}
                >
                  <>
                    <Table.Tr data={rowData} st={{ height: 56 }}>
                      {({ value, property }) => {
                        if (property === 'created_at') {
                          const epoch = new Date(value as string).getTime();
                          return time.timeFormatFromUTCEpoch(epoch, 2);
                        }
                        if (property === 'expand') {
                          return (
                            <Button
                              ripple={false}
                              className="expand"
                              onClick={() => setState({ type: 'expand', payload: index })}
                            >
                              <Chevron st={{ rotate: rowData.expand ? '270deg' : '90deg' }} />
                            </Button>
                          );
                        }
                        return value;
                      }}
                    </Table.Tr>
                    {rowData.expand && <ExpandLiveness rowData={rowData} />}
                  </>
                </Table.Row>
              );
            }}
          </Table.Body>
        </Table>
        <Pagination
          data={state.pagination}
          onChange={(pagination) => setState({ type: 'pagination', payload: pagination })}
        />
      </section>
    </div>
  );
};

export default LivenessLogContent;
