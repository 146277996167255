import React from 'react';
import { Tree } from 'common/components';
import { EddRa } from '../../context/model.type';
import Modal_RaAddItemDropdown from './modal/Modal_RaAddItemDropdown';
import './RiskItem.scss';

interface Props {
  item: EddRa.EddItem;
  raItemNames: string[];
  addRaItem: (item: EddRa.EddItem) => void;
  closeHandler: () => void;
}
function RiskAddItem(props: Props) {
  const { item, raItemNames, addRaItem, closeHandler } = props;
  const { category } = item;

  const submitHandler = (name: string, nextTree: Tree[]) => {
    addRaItem({ ...item, name, tree: nextTree });
  };

  return (
    <div>
      <Modal_RaAddItemDropdown
        closeHandler={closeHandler}
        submitHandler={submitHandler}
        eddCategory={category}
        item={item}
        raItemNames={raItemNames}
      />
    </div>
  );
}

export default RiskAddItem;
