import React from 'react';
import { Button, Dialog } from 'usebeauty';
import './Modal_DuplicateLogin.scss';

interface Props {
  submitHandler: () => void;
}
export function Modal_DuplicateLogin(props: Props) {
  return (
    <Dialog.Base st={{ width: 500 }} id="duplicate-login-modal">
      <Dialog.Summary st={{ textAlign: 'center' }}>
        <i />
        <p>로그아웃 알림</p>
      </Dialog.Summary>
      <Dialog.Content>
        <div className="content">
          <p>다른 기기에서 로그인이 감지되어 </p>
          <p>자동으로 로그아웃합니다.</p>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button className="submit" onClick={() => props.submitHandler()}>
          재 로그인
        </Button>
      </Dialog.Actions>
    </Dialog.Base>
  );
}
