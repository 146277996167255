import React, { Dispatch } from 'react';
import { Button, Dialog } from 'usebeauty';
import { DownArrow, User2 } from '@Icons';
import { Data } from '../../../CtAdminTable';

interface Props {
  rowData: Data;
  setOpen: Dispatch<boolean>;
  submitHandler: () => void;
}

const Unlock = (props: Props) => {
  const { rowData, setOpen, submitHandler } = props;

  return (
    <Dialog.Base id="dialog-unlock-account" st={{ width: 500, gap: 40 }}>
      <Dialog.Summary>계정 잠금 해제</Dialog.Summary>
      <Dialog.Content>
        <div className="description">해당 계정의 잠금을 해제하시겠습니까?</div>
        <div className="info-area">
          <div className="title">
            <User2 />
            <p>회원 정보</p>
          </div>
          <div className="list">
            <dl>
              <dt>아이디</dt>
              <dd>{rowData.username}</dd>
            </dl>
            <dl>
              <dt>활성 여부</dt>
              <dd className="flex">
                <p>계정 잠금</p>
                <DownArrow />
                <p className="active">활성</p>
              </dd>
            </dl>
          </div>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button className="cancel" onClick={() => setOpen(false)}>
          취소
        </Button>
        <Button onClick={submitHandler}>잠금 해제</Button>
      </Dialog.Actions>
    </Dialog.Base>
  );
};

export default Unlock;
