import React, { Dispatch } from 'react';
import { Action, State } from '../AccountContent';
import ChangeAccount from './changeAccount/ChangeAccount';
import AccountSubcode from './subcode/AccountSubcode';
import './AccountSetting.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const AccountSetting = ({ state, setState, loadHandler }: Props) => {
  return (
    <div id="admin-manage-account-setting" className="admin-manage-account-setting">
      <div className="setting-area">
        <div className="setting-title">
          <h3>계좌 인증</h3>
        </div>
      </div>
      <ChangeAccount state={state} setState={setState} loadHandler={loadHandler} />
      <AccountSubcode state={state} setState={setState} loadHandler={loadHandler} />
    </div>
  );
};

export default AccountSetting;
