import React, { useReducer } from 'react';
import { KycLoading, M_Table } from 'common/components';
import { api } from 'common/functions';
import { useApiCancel } from 'common/hooks';
import { produce } from 'immer';
import type { Pagination } from 'models';
import { getGuides } from 'common/api';
import { useKycQuery } from 'common/api/usequery';
import PrivacyTable from './PrivacyTable';

const initPagination = { info: { current: 1, total: 1 }, format: { limit: 10, offset: 0 } };
export type Data = { id: number; key: string; expand: boolean };
export type Action =
  | { type: 'setData'; payload: Data[] }
  | { type: 'expand'; payload: number }
  | { type: 'pagination'; payload: Pagination };

export interface State {
  data: Data[];
  header: M_Table.header[];
  pagination: Pagination;
}

const initialState: State = {
  data: [],
  pagination: { ...initPagination },
  header: [
    { title: '약관 key', property: 'key', flex: 8 },
    { title: '상세보기', property: 'expand', align: 'center' },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'expand': draft.data[action.payload]['expand'] = !draft.data[action.payload].expand; break;
      case 'pagination': draft['pagination'] = action.payload; break;
    }
  });
};

const CtPrivacyTable: React.FC = () => {
  useApiCancel(['guides']);
  const [state, setState] = useReducer(reducer, initialState);
  const { refetch, isFetching } = useKycQuery<{ list: (typeof getGuides.res)[] }>({
    key: ['guides'],
    handler: () => getGuides(),
    options: {
      onSuccess: (response) => {
        const { list } = response.data;
        const payload = list
          .map((el: Omit<Data, 'expand'>) => ({ ...el, expand: false }))
          .filter((el: Data) => el.key.includes('-pa'));
        setState({ type: 'setData', payload });
        setState({ type: 'pagination', payload: api.setTotal(state.pagination, payload.length) });
      },
    },
  });

  return (
    <>
      {isFetching && <KycLoading />}
      <PrivacyTable state={state} setState={setState} loadHandler={refetch} />
    </>
  );
};

export default CtPrivacyTable;
