import styled, { css } from 'styled-components';
import { Styles } from './Radio';

export const Item = styled.div`
  cursor: pointer;
  .jack__radio__box {
    width: 100%;
    display: inline-flex;
    align-items: center;
    position: relative;
    user-select: none;
    font-style: normal;

    .radio-box {
      height: 12px;
      width: 12px;
      flex-shrink: 0;
    }

    .radio-box-text {
      width: 100%;
      margin-left: 8px;
      transition: color 0.1s;
    }
  }
`;

export const Group = styled.div<Styles>`
  display: flex;
  flex-direction: column;
  &.disabled {
    opacity: 0.6;
    ${Item} {
      cursor: default;
      opacity: 1 !important;
    }
  }

  ${Item} {
    &.disabled {
      opacity: 0.6;
    }

    .radio-box-text {
      height: ${({ height }) => `${height ?? 14}px`};
      font-size: ${({ fontSize }) => `${fontSize ?? 11}px`};
      line-height: ${({ height }) => `${height ?? 14}px`};
    }
  }

  ${({ width }) => {
    if (width) {
      if (typeof width === 'string') {
        return css`
          width: ${width};
        `;
      }
      return css`
        width: ${width}px;
      `;
    }
    return '';
  }}
`;
