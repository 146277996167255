import React from 'react';
import { Validator } from 'common/components';
import { modal } from 'layout';
import './Modal_RaNationMode.scss';

interface Props {
  closeHandler: () => void;
  cancelHandler: () => void;
  submitHandler: () => void;
}
function Modal_RaNationMode(props: Props) {
  const { closeHandler, cancelHandler, submitHandler } = props;

  const onSubmit = () => {
    submitHandler();
    closeHandler();
  };

  const onCancel = () => {
    cancelHandler();
    closeHandler();
  };

  return (
    <Validator.Provider onSubmit={onSubmit}>
      <modal.Container id="modal-ra-item-nations-mode-setting">
        <modal.Header align="center">직접 설정 사용</modal.Header>
        <modal.Content>
          <p>직접 설정을 사용하시겠습니까?</p>
          <p>직접 국가 목록을 편집하실 수 있지만,</p>
          <p>최신 업데이트된 위험 국가를 확인하기 어려우실 수 있습니다.</p>
        </modal.Content>
        <modal.Footer closeHandler={onCancel} cancelText="자동 갱신 사용" submitText="직접 설정 사용" />
      </modal.Container>
    </Validator.Provider>
  );
}

export default Modal_RaNationMode;
