import React from 'react';
import classNames from 'classnames';
import './Navigator.scss';

interface Props {
  tab: 0 | 1;
  onChange: (tab: 0 | 1) => void;
}
const Navigator: React.FC<Props> = ({ tab, onChange }) => {
  return (
    <nav className="term-table-row-nav">
      <ul>
        <li className={classNames({ active: tab === 0 })} onClick={() => onChange(0)}>
          개인정보 수집 동의서
        </li>
        <li className={classNames({ active: tab === 1 })} onClick={() => onChange(1)}>
          개인정보처리방침
        </li>
      </ul>
    </nav>
  );
};

export default Navigator;
