import React, { Dispatch } from 'react';
import { Button, Dialog } from 'usebeauty';
import { Data } from '../../../CtReviewerTable';

interface Props {
  rowData: Data;
  setOpen: Dispatch<boolean>;
}

const UnlockConfirm = (props: Props) => {
  const { rowData, setOpen } = props;
  return (
    <Dialog.Base id="dialog-unlock-account" st={{ width: 500, gap: 40 }}>
      <Dialog.Summary>비밀번호 재설정 메일 발송</Dialog.Summary>
      <Dialog.Content>
        <div className="description">
          비밀번호 재설정 메일을 발송하였습니다.
          <br />
          해당 회원의 이메일로 임시 비밀번호를 발송하였습니다.
          <br />
          확인할 수 있도록 요청부탁드립니다.
        </div>
        <div className="info-area">
          <div className="list">
            <dl>
              <dt>아이디</dt>
              <dd>{rowData.username}</dd>
            </dl>
            <dl>
              <dt>이메일</dt>
              <dd>{rowData.email}</dd>
            </dl>
          </div>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={() => setOpen(false)}>확인</Button>
      </Dialog.Actions>
    </Dialog.Base>
  );
};

export default UnlockConfirm;
