import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FlexBox, Form } from '@usebeauty';
import { Button, Dialog, Loading } from 'usebeauty';
import classNames from 'classnames';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { Layer, modal } from 'layout';
import { Detail } from '../Expand';
import './EditInfoModal.scss';

interface Props {
  detail: Detail;
  loadHandler: () => void;
  closeHandler: () => void;
}

const EditInfoModal: React.FC<Props> = ({ detail, loadHandler, closeHandler }) => {
  const { name, industry, phone_number, purchase_type, active } = detail;
  const [loading, setLoading] = useState(false);

  const industryHandler = (industry: number) => {
    let industry_title = '';
    // prettier-ignore
    switch (industry){
      case 1 : industry_title = '비금융권'; break;
      case 2 : industry_title = '금융권'; break;
      case 3 : industry_title = '가상자산사업자'; break;
      case 4 : industry_title = 'P2P온투업자'; break;
    }
    return { title: industry_title, value: industry, id: industry_title };
  };

  const purchaseTypeHandler = (item: number) => {
    let title = '';
    // prettier-ignore
    switch (item){
      case 1 : title = '솔루션'; break;
      case 0 : title = 'API'; break;
    }
    return { title, value: item, id: title };
  };

  const activeHandler = (value: boolean) => {
    if (value) return { title: '활성', value, id: '활성' };
    return { title: '비활성', value, id: '비활성' };
  };

  const {
    control,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
  } = useForm({
    mode: 'onChange',
    values: {
      name,
      nameCheck: false,
      nameMessage: '',
      phone_number,
      industry: industryHandler(industry),
      purchase_type: purchaseTypeHandler(purchase_type),
      active: activeHandler(active),
      modify_check: 0,
    },
  });

  const { set } = Alert.Context();
  const field = watch();

  const styles = { marginTop: 8, marginBottom: 20 };

  // 고객사명 중복 확인
  const nameCheckHandler = () => {
    const params = { customer_name: field.name };
    process(
      api.reqData({ url: 'find/customer', data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data.api_response;
          if (result_code === 'N100') {
            setValue('nameCheck', false);
            setValue('nameMessage', '이미 사용중인 고객사명입니다.');
          }
          if (result_code === 'F540') {
            setValue('nameCheck', true);
            setValue('nameMessage', '사용 가능한 고객사명입니다.');
          }
        }
      })
    );
  };

  const submitHandler = async () => {
    // 이메일 중복 체크
    if (dirtyFields.name && !field.nameCheck) {
      setError('nameMessage', { message: '중복여부를 확인하세요.' });
      return;
    }
    setLoading(true);
    const params = {
      ...field,
      id: detail.id,
      name: field.name,
      industry: field.industry.value,
      purchase_type: field.purchase_type.value,
      active: field.active.value,
    };
    process(
      api.reqData({ url: 'customers', data: params }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          loadHandler();
          closeHandler();
          set({ success: '계정 정보 수정이 완료되었습니다.' });
        }
      })
    );
    setLoading(false);
  };

  const isActive = field.active.value ? true : !!field.modify_check;
  const isCollectActive = !!dirtyFields.active && isActive;

  const disabled =
    field.nameCheck ||
    !!dirtyFields.phone_number ||
    !!dirtyFields.industry ||
    !!dirtyFields.purchase_type ||
    isCollectActive;

  return (
    <>
      {loading && <Loading />}
      <Dialog.Base st={{ width: 500 }} className="edit_info__modal__wrapper">
        <Dialog.Summary>정보 수정</Dialog.Summary>
        <Dialog.Content>
          <div className="content">
            <modal.Heading>고객사명</modal.Heading>
            <div className="name-check">
              <Form.Input
                control={control}
                name="name"
                rules={{ required: '아이디를 입력하세요.' }}
                onChange={() => {
                  setValue('nameCheck', false);
                  setValue('nameMessage', '');
                }}
              />
              <Button onClick={nameCheckHandler} disabled={detail.name === field.name}>
                중복확인
              </Button>
              {field.nameMessage && (
                <div className={classNames('api-check-message', { error: !field.nameCheck })}>{field.nameMessage}</div>
              )}
            </div>
            <modal.Heading>전화번호</modal.Heading>
            <Layer styles={styles}>
              <Form.Input control={control} name="phone_number" rules={{ required: '전화번호를 입력하세요.' }} />
            </Layer>
            <Layer styles={styles}>
              <modal.Heading>구분</modal.Heading>
              <Form.Select control={control} name="industry">
                <Form.Select.Summary />
                <Form.Select.Options>
                  <Form.Select.Item title="비금융권" value={1} />
                  <Form.Select.Item title="금융권" value={2} />
                  <Form.Select.Item title="가상자산사업자" value={3} />
                  <Form.Select.Item title="P2P온투업자" value={4} />
                </Form.Select.Options>
              </Form.Select>
            </Layer>

            <Layer styles={styles}>
              <modal.Heading>구매형태</modal.Heading>
              <Form.Toggle
                name="purchase_type"
                control={control}
                className="box"
                columns={2}
                defaultValue={field.purchase_type}
              >
                <Form.Toggle.Item title="솔루션" value={1} />
                <Form.Toggle.Item title="API" value={0} />
              </Form.Toggle>
            </Layer>

            <Layer styles={styles}>
              <modal.Heading>활성여부</modal.Heading>
              <Form.Toggle
                name="active"
                control={control}
                className="box"
                columns={2}
                onChange={(value) => {
                  if (value) setValue('modify_check', 0);
                }}
              >
                <Form.Toggle.Item title="활성" value />
                <Form.Toggle.Item title="비활성" value={false} />
              </Form.Toggle>
              <FlexBox st={{ gap: 4, marginTop: 4 }}>
                <Form.CheckBox
                  control={control}
                  name="modify_check"
                  disabled={field.active.value === detail.active}
                  label="활성여부를 전환할 경우 서비스에 바로 반영됩니다."
                />
              </FlexBox>
            </Layer>
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <Button className="cancel" onClick={closeHandler}>
            취소
          </Button>
          <Button onClick={submitHandler} disabled={!disabled}>
            완료
          </Button>
        </Dialog.Actions>
      </Dialog.Base>
    </>
  );
};

export default EditInfoModal;
