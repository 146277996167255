import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { pick, omit } from 'lodash';
import Input from '../input/Input';
import { dynamicCSS } from '../input/styled';

export type Styles = {
  width?: number | string;
  height?: number | string;
  fontSize?: number;
  radius?: number;
};

interface Props {
  disabled?: boolean;
  submitHandler?: (searchText: string) => void;
  placeholder?: string;
  value?: string;
  onChange?: (searchText: string) => void;
  styles?: Styles;
  className?: string;
}

const Form = styled.div<Styles>`
  ${(args) => {
    const merge: { [key: string]: string } = {};
    if (args.width) merge['width'] = typeof args.width === 'string' ? args.width : `${args.width}px`;
    if (args.height) merge['height'] = typeof args.height === 'string' ? args.height : `${args.height}px`;
    let string = '';
    for (const key in merge) string += `${key}: ${merge[key]} !important;`;
    return string;
  }}

  &.disabled {
    .search-button {
      cursor: initial;
      i {
        opacity: 0.4;
      }
    }

    .jack__input {
      border-color: #cccccc !important;
      background-color: #fff;
    }
  }

  .jack__input__container {
    .jack__input {
      ${(props) => {
        return css`
          ${dynamicCSS(omit(props, ['width', 'height']))}
          width: inherit;
          height: inherit;
        `;
      }}
    }
  }
`;

const Search: React.FC<Props> = (props) => {
  const { submitHandler = () => false, className = '' } = props;
  const [value, setValue] = useState(props.value ?? '');
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (props.disabled) setValue('');
  }, [props.disabled]);

  return (
    <Form
      {...pick(props.styles, ['width', 'height'])}
      className={classNames('jack__search__form__field', { [className]: className, focus, disabled: props.disabled })}
    >
      <Input
        disabled={props.disabled}
        placeholder={props.placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          if (props.onChange) props.onChange(e.target.value);
        }}
        value={value}
        keyEnter={submitHandler}
        onFocus={() => {
          if (!focus) setFocus(true);
        }}
        onBlur={() => {
          if (focus) setFocus(false);
        }}
      />
      <button
        className="search-button"
        type="button"
        onClick={() => {
          if (!props.disabled) submitHandler(value);
        }}
      >
        <i />
      </button>
    </Form>
  );
};

export default Search;
