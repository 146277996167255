import React from 'react';
import classNames from 'classnames';
import { Data as CompareData } from '../compare/log/CtCompareLog';
import { Data as LivenessData } from '../liveness/log/CtLivenessLog';
import './Resource.scss';

function Before(props: { beforeLower?: number; beforeUpper?: number; column: 1 | 2 | 3 }) {
  const { beforeLower, beforeUpper, column } = props;
  return (
    <div className={classNames('status-view-comp', { default: column === 1 })}>
      <div className="title">변경 전</div>
      <div className="row">
        {column === 1 ? (
          <div className="default-text">기본설정</div>
        ) : (
          <div className="individual">
            <dl>
              <dt>자동 거부</dt>
              <dd>{beforeLower}단계 미만</dd>
            </dl>
            {column === 3 && (
              <dl>
                <dt>수동심사필요</dt>
                <dd>
                  {beforeLower}단계 이상 ~ {beforeUpper}단계 미만
                </dd>
              </dl>
            )}
            <dl>
              <dt>자동 승인 </dt>
              <dd>{beforeUpper}단계 이상</dd>
            </dl>
          </div>
        )}
      </div>
    </div>
  );
}

function After(props: {
  beforeLower?: number;
  beforeUpper?: number;
  afterLower?: number;
  afterUpper?: number;
  column: 1 | 2 | 3;
}) {
  const { beforeLower, beforeUpper, afterLower, afterUpper, column } = props;
  return (
    <div className={classNames('status-view-comp', { default: column === 1 })}>
      <div className="title">변경 후</div>
      <div className="row">
        {column === 1 ? (
          <div className="default-text active">기본설정</div>
        ) : (
          <div className="individual">
            <dl>
              <dt>자동 거부</dt>
              <dd className={classNames({ active: beforeLower !== afterLower })}>{afterLower}단계 미만</dd>
            </dl>
            {column === 3 && (
              <dl>
                <dt>수동심사필요</dt>
                <dd className={classNames({ active: beforeLower !== afterLower || beforeUpper !== afterUpper })}>
                  {afterLower}단계 이상 ~ {afterUpper}단계 미만
                </dd>
              </dl>
            )}
            <dl>
              <dt>자동 승인 </dt>
              <dd className={classNames({ active: beforeUpper !== afterUpper })}>{afterUpper}단계 이상</dd>
            </dl>
          </div>
        )}
      </div>
    </div>
  );
}

function StatusViewCompare(props: { children: JSX.Element[]; rowData: CompareData }) {
  const { rowData, children } = props;
  const { from_match_auto_approve, from_match_manual_review, to_match_auto_approve, to_match_manual_review } = rowData;

  return (
    <div className="expand-comp">
      <>
        {React.cloneElement(children[0], {
          beforeLower: from_match_manual_review,
          beforeUpper: from_match_auto_approve,
        })}
      </>
      <p>{'->'}</p>
      <>
        {React.cloneElement(children[1], {
          beforeLower: from_match_manual_review,
          beforeUpper: from_match_auto_approve,
          afterLower: to_match_manual_review,
          afterUpper: to_match_auto_approve,
        })}
      </>
    </div>
  );
}
StatusViewCompare.Before = Before;
StatusViewCompare.After = After;

export const ExpandCompare = ({ rowData }: { rowData: CompareData }) => {
  return (
    <StatusViewCompare rowData={rowData}>
      <StatusViewCompare.Before column={3} />
      <StatusViewCompare.After column={3} />
    </StatusViewCompare>
  );
};

function StatusViewLiveness(props: { children: JSX.Element[]; rowData: LivenessData }) {
  const { rowData, children } = props;
  const { from_liveness_auto_approve, to_liveness_auto_approve } = rowData;

  return (
    <div className="expand-comp">
      <>
        {React.cloneElement(children[0], {
          beforeLower: from_liveness_auto_approve,
          beforeUpper: from_liveness_auto_approve,
        })}
      </>
      <p>{'->'}</p>
      <>
        {React.cloneElement(children[1], {
          beforeLower: from_liveness_auto_approve,
          beforeUpper: from_liveness_auto_approve,
          afterLower: to_liveness_auto_approve,
          afterUpper: to_liveness_auto_approve,
        })}
      </>
    </div>
  );
}
StatusViewLiveness.Before = Before;
StatusViewLiveness.After = After;

export const ExpandLiveness = ({ rowData }: { rowData: LivenessData }) => {
  return (
    <StatusViewLiveness rowData={rowData}>
      <StatusViewLiveness.Before column={2} />
      <StatusViewLiveness.After column={2} />
    </StatusViewLiveness>
  );
};

export const StatusKind = ({ status }: { status: number }) => {
  const share = parseInt(String(status / 10), 10);

  if (share === 1)
    return (
      <div className="status-kind ">
        <p>개별 설정 추가</p>
      </div>
    );
  if (share === 2)
    return (
      <div className="status-kind approve ">
        <p>기본 설정 수정</p>
      </div>
    );
  if (share === 3)
    return (
      <div className="status-kind">
        <p>개별 설정 삭제</p>
      </div>
    );
  return '';
};

export const StatusResult = (status: number) => {
  const share = status % 10;
  // prettier-ignore
  switch(share) {
    case 1 : return <span className="status-result approve">승인됨</span>
    case 2 : return <span className="status-result reject">반려됨</span>
    case 3 : case 4 :return <span className="status-result cancel">취소됨</span>
  }
  return '-';
};
