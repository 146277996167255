import React from 'react';
import './Promotion.scss';

function Promotion() {
  return (
    <div className="admin-manage-edd-promotion">
      <i className="admin-manage-edd-promotion-icon" />
      <p>페이지 접근 권한이 없습니다.</p>
      <p>해당 기능을 사용하려면 고객센터로 연락해 주세요.</p>
    </div>
  );
}

export default Promotion;
