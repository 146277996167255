import React, { useContext, useEffect, useReducer } from 'react';
import { ContentContext } from 'layout/admin/content/Content';
import { produce } from 'immer';
import { NullishGuard } from 'hoc';
import { values } from 'lodash';
import { getGuidesKey } from 'common/api';
import { Resp } from 'common/api/model';
import { AxiosResponse } from 'axios';
import type { Data as RowData } from '../CtTermsTable';
import Privacy from './content/privacy/Privacy';
import Policy from './content/policy/Policy';
import Navigator from './nav/Navigator';
import './Expand.scss';

interface Props {
  rowData: RowData;
}

export type Data = { title: string; content: string };
type Action = { type: 'setData'; payload: { privacy: Data[]; policy: Data[] } } | { type: 'tab'; payload: 0 | 1 };
export interface State {
  privacy: Data[] | null;
  policy: Data[] | null;
  tab: 0 | 1;
}

const initialState: State = { privacy: null, policy: null, tab: 0 };
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData':
        draft['privacy'] = action.payload.privacy;
        draft['policy'] = action.payload.policy;
      break;
      case 'tab': draft['tab'] = action.payload; break;
    }
  });
};

const Expand: React.FC<Props> = ({ rowData }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const { setLoading } = useContext(ContentContext);
  const { privacy, policy, tab } = state;

  const isPrivacy = !!(privacy && tab === 0); // 개인정보 수집 동의서 탭
  const isPolicy = !!(policy && tab === 1); // 개인정보처리방침 탭

  const loadHandler = async () => {
    setLoading(true);
    if (privacy === null && policy === null) {
      const respData = (res: AxiosResponse<Resp<{ detail: typeof getGuidesKey.res }>>) => {
        if (res && res.data) {
          const { api_response, detail } = res.data;
          if (api_response.result_code === 'N100') {
            const html = JSON.parse(decodeURIComponent(atob(detail.content)));
            return html;
          }
        }
        return { noData: { title: '데이터 없음', content: '데이터 없음 ' } };
      };

      const privacy = await getGuidesKey(rowData.url.collection).then(respData);
      const policy = await getGuidesKey(rowData.url.policy).then(respData);
      setState({ type: 'setData', payload: { privacy: values(privacy), policy: values(policy) } });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <NullishGuard data={[privacy || policy]}>
      <section className="admin-term-table-tr-expand">
        <Navigator tab={tab} onChange={(tab) => setState({ type: 'tab', payload: tab })} />
        {isPrivacy && <Privacy privacy={privacy} />}
        {isPolicy && <Policy policy={policy} />}
      </section>
    </NullishGuard>
  );
};

export default Expand;
