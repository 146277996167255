import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { Alert, M_Table } from 'common/components';
import { getRaThreshold, getRaThresholdLogs } from 'common/api';
import { api, process, time } from 'common/functions';
import type { Order } from 'models';
import CriterionSetting from './settings/CriterionSetting';
import CriterionLogsTable from './logs/CriterionLogsTable';

type Data = {
  threshold: [number, number];
};
export type RaThresholdLogsType = {
  id: number;
  admin_id: number;
  before: [number, number];
  after: [number, number];
  created_at: string;
  user_id: string;
  user_name: string;
};
export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
  thresholdLogs: RaThresholdLogsType[];
  order: Order;
};

export type Action =
  | { type: 'edit'; payload: boolean }
  | { type: 'data'; payload: Data }
  | { type: 'defaultData'; payload: Data }
  | { type: 'thresholdLogs'; payload: RaThresholdLogsType[] }
  | { type: 'order'; payload: Order };

const initialData: Data = {
  threshold: [30, 70],
};
const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
  thresholdLogs: [],
  order: { created_at: 'DESC' },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'edit': draft['edit'] = action.payload; break;
      case 'data': draft['data'] = action.payload; break;
      case 'defaultData': draft['defaultData'] = action.payload; break;
      case 'thresholdLogs': draft['thresholdLogs'] = action.payload; break;
      case 'order': draft['order'] = action.payload; break;
    }
  });
};

const put = (url: string, data: { value: [number, number] }) => {
  return process(api.reqData({ url, data }), api.put.request);
};

function CtCriterionContent() {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();

  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);

    const threshold = await getRaThreshold();
    const thresholdLogs = await getRaThresholdLogs('logs?start=0&count=99');
    setState({ type: 'data', payload: { threshold } });
    setState({ type: 'defaultData', payload: { threshold } });
    setState({ type: 'thresholdLogs', payload: thresholdLogs });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const submitHandler = async () => {
    setLoading(true);
    await put('ra_threshold', { value: state.data.threshold });
    set({ success: '평가 기준이 적용되었습니다.' });
    loadHandler();
    setLoading(false);
  };

  const orderHandler = (order: M_Table.sortPayload) => {
    setState({ type: 'order', payload: order });

    const [key, ordering] = Object.entries(order)[0] as [key: keyof RaThresholdLogsType, ordering: M_Table.ordering];
    const orderedThresholdLogs = [...state.thresholdLogs].sort((prev, next) => {
      if (ordering === 'DESC') {
        if (key === 'created_at') {
          return (time.epochFromDate(next.created_at) as number) - (time.epochFromDate(prev.created_at) as number);
        }
        return next[key] > prev[key] ? 1 : 0;
      }
      if (key === 'created_at') {
        return (time.epochFromDate(prev.created_at) as number) - (time.epochFromDate(next.created_at) as number);
      }
      return next[key] < prev[key] ? -1 : 0;
    });
    setState({ type: 'thresholdLogs', payload: orderedThresholdLogs });
  };

  return (
    <>
      <CriterionSetting state={state} setState={setState} submitHandler={submitHandler} />
      <CriterionLogsTable state={state} orderHandler={orderHandler} />
    </>
  );
}

export default CtCriterionContent;
