import React from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { Box, Error, Input } from '@usebeauty';
import classNames from 'classnames';
import { N_Input } from 'usebeauty/dist/@types';

const F_Input = <T extends FieldValues, U extends FieldPath<T>>(
  props: UseControllerProps<T, U> & N_Input.Props & { error?: string }
) => {
  const {
    className = '',
    rules,
    control,
    name,
    st,
    label,
    onChange,
    onFocus,
    onBlur,
    maxLength,
    spellCheck,
    type,
    lockOpenIcon,
    lockCloseIcon,
    ...rest
  } = props;
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field, field: { value = '' }, fieldState: { error } }) => {
        return (
          <Box className="form__input__element" st={st}>
            <Input
              className={classNames({ [className]: [className], error })}
              {...rest}
              type={type}
              spellCheck={spellCheck}
              name={name}
              label={label}
              value={value}
              lockOpenIcon={lockOpenIcon}
              lockCloseIcon={lockCloseIcon}
              onBlur={(e) => {
                field.onBlur();
                if (onBlur) onBlur(e);
              }}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) onChange(e);
              }}
              onFocus={onFocus}
              maxLength={maxLength}
            />
            <Error>{props.error ? props.error : error?.message}</Error>
          </Box>
        );
      }}
    />
  );
};

export default F_Input;
