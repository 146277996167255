import React from 'react';
import './Error.scss';

export function Error({ children }: { children?: string }) {
  return children ? (
    <p className="kyc__error__label" role="alert">
      {children}
    </p>
  ) : (
    <></>
  );
}
