import axios from 'axios';
import { Resp } from './model';
import { authorization } from './index';

type T_GetReviewers = {
  active: boolean;
  email: string;
  id: number;
  is_approve: boolean;
  name: string;
  phone_number: string;
  username: string;
  locked: boolean;
  login_fail_count: number;
};

export const getReviewers = (params?: { start: number; count: number; [x: number]: string | undefined }) => {
  return axios.get<Resp<{ list: T_GetReviewers[] }>>('reviewers', {
    params,
    headers: authorization(),
  });
};
getReviewers.res = {} as T_GetReviewers;

// ##########################################

type T_GetModules = {
  account_verification: boolean;
  custom_field: boolean;
  edd_field: boolean;
  face_authentication: boolean;
  id_card_ocr: boolean;
  id_card_verification: boolean;
  liveness: boolean;
};

export const getModules = async () => {
  const { data } = await axios.get<Resp<{ module: T_GetModules }>>('modules', {
    headers: authorization(),
  });
  return data.module;
};

getModules.res = {} as T_GetModules;

// ##########################################

type T_GetConfigs = {
  key: keyof Configs;
  value: unknown;
};

export const getConfigs = async () => {
  const { data } = await axios.get<Resp<{ list: T_GetConfigs[] }>>('configs', {
    headers: authorization(),
  });
  return data.list;
};

getConfigs.res = {} as T_GetConfigs;

// ##########################################

type T_GetIdcardsName = {
  name: string;
  description: string;
  active: boolean;
};

export const getIdcardName = (name: string) => {
  return axios.get<Resp<{ detail: T_GetIdcardsName }>>(`idcards/${name}`, {
    headers: authorization(),
  });
};

getIdcardName.res = {} as T_GetIdcardsName;

// ##########################################

export const getRaThreshold = async () => {
  const { data } = await axios.get<Resp<{ value: [number, number] }>>(`ra_threshold`, {
    headers: authorization(),
  });
  return data.value;
};

getRaThreshold.res = {} as { value: [number, number] };

// ##########################################

type T_GetRaThresholdLogs = {
  id: number;
  admin_id: number;
  before: [number, number];
  after: [number, number];
  created_at: string;
  user_id: string;
  user_name: string;
};

export const getRaThresholdLogs = async (log: string) => {
  const { data } = await axios.get<Resp<{ list: T_GetRaThresholdLogs[] }>>(`ra_threshold/${log}`, {
    headers: authorization(),
  });
  return data.list;
};

getRaThresholdLogs.res = {} as T_GetRaThresholdLogs;

// ##########################################

type T_GetSecretsHmac = {
  is_exist: boolean;
};

export const getSecretsHmac = () => {
  return axios.get<Resp<{ is_exist: boolean }>>(`secrets/hmac`, {
    headers: authorization(),
  });
};

getSecretsHmac.res = {} as T_GetSecretsHmac;

// ##########################################

type T_GetThresholdLogs = {
  id: number;
  request_time: string;
  admin_id: string;
  admin_name: string;
  level_before: number;
  level_after: number;
};

export const getThresholdLogs = async (params: { start?: number; count: number }) => {
  const { data } = await axios.get<Resp<{ list: T_GetThresholdLogs[] }>>(`threshold/logs`, {
    params,
    headers: authorization(),
  });
  return data.list;
};

getThresholdLogs.res = {} as T_GetThresholdLogs;

// ##########################################

type T_GetEddCategory = { id: number; edd_type: 'customer' | 'service' | 'country'; name: string; weight: number };

export const getEddCategory = async () => {
  const { data } = await axios.get<Resp<{ list: T_GetEddCategory[] }>>(`field/edd_category`, {
    headers: authorization(),
  });
  return data.list;
};

getEddCategory.res = {} as T_GetEddCategory;

type T_PutEddCategory = { changes: T_GetEddCategory[] };

export const putEddCategory = async (params: T_PutEddCategory) => {
  await axios.put<T_PutEddCategory, Resp<null>>(`field/edd_category`, params, { headers: authorization() });
};

// ##########################################

export const putReviewerCustomerUnlock = async (data: { reviewer_id: number }) => {
  await axios.put<Resp<null>>(`/reviewers/${data.reviewer_id}/unlock`, undefined, {
    headers: authorization(),
  });
};

// ##########################################

// 얼굴 비교 민감도 설정 GET
type T_GetThresholdCompare = {
  match_auto_approve: number;
  match_manual_review: number;
};
export const getThresholdCompare = async () => {
  return axios.get<Resp<T_GetThresholdCompare>>(`/threshold/compare`, {
    headers: authorization(),
  });
};

getThresholdCompare.res = {} as T_GetThresholdCompare;

// ##########################################

// 얼굴 비교 민감도 모델 조회
type T_GetThresholdCompareReference = {
  id: number;
  lv: number;
  fmr: string;
  fnmr: string;
  tpr: number;
  distance_threshold: number;
  confidence_threshold: number;
  model: string;
};
export const getThresholdCompareReference = async () => {
  return axios.get<Resp<{ list: T_GetThresholdCompareReference[] }>>(`/threshold/compare/reference`, {
    headers: authorization(),
  });
};

getThresholdCompareReference.res = {} as T_GetThresholdCompareReference;

// ##########################################

// 얼굴 비교 민감도 설정 변경 PUT
type T_PutThresholdCompare = {
  match_manual_review: number;
  match_auto_approve: number;
};
export const putThresholdCompare = async (params: T_PutThresholdCompare) => {
  return axios.put<Resp<T_GetThresholdCompare>>(`/threshold/compare`, params, {
    headers: authorization(),
  });
};

getThresholdCompareReference.res = {} as T_GetThresholdCompareReference;

// ##########################################
// 얼굴 비교 민감도 설정 변경 로그 GET
type T_GetThresholdLogParam = {
  start: number;
  count: number;
};

type T_GetThresholdCompareLog = {
  id: number;
  from_match_auto_approve: number;
  from_match_manual_review: number;
  to_match_auto_approve: number;
  to_match_manual_review: number;
  user_name: string;
  name: string;
  created_at: string;
};

type T_PageInfo = { current_page: number; total_page: number; total_cnt: number };

export const getThresholdCompareLog = async (params: T_GetThresholdLogParam) => {
  return axios.get<Resp<{ list: T_GetThresholdCompareLog[]; page_info: T_PageInfo }>>(`/threshold/compare/logs`, {
    params,
    headers: authorization(),
  });
};

getThresholdCompareLog.res = {} as T_GetThresholdCompareLog;

// ##########################################

// 라이브니스 민감도 설정 GET
type T_GetThresholdLiveness = {
  liveness_auto_approve: number;
};
export const getThresholdLiveness = async () => {
  return axios.get<Resp<T_GetThresholdLiveness>>(`/threshold/liveness`, {
    headers: authorization(),
  });
};

getThresholdLiveness.res = {} as T_GetThresholdLiveness;

// ##########################################

// 라이브니스 민감도 모델 조회
type T_GetThresholdLivenessReference = {
  id: number;
  lv: number;
  frr: string;
  far: string;
  tpr: number;
  confidence_threshold: number;
  model: string;
};
export const getThresholdLivenessReference = async () => {
  return axios.get<Resp<{ list: T_GetThresholdLivenessReference[] }>>(`/threshold/liveness/reference`, {
    headers: authorization(),
  });
};

getThresholdLivenessReference.res = {} as T_GetThresholdLivenessReference;

// ##########################################

// 라이브니스 민감도 설정 변경 PUT
type T_PutThresholdLiveness = {
  liveness_auto_approve: number;
};
export const putThresholdLiveness = async (params: T_PutThresholdLiveness) => {
  return axios.put<Resp<T_PutThresholdLiveness>>(`/threshold/liveness`, params, {
    headers: authorization(),
  });
};

// 라이브니스 민감도 설정 변경 로그 GET

type T_GetThresholdLivenessLog = {
  id: number;
  from_liveness_auto_approve: number;
  to_liveness_auto_approve: number;
  user_name: string;
  name: string;
  created_at: string;
};

export const getThresholdLivenessLog = async (params: T_GetThresholdLogParam) => {
  return axios.get<Resp<{ list: T_GetThresholdLivenessLog[]; page_info: T_PageInfo }>>(`/threshold/liveness/logs`, {
    params,
    headers: authorization(),
  });
};

getThresholdLivenessLog.res = {} as T_GetThresholdLivenessLog;
