import React from 'react';

import { useLocation } from 'react-router-dom';
import * as admin from 'common/hooks/admin';
import * as reviewer from 'common/hooks/reviewer';

import { authActions as adminActions } from 'store/actions/admin';
import { authActions as reviewerActions } from 'store/actions/reviewer';
import { doubleDigit } from 'common/components/module/lib/functions';
import { Time } from '@Icons';

function TokenTimer() {
  const { pathname } = useLocation();

  const location = pathname.split('/')[1];

  const selector = (location === 'reviewer' && reviewer.useSelector) || (location === 'admin' && admin.useSelector);
  if (!selector) return <></>;

  const adminDispatch = admin.useDispatch();
  const reviewerDispatch = reviewer.useDispatch();

  const { timer } = selector((state) => state.auth);

  const remainMinutes = Math.floor(timer / (1000 * 60));
  const remainSeconds = Math.floor((timer % (1000 * 60)) / 1000);
  const formattedRemainTime = `${doubleDigit(remainMinutes)}:${doubleDigit(remainSeconds)}`;

  const timerExtendHandler = () => {
    if (location === 'admin') adminDispatch(adminActions.refreshToken());
    if (location === 'reviewer') reviewerDispatch(reviewerActions.refreshToken());
  };

  return (
    <div id="kyc-page-token-timer">
      <div className="timer">
        <Time />
        {formattedRemainTime}
      </div>
      <div className="divide" />
      <div className="extend">
        <span onClick={timerExtendHandler}>연장</span>
      </div>
    </div>
  );
}
export default TokenTimer;
