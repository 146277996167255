import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Controller } from 'common/components';
import './ManageAuthencation.scss';

function ManageAuthencation() {
  return (
    <div id="admin-manage-content-navigator">
      <Controller
        title={
          <>
            <NavLink to="/admin/manage/authentication/examination">심사 방식</NavLink>
            <NavLink to="/admin/manage/authentication/idcard">신분증 인증</NavLink>
            <NavLink to="/admin/manage/authentication/account">계좌 인증</NavLink>
            <NavLink to="/admin/manage/authentication/face">얼굴 인증</NavLink>
            <NavLink to="/admin/manage/authentication/edd">고객 위험 평가</NavLink>
          </>
        }
      />
      <Outlet />
    </div>
  );
}

export default ManageAuthencation;
