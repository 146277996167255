import type { CustomField } from './CustomFieldContext';

type CategorizedResp = { customFields: CustomField[] };

export function getParseResp(list: CustomField[]) {
  const { customFields }: CategorizedResp = list.reduce(
    (acc: CategorizedResp, cur: CustomField) => {
      const { customFields } = acc;
      customFields.push({ ...cur, isAppend: false, error: { type: false, name: false, active: false, duplicate: false } }); // prettier-ignore
      return { customFields };
    },
    { customFields: [] }
  );

  return { customFields };
}
