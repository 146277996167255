import React from 'react';
import CtNationLabel from './label/CtNationLabel';
import './NationLabelContent.scss';

function NationLabelContent() {
  return (
    <section id="root-eddra-nation-label">
      <CtNationLabel />
    </section>
  );
}

export default NationLabelContent;
