import React, { Dispatch } from 'react';
import { Radio } from 'common/components';
import classNames from 'classnames';
import { Action, Data } from '../../../../Expand';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

const ServerOcr = (props: Props) => {
  const { data, setState, edit } = props;
  const { config, module } = data;
  const { server_ocr_type } = config;

  return (
    <div className="category border">
      <div className="cate-title">Server OCR</div>
      <div className="category">
        <Radio.Group
          disabled={!module.id_card_ocr || !edit}
          className={classNames('server-ocr-radio', { active: module.id_card_ocr })}
          value={server_ocr_type}
          onChange={(value) => setState({ type: 'config', payload: { server_ocr_type: value } })}
        >
          <Radio.Item value={1} text="useB OCR" />
          <Radio.Item value={2} text="Quram OCR" />
        </Radio.Group>
      </div>
    </div>
  );
};

export default ServerOcr;
