import React, { createContext, useContext, useEffect, useRef } from 'react';
import TreeView from './TreeView';
import { useTreeView, tree } from './useTreeView';

export type TreeNode = {
  id?: string;
  name: string;
  edd_value: number;
  edit: boolean;
  depth: number;
  expand: boolean;
  children: TreeNode[];
};

type Context = ReturnType<typeof useTreeView> & {
  selectHandler: (node: TreeNode) => void;
  addNodeHandler: () => void;
  deleteNodeHandler: () => void;
  expandHandler: (node: TreeNode) => void;
  childComp: (args: { item: TreeNode }) => JSX.Element;
};
export const Context = createContext<null | Context>(null);
type TreeCompArgs = { item: TreeNode; updateHandler: (value: string) => void };
type Props = {
  children: JSX.Element;
  data: TreeNode[];
  render: (args: TreeCompArgs) => JSX.Element;
};
function TreeViewContainer(props: Props) {
  const { data, render, children } = props;

  const treeView = useTreeView(data);
  const { treeData, setTreeData, selectedNode, setSelectedNode, activeNode, setActiveNode } = treeView;

  const addNodeHandler = () => {
    const { treeData: newTreeData, node } = tree.add({ data: treeData, target: selectedNode });
    setTreeData(newTreeData);
    setSelectedNode(node);
    setActiveNode(node);
  };

  const deleteNodeHandler = () => {
    if (selectedNode) {
      const { treeData: newTreeData, parent } = tree.delete({ data: treeData, target: selectedNode });
      setTreeData(newTreeData);
      setSelectedNode(parent);
    }
  };

  const selectHandler = (node: TreeNode) => {
    setSelectedNode(node);
    if (selectedNode && selectedNode.id === node.id) setActiveNode(node);
  };

  const updateHandler = (value: string, blur?: boolean) => {
    if (selectedNode) {
      const { treeData: newTreeData } = tree.update({ data: treeData, target: selectedNode, value });
      setTreeData(newTreeData);
      if (blur) {
        setActiveNode(null);
      }
    }
  };

  const expandHandler = (node: TreeNode) => {
    const { treeData: newTreeData } = tree.expand({ data: treeData, target: node });
    setTreeData(newTreeData);
  };

  const childComp = (args: { item: TreeNode }) => render({ ...args, updateHandler });

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const clickOutSide = (e: Event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
        setSelectedNode(null);
        setActiveNode(null);
      }
    };
    document.addEventListener('click', clickOutSide);

    return () => document.removeEventListener('click', clickOutSide);
  }, [wrapperRef.current]);

  return (
    <Context.Provider
      value={{
        ...treeView,
        selectedNode,
        activeNode,
        deleteNodeHandler,
        addNodeHandler,
        selectHandler,
        expandHandler,
        childComp,
      }}
    >
      <div ref={wrapperRef}>
        {children}
        {treeData.map((el, i) => {
          return (
            <TreeView key={i} item={el}>
              {childComp}
            </TreeView>
          );
        })}
      </div>
    </Context.Provider>
  );
}

TreeViewContainer.Node = TreeView.Node;
TreeViewContainer.Consumer = Context.Consumer as React.Consumer<Context>;

export default TreeViewContainer;

export const useTreeContext = (): Context => {
  const context = useContext(Context);
  if (!context) throw new Error('tree context 없습니다');
  return context;
};
