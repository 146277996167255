import React from 'react';
import { Modal } from 'common/components';
import Terms from './modal/Modal_Terms';
import Policy from './modal/Modal_Policy';
import './Footer.scss';

const Footer: React.FC = () => {
  return (
    <footer className="kyc-footer">
      <div className="footer-content">
        <Modal content={({ closeHandler }) => <Terms closeHandler={closeHandler} />}>
          <p className="terms">이용약관</p>
        </Modal>
        <Modal content={({ closeHandler }) => <Policy closeHandler={closeHandler} />}>
          <p className="policy">개인정보처리방침</p>
        </Modal>
        <div className="contact">
          <p>고객센터</p>
          <p>070-4849-1000</p>
          <p>cs@useb.co.kr</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
