import React, { Dispatch } from 'react';
import { Action, State } from '../GeneralContent';
import Message from './extraservice/message/Message';
import ContinueSetting from './extraservice/countinue/ContinueSetting';
import PreservePrivacy from './extraservice/privacy/PreservePrivacy';
import './GeneralSetting.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const GeneralSetting: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  return (
    <div id="manage-general-setting">
      <h2 className="section-title">부가 서비스</h2>
      <div className="row">
        <Message state={state} setState={setState} loadHandler={loadHandler} />
      </div>
      <div className="row">
        <PreservePrivacy state={state} />
      </div>
      <div className="row">
        <ContinueSetting state={state} />
      </div>
    </div>
  );
};

export default GeneralSetting;
