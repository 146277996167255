import React from 'react';
import classNames from 'classnames';
import { State } from '../../../GeneralContent';
import './PreservePrivacy.scss';

interface Props {
  state: State;
}

const PreservePrivacy = ({ state }: Props) => {
  const { keep_privacy_day } = state.data.vas;

  return (
    <section id="subscribe-management-setting-privacy">
      <div className="flex-box">
        <div className="header">
          <div className="title">
            <h4>개인정보 보존기간 사용</h4>
          </div>
        </div>
        <div className={classNames('used-text active')}>사용중</div>
      </div>

      <div className="judge-type">
        <div className="flex-box">
          <div className="title">보존기간 일 수</div>
          <p>고객이 작성하던 개인 정보를 지정한 일 수 만큼 보관합니다. </p>
        </div>
        <span>{keep_privacy_day ?? 1} 일</span>
      </div>
      <p className="ex">※ 기간 변경을 희망하실 경우 유스비 고객센터 (cs@useb.co.kr) 으로 연락 부탁드립니다.</p>
    </section>
  );
};

export default PreservePrivacy;
