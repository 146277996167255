import React from 'react';
import { cx } from 'common/functions';
import './DateFilter.scss';

interface Props {
  children: JSX.Element;
  onChange: (value: number) => void;
  value: number;
  type: number[];
}

export function DateFilter(props: Props) {
  const { children, value, onChange, type } = props;
  const tabList = [
    { title: '기간설정', value: 1 },
    { title: '월', value: 2 },
    { title: '분기', value: 3 },
    { title: '반기', value: 4 },
    { title: '년', value: 5 },
  ].filter((t) => type.includes(t.value));

  return (
    <div id="date-filter">
      <div className="body">
        <ul className="tab">
          {tabList.map((el, i) => {
            return (
              <li key={i} onClick={() => onChange(i + 1)} className={cx({ active: value === el.value })}>
                {el.title}
              </li>
            );
          })}
        </ul>
        <div className="content">{React.cloneElement(children, { onChange, value })}</div>
      </div>
    </div>
  );
}
