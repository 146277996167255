import axios from 'axios';
import { api_response, Resp } from './model';
import { authorization } from './index';

// ##########################################
export type T_GetCustomers = {
  active: boolean;
  id: number;
  industry: number;
  name: string;
  phone_number: string;
  purchase_type: number;
  status: number;
  expand: boolean;
};

export const getCustomers = (params?: { start: number; count: number; [x: number]: string | undefined }) => {
  return axios.get<Resp<{ list: T_GetCustomers[] }>>('customers', {
    params,
    headers: authorization(),
  });
};
getCustomers.res = {} as T_GetCustomers;

// ##########################################
export type T_GetCustomerAdmins = {
  active: boolean;
  created_at: string;
  email: string;
  id: number;
  is_approve: boolean;
  name: string;
  phone_number: string;
  username: string;
  loginFailCount: number;
  locked: boolean;
  login_fail_count: number;
};

export const getCustomerAdmins = (
  customer_id: number,
  params: {
    start: number;
    count: number;
    keyword_field: string;
    keyword?: string;
    order_field?: string;
    order?: string;
  }
) => {
  return axios.get<Resp<{ list: T_GetCustomerAdmins[] }>>(`customers/${customer_id}/admins`, {
    params,
    headers: authorization(),
  });
};

getCustomerAdmins.res = {} as T_GetCustomerAdmins;

// ##########################################
export const getCustomerAdmin = (customer_id: number, admin_id: number) => {
  return axios.get<Resp<{ account: T_GetCustomerAdmins }>>(`customers/${customer_id}/admins/${admin_id}`, {
    headers: authorization(),
  });
};

getCustomerAdmin.res = {} as T_GetCustomerAdmins;

// ##########################################

type T_GetNotices = {
  id: number;
  version: string;
  created_at: string;
  expand: boolean;
};

export const getNotices = (type: 'pp' | 'tnc', params: { count: number; start?: number }) => {
  return axios.get<Resp<{ list: T_GetNotices[]; total_count: number }>>(`notices/${type}`, {
    params,
    headers: authorization(),
  });
};

getNotices.res = {} as T_GetNotices;

// ##########################################

type T_GetNoticesKey = {
  id: number;
  version: string;
  created_at: string;
  expand: boolean;
  content: string;
};

export const getNoticesKey = (type: 'pp' | 'tnc', version: string) => {
  return axios.get<Resp<{ detail: T_GetNoticesKey; total_count: number }>>(`notices/${type}/${version}`, {
    headers: authorization(),
  });
};

getNoticesKey.res = {} as T_GetNoticesKey;

// ##########################################

type T_GetGuides = {
  id: number;
  key: string;
  expand: boolean;
};

export const getGuides = () => {
  return axios.get<Resp<{ list: T_GetGuides[]; total_count: number }>>(`guides`, {
    headers: authorization(),
  });
};

getGuides.res = {} as T_GetGuides;

// ##########################################

type T_GetGuidesKey = {
  title: string;
  content: string;
  id: number;
  key: string;
};

export const getGuidesKey = (key: string) => {
  return axios.get<Resp<{ detail: T_GetGuidesKey }>>(`guides/${key}`, {
    headers: authorization(),
  });
};

getGuidesKey.res = {} as T_GetGuidesKey;

// ##########################################

type T_getWarnLabels = {
  id: number;
  name_en: string;
  name_kr: string;
  score: number;
  active: boolean;
};

export const getWarnLabels = () => {
  return axios.get<Resp<{ list: T_getWarnLabels[] }>>(`warn_labels`, {
    headers: authorization(),
  });
};

getWarnLabels.res = {} as T_getWarnLabels;

// ##########################################

type T_getWarnLabelLogs = {
  label_name: string;
  label_name_before: string | null;
  log_type: 1 | 2;
  score_log: [number, number] | null;
  active_log: 1 | 2 | null;
  con_add_log: { name_en: string; name_kr: string }[] | null;
  con_del_log: { name_en: string; name_kr: string }[] | null;
  created_at: string;
  root_name: string;
  expand: boolean;
};

export const getWarnLabelLogs = () => {
  return axios.get<Resp<{ list: T_getWarnLabelLogs[]; total_count: number }>>(`warn_label_logs`, {
    headers: authorization(),
  });
};

getWarnLabelLogs.res = {} as T_getWarnLabelLogs;

// ##########################################

type T_getAccessLogRoot = {
  id: number;
  created_at: string;
  root_name: string;
  client_ip: string;
  check_user_name: null | string;
  task_id: number;
};

export const getAccessLogRoot = (params: {
  start: number;
  count: number;
  order_field?: string;
  order?: string;
  start_date?: string;
  end_date?: string;
}) => {
  return axios.get<Resp<{ list: T_getAccessLogRoot[]; total_count: number }>>(`access_log/root`, {
    params,
    headers: authorization(),
  });
};

getAccessLogRoot.res = {} as T_getAccessLogRoot;

// ##########################################

export const postRootLogout = () => {
  return axios.post<api_response>(`root/logout`, null, { headers: authorization() });
};

// ##########################################

export const putAdminCustomerUnlock = async (data: { customer_id: number; admin_id: number }) => {
  await axios.put<Resp<null>>(`/customers/${data.customer_id}/admins/${data.admin_id}/unlock`, undefined, {
    headers: authorization(),
  });
};

// ##########################################

declare namespace T_getDashboardData {
  type Report = {
    dropout: number;
    auto_approve: number;
    auto_reject: number;
    manual_approve: number;
    manual_reject: number;
    manual_wait: number;
  };
  type Count = {
    total_charge: number;
    total_unpaid: number;
    total: number;
  };
  type Daily = Report & {
    date: string;
    count: number;
  };
}

type T_DashboardParams = {
  customer_id: number;
  filter_type: number;
  start_date?: string;
  end_date?: string;
  month?: string;
};

export const getDashboardDataAll = (params: T_DashboardParams) => {
  return axios.get<Resp<{ report: T_getDashboardData.Report; count_info: T_getDashboardData.Count }>>(
    `dashboard/${params.customer_id}/all`,
    {
      params,
      headers: authorization(),
    }
  );
};
getDashboardDataAll.req = {} as T_DashboardParams;
getDashboardDataAll.res = {} as { report: T_getDashboardData.Report; count_info: T_getDashboardData.Count };

export const getDashboardDataDaily = (params: T_DashboardParams) => {
  return axios.get<Resp<{ list: T_getDashboardData.Daily[]; count_info: T_getDashboardData.Count }>>(
    `dashboard/${params.customer_id}/days`,
    {
      params,
      headers: authorization(),
    }
  );
};
getDashboardDataDaily.req = {} as T_DashboardParams;
getDashboardDataDaily.res = {} as { list: T_getDashboardData.Daily[]; count_info: T_getDashboardData.Count };
