import React, { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import Text from './Text';
import { Context } from '../Alert';
import type { Alert, Position } from '../Alert';
import positionHandler from './positionHandler';

interface Props {
  target: HTMLDivElement | null;
  position: Position;
  gap: number;
}

const Portal: React.FC<Props> = (props) => {
  const rootRef = useRef(null);
  const body = document.querySelector('body') as HTMLBodyElement;
  const { alert } = useContext(Context);

  useEffect(() => {
    const setPosition = (): void => {
      if (props.target && rootRef.current) {
        positionHandler({
          position: props.position,
          gap: props.gap,
          target: props.target,
          root: rootRef.current,
        });
      }
    };
    setPosition();
    window.addEventListener('resize', setPosition); // 윈도우 리사이즈시 위치 다시 조정
    return () => {
      window.removeEventListener('resize', setPosition); // 윈도우 리사이즈시 위치 다시 조정
    };
  }, [rootRef.current]);

  return createPortal(
    <div ref={rootRef} className="jack__alarm__wrapper">
      {alert.length > 0 && (
        <div className="jack__alarm__list">
          {alert.map((alert: Alert) => {
            return <Text item={alert} key={alert.id} />;
          })}
        </div>
      )}
    </div>,
    body
  );
};

export default Portal;
