import React, { Dispatch } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'common/components';
import type { Action } from '../FindId';
import type { FindType } from '../../FindAccountPage';
import './Result.scss';

interface Props {
  username: string;
  setState: Dispatch<Action>;
  setFindType: Dispatch<FindType>;
}

const Result: React.FC<Props> = ({ username, setState, setFindType }) => {
  const { role } = useParams();
  const navigate = useNavigate();
  const handlers = {
    findPw: () => setFindType('비밀번호 찾기'),
    goback: () => navigate(`/${role}`),
    retry: () => {
      setState({ type: 'result', payload: { username: '', done: false } });
    },
  };

  const template = {
    success: (
      <div className="success">
        <div className="description">
          <p>입력하신 정보로 조회된 아이디는</p>
          <p>아래와 같습니다.</p>
        </div>

        <div className="notice-id">{username}</div>

        <div className="extra-info">
          <i />
          <div className="text">
            <p>정보 보호를 위해 아이디의 일부는 *로 표기됩니다.</p>
            <p>전체 아이디는 고객센터로 문의해 주세요.</p>
          </div>
        </div>

        <div className="navigator">
          <Button onClick={handlers.findPw} text="비밀번호 찾기" />
          <Button onClick={handlers.goback} text="로그인" />
        </div>
      </div>
    ),
    fail: (
      <div className="fail">
        <div className="fail-icon">
          <i />
        </div>

        <div className="description">일치하는 정보가 없습니다.</div>

        <div className="text">
          <p>입력하신 정보로 조회된 내역이 없습니다.</p>
          <p>가입 여부를 다시 한번 확인해 주세요.</p>
        </div>

        <div className="navigator">
          <Button onClick={handlers.goback} text="이전으로" />
          <Button onClick={handlers.retry} text="재시도" />
        </div>
      </div>
    ),
  };

  return (
    <div className="kyc-find-id-result">
      <h2>아이디 찾기 결과</h2>
      {username ? template.success : template.fail}
    </div>
  );
};

export default Result;
