import React, { useRef } from 'react';
import styled from 'styled-components';
import { RangeSlider, SingleSlider } from 'common/components';
import './ConfidenceSetting.scss';

const ConfidenceLabel = styled.span.attrs<{ $width: number }>((props) => ({
  style: {
    width: `${props.$width}px`,
  },
}))`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
`;

interface Props {
  edit: boolean;
  confidence: number[];
  min?: number;
  max?: number;
  updateHandler: (confidence: number[]) => void;
}
function ConfidenceSetting(props: Props) {
  const { edit, confidence, updateHandler, min = 1, max = 16 } = props;
  const [lowerConfidence, upperConfidence] = confidence;

  const sliderRail = useRef<HTMLDivElement>(null);

  const SLIDER_RAIL_WIDTH = sliderRail?.current?.clientWidth ?? 1102;

  const space = Math.floor(SLIDER_RAIL_WIDTH / (max - min));

  const lowerTextWidth = space * (lowerConfidence - 1);
  const middleTextWidth = space * ((upperConfidence ?? max) - lowerConfidence);
  const upperTextWidth = space * (max - upperConfidence);

  return (
    <>
      <section className="confidence-setting">
        {confidence.length > 1 ? (
          <div className="confidence-content">
            <RangeSlider
              disabled={!edit}
              lower={{
                value: lowerConfidence,
                setValue: (value) => {
                  const minValue = upperConfidence === max && value < min + 1 ? min + 1 : value;
                  const lower = Math.min(minValue, max - 1);
                  updateHandler([lower, upperConfidence]);
                },
              }}
              upper={{
                value: upperConfidence,
                setValue: (value) => {
                  const maxValue = lowerConfidence === min && value > max - 1 ? max - 1 : value;
                  const upper = Math.max(maxValue, min + 1);
                  updateHandler([lowerConfidence, upper]);
                },
              }}
              min={min}
              max={max}
              gap={0}
            />
            <div className="confidence-label" ref={sliderRail}>
              <ConfidenceLabel $width={lowerTextWidth}>{!!lowerTextWidth && '자동 거부'}</ConfidenceLabel>
              <ConfidenceLabel $width={middleTextWidth}>{!!middleTextWidth && '수동심사 필요'}</ConfidenceLabel>
              <ConfidenceLabel $width={upperTextWidth}>{!!upperTextWidth && '자동 승인'}</ConfidenceLabel>
            </div>
          </div>
        ) : (
          <div className="confidence-content">
            <SingleSlider
              disabled={!edit}
              value={{
                value: lowerConfidence,
                setValue: (value) => updateHandler([value]),
              }}
              min={min}
              max={max}
            />
            <div className="confidence-label" ref={sliderRail}>
              <ConfidenceLabel $width={lowerTextWidth}>{!!lowerTextWidth && '자동 거부'}</ConfidenceLabel>
              <ConfidenceLabel $width={middleTextWidth}>{!!middleTextWidth && '자동 승인'}</ConfidenceLabel>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default ConfidenceSetting;
