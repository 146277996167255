import React from 'react';
import { Provider } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { Authentication, InterCeptor } from 'hoc/root';
import * as layout from 'layout';
import MyBox from 'layout/page/header/mybox/root/Popover_MyBox';
import TokenTimer from 'layout/page/header/tokenTimer/root/TokenTimer';
import { Root } from 'pages';
import { root } from 'store';

export default {
  path: '/root',
  element: (
    <Provider store={root.store}>
      <InterCeptor>
        <Outlet />
      </InterCeptor>
    </Provider>
  ),
  children: [
    { index: true, element: <Root.LoginPage /> } /* login page */,
    {
      path: '*',
      element: (
        <Authentication>
          <layout.Page
            tokenTimer={TokenTimer}
            myBox={MyBox}
            links={[
              { url: '/root/client-management', text: '고객사 관리' },
              { url: '/root/admin-management', text: '어드민 관리' },
              { url: '/root/terms-management', text: '약관 관리' },
              { url: '/root/terms-guidance-management', text: '약관 가이드' },
              { url: '/root/eddra-management', text: 'EDD / RA 관리' },
              { url: '/root/privacy-check', text: '관리자 로그' },
            ]}
          />
        </Authentication>
      ),
      children: [
        {
          path: 'client-management',
          element: <layout.root.Content />,
          children: [{ index: true, element: <Root.ClientPage /> }],
        },
        {
          path: 'admin-management',
          element: <layout.root.Content />,
          children: [{ index: true, element: <Root.AdminPage /> }],
        },
        {
          path: 'terms-management',
          element: <layout.root.Content />,
          children: [
            { path: 'privacy', element: <Root.Terms.PrivacyPage /> },
            { path: 'service', element: <Root.Terms.ServicePage /> },
            { path: '*', element: <Navigate to="privacy" /> },
          ],
        },
        {
          path: 'terms-guidance-management',
          element: <layout.root.Content />,
          children: [
            { path: 'privacy', element: <Root.Guidance.PrivacyPage /> },
            { path: 'service', element: <Root.Guidance.PolicyPage /> },
            { path: '*', element: <Navigate to="privacy" /> },
          ],
        },
        {
          path: 'eddra-management',
          element: <layout.root.Content />,
          children: [
            {
              element: <layout.root.Manage />,
              children: [
                {
                  path: 'nation',
                  element: <layout.root.SubManage.NationManage />,
                  children: [
                    { path: 'labels', element: <Root.EddRa.NationLabelPage /> },
                    { path: 'history', element: <Root.EddRa.NationHistoryPage /> },
                    { path: '*', element: <Navigate to="labels" /> },
                  ],
                },
                {
                  path: 'template',
                  element: <layout.root.SubManage.TemplateManage />,
                  children: [
                    { path: 'setting', element: <>template</> },
                    { path: '*', element: <Navigate to="setting" /> },
                  ],
                },
                { path: '*', element: <Navigate to="nation" /> },
              ],
            },
          ],
        },
        {
          path: 'privacy-check',
          element: <layout.root.Content />,
          children: [{ index: true, element: <Root.PrivacyCheckPage /> }],
        },
        { path: '*', element: <Navigate to="client-management" /> },
      ],
    },
  ],
};
