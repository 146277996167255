import React from 'react';
import CtLabelHistoryTable from './CtLabelHistoryTable';

function NationLabelHistoryContent() {
  return (
    <section id="root-eddra-nation-label">
      <CtLabelHistoryTable />
    </section>
  );
}

export default NationLabelHistoryContent;
