import React, { Dispatch } from 'react';
import { Button } from 'usebeauty';
import { Switch } from 'common/components';
import classNames from 'classnames';
import { Action, VAS } from '../../GeneralContent';
import './EditComp.scss';

interface Props {
  edit: boolean;
  onSwitch?: boolean;
  setEdit: Dispatch<boolean>;
  setState: Dispatch<Action>;
  data: { key: keyof VAS; value: boolean };
  handler: {
    loadHandler: () => void;
    submitHandler: () => void;
  };
  disabled?: boolean;
  setCencel?: () => void;
  isAllow?: boolean;
}

const EditComp = (props: Props) => {
  const { setState, data, setEdit, edit, handler, onSwitch = false, disabled, setCencel, isAllow = true } = props;
  const { key, value } = data;

  return (
    <div id="edit-wrap">
      {isAllow && onSwitch && edit && (
        <div className="switch-box">
          <Switch
            disabled={!edit}
            className={classNames({ readonly: !edit })}
            value={data.value}
            onChange={(item) => {
              setState({ type: 'vas', payload: { [key]: item === 1 } as AtLeastOne<VAS> });
            }}
          />
          <p className={classNames('switch-text', { active: value })}>{value ? '사용' : '사용안함'}</p>
        </div>
      )}
      {isAllow && onSwitch && !edit && (
        <div className={classNames('use-text', { active: value })}>{value ? '사용중' : '사용안함'}</div>
      )}

      <div className="edit-btn-box">
        {edit ? (
          <>
            <Button
              className="btn-edit btn-cancel"
              onClick={() => {
                setEdit(false);
                if (setCencel) setCencel();
                setState({ type: 'reset' });
              }}
            >
              취소
            </Button>
            <Button className="btn-edit btn-submit" onClick={handler.submitHandler} disabled={disabled}>
              저장
            </Button>
          </>
        ) : (
          <Button
            className="btn-edit"
            onClick={() => {
              if (isAllow) setEdit(!edit);
            }}
          >
            편집
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditComp;
