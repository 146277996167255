import React, { Dispatch } from 'react';
import { Action, State } from '../../ExaminationContent';
import DriverIssueDate from './driver/issueDate/DriverIssueDate';
import DriverExpiryDate from './driver/expiryDate/DriverExpiryDate';
import IdcardSsa from './ssa/SsaComp';
import './IdcardSetting.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const IdcardSetting = ({ state, setState, loadHandler }: Props) => {
  const { kDriver } = state.data;

  return (
    <>
      <div className="setting-area">
        <div className="setting-title">
          <h3>신분증 인증</h3>
        </div>
        {kDriver && <DriverIssueDate state={state} loadHandler={loadHandler} setState={setState} />}
        {kDriver && <DriverExpiryDate state={state} loadHandler={loadHandler} setState={setState} />}
        <IdcardSsa state={state} setState={setState} loadHandler={loadHandler} />
      </div>
    </>
  );
};

export default IdcardSetting;
