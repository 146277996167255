import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Switch } from 'common/components';
import { Action, Data } from '../../Expand';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

export const FaceComp = (props: Props) => {
  const { data, setState, edit } = props;
  const { module } = data;

  return (
    <div className="setting-item">
      <div className={classNames('switch-box', module.face_authentication ? 'active' : 'inactive')}>
        얼굴 인증
        <Switch
          disabled={!edit || !module.id_card_ocr}
          className={classNames({ readonly: !edit })}
          value={module.face_authentication}
          onChange={(value) => {
            setState({ type: 'module', payload: { face_authentication: !!value } });
            if (!value) setState({ type: 'module', payload: { liveness: !!value } });
          }}
        />
      </div>
      <div className="setting-item-content">
        <div className="category">
          <div className="cate-title">
            Passive Liveness
            <Switch
              disabled={!edit || !module.face_authentication}
              className={classNames({ readonly: !edit })}
              value={module.liveness}
              onChange={(value) => setState({ type: 'module', payload: { liveness: !!value } })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
