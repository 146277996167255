import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Switch } from 'common/components';
import { Action, Data } from '../../../../Expand';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

const WasmOcr = (props: Props) => {
  const { data, setState, edit } = props;
  const { config } = data;

  return (
    <>
      <div className="category border">
        <div className="cate-title">
          WASM OCR
          <Switch
            disabled={!edit}
            className={classNames({ readonly: !edit })}
            value={config.allow_wasm_ocr}
            onChange={(value) => setState({ type: 'config', payload: { allow_wasm_ocr: !!value } })}
          />
        </div>
      </div>
    </>
  );
};

export default WasmOcr;
