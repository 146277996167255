import React, { Dispatch } from 'react';
import classNames from 'classnames';
import type { Action } from './Input';

interface Props {
  setState: Dispatch<Action>;
  disabled?: boolean;
  type: string;
}

const LockIcon: React.FC<Props> = ({ setState, disabled, type }) => {
  return (
    <i
      className={classNames('input__pw__lock__icon', { open: !disabled && type === 'text', disabled })}
      onClick={() => {
        if (!disabled) setState({ type: 'type', value: type === 'text' ? 'password' : 'text' });
      }}
    />
  );
};

export default LockIcon;
