import React from 'react';
import classNames from 'classnames';
import type { Data as RowData } from '../../../../CtResultTable';
import { dateFormatter, Ocr } from '../IdCard';

interface Props {
  origin: Ocr;
  modified: Ocr | null;
  rowData: RowData;
  isModified: (keys: (keyof Ocr)[] | keyof Ocr) => boolean;
}

export const PassPort: React.FC<Props> = ({ origin, modified, rowData, isModified }) => {
  const issueDate = modified && isModified('issueDate') ? `${modified.issueDate}` : `${origin.issueDate}`;
  const expiryDate = modified && isModified('expiryDate') ? `${modified.expiryDate}` : `${origin.expiryDate}`;
  const birthDate = modified && isModified('birthDate') ? `${modified.birthDate}` : `${origin.birthDate}`;
  return (
    <>
      <div>
        <h5>· 이름</h5>
        <p className={classNames({ warning: isModified('userName') })}>{rowData.name}</p>
      </div>

      <div>
        <h5>· 여권 번호</h5>
        <p className={classNames({ warning: modified && isModified('passportNo') })}>
          {modified && isModified('passportNo') ? `${modified.passportNo}` : `${origin.passportNo}`}
        </p>
      </div>

      <div>
        <h5>· 생년월일</h5>
        <p className={classNames({ warning: modified && isModified('birthDate') })}>{dateFormatter(birthDate)}</p>
      </div>

      <div>
        <h5>· 발급 일자</h5>
        <p className={classNames({ warning: modified && isModified('issueDate') })}>{dateFormatter(issueDate)}</p>
      </div>

      <div>
        <h5>· 만료 일자</h5>
        <p className={classNames({ warning: modified && isModified('expiryDate') })}>{dateFormatter(expiryDate)}</p>
      </div>
    </>
  );
};
