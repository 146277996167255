/* eslint-disable no-debugger */
import { NavigateFunction } from 'react-router-dom';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, process } from 'common/functions';
import { Admin } from 'models';
import type { RootState } from './configStore';

type State = Admin.Auth.state;
type Data = Admin.Auth.data;

const initialState: State = { data: null, my: null, showLocked: false, timer: 0, isDuplicateLogin: false };

export const authSlice = createSlice({
  name: 'admin/auth/slice',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<Data>) => {
      state['data'] = payload;
      localStorage.setItem('ADMIN_TOKEN', JSON.stringify(payload));
    },
    init: (state) => {
      state['data'] = null;
      state['timer'] = 0;
      state['isDuplicateLogin'] = false;
    },
    setUser: (state, { payload }) => {
      state['my'] = payload;
    },
    unMount: (state) => {
      state['data'] = null;
      state['my'] = null;
      state['timer'] = 0;
    },
    showLocked: (state, { payload }) => {
      state['showLocked'] = payload;
    },
    setTimer: (state, { payload }) => {
      state['timer'] = payload;
    },
    setIsDuplicateLogin: (state, { payload }) => {
      state['isDuplicateLogin'] = payload;
    },
  },
});

const my = createAsyncThunk('admin/auth/mypage', (_, { dispatch }) => {
  process(
    api.reqData({ url: 'mypage' }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response) dispatch(authSlice.actions.setUser(response.data.account));
    })
  );
});

const unMount = createAsyncThunk('admin/auth/unmount', (_, { dispatch }) => {
  localStorage.removeItem('ADMIN_TOKEN');
  dispatch(authSlice.actions.unMount());
});

const logout = createAsyncThunk('admin/auth/logout', (payload: { navigate: NavigateFunction }, { dispatch }) => {
  dispatch(unMount());
  dispatch(authSlice.actions.init());
  payload.navigate('/admin');
});

// 로그인 인증시 에러 메세지 케이스
const errorMessage = (
  data: {
    api_response: { result_code: string; result_message: string };
    locked: boolean;
    login_fail_count: number;
  },
  pwCheck?: boolean
) => {
  const { api_response, login_fail_count } = data;

  // prettier-ignore
  switch (api_response.result_code) {
    case 'F301': return pwCheck ? '아이디 혹은 비밀번호를 확인해 주세요.' : `아이디 혹은 비밀번호를 확인해 주세요. (현재 ${login_fail_count ?? 1}회 오류)<br />로그인 실패가 5회 이상 발생 시 계정이 잠금처리됩니다.`;
    case 'F302': return '비활성화된 아이디입니다.'; 
    case 'F304': return '비활성화된 아이디입니다.';
    case 'F305': return '5회 이상 비밀번호 오류로 계정잠금 되었습니다.<br />관리자(root)에게 문의하세요.';
    case 'E580': return '데이터 베이스가 유효하지 않습니다.';
    default: return api_response.result_message === '접근 권한이 없음' ? '아이디 혹은 비밀번호를 확인해 주세요.' : api_response.result_message
  }
};

const pwCheck = createAsyncThunk('admin/auth/pwCheck', (password: string, { dispatch, getState }) => {
  if (!password) return '비밀번호를 입력하세요';
  const { auth } = getState() as RootState;

  if (auth && auth.data) {
    const { customer_id, username } = auth.data;
    return process(
      api.reqData({ url: 'sign-in', data: { customer_id, username, password } }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        if (response) dispatch(authSlice.actions.setData({ ...response.data, customer_id }));
        if (error) return errorMessage(error.data, true);
        return '';
      })
    );
  }
  return '사용자 정보가 존재하지 않습니다.';
});

const login = createAsyncThunk(
  'admin/auth/login',
  (payload: { customer_id: number; username: string; password: string; navigate: NavigateFunction }, { dispatch }) => {
    const { customer_id, username, password, navigate } = payload;
    if (!username) return '아이디를 입력하세요.';
    if (!password) return '비밀번호를 입력하세요.';
    return process(
      api.reqData({ url: 'sign-in', data: payload }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        if (response) {
          const { role, is_temp_password } = response.data;
          if (role !== 0) return '아이디 혹은 비밀번호를 확인해 주세요.';
          dispatch(authSlice.actions.setData({ ...response.data, customer_id: payload.customer_id }));
          dispatch(authSlice.actions.setTimer(1000 * 60 * 30 - 1000)); // 토큰 만료 30분 타이머, bumper로 1초 뺐음
          if (is_temp_password) {
            navigate('/admin/temp-pass-modify/admin', { state: { customer_id, username, password } });
          } else navigate('/admin/reviewer-management');
        }
        if (error) {
          const accountLocked = error.data.account_locked;
          const count = error.data.login_fail_count;
          if (count === 0) return errorMessage(error.data, true);
          if (!accountLocked) {
            if (count === 5) dispatch(authSlice.actions.showLocked(true));
          } else {
            dispatch(authSlice.actions.showLocked(false));
          }
          return errorMessage(error.data);
        }
        return '';
      })
    );
  }
);

const timer = createAsyncThunk('admin/auth/timer', (payload: { time: number }, { dispatch }) => {
  dispatch(authSlice.actions.setTimer(payload.time));
});

const refreshToken = createAsyncThunk('admin/auth/refreshToken', (_, { dispatch, getState }) => {
  const { auth } = getState() as RootState;

  return process(
    api.reqData({ url: '/refresh-jwt ' }),
    api.post.request,
    api.fullFilled(({ response, error }) => {
      if (response) {
        dispatch(authSlice.actions.setData({ ...auth.data, ...response.data })); // store 저장
        const MINUTE_30 = 1000 * 60 * 30 - 1000; // 토큰 만료 30분 타이머, bumper로 1초 뺐음
        const remain = Math.min(MINUTE_30, new Date(response.data.expire).getTime());
        dispatch(authSlice.actions.setTimer(remain));
        return response;
      }
      return error;
    })
  );
});

export const authActions = { ...authSlice.actions, login, my, logout, pwCheck, timer, refreshToken, unMount };
