import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import classNames from 'classnames';
import { api, process } from 'common/functions';
import { Button, CheckBox, Input, Search, Validator } from 'common/components';
import { ContentContext } from 'layout/root/content/Content';
import { modal } from 'layout';
import { NationRisk } from '../label/CtNationLabel';
import './Modal_Nation.scss';

function SortIcon(props: { sortBy: string }) {
  const { sortBy } = props;
  const asc = sortBy === 'ASC';
  const desc = sortBy === 'DESC';
  return <i className={classNames('sort__icon', { asc, desc })} />;
}

interface NationTableProps {
  nations: Nation[];
  className?: string;
  title: string;
  sort?: { sortBy: 'ASC' | 'DESC' | 'default'; sortHandler: (sortBy: 'ASC' | 'DESC' | 'default') => void };
  checkedLen: number;
  checkAllHandler: (checked: boolean) => void;
  children: (nation: Nation) => JSX.Element;
}
function NationTable(props: NationTableProps) {
  const { nations, sort, title, children, checkedLen, checkAllHandler } = props;
  const checkAll = !!checkedLen && checkedLen === nations.length;

  const sortHandler = () => {
    if (sort && sort.sortHandler) sort.sortHandler(sort.sortBy);
  };

  const sorted = sort?.sortBy !== 'default';

  return (
    <div className={classNames('nations__table', props.className)}>
      <div className="head">
        <h4 onClick={sortHandler} className={classNames({ sorted })}>
          {title}
          {sort && <SortIcon sortBy={sort.sortBy} />}
        </h4>
        <div>
          <CheckBox text="전체 선택" checked={checkAll ? 1 : 0} onChange={(check) => checkAllHandler(!!check)} />
          <span className={classNames({ disabled: !checkedLen })} onClick={() => checkAllHandler(false)}>
            선택 해제
          </span>
        </div>
      </div>
      <div className="content">
        {nations.length ? nations.map((nation) => children(nation)) : <div>선택된 국가가 없습니다</div>}
      </div>
    </div>
  );
}

type Action =
  | { type: 'searchText'; payload: string }
  | { type: 'nations'; payload: Nation[] }
  | { type: 'score'; payload: number }
  | { type: 'sort'; payload: Sort };
type Nation = NationRisk & { selected: boolean; checked: boolean };
type Sort = { available: 'ASC' | 'DESC' | 'default'; selected: 'ASC' | 'DESC' | 'default' };
type State = { searchText: string; nations: Nation[]; score: number; sort: Sort };

const initialState: State = {
  searchText: '',
  nations: [],
  score: 0,
  sort: { available: 'default', selected: 'default' },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'searchText':
        draft['searchText'] = action.payload;
        break;
      case 'nations':
        draft['nations'] = action.payload;
        break;
      case 'score':
        draft['score'] = action.payload;
        break;
      case 'sort':
        draft['sort'] = action.payload;
        break;
      default:
        break;
    }
  });
};

interface Props {
  closeHandler: () => void;
  submitHandler: (args: { score: number; nations: NationRisk[] }) => void;
  nations: NationRisk[];
  warnLabel: string;
  score: number;
}
function Modal_Nation(props: Props) {
  const { setLoading } = useContext(ContentContext);
  const { closeHandler, submitHandler, nations: item, warnLabel, score } = props;
  const [state, setState] = useReducer(reducer, initialState);

  useEffect(() => {
    setLoading(true);
    process(
      api.reqData({ url: `country?warn_labels=false` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response?.data.list) {
          const { list: nationsAll }: { list: NationRisk[] } = response?.data;
          const nations = nationsAll.map((nation) => {
            if (item.filter((el) => el.id === nation.id).length) {
              return { ...nation, checked: false, selected: true };
            }
            return { ...nation, checked: false, selected: false };
          });
          setState({ type: 'nations', payload: nations });
          setState({ type: 'score', payload: score });
          setLoading(false);
        }
      })
    );
  }, []);

  const onSubmit = () => {
    const selectedNations = state.nations.filter((nation) => nation.selected);
    submitHandler({ score: state.score, nations: selectedNations });
    closeHandler();
  };

  const searchHandler = (searchText: string) => {
    setState({ type: 'searchText', payload: searchText });
  };

  const checkAllHandler = (selected: boolean) => (checked: boolean) => {
    const nations = state.nations.map((nation) => (nation.selected === selected ? { ...nation, checked } : nation));
    setState({ type: 'nations', payload: nations });
  };

  const checkEachHandler = (id: number, checked: boolean) => {
    const nations = state.nations.map((nation) => (nation.id === id ? { ...nation, checked } : nation));
    setState({ type: 'nations', payload: nations });
  };

  const appendHandler = () => {
    const nations = state.nations.map((nation) =>
      !nation.selected && nation.checked ? { ...nation, selected: true, checked: false } : nation
    );
    setState({ type: 'nations', payload: nations });
  };

  const removeHandler = () => {
    const nations = state.nations.map((nation) =>
      nation.selected && nation.checked ? { ...nation, selected: false, checked: false } : nation
    );
    setState({ type: 'nations', payload: nations });
  };

  const filteredNations = state.nations.filter((nation) => nation.name_kr.includes(state.searchText));

  const availableNations = [...filteredNations]
    .sort(() => {
      if (state.sort.available === 'ASC') return -1;
      if (state.sort.available === 'DESC') return 1;
      return 0;
    })
    .filter((nation) => !nation.selected);
  const selectedNations = [...filteredNations]
    .sort(() => {
      if (state.sort.selected === 'ASC') return -1;
      if (state.sort.selected === 'DESC') return 1;
      return 0;
    })
    .filter((nation) => nation.selected);
  const availableNationsCheckedLen = availableNations.filter((nation) => nation.checked).length;
  const selectedNationsCheckedLen = selectedNations.filter((nation) => nation.checked).length;

  return (
    <Validator.Provider onSubmit={onSubmit}>
      <modal.Container id="modal-root-ra-item-nations-setting" styles={{ width: 1016 }}>
        <modal.Header>{warnLabel} 국가 설정</modal.Header>
        <modal.Content>
          <div className="root__nations__controller">
            <div className="searchbox__filter">
              <h4>국가 검색</h4>
              <Search
                placeholder="국가명을 검색하세요."
                submitHandler={searchHandler}
                styles={{ width: '100%' }}
                onChange={searchHandler}
              />
            </div>
            <div className="score">
              <h4>점수</h4>
              <Input
                type="text"
                className="basic"
                styles={{ height: 40 }}
                value={state.score}
                onChange={(e) => {
                  const { value } = e.target;
                  let numValue = Number(value.replace(/[^\d]/g, ''));
                  numValue = numValue > 100 ? 100 : numValue;
                  setState({ type: 'score', payload: numValue });
                }}
              />
            </div>
          </div>
          <div className="nations__container">
            <NationTable
              nations={availableNations}
              className="available"
              title="선택 가능"
              sort={{
                sortBy: state.sort.available,
                sortHandler: (sortBy: 'ASC' | 'DESC' | 'default') => {
                  // eslint-disable-next-line no-nested-ternary
                  const sort = sortBy === 'default' ? 'ASC' : sortBy === 'ASC' ? 'DESC' : 'default';
                  setState({ type: 'sort', payload: { ...state.sort, available: sort } });
                },
              }}
              checkedLen={availableNationsCheckedLen}
              checkAllHandler={checkAllHandler(false)}
            >
              {(nation) => (
                <div key={`${nation.iso_code3}`} className={classNames('nation', { checked: nation.checked })}>
                  <span>
                    <CheckBox
                      checked={nation.checked ? 1 : 0}
                      onChange={(check) => checkEachHandler(nation.id, !!check)}
                      text={nation.name_kr}
                    />
                  </span>
                </div>
              )}
            </NationTable>
            <div className="control">
              <Button type="button" className="append" disabled={!availableNationsCheckedLen} onClick={appendHandler}>
                <>
                  추가
                  <i />
                </>
              </Button>
              <Button type="button" className="remove" disabled={!selectedNationsCheckedLen} onClick={removeHandler}>
                <>
                  삭제
                  <i />
                </>
              </Button>
            </div>
            <NationTable
              nations={selectedNations}
              className="selected"
              title="선택 됨"
              sort={{
                sortBy: state.sort.selected,
                sortHandler: (sortBy: 'ASC' | 'DESC' | 'default') => {
                  // eslint-disable-next-line no-nested-ternary
                  const sort = sortBy === 'default' ? 'ASC' : sortBy === 'ASC' ? 'DESC' : 'default';
                  setState({ type: 'sort', payload: { ...state.sort, selected: sort } });
                },
              }}
              checkedLen={selectedNationsCheckedLen}
              checkAllHandler={checkAllHandler(true)}
            >
              {(nation) => (
                <div key={`${nation.iso_code3}`} className={classNames('nation', { checked: nation.checked })}>
                  <span>
                    <CheckBox
                      checked={nation.checked ? 1 : 0}
                      onChange={(check) => checkEachHandler(nation.id, !!check)}
                      text={nation.name_kr}
                    />
                  </span>
                </div>
              )}
            </NationTable>
          </div>
        </modal.Content>
        <modal.Footer disabled={!selectedNations.length} closeHandler={closeHandler} submitText="설정" />
      </modal.Container>
    </Validator.Provider>
  );
}

export default Modal_Nation;
