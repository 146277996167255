import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Input, Popover, Switch } from 'common/components';
import type { Action, Data, Error } from '../Expand';
import './Addons.scss';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
  error: Error;
}

const Addons = ({ edit, data, setState, error }: Props) => {
  const { config } = data;

  const privacyErrorHander = (value: number) => {
    if (value === 0) return '1보다 높아야 합니다.';
    if (value > 1825) return '1825일 (=5년)을 초과할 수 없습니다.';
    return '';
  };

  const reuseLimitMinuteErrorHnadler = (value: number) => {
    if (value === 0) return '1보다 높아야 합니다.';
    if (value > 1825) return '1825일 (=5년)을 초과할 수 없습니다.';
    if (value > config.keep_privacy_day) return '개인정보 보존기간을 초과할 수 없습니다.';
    return '';
  };

  return (
    <article className="root-page-client-expand-addons">
      <h5>부가 기능 설정</h5>
      <div className="addons-container">
        <div className="addons-item">
          <p className="extras-title">고객 신원 확인 결과 알림</p>
          <div className="category">
            <div className="cate-title">
              이메일 발송
              <Switch
                disabled={!edit}
                className={classNames({ readonly: !edit })}
                value={config.email_notification}
                onChange={(value) => setState({ type: 'config', payload: { email_notification: !!value } })}
              />
            </div>
          </div>
          <div className="category">
            <div className="cate-title">
              SMS 발송
              <Switch
                disabled={!edit}
                className={classNames({ readonly: !edit })}
                value={config.sms_notification}
                onChange={(value) => setState({ type: 'config', payload: { sms_notification: !!value } })}
              />
            </div>
          </div>
        </div>
        <div className="addons-item">
          <div className="extras-title">
            <h4>개인정보 수집 설정</h4>
            <Popover
              clickOutside
              position="right center"
              content={() => (
                <div id="root-page-client-expand-popover" className="addon">
                  사용자 작성 정보 보존 기간 설정입니다. <br />
                  최소 1일, 최대 5년(1825일) 설정 가능합니다.
                </div>
              )}
            >
              <i className="tooltip" />
            </Popover>
          </div>

          <div className="category">
            <div className="cate-title">
              <div>개인정보 보존기간</div>
              <div className="flex-box">
                <Input
                  min="0"
                  type="number"
                  readonly={!edit}
                  className={classNames('input', { error: error.privacy })}
                  value={config.keep_privacy_day.toString()}
                  onChange={(e) => {
                    const { value } = e.target;
                    setState({ type: 'error', payload: { privacy: privacyErrorHander(Number(value)) } });
                    const countinueResult = config.reuse_limit_minute / 1440 > Number(value) ? '개인정보 보존기간을 초과할 수 없습니다.' : ''; // prettier-ignore
                    setState({ type: 'error', payload: { countinue: countinueResult } });
                    setState({ type: 'config', payload: { keep_privacy_day: Number(value) } });
                  }}
                />
                일
              </div>
            </div>
            {error.privacy && <div className="error-title">{error.privacy}</div>}
          </div>

          <div className="category hr">
            <div className="cate-title">
              이어하기 사용
              <Switch
                disabled={!edit}
                className={classNames({ readonly: !edit })}
                value={config.allow_reuse}
                onChange={(value) => {
                  setState({ type: 'config', payload: { allow_reuse: !!value } });
                  if (!value) {
                    setState({ type: 'config', payload: { reuse_limit_minute: 1440 } });
                    setState({ type: 'error', payload: { countinue: '' } });
                  }
                }}
              />
            </div>
          </div>
          {config.allow_reuse && (
            <div className="category reuse">
              <div className="cate-title">
                <div className="continue-title">이어하기 기간</div>
                <div className="flex-box">
                  <Input
                    min="0"
                    max="1826"
                    type="number"
                    rules={[{ max: 1826 }]}
                    className={classNames('input', { error: error.countinue })}
                    readonly={!edit}
                    value={(config.reuse_limit_minute / 1440).toString()}
                    onChange={(e) => {
                      const { value } = e.target;
                      setState({ type: 'error', payload: { countinue: reuseLimitMinuteErrorHnadler(Number(value)) } });
                      setState({ type: 'config', payload: { reuse_limit_minute: Number(value) * 1440 } });
                    }}
                  />
                  일
                </div>
              </div>
              {error.countinue && <div className="error-title">{error.countinue}</div>}
            </div>
          )}
        </div>
        <div className="addons-item">
          <p className="extras-title">테마 설정</p>
          <div className="category">
            <div className="cate-title">
              커스텀 테마 사용
              <Switch
                className={classNames({ readonly: !edit })}
                disabled={!edit}
                value={config.allow_custom_theme}
                onChange={(value) => {
                  setState({ type: 'config', payload: { allow_custom_theme: !!value } });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Addons;
