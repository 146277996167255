import React, { Dispatch } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dialog, Pagination, Table } from 'usebeauty';
import { Controller } from 'common/components';
import { time } from 'common/functions';
import classNames from 'classnames';
import * as M from 'models';
import type { Action, State } from './CtPrivacyTable';
import Expand from './expand/Expand';
import ModalRegistPrivacy from './modal/Modal_RegistPrivacy';
import './PrivacyTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const PrivacyTable: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  const { header, data, pagination } = state;

  const handlers = {
    refresh: loadHandler,
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  return (
    <section id="root-terms-privacy-table">
      <Controller
        title={
          <>
            <NavLink to="/root/terms-management/privacy">개인정보처리방침</NavLink>
            <NavLink to="/root/terms-management/service">이용 약관</NavLink>
          </>
        }
      >
        <Dialog
          content={({ closeHandler }) => <ModalRegistPrivacy closeHandler={closeHandler} refresh={handlers.refresh} />}
        >
          <Button className="register">+ 개정</Button>
        </Dialog>
      </Controller>

      <Table>
        <Table.Head data={header} />
        <Table.Body data={data}>
          {({ index, rowData }) => {
            return (
              <Table.Row key={rowData.id} st={{ height: rowData.expand ? 'auto' : 72 }}>
                <>
                  <Table.Tr data={rowData}>
                    {({ value, property }) => {
                      if (property === 'created_at') {
                        const epoch = new Date(value as string).getTime();
                        return time.timeFormatFromUTCEpoch(epoch, 3);
                      }
                      if (property === 'expand')
                        return (
                          <i
                            className={classNames('expand-icon', { expanded: rowData.expand })}
                            onClick={() => setState({ type: 'expand', payload: index })}
                          />
                        );
                      return value || '-';
                    }}
                  </Table.Tr>
                  {rowData.expand && <Expand rowData={rowData} />}
                </>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination data={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default PrivacyTable;
