import React, { useReducer } from 'react';
import { KycLoading, M_Table } from 'common/components';
import { api } from 'common/functions';
import { useApiCancel } from 'common/hooks';
import { getNotices } from 'common/api';
import { produce } from 'immer';
import type { Pagination } from 'models';
import { useKycQuery } from 'common/api/usequery';
import PrivacyTable from './PrivacyTable';

const initPagination = { info: { current: 1, total: 0 }, format: { limit: 20, offset: 0 } };
export type Data = typeof getNotices.res;

export type Action =
  | { type: 'setData'; payload: Data[] }
  | { type: 'expand'; payload: number }
  | { type: 'pagination'; payload: Pagination }
  | { type: 'loading'; payload: boolean };

export interface State {
  data: Data[];
  header: M_Table.header[];
  pagination: Pagination;
}

const initialState: State = {
  data: [],
  pagination: { ...initPagination },
  header: [
    { title: '등록일', property: 'created_at', flex: 2, align: 'center' },
    { title: '버전', property: 'version', flex: 2, align: 'center' },
    { title: '상세보기', property: 'expand', align: 'center' },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'expand': draft.data[action.payload]['expand'] = !draft.data[action.payload].expand; break;
      case 'pagination': draft['pagination'] = action.payload; break;
    }
  });
};

const CtPrivacyTable: React.FC = () => {
  useApiCancel(['notices/pp']);
  const [state, setState] = useReducer(reducer, initialState);

  const params = {
    start: state.pagination.format.offset,
    count: state.pagination.format.limit,
  };

  const { refetch, isFetching } = useKycQuery<{ list: (typeof getNotices.res)[]; total_count: number }>({
    key: ['getNotices', state.pagination.info.current],
    handler: () => getNotices('pp', params),
    options: {
      onSuccess: (response) => {
        const { list, total_count } = response.data;
        const payload = list.map((el: Omit<Data, 'expand'>) => ({ ...el, expand: false }));
        setState({ type: 'setData', payload });
        setState({ type: 'pagination', payload: api.setTotal(state.pagination, total_count) });
      },
    },
  });

  return (
    <>
      {isFetching && <KycLoading />}
      <PrivacyTable state={state} setState={setState} loadHandler={refetch} />
    </>
  );
};

export default CtPrivacyTable;
