import React from 'react';
import classNames from 'classnames';
import { useEddConext } from '../../../context/EddContext';
import { EddRa } from '../../../context/model.type';
import './NationRisk.scss';

interface Props {
  use_ra: boolean;
}
function NationRisk({ use_ra }: Props) {
  const { edit, state, setState } = useEddConext();
  const { edd_category } = state.data;
  const countryCategory = edd_category.find((el) => el.edd_type === 'country') as EddRa.EddCategory;

  return (
    <article className={classNames('edd-setting-nation-risk', { disabled: !countryCategory.active })}>
      <div className="title" onClick={() => setState({ type: 'detailPage', payload: 'country' })}>
        <h4>국가 위험</h4>
        <div className="score">
          {use_ra && (
            <>
              <div>
                <span>부여 점수</span>
                <span>100점</span>
              </div>
              <div>
                {edit && (
                  <>
                    <span>남은 점수</span>
                    <span className={classNames('extra-score')}>0점</span>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <i />
      </div>
    </article>
  );
}

export default NationRisk;
