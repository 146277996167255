import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FlexBox, Form } from '@usebeauty';
import { Button, Dialog, Loading } from 'usebeauty';
import { Alert, Input, TextArea } from 'common/components';
import { Layer, modal } from 'layout';
import { api, process } from 'common/functions';
import classNames from 'classnames';
import { excutions } from 'common/validations';
import './Modal_RegistPrivacy.scss';

interface Props {
  closeHandler: () => void;
  refresh: () => void;
}

const defaultValues = { data: [{ title: '', content: '' }], key: '', check: false, message: '' };

const ModalRegistPrivacy: React.FC<Props> = ({ closeHandler, refresh }) => {
  const { set } = Alert.Context();
  const [loading, setLoading] = useState(false);
  const { control, watch, setValue } = useForm({ mode: 'onChange', defaultValues });
  const field = watch();

  // key 중복 확인
  const dupCheckHandler = () => {
    if (!field.key.includes('guide-pa')) {
      setValue('message', "입력값에 'guide-pa' 가 포함되어야 합니다.");
      setValue('check', false);
      return;
    }

    process(
      api.reqData({ url: 'guides/check/key', data: { key: field.key } }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        if (response) {
          const { is_available } = response.data;
          setValue('check', !!is_available);
          setValue('message', is_available ? '등록 가능한 약관 Key 입니다.' : '이미 등록된 약관 Key 입니다.');
        }

        if (error) {
          const { result_code } = error.data.api_response;
          if (result_code === 'E531') {
            setValue('message', '약관 Key를 입력하세요');
          }
          setValue('check', false);
        }
      })
    );
  };

  const submitHandler = async () => {
    // 버전 중복 체크
    if (!field.check) {
      setValue('message', '중복여부를 확인하세요.');
      return;
    }

    setLoading(true);
    const params = { key: field.key, content: btoa(encodeURIComponent(JSON.stringify(field.data))) };
    process(
      api.reqData({ url: 'guides', data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data.api_response;
          if (result_code === 'N100') {
            closeHandler();
            set({ success: '약관 가이드 등록이 완료되었습니다.' });
            refresh();
          }
        }
      })
    );
    setLoading(false);
  };

  const isEmpty = field.data.some((el) => !el.title.length || !el.content.length);
  const 중복체크안함 = field.key && !field.check && !field.message;
  const 중복확인메세지 = field.message;
  const error = (중복확인메세지 && !field.check) || 중복체크안함;

  const termsKey = excutions.termsKey.regExp(field.key);
  const orderedErrorMessage = () => {
    if (termsKey) return '영문 소문자, 숫자, 특수문자(-)만 사용 가능합니다.';
    if (중복체크안함) return '중복여부를 확인하세요.';
    if (중복확인메세지) return 중복확인메세지;
    return '';
  };

  return (
    <>
      {loading && <Loading />}
      <Dialog.Base id="root-guidance-privacy-regist" st={{ width: 720, maxHeight: 800 }}>
        <Dialog.Summary>약관 등록</Dialog.Summary>
        <Dialog.Content>
          <modal.Heading>약관 Key</modal.Heading>
          <div className="duplicate-check">
            <FlexBox st={{ gap: 10, alignItems: 'initial' }}>
              <Form.Input
                st={{ width: 540 }}
                control={control}
                name="key"
                maxLength={50}
                rules={{ maxLength: 50, required: '약관 key를 입력하세요.' }}
                placeholder="약관 Key를 입력하세요"
                className={classNames('basic', { error })}
                onChange={() => {
                  setValue('message', '');
                  setValue('check', false);
                }}
              />
              <Button onClick={dupCheckHandler}>중복확인</Button>
            </FlexBox>

            <div className={classNames('api-check-message', { error: !field.check })}>{orderedErrorMessage()}</div>
          </div>

          <modal.Heading>약관 내용 (HTML)</modal.Heading>
          {field.data.map((el, i) => {
            const showAdd = field.data.length - 1 === i;
            const showRemove = field.data.length > 1;
            const multiple = showAdd && showRemove;
            return (
              <Layer key={i} styles={{ marginTop: 8, marginBottom: 20 }} className="terms-layer">
                <div className="content">
                  <Input
                    placeholder="제목을 입력하세요."
                    className="basic"
                    validType="realtime"
                    rules={['required', { maxLength: 100 }]}
                    value={el.title}
                    styles={{ width: '100%' }}
                    onChange={(e) => {
                      const assingData = [...field.data];
                      assingData[i].title = e.target.value;
                      setValue('data', assingData);
                    }}
                  />
                  <TextArea
                    placeholder="약관 내용은 HTML 형태로 입력하세요."
                    styles={{ width: '100%', height: 274 }}
                    value={el.content}
                    rules={['required']}
                    onChange={(e) => {
                      const assingData = [...field.data];
                      assingData[i].content = e.target.value;
                      setValue('data', assingData);
                    }}
                  />
                </div>
                <div className="utils">
                  {showAdd && (
                    <i
                      className={classNames('add', { multiple })}
                      onClick={() => {
                        const assignData = [...field.data, { title: '', content: '' }];
                        setValue('data', assignData);
                      }}
                    />
                  )}
                  {showRemove && (
                    <i
                      className={classNames('remove', { multiple })}
                      onClick={() => {
                        const assignData = [...field.data].splice(i, 1);
                        setValue('data', assignData);
                      }}
                    />
                  )}
                </div>
              </Layer>
            );
          })}
        </Dialog.Content>
        <Dialog.Actions>
          <Button className="cancel" onClick={closeHandler}>
            취소
          </Button>
          <Button onClick={submitHandler} disabled={!field.check || isEmpty}>
            등록
          </Button>
        </Dialog.Actions>
      </Dialog.Base>
    </>
  );
};

export default ModalRegistPrivacy;
