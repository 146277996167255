/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Doughnut as DoughnutJS } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, DoughnutController, Legend, Tooltip } from 'chart.js';
import type { Align, ChartData, ChartOptions, LayoutPosition, Plugin } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { merge } from 'lodash';
import { findPointAtDistance } from '../func';

ChartJS.register(ArcElement, DoughnutController, Tooltip, Legend, ChartDataLabels);

type Data = {
  label: string;
  value: number;
};
interface Props {
  legendPosition?: LayoutPosition;
  chartData: Data[] | undefined;
  datasetBackgroundColor?: string[];
  datasetLabelColor?: string[];
  datasetLabelFormatter?: (value: string) => string;
  chartOptions?: ChartOptions<'doughnut'>;
}

const Doughnut = (props: Props) => {
  const {
    legendPosition = 'right',
    chartData,
    datasetBackgroundColor = ['#EA2940', '#EE5466', '#F27F8C', '#F7A9B3', '#FDEAEC', '#ABACAD'],
    datasetLabelColor = ['#FFF', '#FFF', '#FFF', '#EA2940', '#EA2940'],
    datasetLabelFormatter = (value: string) => `${value}건`,
    chartOptions,
  } = props;

  const legendOptions = {
    position: legendPosition,
    align: 'center' as Align,
    labels: {
      borderRadius: 13,
      boxWidth: 10,
      boxHeight: 10,
      pointStyle: 'circle',
      usePointStyle: true,
      useBorderRadius: true,
      padding: 20,
      font: {
        size: 16,
        weight: 500,
        family: 'Pretendard',
      },
    },
  };
  const defaultOptions: ChartOptions<'doughnut'> = {
    animation: false,
    aspectRatio: 1.5,
    plugins: {
      legend: legendOptions,
      datalabels: {
        labels: {
          label: {
            font: {
              size: 14,
              weight: 700,
              family: 'Pretendard',
            },
          },
        },
      },
    },
  };

  const _options: ChartOptions<'doughnut'> = merge(defaultOptions, chartOptions);

  const _total = chartData?.reduce((acc, cur) => acc + cur.value, 0) ?? 0;

  const DATALABEL_THRESHOLD_RATE = 3;
  const isLessThanDatalabelThreshold = (value: number) => (value / _total) * 100 < DATALABEL_THRESHOLD_RATE;

  const _data: ChartData<'doughnut'> = {
    labels: chartData?.map((d) => d.label),
    datasets: [
      {
        data: chartData?.map((d) => d.value) ?? [],
        datalabels: {
          color: datasetLabelColor,
          formatter: (value: number) => (isLessThanDatalabelThreshold(value) ? `` : datasetLabelFormatter(`${value}`)),
        },
        backgroundColor: datasetBackgroundColor,
        borderWidth: 1,
      },
    ],
  };

  const customDatalabels: Plugin<'doughnut'> = {
    id: 'customDatalabels',
    afterDatasetsDraw(chart) {
      const {
        ctx,
        data,
        chartArea: { width, height },
      } = chart as ChartJS<'doughnut'>;

      ctx.save();

      const displayDataLabelIndex = (chart as any).$datalabels._labels.map((l: any) => l._index);
      const halfWidth = width / 2;
      const halfHeight = height / 2; // 반지름 r

      const doughnutThickness = halfHeight / 2; // cutout "50%"
      const halfDoughnutThickness = doughnutThickness / 2;

      const { data: chartData } = data.datasets[0];
      const _total = chartData.reduce((acc, cur) => acc + cur, 0);

      const { data: datasetMeta } = chart.getDatasetMeta(0);
      const { x: cx, y: cy } = datasetMeta[0]; // chart  도넛의 중심좌표

      chartData.forEach((datapoint, index) => {
        const { x, y } = datasetMeta[index].tooltipPosition(false);
        const _dataRate = (datapoint / _total) * 100;

        const isDiaply = displayDataLabelIndex.includes(index);

        if (_dataRate < DATALABEL_THRESHOLD_RATE && _dataRate > 0 && isDiaply) {
          const direction = x <= halfWidth ? -1 : 1;
          const { x: sx, y: sy } = findPointAtDistance(cx, cy, x, y, halfDoughnutThickness * direction);
          const { x: dx, y: dy } = findPointAtDistance(cx, cy, x, y, (halfDoughnutThickness + 5) * direction);
          const { x: tx, y: ty } = findPointAtDistance(cx, cy, x, y, (halfDoughnutThickness + 15) * direction);

          const xBump = (chartData.length - index) * direction;
          const yBump = index * direction;

          ctx.font = `bold 11px Pretendard`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.strokeStyle = '#CED1D2';
          ctx.fillStyle = '#363636';
          ctx.beginPath();
          ctx.moveTo(sx, sy);
          ctx.lineTo(dx, dy);
          ctx.lineTo(dx + 10 * xBump, dy + yBump);
          ctx.stroke();
          ctx.fillText(`${datapoint}건`, tx + 20 * xBump, ty + yBump);
        }
      });
    },
  };

  const _plugins: Plugin<'doughnut'>[] = [customDatalabels];

  return <DoughnutJS data={_data} options={_options} plugins={_plugins} />;
};

export default Doughnut;
