import React from 'react';
import classNames from 'classnames';
import ConfidenceSetting from './setting/ConfidenceSetting';
import './FaceConfidence.scss';

function _generateMessage(ratio: string, total: string, type: 'FNMR' | 'FMR', isLiveness: boolean) {
  const compareText = type === 'FNMR' ? '본인거부발생' : '타인수락발생';
  const livenessText = type === 'FNMR' ? '실물얼굴 거부 발생' : '가짜 얼굴 수락 발생';
  return `${total ?? 0}회 중 ${ratio ?? 0}회 정도 ${isLiveness ? livenessText : compareText}`;
}

function _refer(ref: I_Reference) {
  return [ref.fnmr || ref.frr || '', ref.fmr || ref.far || ''];
}

interface I_Reference {
  lv: number;
  fmr?: string; // 얼굴비교 본인거부율
  fnmr?: string; // 얼굴비교 타인수락률
  frr?: string; // 라이브니스 오거부율
  far?: string; // 라이브니스 오수락률
}
interface Props {
  data: number[];
  edit?: boolean;
  onChange?: (value: number[]) => void;
  reference: unknown;
}
export function FaceConfidence(props: Props) {
  const { data, edit = true, onChange, reference } = props;
  const [rangeLower, rangeUpper] = data;
  const isLiveness = data.length === 1;

  const lowerRefer = (reference as I_Reference[]).map((r) => _refer(r))[rangeLower - 1] ?? [];
  const upperRefer = (reference as I_Reference[]).map((r) => _refer(r))[rangeUpper - 1] ?? [];

  return (
    <section className="face-confidence-container">
      <div className="face-confidence-setting-wrapper">
        <ConfidenceSetting
          edit={edit}
          confidence={data}
          max={rangeUpper ? 16 : 7}
          updateHandler={(confidence) => {
            if (onChange) onChange(confidence);
          }}
        />
      </div>
      <div className="face-confidence-description-wrapper">
        <div className="content">
          <div className={classNames('face-confidence-description-content', { liveness: isLiveness })}>
            <h4 className="description-title">
              <span>{rangeLower}단계 미만</span>
              <span className="lower">자동 거부</span>
            </h4>
            {isLiveness && (
              <h4 className={classNames('description-title', { liveness: isLiveness })}>
                <span>{rangeLower}단계 이상</span>
                <span className="upper">자동 승인</span>
              </h4>
            )}
            <div className="description-content">
              <div className={classNames('description', { liveness: isLiveness })}>
                <p className={classNames('title', { liveness: isLiveness })}>
                  <span>{isLiveness ? `오거부율(FRR)` : `본인거부율(FNMR)`} : </span>
                  <span>{lowerRefer[0]}</span>
                </p>
                <p className="desc">
                  {_generateMessage(lowerRefer[0]?.split(' / ')[0], lowerRefer[0]?.split(' / ')[1], 'FNMR', isLiveness)}
                </p>
              </div>
              <div className={classNames('description', { liveness: isLiveness })}>
                <p className={classNames('title', { liveness: isLiveness })}>
                  <span>{isLiveness ? `오수락률(FAR)` : `타인수락률(FMR)`} : </span>
                  <span>{lowerRefer[1]}</span>
                </p>
                <p className="desc">
                  {_generateMessage(lowerRefer[1]?.split(' / ')[0], lowerRefer[1]?.split(' / ')[1], 'FMR', isLiveness)}
                </p>
              </div>
            </div>
          </div>
          {!isLiveness && (
            <>
              <div className="division" />
              <div className="face-confidence-description-content">
                <h4 className="description-title">
                  <span>{rangeUpper}단계 이상</span>
                  <span className="upper">자동 승인</span>
                </h4>

                <div className="description-content">
                  <div className="description">
                    <p className="title">
                      <span>본인거부율(FNMR) : </span>
                      <span>{upperRefer[0]}</span>
                    </p>
                    <p className="desc">
                      {_generateMessage(
                        upperRefer[0]?.split(' / ')[0],
                        upperRefer[0]?.split(' / ')[1],
                        'FNMR',
                        isLiveness
                      )}
                    </p>
                  </div>
                  <div className="description">
                    <p className="title">
                      <span>타인수락률(FMR) : </span>
                      <span>{upperRefer[1]}</span>
                    </p>
                    <p className="desc">
                      {_generateMessage(
                        upperRefer[1]?.split(' / ')[0],
                        upperRefer[1]?.split(' / ')[1],
                        'FMR',
                        isLiveness
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
