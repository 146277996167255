import React from 'react';
import classNames from 'classnames';
import { useEddConext } from '../../context/EddContext';
import CustomerRiskDetail from './customer/CustomerRiskDetail';
import ServiceRiskDetail from './service/ServiceRiskDetail';
import CountryRiskDetail from './country/CountryRiskDetail';
import './RiskDetail.scss';

function RiskDetail() {
  const { state } = useEddConext();
  const { detailPage } = state;

  return (
    <div className={classNames('risk-detail-container', { detail: detailPage !== 'none' })}>
      {state.detailPage === 'customer' && <CustomerRiskDetail />}
      {state.detailPage === 'service' && <ServiceRiskDetail />}
      {state.detailPage === 'country' && <CountryRiskDetail />}
    </div>
  );
}

export default RiskDetail;
