import React, { useContext, useEffect, useReducer } from 'react';
import { Button } from 'usebeauty';
import { produce } from 'immer';
import { getConfigs, getModules } from 'common/api';
import { parseConfigs } from 'common/functions';
import { M_Select } from 'common/components';
import { ContentContext } from 'layout/admin/content/Content';
import classNames from 'classnames';
import RaWeightProvider from '../context/WeightContext';
import Promotion from '../promotion/Promotion';
import Forbidden from '../forbidden/Forbidden';
import RealScoreContent from './RealScoreContent';
import './RealScoreContainer.scss';

type Data = {
  module: { edd_field: boolean };
  configs: {
    allow_ra: boolean;
    use_edd: boolean;
    use_ra: boolean;
    use_custom_field: boolean;
    edd_countries_mode: number;
  };
  template: M_Select.item;
};
export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
  scoreViewType: boolean;
};

export type Action =
  | { type: 'data'; payload: Partial<Data> }
  | { type: 'defaultData'; payload: Partial<Data> }
  | { type: 'scoreViewType'; payload: boolean };

const initialData = {
  module: { edd_field: false },
  configs: { allow_ra: false, use_edd: false, use_ra: false, use_custom_field: false, edd_countries_mode: 1 },
  template: { title: '기본 템플릿 설정', value: 'default' },
};
const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
  scoreViewType: false,
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'data': draft['data'] = { ...draft.data, ...action.payload }; break;
      case 'defaultData': draft['defaultData'] = { ...draft.defaultData, ...action.payload }; break;
      case 'scoreViewType': draft['scoreViewType'] = action.payload; break;
    }
  });
};

function RealScoreContainer() {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { module, configs } = state.defaultData;

  const loadHandler = async () => {
    setLoading(true);

    const [list, module] = await Promise.all([getConfigs(), getModules()]);
    const configs = parseConfigs(list, Object.keys(initialData.configs) as Array<keyof Data['configs']>);

    setState({ type: 'data', payload: { module, configs } });
    setState({ type: 'defaultData', payload: { module, configs } });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-ra-realscore-container">
      {module.edd_field && configs.allow_ra ? (
        <>
          <div className="header">
            <h3>위험별 점수 및 실제반영 점수</h3>
            {configs.use_edd && configs.use_ra && (
              <div className="control-pannel">
                <Button
                  type="button"
                  ripple={false}
                  className={classNames({ active: !state.scoreViewType })}
                  onClick={() => setState({ type: 'scoreViewType', payload: false })}
                >
                  간략히 보기
                </Button>
                <Button
                  type="button"
                  ripple={false}
                  className={classNames({ active: state.scoreViewType })}
                  onClick={() => setState({ type: 'scoreViewType', payload: true })}
                >
                  상세히 보기
                </Button>
              </div>
            )}
          </div>
          {configs.use_edd && configs.use_ra ? (
            <RaWeightProvider>
              <RealScoreContent edd_countries_mode={configs.edd_countries_mode} scoreViewType={state.scoreViewType} />
            </RaWeightProvider>
          ) : (
            <>
              <Forbidden
                content={
                  <>
                    <p>RA 기능을 활성화 하신 후</p>
                    <p>실제반영 점수를 확인하실 수 있습니다</p>
                  </>
                }
              />
            </>
          )}
        </>
      ) : (
        <Promotion />
      )}
    </div>
  );
}

export default RealScoreContainer;
