import React from 'react';
import classNames from 'classnames';
import type { Data as RowData } from '../../../../CtReviewTable';
import { dateFormatter, Ocr } from '../IdCard';

interface Props {
  origin: Ocr;
  modified: Ocr | null;
  rowData: RowData;
  isModified: (keys: (keyof Ocr)[] | keyof Ocr) => boolean;
}

export const ForiegnPassPort: React.FC<Props> = ({ origin, modified, rowData, isModified }) => {
  const birthDate = modified && isModified('birthDate') ? `${modified.birthDate}` : `${origin.birthDate}`;
  return (
    <>
      <div>
        <h5>· 이름</h5>
        <p className={classNames({ warning: isModified('userName') })}>{rowData.name}</p>
      </div>

      <div>
        <h5>· 여권 번호</h5>
        <p className={classNames({ warning: modified && isModified('passportNo') })}>
          {modified && isModified('passportNo') ? `${modified.passportNo}` : `${origin.passportNo}`}
        </p>
      </div>

      <div>
        <h5>· 국적</h5>
        <p className={classNames({ warning: modified && isModified('nationality') })}>
          {modified && isModified('nationality') ? `${modified.nationality}` : `${origin.nationality}`}
        </p>
      </div>

      <div>
        <h5>· 생년월일</h5>
        <p className={classNames({ warning: modified && isModified('birthDate') })}>{dateFormatter(birthDate)}</p>
      </div>
    </>
  );
};
