import React, { Dispatch, useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import classNames from 'classnames';
import styled from 'styled-components';
import { api, process } from 'common/functions';
import { Button, Input, Switch } from 'common/components';
import { ContentContext } from 'layout/root/content/Content';
import Nation from '../Nation';
import { NationRisk, Action as PropAction, State as PropState, WarnLabel } from './CtNationLabel';
import './NationLabel.scss';

const RiskContent = styled.div.withConfig({ shouldForwardProp: (props) => props === 'children' || !props })<{
  expand: boolean;
}>`
  transition: max-height 0.3s ease, padding 0.3s ease;
  border-radius: 8px;
  ${({ expand }) => {
    if (expand) return `overflow: auto; max-height: 870px; padding: 8px 16px;`;
    return `overflow: hidden; max-height: 0; padding: 0 16px;`;
  }}
}}
`;

type Action =
  | { type: 'edit'; payload: boolean }
  | { type: 'expand'; payload: boolean }
  | { type: 'data'; payload: NationRisk[] }
  | { type: 'defaultData'; payload: NationRisk[] };

type State = {
  edit: boolean;
  expand: boolean;
  data: NationRisk[];
  defaultData: NationRisk[];
};

// prettier-ignore
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'edit': draft['edit'] = action.payload; break;
      case 'expand': draft['expand'] = action.payload; break;
      case 'data': draft['data'] = action.payload; break;
      case 'defaultData': draft['defaultData'] = action.payload; break;
    }
  });
};

const initialState = {
  edit: false,
  expand: false,
  data: [],
  defaultData: [],
};

interface Props {
  warnLabel: WarnLabel;
  state: PropState;
  setState: Dispatch<PropAction>;
  loadHandler: () => void;
  warnLabelChangeHandler: (id: number, warnLabel: WarnLabel) => void;
}
function NationLabel(props: Props) {
  const { setLoading } = useContext(ContentContext);
  const { warnLabel, warnLabelChangeHandler } = props;
  const [state, setState] = useReducer(reducer, initialState);

  const isDuplicatedName =
    props.state.data.map((n) => n.name_en).filter((name) => name === warnLabel.name_en).length > 1;

  const loadHandler = () => {
    setLoading(true);
    process(
      api.reqData({ url: `warn_labels/${warnLabel.id}` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response?.data.list) {
          setState({ type: 'data', payload: response?.data.list });
          setState({ type: 'defaultData', payload: response?.data.list });
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    if (state.expand) loadHandler();
  }, [state.expand]);

  const submitHandler = () => {
    setLoading(true);

    const data = { ...warnLabel, country_ids: state.data.map((n) => n.id) };
    process(
      api.reqData({ url: `warn_labels/${warnLabel.id}`, data }),
      api.put.request,
      api.fullFilled(() => {
        setState({ type: 'edit', payload: false });
        props.setState({ type: 'edit', payload: false });
        setLoading(false);
        loadHandler();
        props.loadHandler();
      })
    );
  };

  const updateNation = (args: { score: number; nations: NationRisk[] }) => {
    warnLabelChangeHandler(warnLabel.id, { ...warnLabel, score: args.score });
    setState({ type: 'data', payload: args.nations });
  };

  return (
    <article className={classNames('nation-label', { disabled: !state.expand && !warnLabel.active && !state.edit })}>
      <div className={classNames('title', { expand: state.expand })}>
        <i
          className={classNames({ expand: state.expand })}
          onClick={() => setState({ type: 'expand', payload: !state.expand })}
        />
        <h4
          onClick={() => {
            if (!state.edit) setState({ type: 'expand', payload: !state.expand });
          }}
        >
          {state.edit ? (
            <Input
              type="text"
              className="basic"
              error={isDuplicatedName ? '이미 사용 중인 라벨명입니다.' : undefined}
              value={warnLabel.name_en}
              onChange={(e) => {
                const { value } = e.target;
                warnLabelChangeHandler(warnLabel.id, { ...warnLabel, name_en: value });
              }}
            />
          ) : (
            warnLabel.name_en
          )}
        </h4>
        <div className="active">
          {state.edit && (
            <Switch
              value={warnLabel.active}
              onChange={(value) => warnLabelChangeHandler(warnLabel.id, { ...warnLabel, active: !!value })}
            />
          )}
        </div>
        <div className={classNames('score', { edit: state.edit })}>
          <div>
            {state.edit ? (
              <>
                <Input
                  type="text"
                  className="basic"
                  styles={{ width: 56 }}
                  disabled={!state.edit}
                  value={warnLabel.score}
                  onChange={(e) => {
                    const { value } = e.target;
                    let numValue = Number(value.replace(/[^\d]/g, ''));
                    numValue = numValue > 100 ? 100 : numValue;
                    warnLabelChangeHandler(warnLabel.id, { ...warnLabel, score: numValue });
                  }}
                />
                점
              </>
            ) : (
              <span>{warnLabel.score}점</span>
            )}
          </div>
        </div>

        <div className={classNames('control', { expand: state.expand })}>
          <div className="button-group">
            {state.edit ? (
              <>
                <Button
                  text="취소"
                  onClick={() => {
                    setState({ type: 'data', payload: state.defaultData });
                    setState({ type: 'edit', payload: false });
                    props.setState({ type: 'data', payload: props.state.defaultData });
                    props.setState({ type: 'edit', payload: false });
                  }}
                />
                <Button text="저장" disabled={!state.expand || isDuplicatedName} onClick={submitHandler} />
              </>
            ) : (
              <Button
                text="목록 편집"
                disabled={props.state.edit}
                onClick={() => {
                  setState({ type: 'edit', payload: true });
                  props.setState({ type: 'edit', payload: true });
                }}
              />
            )}
          </div>
        </div>
      </div>
      <RiskContent expand={state.expand}>
        <div className="item-container">
          <div className="item-list">
            <Nation warnLabel={warnLabel} nations={state.data} edit={state.edit} updateNation={updateNation} />
          </div>
        </div>
      </RiskContent>
    </article>
  );
}

export default NationLabel;
