import { format, getQuarter } from 'date-fns';

export { DateFilter } from './DateFilter';
export { HalfYear } from './halfyear/HalfYear';
export { HeadYearComp } from './headyear/HeadYearComp';
export { MonthComp } from './month/MonthComp';
export { Quarter } from './quarter/Quarter';
export { SetPeriod } from './setperiod/SetPeriod';
export { YearComp } from './year/YearComp';
export { default as Pop_DateFilter } from './popover/Pop_DateFilter';

type T_Filter = {
  type: number;
  year: string;
  month: string;
  day: { start: string; end: string };
  halfYear: number; // 반기
  quarter: number; // 분기
};

export const initialDateFilter: T_Filter = (() => {
  const now = new Date();
  const DAY = 1000 * 60 * 60 * 24; // 1 day
  const weekAgo = new Date(now.getTime() - 7 * DAY); // 7일 전
  return {
    type: 1, // 1.기간 설정, 2.월, 3.분기, 4.반기, 5.년
    year: format(now, 'yyyy'),
    month: format(now, 'MM'),
    day: {
      start: format(weekAgo, 'yyyy-MM-dd'),
      end: format(now, 'yyyy-MM-dd'),
    },
    halfYear: getQuarter(now) < 3 ? 1 : 2,
    quarter: getQuarter(now), // 분기별
  };
})();
