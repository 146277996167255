import React, { useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Form } from '@usebeauty';
import { Validator } from 'common/components';
import { regExp } from 'common/validations';
import { produce } from 'immer';
import { api, delayed, findCustomer, process } from 'common/functions';
import { NullishGuard } from 'hoc';
import { InputCheck } from '@Icons';
import Result from './result/Result';
import './FindPw.scss';

export type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'customerLoading'; payload: boolean }
  | { type: 'customerId'; payload: number }
  | { type: 'result'; payload: { email: string; done: boolean } }
  | { type: 'success'; payload: boolean };

export interface State {
  loading: boolean;
  type: '아이디 찾기' | '비밀번호 찾기';
  customerLoading: boolean;
  customerId: number;
  result: { email: string; done: boolean };
  success: boolean;
}

const initialState: State = {
  loading: false,
  type: '아이디 찾기',
  customerLoading: false,
  customerId: -1,
  result: { email: '', done: false },
  success: true,
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
      case 'customerLoading': draft['customerLoading'] = payload; break;
      case 'customerId': draft['customerId'] = payload; break;
      case 'result': draft['result'] = payload; break;
      case 'success': draft['success'] = payload; break;
    }
  });
};

interface Props {
  children: React.ReactNode;
  set: (payload: { success?: string | JSX.Element; fail?: string }) => void;
}

const FindPw: React.FC<Props> = ({ children, set }) => {
  const { role } = useParams();
  const [state, setState] = useReducer(reducer, initialState);
  const { loading, result, customerLoading, customerId } = state;

  const {
    control,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: { customer_name: '', username: '', name: '', email: '' },
  });

  watch();

  const submitHandler = async () => {
    // 고객사가 존재할때
    if (customerId > -1) {
      setState({ type: 'loading', payload: true });
      const params = {
        customer_id: customerId,
        username: getValues('username'),
        name: getValues('name'),
        email: getValues('email'),
        from: 'kyc',
        role: role === 'admin' ? 0 : 1,
      };
      if (state.result.done) {
        set({ success: '재발송이 완료되었습니다.' });
      }

      process(
        api.reqData({ url: 'find/password', data: params }),
        api.post.request,
        api.fullFilled(({ response }) => {
          const payload = { email: '', done: true };
          setState({ type: 'result', payload });
          setState({ type: 'loading', payload: false });

          if (response) payload.email = getValues('email');

          if (response?.data.api_response.result_code === 'N100') setState({ type: 'success', payload: true });
          else setState({ type: 'success', payload: false });
        })
      );
    }
  };

  const clientCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ type: 'customerLoading', payload: true });
    const { value } = e.target;
    delayed.cancel();
    if (value) {
      delayed(async () => {
        const result = await findCustomer({ customer_name: value });
        setState({ type: 'customerId', payload: result.id });
        if (errors.customer_name) clearErrors('customer_name');

        if (result.id === -1) {
          setError('customer_name', { message: '등록되지 않은 고객사명입니다.' });
          setState({ type: 'customerId', payload: 0 });
        }
        setState({ type: 'customerLoading', payload: false });
      });
    } else {
      setState({ type: 'customerLoading', payload: false });
    }
  };

  const disabledClient = !!errors.customer_name || !getValues('customer_name') || !!errors.username || customerLoading;

  return (
    <>
      <NullishGuard data={[result.done]}>
        <Result state={state} setState={setState} submitHandler={submitHandler} loading={loading} />
      </NullishGuard>

      <NullishGuard data={[!result.done]}>
        <Validator.Provider id="kyc-find-pw-form-field" form onSubmit={submitHandler}>
          {children}
          <div className="customer-input">
            <Form.Input
              st={{ marginBottom: 10 }}
              name="customer_name"
              control={control}
              placeholder="고객사명을 입력하세요."
              onChange={clientCheckHandler}
              rules={{
                validate: {
                  unRegisteredCustomer: () => {
                    if (!getValues('customer_name').length) return true;
                    return customerId === 0 ? '등록되지 않은 고객사명입니다.' : true;
                  },
                },
              }}
            />
            {!disabledClient && <InputCheck className="check" />}
          </div>

          <Box st={{ marginTop: 16 }}>
            <Form.Input
              name="username"
              st={{ marginBottom: 10 }}
              control={control}
              placeholder="아이디"
              rules={{
                pattern: {
                  value: regExp.id,
                  message: '8~20자의 영문 대, 소문자만 사용 가능합니다.',
                },
              }}
            />
          </Box>

          <Box st={{ marginTop: 16 }}>
            <Form.Input
              name="name"
              control={control}
              placeholder="이름"
              rules={{
                pattern: {
                  value: regExp.name,
                  message: '한글, 영문 대/소문자, 빈칸만 사용 가능합니다.',
                },
              }}
            />
          </Box>

          <Box st={{ marginTop: 16 }}>
            <Form.Input
              name="email"
              control={control}
              placeholder="이메일"
              rules={{
                pattern: {
                  value: regExp.email,
                  message: '입력 형식이 올바르지 않습니다.',
                },
              }}
            />
          </Box>

          <Validator.Submit
            text="다음"
            disabled={
              disabledClient || !getValues('username') || !getValues('name') || !getValues('email') || !!errors.email
            }
            loading={loading}
            styles={{ width: '100%', height: 56, radius: 8 }}
          />
        </Validator.Provider>
      </NullishGuard>
    </>
  );
};

export default FindPw;
