import React, { Dispatch, useState } from 'react';
import { ModalPortal } from 'common/components/module/lib/modal/ModalPortal';
import { validateLogoImage } from 'common/components/module/lib/functions';
import classNames from 'classnames';
import { Action, Data } from '../../ThemeSettingContent';

const MAX_FILE_SIZE = 500 * 1024; // 500KB
const IMAGE_MAX_WIDTH = 100;
const IMAGE_MAX_HEIGHT = 20;
const errorMessage: { [key: string]: { title: string; content: string[] } } = {
  FE001: {
    title: '파일 형식 오류',
    content: ['지원하지 않는 형식의 파일입니다.', 'PNG, JPG, GIF, SVG 파일만 첨부할 수 있습니다.'],
  },
  FE002: {
    title: '파일 형식 오류',
    content: ['파일의 확장자와 형식이 일치하지 않습니다.', '올바른 파일을 첨부해 주세요.'],
  },
  FE003: {
    title: '용량 초과',
    content: [`첨부 가능한 파일 용량을 초과하였습니다.`, `${MAX_FILE_SIZE / 1024}KB 이하만 첨부가 가능합니다.`],
  },
  FE004: {
    title: '이미지 해상도 오류',
    content: [
      `지원하지 않는 해상도의 이미지입니다.`,
      `이미지는 최대 ${IMAGE_MAX_WIDTH}*${IMAGE_MAX_HEIGHT}px 이내만 가능합니다.`,
    ],
  },
};

interface Props {
  datas: Data;
  setState: Dispatch<Action>;
}
const LogoCustom = ({ datas, setState }: Props) => {
  const [error, setError] = useState({ title: '', content: [''] });
  const logoChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        const { message } = await validateLogoImage(file);

        setState({
          type: 'setData',
          payload: { ...datas, use_custom_logo: true, custom_logo: message, base64String: message },
        });
      } catch (e) {
        const { type } = e as { type: string };
        setError({ ...errorMessage[type] });
      }
    }
  };

  const isCustomLogo = datas.custom_logo.indexOf('data:image') > -1;

  return (
    <>
      <div className="logo-input-container">
        <div className={classNames('input', { logo: isCustomLogo })}>
          <input
            type="file"
            id="logo_input"
            onChange={logoChangeHandler}
            accept="image/png,image/jpeg,image/gif,image/svg+xml"
          />
          {isCustomLogo ? (
            <>
              <div className="image">
                <img src={datas.custom_logo} alt="logo" />
              </div>
              <label htmlFor="logo_input">수정</label>
            </>
          ) : (
            <label className="required" htmlFor="logo_input">
              + 파일 업로드
            </label>
          )}
        </div>
        <div className="description">
          <p>{`이미지 해상도 ${IMAGE_MAX_WIDTH}*${IMAGE_MAX_HEIGHT} 이내 (SVG는 제한없음)`}</p>
          <p>{`PNG, JPG, GIF, SVG / 최대 ${MAX_FILE_SIZE / 1024}KB`}</p>
        </div>
      </div>
      {error.title && (
        <ModalPortal setToggle={() => setError({ title: '', content: [''] })}>
          {({ closeHandler }) => (
            <div className="logo__image__error">
              <h2>{error.title}</h2>
              {error.content.map((v) => (
                <p key={v}>{v}</p>
              ))}
              <button type="button" onClick={closeHandler}>
                확인
              </button>
            </div>
          )}
        </ModalPortal>
      )}
    </>
  );
};

export default LogoCustom;
