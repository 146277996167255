import { admin, reviewer, root } from 'store';

export * from './root';
export * from './admin';
export * from './reviewer';

export const authorization = () => {
  const location = window.location.pathname.split('/').filter((el) => el)[0];
  let auth = null;
  if (location === 'root') auth = root.store.getState().auth;
  if (location === 'admin') auth = admin.store.getState().auth;
  if (location === 'reviewer') auth = reviewer.store.getState().auth;

  return { Authorization: `Bearer ${auth?.data?.token}` };
};
