import React from 'react';

export const Loading: React.FC = (): JSX.Element => {
  return (
    <div className="jack__loading">
      <div className="jack__loading__back" />
      <div className="loading__spinner__wrap">
        <div className="loading__spinner" />
      </div>
    </div>
  );
};

export const Spiner: React.FC = (): JSX.Element => (
  <div className="jack__loding__spinner">
    <div className="jack__loding__inner__spinner" />
  </div>
);

export const Proccessing = () => {
  return (
    <div className="jack__proccessing__loding__type">
      <div className="on__proccessing">
        <div className="rect__aling_1" />
        <div className="rect__aling_2" />
        <div className="rect__aling_3" />
        <div className="rect__aling_4" />
        <div className="rect__aling_5" />
      </div>
    </div>
  );
};

export const Waiting: React.FC<{ loading: boolean }> = ({ loading }): JSX.Element => {
  return loading ? (
    <div className="jack__waiting__loading">
      <div className="waiting_first" />
      <div className="waiting_second" />
      <div className="waiting_third" />
    </div>
  ) : (
    <></>
  );
};
