import React from 'react';
import './NotAllowedPage.scss';

export const NotAllowedPage = () => {
  return (
    <div className="not__allowed__page">
      <i className="icon" />
      <p>페이지 접근 권한이 없습니다.</p>
      <p>해당 기능을 사용하려면 고객센터로 연락해 주세요.</p>
    </div>
  );
};
