import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classNames from 'classnames';
import styled from 'styled-components';
import update from 'immutability-helper';
import { Alert, Button, Modal, Switch } from 'common/components';
import { EddRa } from '../../../context/model.type';
import { useEddConext } from '../../../context/EddContext';
import { addRaData } from '../../settings/util';
import RiskItem from '../../settings/RiskItem';
import RiskAddItem from '../../settings/RiskAddItem';
import './ServiceRiskDetail.scss';

const RiskContent = styled.div`
  transition: max-height 0.3s ease, padding 0.3s ease;
  border-radius: 8px;
}}
`;

function ServiceRiskDetail() {
  const { set } = Alert.Context();
  const { edit, use_ra, state, setState, deleteHandler } = useEddConext();
  const { service, edd_category } = state.data;
  const serviceCategory = edd_category.find((category) => category.edd_type === 'service') as EddRa.EddCategory;

  const addRaItem = (item: EddRa.Service) => {
    setState({ type: 'service', payload: [...service, item] });
  };

  const updateRaItem = (item: EddRa.Service) => {
    const nextData = service.map((el) => (el.id === item.id ? item : el));
    setState({ type: 'service', payload: nextData });
  };

  const removeRaItem = (item: EddRa.Service) => {
    const removed = service.filter((el) => el.id !== item.id);
    deleteHandler({ category: 'service', id: item.id });
    setState({ type: 'service', payload: removed });
    set({ success: '항목이 삭제되었습니다.' });
  };

  const moveRaItem = (dragIndex: number, destIndex: number) => {
    if (dragIndex > service.length) return;
    const nextOrderedRa = update(service, {
      $splice: [
        [dragIndex, 1],
        [destIndex, 0, service[dragIndex]],
      ],
    });
    setState({ type: 'service', payload: nextOrderedRa });
  };

  const updateRaRisk = (value: boolean) => {
    if (!value) {
      // TODO: service, 되돌려야 함
      const { service } = state.defaultData;
      setState({ type: 'data', payload: { ...state.data, service } });
    }
    setState({ type: 'eddCategory', payload: { edd_type: serviceCategory.edd_type, value } });
  };

  const totalScore = service.reduce((acc, cur) => acc + cur.score, 0);
  const availableScore = 100 - totalScore;
  const atLeastOneActive = service.some((el) => el.active);

  const errorMessage = (() => {
    if (serviceCategory.active && !atLeastOneActive) return '최소 1개 항목은 활성화 되어야 합니다.';
    if (use_ra && availableScore !== 0) return '남은 점수는 0점이 되어야 합니다.';
    return '';
  })();

  return (
    <article className={classNames('edd-setting-service-risk-detail', { disabled: !serviceCategory.active })}>
      <div className="title">
        <i onClick={() => setState({ type: 'detailPage', payload: 'none' })} />
        <h4>상품 및 서비스 위험</h4>
        <div className="error-message">{errorMessage && <div className="message">{errorMessage}</div>}</div>
        {use_ra ? (
          <div className={classNames('score', { edit })}>
            <div>
              <span className={classNames({ hide: !edit })}>부여 점수</span>
              <span>{totalScore}점</span>
            </div>
            <div>
              {edit && (
                <>
                  <span>남은 점수</span>
                  <span className={classNames('extra-score', { error: availableScore !== 0 })}>{availableScore}점</span>
                </>
              )}
            </div>
          </div>
        ) : (
          edit && (
            <div className="active_switch">
              <Switch
                disabled={use_ra || !edit}
                value={serviceCategory.active}
                onChange={(value) => updateRaRisk(!!value)}
              />
            </div>
          )
        )}
      </div>
      <RiskContent>
        <div className="item-container">
          <DndProvider backend={HTML5Backend}>
            <div className="item-list">
              {service.length &&
                service.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <RiskItem
                        item={item}
                        index={index}
                        edit={edit}
                        active={item.active && serviceCategory.active}
                        use_ra={use_ra}
                        updateRaItem={updateRaItem}
                        removeRaItem={removeRaItem}
                        moveRaItem={moveRaItem}
                        activeComp={
                          <Switch
                            disabled={!serviceCategory.active}
                            value={item.active}
                            onChange={(value) => {
                              updateRaItem({ ...item, active: !!value, score: !value ? 0 : item.score });
                            }}
                          />
                        }
                      />
                    </React.Fragment>
                  );
                })}
            </div>
          </DndProvider>
          {edit && serviceCategory.active && (
            <Modal
              content={({ closeHandler }) => (
                <RiskAddItem
                  item={addRaData(service.length, 'service')}
                  addRaItem={addRaItem}
                  raItemNames={service.map((el) => el.name)}
                  closeHandler={closeHandler}
                />
              )}
            >
              <Button className="item-append" type="button">
                <>
                  <i />
                  항목 추가하기
                </>
              </Button>
            </Modal>
          )}
        </div>
      </RiskContent>
    </article>
  );
}

export default ServiceRiskDetail;
