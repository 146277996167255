import React from 'react';
import { T_RaResult } from '../../../Expand';

function RaResult({ ra_result, isExpired }: { ra_result: NonNullable<T_RaResult>; isExpired: boolean }) {
  const { ra_grade, ra_score, customer_score, customer_std_score, svc_score, svc_std_score, country_score, country_std_score } = ra_result; // prettier-ignore

  const raGradeMap = { low: '저위험', middle: '중위험', high: '고위험' } as const;

  return (
    <article className="reviewer-review-ra-result">
      <h5>RA (고객 위험도 평가)</h5>
      <div className="info-section">
        <div className="ra-result">
          <div>
            <h4>총 점</h4>
            {isExpired ? <p className="expired">저장 기간 만료</p> : <p className={`${ra_grade}`}>{`${ra_score}점`}</p>}
          </div>
          <div>
            <h4>위험도</h4>
            {isExpired ? (
              <p className="expired">저장 기간 만료</p>
            ) : (
              <p className={`${ra_grade}`}>{raGradeMap[ra_grade]}</p>
            )}
          </div>
        </div>
        {!isExpired && (
          <div className="ra-result-table">
            <div className="ra-result-table-head">
              <div className="ra-result-table-row">
                <span>위험 종류</span>
                <span>점수</span>
                <span>/ 배점</span>
              </div>
            </div>
            <div className="ra-result-table-body">
              <div className="ra-result-table-row">
                <h4>고객 위험</h4>
                <span>{customer_score} 점</span>
                <span>/ {customer_std_score}점</span>
              </div>
              <div className="ra-result-table-row">
                <h4>상품 및 서비스 위험</h4>
                <span>{svc_score} 점</span>
                <span>/ {svc_std_score}점</span>
              </div>
              <div className="ra-result-table-row">
                <h4>국가 위험</h4>
                <span>{country_score} 점</span>
                <span>/ {country_std_score}점</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </article>
  );
}

export default RaResult;
