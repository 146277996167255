import React, { useState } from 'react';
import classNames from 'classnames';
import { api, process } from 'common/functions';
import { Button, Modal } from 'common/components';
import { ReactComponent as Loading } from 'assets/Loading_40px_black.svg';
import { T_CustomFieldArray } from '../../../Expand';

const getValue = (type: string, value: string) => {
  if (type === 'tree' && value) {
    const trees = JSON.parse(value);
    return trees.join(' - ');
  }
  if (type === 'addr' && value) {
    const [country, ...address] = JSON.parse(value);
    return [country, address.join(' ')];
  }
  return value;
};

function Survey({ survey, isExpired }: { survey: NonNullable<T_CustomFieldArray>; isExpired: boolean }) {
  const [image, setImage] = useState<string>('');

  const downloadHandler = (id: number | undefined, file_name: string | undefined) => {
    if (id === undefined) return;
    if (!file_name) return;

    const type = file_name.split('.')[file_name.split('.').length - 1];

    function downloadBase64File(contentType: string, base64Data: string, fileName: string) {
      const linkSource = `data:${contentType};base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('target', '_blank');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    process(
      api.reqData({ url: `attachments/${id}` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          let contentType = '';
          if (type === 'pdf') {
            contentType = 'application/pdf';
            downloadBase64File(contentType, response.data.data, file_name);
          } else {
            contentType = `image/${type}`;
            setImage(response.data.data);
          }
        }
      })
    );
  };

  const LoadImage = (arg: { image: string; name: string }) => {
    if (!arg.image) return <Loading />;
    return <img style={{ width: '100%' }} src={`data:image/jpeg;base64,${image}`} alt={`${arg.name}-img`} />;
  };

  return (
    <article className="reviewer-result-survey">
      <h5>추가 설문</h5>
      <ul className="info-section">
        {survey.map((field, index) => {
          const { key, type, attachment } = field;
          const value = isExpired ? '저장 기간 만료' : getValue(type, field.value);
          return (
            <li key={`${field.key}_${index}`}>
              <div className="label">
                <h4>{key}</h4>
              </div>
              {type === 'file' ? (
                <>
                  {!attachment && <p className="disabled">첨부 파일 없음</p>}
                  {attachment && (
                    <>
                      {!attachment.name.includes('pdf') && (
                        <Modal
                          content={({ closeHandler }) => {
                            return (
                              <div className={classNames(['reviewer-image-section-modal', { expired: isExpired }])}>
                                <i className="close-btn" onClick={closeHandler} />
                                {isExpired ? (
                                  <i className="image-expired" />
                                ) : (
                                  <LoadImage image={image} name={attachment.name} />
                                )}
                              </div>
                            );
                          }}
                        >
                          <Button type="button" onClick={() => downloadHandler(attachment.id, attachment.name)}>
                            <p>첨부 사진 보기</p>
                          </Button>
                        </Modal>
                      )}
                      {attachment.name.includes('pdf') && (
                        <Button type="button" onClick={() => downloadHandler(attachment.id, attachment.name)}>
                          <p>PDF 다운로드</p>
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className={`value ${isExpired ? 'expired' : ''}`}>
                  {type === 'addr' && !isExpired ? (
                    <>
                      <p>{value[0]}</p>
                      <p>{value[1]}</p>
                    </>
                  ) : (
                    value
                  )}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </article>
  );
}

export default Survey;
